import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { DatePicker, Icon, useClickOutside, FilterDropdownContainer, Input } from 'scorer-ui-kit';
import format from 'date-fns/format';
import { DATE_FORMAT } from '../constants';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

const DateTimeInput = styled(Input)`
  width: 300px;
  margin: 1.5px 0;
  padding-right: 33px;
  padding-left: 10px;
  padding: 0 33px 0 10px;
  position: relative;
  height: 40px;
  flex: 1;
`;

const DateIconWrapper = styled.div`
  margin: 15px -18px -2px -29px;
  z-index: 1;
`;

const DateDropdown = styled.div`
  position: relative;
  margin-top: 6px;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: rgba(120, 138, 144, 0.72);
  margin: 0 25px 0 0;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  padding: 0;
  line-height: 1.4;
`;

const DateItemContainer = styled.div`
  margin-right: 17px;
  width: 300px;
`;

const IconInputContainer = styled.div`
  display: flex; 
  flexDirection: row;
`;

const DateDrawer = styled(FilterDropdownContainer)`
  position: absolute;
  top: 50px;
  z-index: 100;
  left: 0;
`;

interface IInterval {
  start: Date,
  end: Date
}

interface IProps {
  labelText: string;
  date: Date;
  callBackFunction: (date: Date | IInterval) => void;
}

const DateInput = ({ labelText, date, callBackFunction }: IProps) => {
  const [showDateDrawer, setShowDateDrawer] = useState(false);
  const { t } = useTranslation(['CommonDict']);
  const dateRef = useRef<HTMLDivElement>(null);

  useClickOutside(dateRef, () => {
    setShowDateDrawer(false);
  });

  const handleDateClick = () => {
    setShowDateDrawer((showDateDrawer) => !showDateDrawer);
  };

  return (
    <DateItemContainer>
      <LabelText>{labelText}</LabelText>
      <DateDropdown ref={dateRef}>
        <IconInputContainer onClick={handleDateClick}>
          <DateTimeInput readOnly value={format(date, DATE_FORMAT) + ' JST'} />
          <DateIconWrapper>
            <Icon icon='DateTime' size={17} color='primary' />
          </DateIconWrapper>
        </IconInputContainer>
        {showDateDrawer && (
          <DateDrawer>
            <DatePicker initialValue={date} dateMode='single' timeMode='single' dateTimeTextUpper={t(labelText)} updateCallback={callBackFunction} lang={i18n.language === 'ja' ? 'ja' : 'en'} timeZoneTitle={t('Timezone')} />
          </DateDrawer>
        )}
      </DateDropdown>
    </DateItemContainer>
  );
};

export default DateInput;
