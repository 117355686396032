import React, { useCallback, useState } from 'react';

interface IProps {}

interface IContext {
  isAlertSwitchEnabled: boolean,
  isAlertSettingsUpdated: boolean,
  getNewAlertsSettings: (value: boolean) => void,
  toggleAlertsSwitch: (value: boolean) => void
}

export const AlertDrawerContext = React.createContext<IContext>({
  isAlertSwitchEnabled: false,
  isAlertSettingsUpdated: false,
  getNewAlertsSettings: () => {},
  toggleAlertsSwitch: () => {}
});

const AlertSwitchProvider: React.FC<IProps> = ({children}) => {
  const [isAlertSwitchEnabled, setIsAlertSwitchEnabled] = useState(false);
  const [isAlertSettingsUpdated, setIsAlertSettingsNew] = useState(false);

  const toggleAlertsSwitch = useCallback((value: boolean) => {
    setIsAlertSwitchEnabled(value);
  }, []);

  const getNewAlertsSettings = useCallback((value: boolean) => {
    setIsAlertSettingsNew(value);
  }, []);

  return (
    <AlertDrawerContext.Provider
      value={{isAlertSwitchEnabled, isAlertSettingsUpdated, toggleAlertsSwitch, getNewAlertsSettings}}
    >
      {children}
    </AlertDrawerContext.Provider>
  );
};

export default AlertSwitchProvider;