import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import TokenService from 'services/tokenService';

export default function SecureRoute({...routeProps}: RouteProps) {
  if(TokenService.getUser()) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to='/login' />;
  }
}