import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, INotificationProps, SmallInput, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';

const ModalImportHeader = styled.div`
  text-align: center;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const ImportInput = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  gap: 0 15px;
`;

const CancelButton = styled(Button)`
  background: #e4edf4;
  :hover:enabled {
    background: #d4e4f0;
  }
`;

const ImportButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 15px;
`;

const ImportExportInput = styled(SmallInput)`
  width: 100%;
  & > label {
    width: 100%;
    margin-bottom: 0;
    & > span {
      margin-bottom: 0;
    }
  }
`;

const ModalContainer = styled.div`
  padding: 20px;
  min-width: 400px;
  min-height: 160px;
  position: relative;
`;

interface IProps {
  onImportCallback: (data: any) => void;
  sendNotification: (newNotification: INotificationProps) => void;
}

const ImportSettingsModal: React.FC<IProps> = ({ onImportCallback, sendNotification }) => {

  const [selectedFile, setSelectedFile] = useState<FileList | undefined>();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation(['CommonDict']);
  const { setModalOpen } = useModal();

  const onBrowse = useCallback(() => {
    if (fileInput.current !== null)
      fileInput.current.click();
  }, []);

  const onChangeFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length !== 0) {
      setSelectedFile(event.target.files);
    } else {
      setSelectedFile(undefined);
    }
  }, []);

  const onImport = useCallback(() => {
    if (!selectedFile) {
      sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Please select a file')})));
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile[0], 'UTF-8');
    fileReader.onload = e => {
      onImportCallback(e.target);
    };
  }, [selectedFile, t, sendNotification, onImportCallback]);

  return (
    <ModalContainer>
      <ModalImportHeader>{t('Please select JSON file')}</ModalImportHeader>
      <ImportInput>
        <input type='file' ref={fileInput} onChange={onChangeFile} accept='.json' style={{ display: 'none' }} />
        <ImportExportInput onClick={onBrowse} label='' value={selectedFile ? selectedFile[0].name : ''} placeholder={t('No files selected')} readOnly />
        <Button style={{ flexShrink: '0' }} onClick={onBrowse} size='small'>{t('Browse')}</Button>
      </ImportInput>
      <ImportButton>
        <CancelButton design='secondary' size='small' onClick={() => setModalOpen(false)}>{t('Cancel')}</CancelButton>
        <Button onClick={onImport} design='primary' size='small'>{t('Import')}</Button>
      </ImportButton>
    </ModalContainer>
  );
};

export default ImportSettingsModal;