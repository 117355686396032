import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Common from './locales/Common.json';
import Application from './locales/Application.json';

import LanguageDetector from 'i18next-browser-languagedetector';

const mergedJa = {
  ...Common,
  ...Application
};

export interface IJsonData {
  [key: string]: string
}

const mergedEn:IJsonData = {};
Object.keys(mergedJa).map((key: string) =>{
  mergedEn[key]=key;
  return 0;
});

const resources = {
  en: {
    CommonDict: mergedEn
  },
  ja: {
    CommonDict: mergedJa
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) 
  .init({
    resources,
    fallbackLng: 'ja',
    keySeparator: false, 
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;