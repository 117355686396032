import { useEffect, useState } from 'react';


export const useOnRefreshAlert = () => {
  const [message] = useState<string>('');
  const [continueShow, setContinueShow] = useState<boolean>(false);

  useEffect(() => {
    if (!continueShow) {
      return;
    }
  }, [continueShow]);
  return { continueShow, message, setContinueShow };
};
