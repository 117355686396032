import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {  ButtonWithLoading, TextField, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import { EDGE_API_BASE_URL } from '../constants';
import axios from 'axios';
import i18n from 'i18n';

const SetupContainer = styled.div`
  width: 100%;
  max-width: 937px;
  padding: 35px 10px 30px 40px;
`;

const TextFieldRequired = styled.div<{isJapanese: boolean}>`
  span::after {
    font-weight: bold;
    color: rgb(238, 75, 43);
  }
  label {
    display: flex;
    gap: 30px;
    align-items: center;
    align-items: flex-end;
  }
  span {
    min-width: ${({isJapanese}) => isJapanese ? '170px' : '96px'};
  }
  input {
    width: 360px;
  }

`;

const InputFieldBox = styled.div`
  margin-top: 20px;
  min-width: 380px;
`;

const HeaderButton = styled.div`
  display: flex;
  margin-top: 2px;
  display: flex;
  justify-content: flex-end;
`;

const SetupIntro = styled.div`
  font-size: 15.8px;
  line-height: 2.1;
`;

const InputFieldBoxAlign = styled.div`
  width: max-content;
`;

const ADVANCE_SECTION_DATA = {
  type: 'safie_settings',
  enabled: true,
  configuration_time: '',
  refresh_token: '',
  client_id: '',
  client_secret: '',
  access_token_status: '',
};

interface IAdvanceSectionData {
  client_id: string;
  client_secret: string;
  refresh_token: string;
  type: string;
  enabled: boolean;
  configuration_time: string;
  access_token_status: string;
}

interface IAdvanceSafieInegration {
    otp:string,
    getConfigDetails:() => void;
}

const AdvanceSafieInegration: React.FC<IAdvanceSafieInegration>  = ({otp, getConfigDetails}) => {

  const [advanceSectionData, setAdvanceSectionData] = useState<IAdvanceSectionData>(ADVANCE_SECTION_DATA);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeHandler = useCallback(({ target: { name, value } }) => {
    if (name === 'client_id') {
      setAdvanceSectionData((prev) => ({ ...prev, client_id: value }));
    } else if (name === 'client_secret') {
      setAdvanceSectionData((prev) => ({ ...prev, client_secret: value }));
    } else {
      setAdvanceSectionData((prev) => ({ ...prev, refresh_token: value }));
    }
  }, []); 
  
  const isEmpty = useCallback((val) => {
    return (val === undefined || val.trim() === '');
  }, []);

  const onSaveAdvance = (typeOfAutorization: string) => {
    setLoading(true);
    const jsonData = Object.assign({}, advanceSectionData);
    const safieEnable = jsonData.enabled;

    const finalJson: any = {};
    finalJson.type = 'safie';
    finalJson.namespace = 'system';
    finalJson.enabled = safieEnable;
    finalJson.services = {};
    finalJson.services.safie_settings1 = {};
    finalJson.services.safie_settings1.enabled = true;
    finalJson.services.safie_settings1.type = 'safie_settings';
    finalJson.services.safie_settings1.type_of_authorization =
      typeOfAutorization;

    if (typeOfAutorization === 'advanced') {
      const refreshToken = jsonData.refresh_token;
      const clientId = jsonData.client_id;
      const clientSecret = jsonData.client_secret;

      if (isEmpty(clientId)) {
        setLoading(false);
        notificationRef.current({
          type: 'error',
          message: tRef.current('Please enter client id'),
        });
        return;
      }
      if (isEmpty(clientSecret)) {
        setLoading(false);
        notificationRef.current({
          type: 'error',
          message: tRef.current('Please enter client secret'),
        });
        return;
      }
      if (isEmpty(refreshToken)) {
        setLoading(false);
        notificationRef.current({
          type: 'error',
          message: tRef.current('Please enter refresh token'),
        });
        return;
      }
      finalJson.services.safie_settings1.refresh_token = refreshToken;
      finalJson.services.safie_settings1.client_id = clientId;
      finalJson.services.safie_settings1.client_secret = clientSecret;
    } else if (typeOfAutorization === 'otp') {
      if (isEmpty(otp)) {
        setLoading(false);
        notificationRef.current({
          type: 'error',
          message: tRef.current('Please enter One-time Password'),
        });
        return;
      }
      finalJson.services.safie_settings1.otp = otp;
    } else {
      setLoading(false);
      return;
    }
    axios
      .put(EDGE_API_BASE_URL + 'system/safie/all', finalJson)
      .then(() => {
        notificationRef.current({
          type: 'success',
          message: tRef.current('Safie Connector configured successfully'),
        });
        setLoading(false);
        getConfigDetails();
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response) {
          const errorObj = error.response.data;
          if(errorObj?.error?.message === '(invalid_client)No client could be found'){
            notificationRef.current({type: 'error', message: tRef.current('Invalid client ID')});
            return;
          }else if(errorObj?.error?.message === '(invalid_client)Invalid client credentials'){
            notificationRef.current({type: 'error', message: tRef.current('Invalid client credentials')});
            return;
          }else if (errorObj?.error?.message === '(invalid_request)Invalid refresh token'){
            notificationRef.current({type: 'error', message: tRef.current('Invalid refresh token')});
            return;
          }
        } else {
          notificationRef.current({type: 'error', message: tRef.current('Unable to connect to the edge-api service.')});
        }
      });
  };

  return (
    <>
      <SetupContainer>
        <SetupIntro>
          {t('Use your own credentials instead of a one-time password obtained from Safie Connector, you can enter your credentials for advanced usage.')}
        </SetupIntro>
        <InputFieldBoxAlign>
          <InputFieldBox>
            <TextFieldRequired isJapanese={i18n.language === 'ja'}>
              <TextField
                tabIndex={1}
                name='client_id'
                label={t('Client ID')}
                fieldState='default'
                onChange={onChangeHandler}
                value={advanceSectionData.client_id}
                maxLength={255}
              />
            </TextFieldRequired>
          </InputFieldBox>

          <InputFieldBox>
            <TextFieldRequired isJapanese={i18n.language === 'ja'}>
              <TextField
                tabIndex={1}
                name='client_secret'
                label={t('Client Secret')}
                fieldState='default'
                onChange={onChangeHandler}
                value={advanceSectionData.client_secret}
                maxLength={255}
              />
            </TextFieldRequired>
          </InputFieldBox>

          <InputFieldBox>
            <TextFieldRequired isJapanese={i18n.language === 'ja'}>
              <TextField
                tabIndex={1}
                name='refresh_token'
                label={t('Refresh Token')}
                fieldState='default'
                onChange={onChangeHandler}
                value={advanceSectionData.refresh_token}
                maxLength={255}
              />
            </TextFieldRequired>
          </InputFieldBox>
          <HeaderButton>
            <ButtonWithLoading
              onClick={() => onSaveAdvance('advanced')}
              disabled={
                advanceSectionData.refresh_token === '' ||
                advanceSectionData.client_secret === '' ||
                advanceSectionData.client_id === ''
              }
              loading={loading}
            >
              {t('Save')}
            </ButtonWithLoading>
          </HeaderButton>
        </InputFieldBoxAlign>  
      </SetupContainer>
    </>
  );
};

export default AdvanceSafieInegration;