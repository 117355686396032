import React from 'react';
import { Icon, Spinner } from 'scorer-ui-kit';
import styled from 'styled-components';

const StatusContainer = styled.div`
  min-width: 160px;
  width: max-content;
  height: 60px;
  display: flex;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #eee;
  background-color: #fcfcfc;
  overflow-y: hidden;
`;

interface IStausColor {
  bgColor: string;
}

const StatusColor = styled.div<IStausColor>`
  width: 5px;
  height: 60px;
  background-color: ${({bgColor}) => bgColor };
`;

interface IPaddingRigt {
  isPaddingRight: boolean;
}

const DetailsContainer = styled.div<IPaddingRigt>`
  width: 100%;
  padding: 10px 9px 9px 16px;
  ${({isPaddingRight}) => !isPaddingRight && 'padding-right: 24px;'}
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const StatusTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #8b9196;
`;

const Details = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #788b91;
`;

interface IStatusProps {
  title: string;
  details?: string;
  showInfo?: boolean;
  statusColor: string;
  notConfigured?: boolean;
  isLoader?: boolean;
}

const StatusBox: React.FC<IStatusProps> = ({title, details, showInfo = false, statusColor, notConfigured, isLoader})=> {

  return (
    <StatusContainer>
      <StatusColor bgColor={statusColor} />
      <DetailsContainer isPaddingRight={showInfo}>
        <StatusTitle>
          {title}
          {showInfo && <Icon size={12} color='dimmed' icon='Question' />}
        </StatusTitle>
        {isLoader ?
          <Spinner size='small' styling='secondary' />
          : <Details>{notConfigured ? '-' : details}</Details>}
      </DetailsContainer>
    </StatusContainer>
  );
};

export default StatusBox;
