import React, {useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'scorer-ui-kit';
import styled from 'styled-components';

const Container = styled.div`
  margin: -5px 0;
`;

const InputFieldContainer = styled.div`
  display: flex;
  position: relative;
  top: 51px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 864px;
`;

const InputFieldBox = styled.div`
  min-width: 100%;
`;

const TextFieldRequired = styled.div`
  span::after{
    font-weight: bold;
    content: " *";
    color: rgb(238, 75, 43);
  }
`;

interface IProps {
  hlsUrl: string;
  onHlsUrlChange: (url: string) => void
}

const HLSCamera: React.FC<IProps> = ({hlsUrl, onHlsUrlChange}) => {

  const { t } = useTranslation(['CommonDict']);

  const onChangeHandler = useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    onHlsUrlChange(value);
  }, [onHlsUrlChange]);

  return (
    <Container>
      <InputFieldContainer>
        <InputFieldBox>
          <TextFieldRequired>
            <TextField
              tabIndex={4}
              name='rtsp_url'
              label={t('URL')}
              placeholder='https://hls-server.example.com/'
              fieldState='default'
              onChange={onChangeHandler}
              maxLength={255}
              value={hlsUrl}
              autoComplete='off'
            />
          </TextFieldRequired>
        </InputFieldBox>
      </InputFieldContainer>
    </Container>
  );
};

export default HLSCamera;