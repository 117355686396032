import React, {useCallback, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';
import i18n from 'i18n';

const ModalContainer = styled.div`
  padding: 20px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const ConfirmText = styled.p<{maxWidth: string}>`
  max-width: ${({maxWidth}) => maxWidth};
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(2) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  > #DisableButton:focus {
    outline: 2px solid #838383;
  }

  > #EnableButton:focus {
    outline: 2px solid #838383;
  }
`;

interface IProps {
  onCallback: (isEnabled: boolean) => void
}

const DisableConfirmationModal: React.FC<IProps> = ({onCallback = () => {}}) => {

  const {t} = useTranslation(['CommonDict']);
  const {isModalOpen} = useModal();

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('EnableButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('DisableButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('DisableButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'DisableButton') {
          document.getElementById('EnableButton')?.focus();
          return;
        }
        document.getElementById('DisableButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('EnableButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const handleButtonClick = useCallback((isEnabled: boolean) => {
    onCallback(isEnabled);
  }, [onCallback]);

  return (
    <ModalContainer>
      <ConfirmText maxWidth={i18n.language === 'ja' ? 'auto' : '450px'}>{t('Configuration is not enabled. Do you want to enable it while saving changes?')}</ConfirmText>
      <ModalButtonContainer>
        <Button id='EnableButton' onClick={() => handleButtonClick(true)}>{t('Enable Feature')}</Button>
        <Button id='DisableButton' design='secondary' onClick={() => handleButtonClick(false)}>{t('Keep Disabled')}</Button>
      </ModalButtonContainer>
    </ModalContainer>
  );
};

export default DisableConfirmationModal;