import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Button, Content, Icon, PageHeader, Spinner, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import { getConfiguration } from 'services/camerasService';
import { IConfigurationData } from 'interface';
import { ENABLE_ADAVANCE_LOW_SPEED_ANALYSIS } from '../../constants';

const MainContainer = styled(Content)`
  @media screen and (min-width: 1440px) {
    padding: 62px 0 62px 170px;
  }
  @media screen and (max-width: 1439px) {
    padding: 62px 0 62px 80px;
  }
  > div {
    max-width: 944px !important;
  }
`;

const UpperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  margin-top: -21px;
  padding: 0;
  p {
    margin-top: -14px;
  }
`;

const PageHeaderDiv = styled.div`
  & > div > div > a {
    width: 162px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > span:nth-child(2){
    line-height: 1.3 !important;
  }
  & > span:nth-child(2) > div > div > h1{
    margin: 0 0 6px !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px 0;
  & > button {
    width: 100px;
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const AreaContainer = styled.div`
  min-width: 200px;
  max-width: 750px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
`;

const HeadTitle = styled.span`
  & div > div > div > div {
    margin-top: -8px;
  }
`;

const Divider = styled.div<{margin: string}>`
  height: 1px;
  margin: ${({margin}) => margin};
  border-radius: 3px;
  background-color: #efefef;
`;

const StreamAnalysisContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
`;

const StreamDetectionHeader = styled.span`
  height: 19px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 16px;
  font-weight: 500;
  color: #5a6269;
`;

const StreamDetectionText = styled.span`
  height: 25px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  line-height: 1.79;
  color: #8b9196;
  margin-top: 11px;
`;

const BoxContainer = styled.div`
  padding-top: 21px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const BoxWrapper = styled.div`
  position: relative;
`;

const Box = styled.div`
  width: 301px;
  height: 154.4px;
  margin-top: 6.6px;
  padding: 26.4px 0 17px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(123, 123, 123, 0.28);
  background-color: #fff;
  cursor: pointer;
`;

const ConfigurationContainer = styled.div`
  display: flex ;
  justify-content: space-between;
  cursor: pointer;
`;

const ConfigurationHeader = styled.span`
  height: 16px;
  margin: 4px 0px 19px 22px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal; 
  line-height: normal;
  letter-spacing: 0.4px;
  color: #949799;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: 2px 10px 15px 0px;
  padding: 3px;
  cursor: pointer;
`;

const BoxDivider = styled.div`
  width: 301px;
  height: 1px;
  margin: 0px 0 21px;
  background-color: #f2f2f2;
`;

const ConfigurationText = styled.div`
  width: 242px;
  height: 50px;
  margin: -2px 27px 0 22px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  line-height: 1.79;
  color: #8b9196;
`;

const DetectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 54px;
`;

const EnableContainer = styled.div<{ background: boolean, hideEnabled: boolean }>`
  display: ${({hideEnabled}) => hideEnabled ? 'none' : 'flex'};
  padding: 0 5px;
  justify-content: center;
  height: 14.1px;
  top: 0px;
  left: 14px;
  bottom: 0;
  position: absolute;
  border-radius: 5px;
  background-color: ${({ background }) => background ? '#80b5ff' : '#d9d9d9'} ;
`;

const EnableText = styled.span`
  height: 11px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #fff;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const LabelText = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
`;

interface IConfigProps {
  hideEnabled?: boolean,
  disabled: boolean,
  path: string,
  headerText: string,
  infoText: string
}

const ConfigBox: React.FC<IConfigProps> = ({hideEnabled = false, disabled = false, path = '', headerText = '', infoText = ''}) => {
  const { push } = useHistory();
  const { t } = useTranslation(['CommonDict']);

  return (
    <BoxWrapper>
      <Box onClick={() => { push(path); }}>
        <EnableContainer hideEnabled={hideEnabled} background={!disabled}><EnableText>{t(`${disabled ? 'Disabled' : 'Enabled'}`)}</EnableText> </EnableContainer>
        <ConfigurationContainer>
          <ConfigurationHeader>{t(headerText)}</ConfigurationHeader>
          <IconWrapper><Icon color='dimmed' icon='Right' size={18} weight='regular' /></IconWrapper>
        </ConfigurationContainer>
        <BoxDivider />
        <ConfigurationText>{t(infoText)}</ConfigurationText>
      </Box>
    </BoxWrapper>
  );
};

const CameraConfiguration = () => {
  const { push } = useHistory();
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const [configurationData, setConfigurationData] = useState<IConfigurationData>();
  const [loading, setLoading] = useState(true);
  const { streamName }: { streamName: string } = useParams();
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);

  const streamAnalysisList: IConfigProps[] = useMemo(() => {
    return ([
      {
        disabled: !(configurationData?.exclusion_area_enabled),
        path: '/cameras/camera-configuration/' + streamName + '/exclusion-zones',
        headerText: 'Exclusion Zones',
        infoText: 'Exclude certain areas of the stream from affecting analysis.'
      },
      {
        disabled: !(configurationData?.alert_suppression_enabled),
        path: '/cameras/camera-configuration/' + streamName + '/alert-suppression',
        headerText: 'Alert Suppression',
        infoText: 'Stop alerts from occuring with scheduled suppression.'
      },
      {
        hideEnabled: true,
        disabled: false,
        path: '/cameras/camera-configuration/' + streamName + '/hit-point',
        headerText: 'Hit Point',
        infoText: 'Configure point within bounding box which will be considered for analysis.'
      },
    ]);
  }, [configurationData, streamName]);

  const configList: IConfigProps[] = useMemo(() => {
    return ([
      {
        disabled: !(configurationData?.traffic_counter_enabled),
        path: '/cameras/camera-configuration/' + streamName + '/traffic-counter',
        headerText: 'Traffic Counting',
        infoText: 'Detects when a vehicle or person crosses a detection line.'
      },
      {
        disabled: !(configurationData?.congestion_and_stop_detection_enabled),
        path: '/cameras/camera-configuration/' + streamName + '/congestion-stop',
        headerText: 'Congestion & Stop',
        infoText: 'Detects when traffic has become congested or stopped unexpectedly.'
      },
      {
        disabled: !(configurationData?.low_speed_detection_enabled),
        path: '/cameras/camera-configuration/' + streamName + '/low-speed-detection',
        headerText: 'Low Speed',
        infoText: 'Detects when the speed of traffic has dropped.'
      },
      ...(ENABLE_ADAVANCE_LOW_SPEED_ANALYSIS ?
        [{
          disabled: !(configurationData?.advanced_low_speed_detection_enabled),
          path: '/cameras/camera-configuration/' + streamName + '/advanced-low-speed-detection',
          headerText: 'Advanced - Low Speed',
          infoText: 'Advanced options for being able to detect speed drops in traffic.'
        }]
        : []),
      {
        disabled: !(configurationData?.frequent_lane_change_enabled),
        path: '/cameras/camera-configuration/' + streamName + '/frequent-lane',
        headerText: 'Frequent Lane Change',
        infoText: 'Detect when vehicles are frequently changing lanes.'
      },
      {
        disabled: !(configurationData?.reverse_run_enabled),
        path: '/cameras/camera-configuration/' + streamName + '/reverse-run-detection',
        headerText: 'Reverse Run',
        infoText: 'Detect when a vehicle is moving in the wrong direction.'
      }
    ]);
  }, [configurationData, streamName]);

  const fetchConfiguration = useCallback(async () => {
    try {
      const resp = await getConfiguration(streamName);
      if (resp.data.status_code === 0) {
        setConfigurationData(resp.data.data);
      } else {
        sendNotificationRef.current({
          type: 'error',
          message: tRef.current('Failed to load configuration details')
        });
      }
    } catch (error) {
      sendNotificationRef.current({
        type: 'error',
        message: tRef.current('Failed to communicate with the system')
      });
    } finally {
      setLoading(false);
    }
  }, [streamName]);

  useEffect(() => {
    fetchConfiguration();
  }, [fetchConfiguration]);

  const goToPreviousPage = useCallback(() => {
    push('/cameras/camera-details/' + streamName + '/overview');
  }, [push, streamName]);

  return (
    <MainContainer>
      <UpperContainer>
        <PageHeaderDiv>
          <AreaContainer onClick={goToPreviousPage}>{configurationData?.display_name ? configurationData?.display_name : '-'}</AreaContainer>
          <HeadTitle>
            <PageHeader title={t('Configuration')} icon='Analyse' updateDocTitle={false} />
          </HeadTitle>
        </PageHeaderDiv>
        <ButtonContainer>
          <Button
            design='secondary'
            size='small'
            onClick={goToPreviousPage}
          >
            {t('Back')}
          </Button>
        </ButtonContainer>
      </UpperContainer>
      <Divider margin='0' />
      {loading ?
        <SpinnerContainer>
          <Spinner size='large' styling='primary' />
          <LabelText>{t('Loading')}</LabelText>
        </SpinnerContainer> :
        <>
          <StreamAnalysisContainer>
            <StreamDetectionHeader>{t('Stream Analysis Settings')}</StreamDetectionHeader>
            <StreamDetectionText>{t('Settings here will effect the behaviour of all analysis running on this stream.')}</StreamDetectionText>
            <BoxContainer>
              {
                streamAnalysisList.map(({hideEnabled = false, disabled, path, headerText, infoText}, index) => {
                  return (<ConfigBox key={index} {...{hideEnabled, disabled, path, headerText, infoText}} />);
                })
              }
            </BoxContainer>
          </StreamAnalysisContainer>
          <DetectionContainer>
            <StreamDetectionHeader>{t('Detection Options and Configuration')}</StreamDetectionHeader>
            <BoxContainer>
              {
                configList.map(({disabled, path, headerText, infoText}, index) => {
                  return (<ConfigBox key={index} {...{disabled, path, headerText, infoText}} />);
                })
              }
            </BoxContainer>
          </DetectionContainer>
        </>}
    </MainContainer>
  );
};

export default CameraConfiguration;