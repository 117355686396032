import Pagination from 'components/Pagination';
import { ISortBy, ISortItem } from 'components/molecules/SortBy';
import { BASE_API_URL, DATE_FORMAT_DASH_WITHOUT_SEC, DATE_FORMAT_WITHOUT_SEC, ENABLE_DOWNLOAD_VIDEO, alertStatusList, analysisTypeList } from '../constants';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, ButtonWithIcon, ButtonWithLoading, DateInterval, FilterBar, IFilterDatePicker, IFilterDropdownConfig, IFilterItem, IFilterResult, IFilterValue, IconButton, SortDropdown, TypeTable, useNotification } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StatusContext } from 'App';
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';
import { downloadFile, downloadZipFile, formatDate, getSelectedDate, readParams, updateParams } from 'utils';
import { format } from 'date-fns';
import { IAlertDownloadPayload, IAlertHistoryDismissPayload, IAlertHistoryDismissedData, IAlertHistoryParams } from 'interface';
import { dismissedAlertHistory, dismissedSingleAlertHistory, downloadSelectedAlerts, downloadVideo, getAlertsHistory } from 'services/camerasService';
import i18n from 'i18n';
import TokenService from 'services/tokenService';

const AlertHistoryTabContainer = styled.div``;

const FilterAndDismiss = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const SortBox = styled.div`
  display: flex;
  align-self:center;
  justify-content: space-between;
  align-items: flex-end;
  white-space: nowrap;
  margin-top: -16px;
  button > div > div:nth-child(2){
    min-width: 150px;
  }
`;

const FilterbarWrapper = styled.div<{width: string}>`
  width: ${({width}) => width};
  height: 130px;
  > div > div:first-child {
    margin-bottom: 14px;
    margin-left: 1px;
    letter-spacing: -0.2px;
  }
  > div > div:nth-child(2) {
    flex-wrap: nowrap;
    > :nth-child(1) {
      margin-left: 0;
    }
  }
  > div > div:last-child {
    margin-top: 20px;
    margin-left: 2px;
  }
`;

const BlurredDiv = styled.div`
  height: 59px;
  position: absolute;
  bottom: 0;
  top: -30px;
  left: -100%;
  right: -5%;
  opacity: 0.8;
  filter: blur(1px);
  background-color: rgb(248 249 250);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1;
`;

const Actions = styled.div`
  flex-grow: 1;
  height: 30px;
  margin-top: 8px;
  margin-left: 3px;
`;

const DismissButtonContainer = styled.div`
  > button {
    padding: 0 15px 0 20px;
    background: #e4edf4 !important;
    &:hover:enabled {
      background: #d4e4f0 !important;
    }
    > div {
      > :nth-child(1) {
        padding-left: 11px;
      }
    }
  }
`;

const DismissSortingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: 'relative';
  margin-top: 32px;
  gap: 24px;
  min-height: 84px;
`;

const ActionButtons = styled.div<{enabledVideo:boolean}>`
  display: flex;
  justify-content: ${({enabledVideo})=>enabledVideo ? 'space-between' : 'flex-end'};
  width: 100%;
  margin-top: 2px;
  padding-right: 4.5px;
`;

const ActionsMainContainer = styled.div`
  position: sticky;
  top: -6%;
  z-index: 1;
`;

const Action = styled.span`
  width: 137px;
  height: 10px;
  margin-left: 3px;
  position: relative;
  bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  color: hsl(207,5%,57%);
  z-index: 2;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: end;
  & > button {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const VideoButtonWithIcon = styled(ButtonWithIcon)`
  margin-right: 5px;
`;

const DownloadButtonWithIcon = styled(ButtonWithIcon)`
  margin-right: 5px;
`;

const DownloadButtonWithLoading = styled(ButtonWithLoading)`
  height: 100%;
  margin-right: 5px;
`;

const SelectedResults = styled.span`
  height: 14px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  color: hsl(207,5%,57%);
  margin-top:-20px;
`;

const FinishButton = styled(Button)`
  height: 30px;
  align-self: flex-end;
  padding: 0px 20px;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-image: linear-gradient(111deg, #71c3ed, #5baced);
`;

const ActionFinishContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const ResultTextSortContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 38px;
  padding-bottom: 14px;
  padding-left: 3px;
  > div {
    padding-top: 2px;
  }
`;

const TextDiv = styled.div<({maxWidth?: string})>`
  line-height: 1.2;
  max-width: ${({maxWidth}) => maxWidth};
  font-size: 12px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px 0;
  padding: 9px 0;
`;

const MessageTitle = styled.div`
  font-size: 10px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fontFamily.data };
  color: #a0bfcb;
  line-height: 1.2;
`;

const StatusButton = styled.button`
  width: 110px;
  height: 25px;
  font-size: 13px;
  border-radius: 3px;
  background-color: #fbfafd;
  border: none;
  color: #9097a8;
  cursor: pointer;
`;

const StatusActiveButton = styled.button`
  width: 110px;
  height: 25px;
  font-size: 13px;
  border-radius: 3px;
  background-color: #fbfafd;
  border: none;
  color: rgb(255, 90, 90);
  font-weight: 700;
  cursor: pointer;
`;

const IgnoredButton = styled.button`
  width: 110px;
  height: 25px;
  font-size: 13px;
  border-radius: 3px;
  background-color: #fbfafd;
  border: none;
  color: #9097a8;
  font-style: italic;
`;

const PaginationContainer = styled.div`
  margin-bottom: 30px;
`;

const TableContainer = styled.div`
  margin: 33px 0 0 -28px;
  > div > div > div > div > div > :nth-child(2) {
    z-index: 0;
  }
`;

interface IAppFilter {
  [key: string]: DateInterval | string | number | undefined | Date | IFilterItem[] | boolean
}

const defaultFilterState: IAppFilter = {
  alertStatus: undefined,
  analysisName: undefined,
  cameraName: undefined,
  startDate: undefined,
  endDate: undefined,
  pageSize: localStorage.getItem('preferredPageSizeCamAlerts') === null ? 10 : parseInt(localStorage.getItem('preferredPageSizeAlerts') as string),
  sortOrder: 'desc',
  sortBy: 'timestamp',
  paramsLoaded: false
};

interface IAlertHisorty{
  id: string | number,
  alert_type: string,
  timestamp: number,
  message: string,
  video_name: string,
  status: number
}

const initialRows: ITypeTableData = [
  {
    columns: []
  }
];

const getSelectedFilter = (value: DateInterval | undefined | string | number | Date, filterType: string) => {
  switch (filterType) {
  case 'type':
    return analysisTypeList.find(item => item.value === value);
  case 'status':
    return alertStatusList.find(item => item.value === value);
  }
};

interface ISelectedStream {
  [key: string]: boolean
}

interface IProps {
  selectedTab: string;
  camName: string;
  streamName: string;
  fetchActiveCountCallBack: (params: IAlertHistoryParams, isRecall?: boolean) => void;
}

const CameraAlertsTab: React.FC<IProps> = ({selectedTab = '', camName = '', streamName = '',fetchActiveCountCallBack = () => {}}) => {

  const [finish , setFinish]=useState(true);
  const [isDownloadStarted, setIsDownloadStarted] = useState<boolean>(false);
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const [alertHistory, setAlertHistory] = useState<IAlertHisorty[]>([]);
  const {setActiveAlertTimePages, alertData} = useContext(StatusContext);
  const [sorting, setSorting] = useState<ISortBy>({
    sort: 'timestamp',
    ascending: false,
    title: t('Sort By Date/Time')
  });
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const localPageSize = localStorage.getItem('preferredPageSizeCamAlerts');
  const [pageSize, setPageSize] = useState(localPageSize === null ? 10 : parseInt(localPageSize as string));
  const sizeOptions = [10, 20, 50, 100];
  const {sendNotification} = useNotification();
  const notificationRef = useRef(sendNotification);
  const [filterValues, setFilterValues] = useState<IAppFilter>(defaultFilterState);
  const [selectRow, setSelectRow]=useState(0);
  const { push } = useHistory();
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [selectedData, setSeletedData]=useState<IRowData[]>([]);
  const [selectedStreams, setSelectedStreams] = useState<ISelectedStream>({});
  const [resultCount, setResultCount] = useState<number>(0);
  const [dismissCount, setDismissCount]=useState(0);
  const [activeCount, setActiveCount]=useState<number>(0);
  const token = TokenService.getLocalAccessToken();
  const [loading, setLoading] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const params = useLocation().search;
  const [historyParams] = useState(params);

  useEffect(()=>{
    let title = 'Sort By Date/Time';
    if(filterValues.sortBy === 'timestamp'){
      title = 'Sort By Date/Time';
    }
    else if(filterValues.sortBy === 'status'){
      title = 'Sort By Alert Status';
    }
    setSorting({
      title,
      ascending: filterValues.sortOrder === 'asc',
      sort: filterValues.sortBy as string
    });
  },[filterValues]);

  const handleFinish = useCallback((data) =>{
    const newRows = [...data];
    newRows.forEach((row) => {
      row._checked = false;
    });
    setRowData(newRows);
    setSelectRow(0);
    setSeletedData([]);  
    setSelectedStreams({});
    setFinish(true);
  },[]);

  const columnConfig : ITableColumnConfig[] = [
    {
      cellStyle: 'firstColumn',
      columnId: 'message',
      groupTitle: tRef.current('Detection Meta'),
      header: tRef.current('Message'),
      sortActive: true,
      sortable: false,
    },
    {
      cellStyle: 'normalImportance',
      columnId: 'when',
      hasCopyButton: false,
      groupTitle: tRef.current('Detection Meta'),
      header: tRef.current('When'),
      sortable: false,
      minWidth: 140
    },
    {
      alignment: 'center',
      cellStyle: 'normalImportance',
      columnId: 'alertStatus',
      hasCopyButton: false,
      header: tRef.current('Alert Status'),
      minWidth: 120,
    },
    {
      alignment: 'right',
      cellStyle: 'normalImportance',
      columnId: 'actions',
      hasCopyButton: false,
      header: '',
      minWidth: 60,
    },
  ];

  const dateConfig:IFilterDatePicker[] = [
    {
      buttonIcon: 'DateTime',
      buttonText: tRef.current('Date Range'),
      dateMode: 'interval',
      id: 'datePickerForRuntime',
      timeMode: 'interval',
      lang: i18n.language === 'ja' ? 'ja' : 'en',
      timeZoneTitle: tRef.current('Timezone'),
      dateTimeTextUpper: tRef.current('From'),
      dateTimeTextLower: tRef.current('To'),
      selected: getSelectedDate({start: filterValues.startDate, end: filterValues.endDate}) as DateInterval | undefined,
    },
  ];

  const dropdownConfig: IFilterDropdownConfig[] = [
    {
      id: 'analysisName',
      buttonText: t('AnalysisType').replace('Analysis', ''),
      list: analysisTypeList.map(({text, value}) => ({text : t(text), value})) as IFilterItem[],
      buttonIcon: 'Analyse',
      optionType:'radio',
      selected: getSelectedFilter(filterValues.analysisName as number, 'type') as IFilterValue | undefined
    },
    {
      id: 'alertStatus',
      buttonText: t('Alert Status'),
      list: alertStatusList.map(({text, value}) => ({text : t(text), value})) as IFilterItem[],
      buttonIcon: 'Notifications',
      optionType:'radio',
      selected: getSelectedFilter( filterValues.alertStatus as number, 'status') as IFilterValue | undefined
    },
  ];

  const getDropdownConfig = () => {
    return dropdownConfig.filter((item) => {
      return item.id === 'analysisName' || item.id === 'alertStatus' || item.id === 'datePickerForRuntime';
    });
  };

  useEffect(() => {
    if (selectedTab === 'overview') return;
    setFilterValues(readParams(historyParams, defaultFilterState));
  }, [historyParams, selectedTab]);

  useEffect(() => {
    if (!filterValues.paramsLoaded) return;
    push({ search: updateParams(filterValues) });
  }, [push, filterValues, selectedTab]);

  const fileDownload = useCallback((fileUrl: string, timestamp: number, fileType: 'mp4'|'jpg', analysisType: string) => {
    if(fileType === 'mp4'){
      fileUrl = fileUrl + '?t=' + new Date().getTime();
    }
    axios.get<Blob>(fileUrl, {responseType: 'blob'}).then((res) => {
      if(res.data && res.data.type !== 'text/html'){
        const file = new Blob([res.data]);
        const _url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = _url;
        link.setAttribute('download', `${
          streamName.length > 180 ? streamName.slice(0, 180) + '...' : streamName
        }_${analysisType}_${format(new Date(timestamp*1000), 'yyyyMMdd_HHmmss')}_JST.${fileType}`);
        document.body.appendChild(link);
        link.click();
        notificationRef.current({
          type: 'success',
          message: t('Downloading started successfully')
        });
      } else {
        notificationRef.current({
          type: 'error',
          message: fileType==='mp4' ? t('Video is not generated yet. Please check after sometime.') : t('Fail to download')
        });
      }
    }).catch(err => {
      console.error(err);
      notificationRef.current({
        type: 'error',
        message: t('Failed to communicate with the system')
      });
    });
  },[t, streamName]);

  const getAction = useCallback((imgURL: string, vidName: string, timestamp: number, analysisType: string) => {
    const vidUrl = '/data/archives/videos/' + vidName;
    return (
      <ActionButtons enabledVideo={ENABLE_DOWNLOAD_VIDEO}>
        {ENABLE_DOWNLOAD_VIDEO ? <IconButton title={t('Download Video')} onClick={() => fileDownload(vidUrl, timestamp, 'mp4', analysisType)} icon='DownloadVideo' /> : ''}
        <IconButton title={t('Download Image')} onClick={() => fileDownload(imgURL, timestamp, 'jpg', analysisType)} icon='Download' />
      </ActionButtons>
    );
  },[fileDownload, t]);

  const selectedDownloadVideo = useCallback(async() =>{
    try {
      const dismissArr: number[] = [];
      const selectedRow = [...selectedData];
      selectedRow.map((item)=>{
        dismissArr.push(item.id as number);
        return item;
      });
      const payload ={
        action: 'mp4_download',
        data: dismissArr
      };
      const resData = await downloadVideo(streamName + '_alerts_video_download', payload);
      const downloadStatus = await downloadZipFile('/data/downloads/' + resData?.data?.download_filename);
      if(downloadStatus){
        notificationRef.current({ type: 'success', message: t('Downloading started successfully') });
      } else {
        notificationRef.current({ type: 'error', message: t('Fail to download') });
      }

      const newRows = [...rowData];
      newRows.forEach((row) => {
        row._checked = false;
      });
      setRowData(newRows);
      setSelectRow(0);
      setSeletedData([]);
      setSelectedStreams({});
      return true;
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    }
    setSeletedData([]);
    setSelectedStreams({});
  },[ selectedData, rowData, t, streamName]);

  const fetchAlertHistory = useCallback(async(params)=>{
    setLoading(true);
    setFilterLoading(true);
    try{
      const { data } = await getAlertsHistory(camName, params);
      setActiveCount(data.active_count as number);
      setResultCount(data.count);
      const totalCount = Math.abs(Math.ceil(data.total_count / data.page_size));
      setTotalPages(totalCount <= 0 ? 1 : totalCount);
      setAlertHistory(data.data);
    } catch (error) {
      console.error(error);
      setResultCount(0);
      setAlertHistory([]);
    } finally {
      setLoading(false);
    }
  },[camName]);

  const getApiParams = useCallback(() => {
    const {pageSize = 10, cameraName, alertStatus, analysisName, sortOrder = 'asc', sortBy = 'display_name', startDate, endDate} = filterValues;

    const params: IAlertHistoryParams = {
      page_size: pageSize as number,
      page: currentPage - 1,
      sort_by: sortBy as string,
      sort_order: sortOrder as string,

      ...(alertStatus !== undefined ? { status: alertStatus as number } : {}),
      ...(analysisName !== undefined ? { analysis_name: analysisName as number } : {}),
      ...(cameraName !== undefined ? { stream_display_name: cameraName as string } : {}),
      ...(endDate !== undefined ? { end_date: endDate as Date } : {}),
      ...(startDate !== undefined ? { start_date: startDate as Date } : {}),
    };

    return params;
  }, [currentPage, filterValues]);

  useEffect(() => {
    if(selectedTab === 'alerts'){
      if (!filterValues.paramsLoaded) return;
      fetchAlertHistory(getApiParams());
    }
  }, [fetchAlertHistory, getApiParams, selectedTab, localPageSize, filterValues, alertData]);

  const handleFilter = useCallback((res: IFilterResult[]) => {
    setFilterLoading(true);
    const searchFilter: IAppFilter = {
      alertStatus: undefined,
      analysisName: undefined,
      startDate: undefined,
      endDate: undefined,
    };
    res.forEach((item) => {
      if(item.id === 'datePickerForRuntime') {
        searchFilter.startDate = format((item.selected as DateInterval).start, (DATE_FORMAT_DASH_WITHOUT_SEC + ':00'));
        searchFilter.endDate = format((item.selected as DateInterval).end, (DATE_FORMAT_DASH_WITHOUT_SEC + ':59'));
      } else {
        searchFilter[item.id] = (item.selected as IFilterItem).value as string;
      }
    });
    setFilterValues(prev => ({...searchFilter, pageSize: prev.pageSize, sortOrder: prev.sortOrder, sortBy: prev.sortBy, paramsLoaded: true}));
    setCurrentPage(1);
  },[]);

  const handleDismissed = useCallback(async(id:string | number) =>{
    try {
      const dismissArr: IAlertHistoryDismissedData[] = [];
      
      const item ={
        id: id,
        status: 1
      };
      dismissArr.push(item);
        
      const payload: IAlertHistoryDismissPayload ={
        action: 'update_status',
        data: dismissArr
      };
      //setFilterLoading(true);
      const res = await dismissedSingleAlertHistory(payload);
      if(res.data.status_code === 0) {
        Object.keys(alertHistory).map((item:string) => {
          const val = parseInt(item);
          if(alertHistory[val].id === id) {
            const updateAlertList:IAlertHisorty[] = [...alertHistory];
            updateAlertList[val].status=1;
            setAlertHistory(updateAlertList);
            setActiveAlertTimePages(new Date().getMilliseconds());
            fetchActiveCountCallBack({}, true);
          }
          return item;
        });
        setLoading(false);
        //setFilterLoading(false);
      } else {
        notificationRef.current({ type: 'error', message: t('Dismissed Error') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: t('Dismissed Error') });
    }
  },[t,alertHistory, setActiveAlertTimePages,fetchActiveCountCallBack]);

  const handleActive = useCallback(async(id:string | number) =>{
    try {
      const dismissArr: IAlertHistoryDismissedData[] = [];
      
      const item ={
        id: id,
        status: 0
      };
      dismissArr.push(item);
        
      const payload: IAlertHistoryDismissPayload ={
        action: 'update_status',
        data: dismissArr
      };
      //setFilterLoading(true);
      const res = await dismissedSingleAlertHistory(payload);
      if(res.data.status_code === 0) {
        Object.keys(alertHistory).map((item:string) => {
          const val = parseInt(item);
          if(alertHistory[val].id === id) {
            const updateAlertList:IAlertHisorty[] = [...alertHistory];
            updateAlertList[val].status=0;
            setAlertHistory(updateAlertList);
            setActiveAlertTimePages(new Date().getMilliseconds());
            fetchActiveCountCallBack({}, true);
          }
          return item;
        });
        setLoading(false);
        //setFilterLoading(false);
      } else {
        notificationRef.current({ type: 'error', message: t('Active Error') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: t('Active Error') });
    }
  },[t,alertHistory, setActiveAlertTimePages,fetchActiveCountCallBack]);

  const handleAlertStatus = useCallback((id:string | number) =>{
    const selectedItem = alertHistory.filter((item, i)=>{
      return id === i;
    });
    if(selectedItem){
      const dismiss_id = document.getElementById('status_1' + id);
      const active_id = document.getElementById('status_2' + id);
      dismiss_id && (dismiss_id.style.backgroundColor = ' #e4edf4');
      dismiss_id && (dismiss_id.style.fontStyle = 'normal');
      dismiss_id && (dismiss_id.style.fontWeight = '700');
      dismiss_id && (dismiss_id.innerText = t('Mark Active'));

      active_id && (active_id.style.backgroundColor = ' #e4edf4');
      active_id && (active_id.style.fontStyle = 'normal');
      active_id && (active_id.style.color = '#9097a8');
      active_id && (active_id.style.fontWeight = 'normal');
      active_id && (active_id.innerText = t('Dismiss'));
    }
  },[alertHistory, t]);

  const handleHover = useCallback((id:string | number) => {
    const selectedItem = alertHistory.filter((item, i)=>{
      return id === i;
    });
    if(selectedItem){
      const dismiss_id = document.getElementById('status_1' + id);
      const active_id = document.getElementById('status_2' + id);
      dismiss_id && (dismiss_id.style.backgroundColor = '');
      dismiss_id && (dismiss_id.style.fontStyle = 'normal');
      dismiss_id && (dismiss_id.style.color = '#9097a8');
      dismiss_id && (dismiss_id.style.fontWeight = 'normal');
      dismiss_id && (dismiss_id.innerText = t('Dismissed'));

      active_id && (active_id.style.backgroundColor = '');
      active_id && (active_id.style.fontStyle = 'bold');
      active_id && (active_id.style.fontWeight = '700');
      active_id && (active_id.style.color = 'rgb(255, 90, 90)');
      active_id && (active_id.innerText = t('Active'));
    }
  },[alertHistory, t]);

  const getAlertStatus = useCallback((item) =>{
    return(
      <>
        {
          item.status === 0 ?
            <StatusButton key={item.id} value={item.id} id={'status_1'+item.id} onMouseOver={()=> handleAlertStatus( item.id)} onMouseOut={()=>handleHover(item.id)} onClick={()=>handleDismissed(item.id)}>{t('Dismissed')}</StatusButton> :
            item.status===1 ? 
              <StatusActiveButton key={item.id} id={'status_2'+item.id} onMouseOver={()=> handleAlertStatus(item.id)} onMouseOut={()=>handleHover(item.id)} onClick={()=>handleActive(item.id)}>{t('Active')}</StatusActiveButton>
              : <IgnoredButton key={item.id}>{t('Ignored')}</IgnoredButton>
        }
      </>
    );
  },[handleActive, handleAlertStatus, handleDismissed, handleHover, t]);

  const getAlertTableColumns = useCallback((item, imgUrl) =>{
    let messageTitle = '', messageBody = '';

    if (item.message !== undefined && (item.message as string).includes('#')) {
      const messageArr = (item.message as string).split('#');
      messageTitle = messageArr[0];
      messageBody = messageArr[1];
    }

    return(
      [
        {customComponent:
          (item.message !== undefined && (item.message as string).includes('#')) ?
            <MessageContainer>
              <MessageTitle>{messageTitle}</MessageTitle>
              <TextDiv maxWidth='calc(100% - 20px)'>{messageBody}</TextDiv>
            </MessageContainer>
            : <TextDiv maxWidth='calc(100% - 20px)'>{item.message}</TextDiv>
        },
        {text: formatDate(item.timestamp, DATE_FORMAT_WITHOUT_SEC) + ' JST'},
        {customComponent: getAlertStatus(item)},
        {customComponent: getAction(imgUrl, item.video_name, item.timestamp, item.alert_type)}
      ]
    );
  },[getAction, getAlertStatus]);

  useEffect(()=>{
    if (alertHistory.length === 0) return;
    let countForActive=0;
    let countForDismiss=0;
    alertHistory.forEach((item)=>{
      selectedData.forEach((data)=>{
        if(item.id===data.id){
          if(item.status===1){
            countForActive=countForActive+1;
          }
          if(item.status===0){
            countForDismiss=countForDismiss+1;
          }
          setDismissCount(countForDismiss);
        }
      });
    });
    
  },[selectedData, alertHistory, selectedTab]);

  const generateRowData = useCallback((): ITypeTableData => {
    const _rows: ITypeTableData = alertHistory.map(item => {
      const imgUrl = `${BASE_API_URL}alerts/images/${item.id}?access_token=${token}`;
      const _item: IRowData = {
        id: item.id,
        _checked: selectedStreams[item.id] || false ,
        header:{
          image: imgUrl,
          mediaUrl: imgUrl,
          mediaType: 'img'
        },
        columns: getAlertTableColumns(item, imgUrl)
      };
      return _item;
    });
    return _rows.length > 0 ? _rows : initialRows;
  }, [token, alertHistory, getAlertTableColumns, selectedStreams]);

  useEffect(() => {
    if (loading) return;
    if(selectedTab === 'alerts') {
      if (alertHistory.length > 0) {
        setRowData(generateRowData());
      } else {
        setRowData(initialRows);
      }
      setFilterLoading(false);
    }
  }, [generateRowData, selectedTab, loading, alertHistory.length]);

  const toggleAllCallback = useCallback((checked: boolean) => {
    alertHistory.forEach(({id}) => {
      if(id){
        setSelectedStreams((selectedStreams) => ({ ...selectedStreams, [id]: checked }));
      }
    });
    const newRows = [...rowData];
    const alldata: ITypeTableData = newRows.map((row) => {
      row._checked = checked;
      return row;
    });
    setRowData(newRows);
    if(checked){
      setSelectRow(alldata.length);
      setSeletedData(alldata);
    }else{
      setSelectRow(0);
      setSeletedData([]);
      setSelectedStreams({});
    }
  },[rowData, setRowData, alertHistory]);

  const selectCallback = useCallback((checked: boolean, id?: string | number) => {
    if(id){
      setSelectedStreams((selectedStreams) => ({ ...selectedStreams, [id]: checked }));
    }
    const newRows = [...rowData];
    const targetRowIndex = newRows.findIndex((row) => row.id === id);
    newRows[targetRowIndex]._checked = checked;
    setRowData(newRows);
    if(checked){
      setSelectRow(selectRow+1);
      setSeletedData([...selectedData,newRows[targetRowIndex]]);
    }else{
      setSelectRow(selectRow-1);
      setSeletedData(prev => prev.filter((item)=>{
        return item.id !== newRows[targetRowIndex].id;
      }));
    }
  },[ rowData, setRowData, selectRow, selectedData]);

  const selectRowDismiss = useCallback(async() =>{
    try {
      const dismissArr: IAlertHistoryDismissedData[] = [];
      const selectedRow = [...selectedData];
      selectedRow.map((item)=>{
        const data ={
          id: item.id,
          status: 0
        };
        dismissArr.push(data);
        return item;
      });
      const payload: IAlertHistoryDismissPayload ={
        action: 'update_status',
        data: dismissArr
      };
      const res = await dismissedSingleAlertHistory(payload);
      if(res.data.status_code === 0) {
        fetchAlertHistory(getApiParams());
        setActiveAlertTimePages(new Date().getMilliseconds());
        fetchActiveCountCallBack({}, true);
        notificationRef.current({ type: 'success', message: t('Alerts dismissed successfully') });
      } 
      else {
        notificationRef.current({ type: 'error', message: t('Failed to dismiss alert') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: t('Failed to dismiss alert') });
    }
    setSeletedData([]);
    setSelectedStreams({});
    setSelectRow(0);
  },[getApiParams, fetchAlertHistory, t, selectedData, setActiveAlertTimePages,fetchActiveCountCallBack]);

  const selectRowActive = useCallback(async() =>{
    try {
      const dismissArr: IAlertHistoryDismissedData[] = [];
      const selectedRow = [...selectedData];
      selectedRow.map((item)=>{
        const data ={
          id: item.id,
          status: 1
        };
        dismissArr.push(data);
        return item;
      });
      const payload: IAlertHistoryDismissPayload ={
        action: 'update_status',
        data: dismissArr
      };
      const res = await dismissedSingleAlertHistory(payload);
      if(res.data.status_code === 0) {
        fetchAlertHistory(getApiParams());
        setActiveAlertTimePages(new Date().getMilliseconds());
        fetchActiveCountCallBack({}, true);
        notificationRef.current({ type: 'success', message: t('Alerts Active successfully') });
      } 
      else {
        notificationRef.current({ type: 'error', message: t('Failed to dismiss alert') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: t('Failed to dismiss alert') });
    }
    setSeletedData([]);
    setSelectedStreams({});
    setSelectRow(0);
  },[getApiParams, fetchAlertHistory, t, selectedData, setActiveAlertTimePages,fetchActiveCountCallBack]);

  const onClickDismissAll = useCallback(async() => {
    setSeletedData([]);
    setSelectRow(0);
    setSelectedStreams({});
    setLoadingSpinner(true);
    try {
      const res = await dismissedAlertHistory(camName,'dismiss');
      if(res.data.status_code === 0) {
        setActiveAlertTimePages(new Date().getMilliseconds());
        fetchAlertHistory(getApiParams());
        fetchActiveCountCallBack({}, true);
        notificationRef.current({ type: 'success', message: t('All alerts dismissed successfully') });
      } else {
        notificationRef.current({ type: 'error', message: t('Failed to dismiss alert') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: t('Failed to dismiss alert') });
    }
    setLoadingSpinner(false);
  }, [camName, t, getApiParams, fetchAlertHistory, setActiveAlertTimePages,fetchActiveCountCallBack]);

  const onDownloadAlerts = useCallback(async () => {
    try {
      setIsDownloadStarted(true);
  
      const selectedRow = selectedData.map((item)=>{
        return item.id as number;
      });
      const payload: IAlertDownloadPayload ={
        language: i18n.language === 'ja' ? 'jp' : 'en',
        alerts_ids: selectedRow
      };
  
      const resData = await downloadSelectedAlerts(payload);

      if (resData === undefined || resData.data === undefined || resData.data.data === undefined) {
        setIsDownloadStarted(false);
        notificationRef.current({ type: 'error', message: t('Failed to download') });
        return;
      }

      if (resData.data.message === 'Success') {
        const downloadStatus = await downloadFile(resData.data.data);
        
        if(downloadStatus){
          notificationRef.current({ type: 'success', message: t('Downloading started successfully') });
          toggleAllCallback(false);
          setIsDownloadStarted(false);
        } else {
          setIsDownloadStarted(false);
          notificationRef.current({ type: 'error', message: t('Failed to download') });
        }
      } else {
        setIsDownloadStarted(false);
        notificationRef.current({ type: 'error', message: t((resData.data.message + '').replace('.', '')) });
      }
    } catch (error) {
      console.error(error);
      setIsDownloadStarted(false);
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    }
  }, [selectedData, toggleAllCallback, t]);

  useEffect(() => {
    if (selectRow > 0) {
      setFinish(false);
    } else {
      setFinish(true);
    }
  }, [selectRow]);

  const updateValues = useCallback(() => {
    setLoading(false);
    setCurrentPage(1);
  }, []);

  const onSizePerPageChange = useCallback((size: number) => {
    setPageSize(size);
    updateValues();
    setSeletedData([]);
    setSelectRow(0);
    setSelectedStreams({});
    setFilterValues(prev => ({...prev, pageSize: size, paramsLoaded: true}));
    localStorage.setItem('preferredPageSizeCamAlerts', size.toString());
  }, [updateValues]);

  useEffect(() => {
    if (localPageSize !== null) {
      onSizePerPageChange(parseInt(localPageSize as string));
    }
  }, [localPageSize, onSizePerPageChange]);

  useEffect(() => {
    if (filterValues.pageSize) {
      setPageSize(filterValues.pageSize as number);
    }
  }, [filterValues.pageSize]);

  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
    setSeletedData([]);
    setSelectRow(0);
    setSelectedStreams({});
  }, []);

  const handleSort = useCallback((event, ascending) => {
    setFilterLoading(true);
    setSeletedData([]);
    setSelectRow(0);
    setSelectedStreams({});
    let sortOrder = 'asc';
    if(ascending) {
      sortOrder = 'asc';
    } else {
      sortOrder = 'desc';
    }
    setCurrentPage(1);
    setFilterValues(prev => ({...prev, sortOrder, sortBy: event.value.toString(), paramsLoaded: true}));
  }, []);

  const sortByList: ISortItem[] = [
    {
      sort: 'timestamp',
      title: t('Sort By Date/Time')
    },
    {
      sort : 'status',
      title: t('Sort By Alert Status')
    },
  ];

  return (
    <>
      {
        (finish === false || selectRow > 0) ?
          (
            <>
              <Action>{t('Actions')}:</Action>
              <ActionsMainContainer>
                <Actions>
                  <ActionFinishContainer>
                    <ActionsContainer>
                      {isDownloadStarted ? <DownloadButtonWithLoading loading position='left' design='secondary' disabled>{t('Download')}</DownloadButtonWithLoading>
                        : <DownloadButtonWithIcon position='left' icon='Download' design='secondary' size='small' onClick={onDownloadAlerts}> {t('Download')} </DownloadButtonWithIcon>}
                      {ENABLE_DOWNLOAD_VIDEO ? <VideoButtonWithIcon position='left' icon='DownloadVideo' design='secondary' size='small' disabled={selectRow===0} onClick={selectedDownloadVideo}>{t('Download Video')}</VideoButtonWithIcon> : null}
                      <VideoButtonWithIcon position='left' icon='Notifications' design='secondary' size='small' disabled={activeCount===0 && dismissCount!==0} onClick={()=>selectRowDismiss()}> {t('Dismiss Alert')} </VideoButtonWithIcon>
                      <VideoButtonWithIcon position='left' icon='Notifications' design='secondary' size='small' disabled={activeCount!==0 && dismissCount===0} onClick={()=>selectRowActive()}> {t('Mark Active')} </VideoButtonWithIcon>
                    </ActionsContainer>
                    <FinishButton onClick={()=>handleFinish(rowData)}>{t('Finish')}</FinishButton>
                  </ActionFinishContainer>
                  <BlurredDiv />
                </Actions>
              </ActionsMainContainer>
            </>
          ) : null
      }
      <AlertHistoryTabContainer>
        {
          (finish===true || selectRow === 0) ?
            <FilterAndDismiss>
              <FilterbarWrapper width='760px'> 
                <FilterBar filtersTitle={t('Filters')+':'} resultTextTemplate={t('Showing Results') + t(' ({X})').replace('{X}', '' + alertHistory.length) + ':'} dropdownsConfig={getDropdownConfig()} resultsDateFormat={DATE_FORMAT_WITHOUT_SEC} datePickersConfig={dateConfig} totalResults={resultCount} onChangeCallback={handleFilter} clearText={t('CLEAR ALL')} />
              </FilterbarWrapper>
              <DismissSortingWrapper>
                <DismissButtonContainer>
                  {
                    loadingSpinner ?
                      <ButtonWithLoading loading position='left' design='secondary' size='small'>{t('Dismiss All Alerts')}</ButtonWithLoading>
                      : <ButtonWithIcon design='secondary' icon='Notifications' position='left' size='small' disabled={activeCount===0} onClick={() => {onClickDismissAll();}}> {t('Dismiss All Alerts')} </ButtonWithIcon>
                  }
                </DismissButtonContainer>
                <SortBox>
                  <SortDropdown
                    ascendingText={t('Ascending')}
                    buttonText={t(sorting.title)}
                    descendingText={t('Descending')}
                    isSortAscending={sorting.ascending}
                    list={sortByList.map(({title, sort}) => ({text: t(title), value:sort}))}
                    onSelect={handleSort}
                    selected={{ text: sorting.title, value: sorting.sort }}
                  />
                </SortBox>
              </DismissSortingWrapper>
            </FilterAndDismiss>
            : null
        }
        {
          (finish === false || selectRow > 0) ?
            <ResultTextSortContainer>
              <SelectedResults>{t('Selected {x} of {y} Results').replace('{x}',''+ selectRow).replace('{y}', '' + resultCount)}</SelectedResults>
              <SortBox>
                <SortDropdown
                  ascendingText={t('Ascending')}
                  buttonText={t(sorting.title)}
                  descendingText={t('Descending')}
                  isSortAscending={sorting.ascending}
                  list={sortByList.map(({title, sort}) => ({text: t(title), value:sort}))}
                  onSelect={handleSort}
                  selected={{ text: sorting.title, value: sorting.sort }}
                />
              </SortBox>
            </ResultTextSortContainer>
            : null
        }
        <TableContainer>
          <TypeTable columnConfig={columnConfig} rows={rowData} loadingText={t('Loading')} defaultAscending isLoading={loading || filterLoading} hasThumbnail hasHeaderGroups emptyTableTitle={t('No Alerts Found.')} selectCallback={selectCallback} selectable toggleAllCallback={toggleAllCallback} closeText={t('CLOSE')} />
        </TableContainer>
        {
          alertHistory.length === 0 ? null :<PaginationContainer> <Pagination pageSizeOptions={sizeOptions} totalPages={totalPages} defaultPage={currentPage} defaultPageSize={pageSize} onPageSizeChange={onSizePerPageChange} onPageChange={onPageChange} pageSizeText={t('Items Per Page')} prevPageText={t('Page')} showPrevNextButtons /></PaginationContainer>
        }
      </AlertHistoryTabContainer>
    </>
  );
};

export default CameraAlertsTab;