import React, { useCallback, useEffect, useRef, useState, ReactElement, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Button, ButtonWithIcon, Content, Icon, PageHeader, SelectField, SmallInput, Spinner, Switch, useModal, useNotification, useMediaModal } from 'scorer-ui-kit';
import { getCamera, getReverseRunSettings, saveReverseRunSettings, toggleConfigurationAnalysis } from 'services/camerasService';
import styled, {css} from 'styled-components';
import { IExclusionAreas, IReverseRunData, IReverseRunDataResponse, IReverseRunSettings, IReverseRunDataMapped, IReverseRunSettingsMapped } from 'pages/CameraConfiguration/types';
import LineViewer from 'components/LineViewer/configurationLineViewer';
import cloneDeep from 'lodash.clonedeep';
import { NEW_AREA, EDGE_API_BASE_URL } from '../../constants';
import isEqual from 'lodash.isequal';
import { IPointSet, IVector2, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import i18n from 'i18n';
import DisableConfirmationModal from 'components/DisableConfirmationModal';
import {IToggleAnalysisPayload, ICameraDetails, IPoints} from '../../interface';
import ImportSettingsModal from './ConfigurationImportModal';
import axios from 'axios';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 54px 0 54px 90px;
  @media screen and (min-width: 1440px) {
    padding: 54px 40px 54px 170px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 940px !important;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const ResultText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.data};
  color: #5a6269;
  margin-top: -3px;
  padding: 0;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 941px !important;
  justify-content: space-between;
  margin-top: -22px;
  padding: 0;
  p {
    margin-top: -14px;
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
  > div > div {
    > h1 {
      margin-left: -1px;
    }
    > :nth-child(2) {
      bottom: 6px;
      left: -45px;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 0;
  > button {
    width: 133px;
  }
  > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  & > :nth-child(2) {
    width: 135px;
    margin-right: -2px;
    padding: 0 18px;
  }
`;

const AreaContainer = styled.span`
  min-width: 200px;
  max-width: 750px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
`;

const UnsavedChangesText = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.ui};
`;

const ToggleDetection = styled.div<{ state?: string }>`
  display: flex;
  align-items: center;
  margin-top: -4px;
  gap: 0 12px;
  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
    `}
  margin-bottom: 15px;
`;

const SmallLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
`;

const InputAndButtonContainer = styled.div<{ gap: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  & > button {
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #efefef;
`;

const LineSettingsContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  margin: 4px 0;
`;

const LineSelectFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  position: relative;
  & > div:first-child {
    display: flex;
    position: absolute;
    top: 6px;
    left: -24px;
  }
`;

const StyledSelectField = styled(SelectField)`
  width: 229px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  } // to align drop down icon
`;

const ImportExportContainer = styled.div<{ gap: string; lang?: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 11px;
  margin-top: -15px;
  margin-bottom: 15px;

  > button {
    padding: 0 8px;
    > div > :first-child {
      padding-left: 12px;
    }
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const LineUIContainer = styled.div`
  width: 100%;
  min-height: 390px;
  margin: 30px 0 15px;
  display: flex;
  border-radius: 3px;
  border: solid 1px #eee;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.02);
  background-color: rgba(252, 252, 253, 0.85);
  position: relative;

  &::before {
    content: "";
    width: 14.5px;
    height: 51px;
    position: absolute;
    top: -28px;
    left: -16px;
    border-left: 2px solid #d9dad9;
    border-bottom: 2px solid #d9dad9;
  }
`;

const LineUILeftPanel = styled.div`
  flex-shrink: 0;
  width: 239px;
  min-height: 390px;
  padding: 14px 0;
`;

const SidePaddingDiv = styled.div`
  position: relative;
  padding: 0 19px;
  & > div > label > span {
    margin-bottom: 5px !important;
  }
  & > div > label > div {
    width: 200px !important;
  }
`;

interface ISmallInput {
  length: string;
}

const StyledSmallInputWithLabel = styled(SmallInput)<ISmallInput>`
  & > label {
    width: ${({ length }) => length};
    margin-bottom: 0;
  }
`;

const DividerWithMargin = styled(Divider)`
  margin: 12px 0 12px;
`;

const DurationContainer = styled.div`
  padding: 10px 20px 0 19px;
  & > div > label > div {
    width: 135px !important;
  }
  & > div > label > span {
    margin-bottom: 4px !important;
  }
  & > div > label {
    margin-bottom: 14px !important;
  }
`;

const DirectionContainer = styled.div`
  padding: 1px 20px 0 19px;
  & > div > label > div {
    width: 135px !important;
  }
  & > div > label > span {
    margin-bottom: 4px !important;
  }
  & > div > label > div > div {
    right: 6px !important;
  }
  display: flex;
`;

const LineUIRightPanel = styled.div`
  flex: 1;
  flex-shrink: 0;
  border-radius: 3px;
`;

const CancelModalContainer = styled.div`
  padding: 18px 48px 22px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const LineUIActionsDisplayContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 396.5px;
  height: max-content;
  & > div:nth-child(1) {
    margin-top: -3px;
  }
  & > div:nth-child(2) {
    margin-top: -14px;
  }
  position: absolute;
  z-index: 10;
  bottom: -34px;
`;

const IconWrapper = styled.div``;

const SelectZoneShow = styled(SelectField)`
  width: 138px;
  height: 30px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 25px 0 8px !important;
  margin-top: 5px;
  font-family: 'Lato' !important;
  font-style: normal !important;
  color: #8b9196 !important;
  letter-spacing: -0.12px;
  & + div {
    right: 14.5px !important;
  }
`;

const ForwardIcon = styled.div<{
  direction: number | string;
  language: string;
}>`
  position: relative;
  top: 28px;
  right: ${({ language }) => (language === 'ja' ? '' : '32px')};
  left: ${({ language }) => (language === 'ja' ? '6px' : '')};
  transform: rotate(-90deg);
  transform: ${({ direction }) =>
    `rotate(${
      typeof direction === 'string' ? parseInt(direction, 10) : direction - 90
    }deg)`};
  height: 24px;
`;

const CancelChangesButton = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const options: LineUIOptions = {
  showSetIndex: false,
  showPoint: true,
  showDirectionMark: false,
  showMoveHandle: true,
  showPointHandle: true,
  setIndexOffset: 0,
  boundaryOffset: 0
};

type key = 'lineReference' | 'threshold' | 'direction';

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

interface ILinePoints {
  points: IVector2[],
  changeLineStyle: boolean
}

type ReverseRunRes = IReverseRunDataMapped;
type ReverseRunKeys = keyof ReverseRunRes;
type AreaKeys = keyof IReverseRunSettingsMapped;
type PointsKeys = keyof IPoints;
type CheckTypeValue = number | string | boolean | IReverseRunSettingsMapped[] | IPoints | undefined;

const checkType = (key: string, value: CheckTypeValue): boolean => {
  switch (key) {
  case 'reverse_run':
    return Array.isArray(value);
  case 'name':
    return typeof value === 'string';
  case 'seconds':
    return typeof value === 'number';
  case 'forward_direction_degrees':
    return typeof value === 'number';
  case 'area':
    return true;
  case 'x':
  case 'y':
    return typeof value === 'number';
  default:
    return false;
  }
};

const ReverseRunDetection = () => {
  const { t } = useTranslation(['CommonDict']);
  const { streamName }: { streamName: string } = useParams();
  const [cameraData, setCameraData] = useState<ICameraDetails>();
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const { push } = useHistory();
  const { createModal , setModalOpen, isModalOpen} = useModal();
  const [reverseRunData, setReverseRunData] = useState<IReverseRunData>({enabled: false, reverse_run_settings:[]});
  const [prevValuesForCompare, setPrevValuesForCompare] = useState<IReverseRunData>({enabled: false, reverse_run_settings:[]});
  const [prevReverseRunData, setPrevReverseRunData] = useState<IReverseRunData>({enabled: false, reverse_run_settings:[]});
  const [reverseRunAreaSettings, setReverseRunAreaSettings] = useState<IReverseRunSettings[]>([]);
  const [exclusionAreas, setExclusionAreas] = useState<IExclusionAreas>({enabled: false, areas: []});
  const [reverseSelectOptions, setAreaSelectOptions] = useState<string[]>([NEW_AREA]);
  const [unSavedChanges, setUnsavedChanges] = useState<number>(0);
  const [selectedAreaIndex, setSelectedAreaIndex] = useState<number>(0);
  const [selectedArea, setSelectedArea] = useState<string>(NEW_AREA);
  const [reverseSaveLoading, setReverseSaveLoading] = useState(false);
  const [showAllZone, setShowAllZone] = useState(true);
  const [showAllOrSelectedOption, setShowAllOrSelectedOption] = useState('Show All');
  const tRef = useRef(t);
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [stateDetails, setStateDetails] = useState<IPointSet[]>([]); 
  const stateRef = useRef<IPointSet[]>([]);
  const [toggleAnalysis, setToggleAnalysis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const { isMediaUrlValid } = useMediaModal();
  const [image, setImage] = useState('');
  const [isImporting, setIsImporting] = useState(true);

  function orientation(p: { y: number; x: number; }, q: { y: number; x: number; }, r: { x: number; y: number; }) {
    const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
    if (val === 0) return 0; 
    return (val > 0) ? 1 : 2; 
  }

  const doIntersect = useCallback((p1: IPoints, q1: IPoints, p2: IPoints, q2: IPoints) => {
    const o1 = orientation(p1, q1, p2);
    const o2 = orientation(p1, q1, q2);
    const o3 = orientation(p2, q2, p1);
    const o4 = orientation(p2, q2, q1);
    if (o1 !== o2 && o3 !== o4) {
      return true;
    }
    return false;
  },[]);

  const arePointsCrossingLines = useCallback((points: IPoints[]) => {
    const [p1, p2, p3, p4] = points;
    if (doIntersect(p1, p2, p3, p4) || doIntersect(p2, p3, p4, p1) || doIntersect(p3, p4, p1, p2) || doIntersect(p4, p1, p2, p3)) {
      return true;
    }
    return false;
  },[doIntersect]);

  useEffect(() => {
    const state: IPointSet[] = [];
    if (showAllOrSelectedOption === 'Show All') {
      reverseRunData?.reverse_run_settings?.forEach((item) => {            
        state.push({
          points: item.points,
          styling: item.styling,
          readOnly: (item.index !== selectedAreaIndex),
          showPointHandle: (item.index === selectedAreaIndex),
          showMoveHandle: (item.index === selectedAreaIndex),
          areaFillColor: '#1684cf',
          areaTransparencyLevel: 20,
          areaName: item.name.length > 24 ? item.name.slice(0, 24) : item.name
        });
      });
    } else {
      if (selectedAreaIndex !== 0) {
        const lines = reverseRunData?.reverse_run_settings[selectedAreaIndex - 1];
        state.push({
          points: lines!.points,
          styling: lines!.styling,
          readOnly: false,
          showPointHandle: true,
          areaFillColor: '#1684cf',
          areaTransparencyLevel: 20,
          areaName: lines.name.length > 24 ? lines.name.slice(0, 24) : lines.name
        });
      }
    }
    setStateDetails(state);
    stateRef.current = state;
  }, [reverseRunData, selectedAreaIndex, showAllOrSelectedOption]);

  const zoneChangesCounter = (prevDetails: IReverseRunData, currentDetails: IReverseRunData) => {
    let counter = 0;
    
    // If any Area has length 0
    if(prevDetails.reverse_run_settings && currentDetails.reverse_run_settings){
      if (currentDetails.reverse_run_settings?.length === 0 || prevDetails?.reverse_run_settings.length === 0) {
        counter = counter + currentDetails?.reverse_run_settings.length + prevDetails?.reverse_run_settings.length;
        return counter;
      }

      currentDetails.reverse_run_settings.forEach((lane1) => {
        let found = 1;
        for (const lane2 of prevDetails.reverse_run_settings) {
          if (isEqual(lane1, lane2)) {
            found = 0;
            break;
          }
        }
        counter += found;
      });

      if (currentDetails.reverse_run_settings.length < prevDetails.reverse_run_settings.length) {
        counter += Math.abs(currentDetails.reverse_run_settings.length - prevDetails.reverse_run_settings.length);
      }
    }
    return counter;
  };

  useEffect(()=>{
    const currentExclusionAreaSettings = reverseRunData?.reverse_run_settings.map(obj => {
      const newObj = { ...obj };
      delete newObj.index;
      delete newObj.showMoveHandle;
      delete newObj.showPointHandle;
      return newObj;
    });
    const prevExclusionAreaSettings = prevValuesForCompare?.reverse_run_settings.map(obj => {
      const newObj = { ...obj };
      delete newObj.index;
      delete newObj.showMoveHandle;
      delete newObj.showPointHandle;
      return newObj;
    });
    const prevSettings = {
      ...prevValuesForCompare,
      reverse_run_settings: prevExclusionAreaSettings
    };

    const currentDetails:IReverseRunData = {enabled: reverseRunData?.enabled, reverse_run_settings: currentExclusionAreaSettings};
    let changes = 0;
    changes = zoneChangesCounter(prevSettings, currentDetails);
    setUnsavedChanges(changes);
  },[prevValuesForCompare, reverseRunData]);

  const fetchCamera = useCallback(() => {
    getCamera(streamName).then((res) => {
      if (res.status === 200 && res.data.data) {
        setCameraData(res.data.data);
      } else {
        sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
      }
    }).catch(() => {
      sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  }, [streamName]);

  const getReverseData = useCallback(() => {
    getReverseRunSettings(streamName).then((res: { data: IReverseRunDataResponse }) => {
      if (res.data.status_code === 0 && res.data.data ) {
        if (res.data.exclusion_areas) {
          const {enabled: enabledExclusion, exclusion_zone_settings = []} = res.data.exclusion_areas;
          setExclusionAreas({
            enabled: enabledExclusion,
            areas: exclusion_zone_settings.map(area => ({points: area.points}))
          });
        }
        setReverseRunData(res.data.data);
        setPrevValuesForCompare(res.data.data);
        setPrevReverseRunData(cloneDeep(res.data.data));
        setReverseRunAreaSettings(res.data.data?.reverse_run_settings);
        const zoneSelect = res.data.data?.reverse_run_settings.map(area => area.name);
        zoneSelect.unshift(NEW_AREA);
        
        setAreaSelectOptions(zoneSelect);
        setSelectedAreaIndex(0);
        setSelectedArea(NEW_AREA);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    }).catch(() => {
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
    }).finally(() => {
      setLoading(false);
    });
  }, [streamName]);

  useEffect(() => {
    fetchCamera();
    getReverseData();
  }, [fetchCamera, getReverseData]);

  const onLineSelect = useCallback((e: string) => {
    setSelectedArea(e);
    if (e === NEW_AREA || e === '［新規エリア］') {
      setSelectedAreaIndex(0);
    } else {
      setSelectedAreaIndex(parseInt(e.substring(0, e.indexOf(':'))));
    }

    reverseRunData?.reverse_run_settings?.forEach((areaLine) => {
      areaLine.showPointHandle = areaLine.index === selectedAreaIndex ? true : false;
      areaLine.showMoveHandle = areaLine.index === selectedAreaIndex ? true : false;
    });
  },[reverseRunData, selectedAreaIndex]);

  const onChangeReverseRunData = useCallback((formData) => {
    setReverseRunData({ ...formData });
  }, []);

  const handleAnalysis = useCallback((value)=>{
    setToggleAnalysis(true);
    const newExclusionZonesData = cloneDeep(reverseRunData);
    newExclusionZonesData!.enabled = value;
    onChangeReverseRunData(newExclusionZonesData);

    const payload: IToggleAnalysisPayload ={
      action: value ? 'enable' : 'disable',
      analysis_type: ['reverse_run']
    };
    toggleConfigurationAnalysis(streamName, payload).then((res: { data: { status_code: number; }; }) => {
      if (res.data.status_code === 0) {
        sendNotificationRef.current({ type: 'success', message: value ? tRef.current('Analysis enabled successfully') : tRef.current('Analysis disabled successfully')});
        setToggleAnalysis(false);
      } else { 
        newExclusionZonesData!.enabled = !value;
        onChangeReverseRunData(newExclusionZonesData);
        sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        setToggleAnalysis(false); }
    }).catch(() => {
      newExclusionZonesData!.enabled = !value;
      onChangeReverseRunData(newExclusionZonesData);
      setToggleAnalysis(false);
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  },[onChangeReverseRunData, streamName, reverseRunData]);

  const onMediaLoadedCallback = useCallback(() => {
    setIsMediaLoaded(true);
  }, []);

  const handleCancel = useCallback(async (setModalOpen: (newStatus: boolean) => void) => {
    setSelectedAreaIndex(0);
    await setModalOpen(false);
    if(prevReverseRunData) {
      setReverseRunData(prev => ({...prevReverseRunData, enabled: prev.enabled}));
      setPrevValuesForCompare(prev => ({...prevReverseRunData, enabled: prev.enabled}));
      setReverseRunAreaSettings(prevReverseRunData.reverse_run_settings);
      const zoneSelect = prevReverseRunData.reverse_run_settings.map(area => area.name);
      zoneSelect.unshift(NEW_AREA);
      setAreaSelectOptions(zoneSelect);
      setSelectedAreaIndex(0);
      setSelectedArea(NEW_AREA);
    }
  }, [prevReverseRunData]);

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('CancelButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('CancelButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'CancelButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('CancelButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('CancelButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const handleCancleNo = useCallback(() =>{
    setModalOpen(false);
  },[setModalOpen]);

  const getCancelModal = useCallback((setModalOpen: (newStatus: boolean) => void) => {
    return (
      <CancelModalContainer>
        <CancelConfirmText>{tRef.current('Are you sure you want to cancel the changes?')}</CancelConfirmText>
        <ModalButtonContainer>
          <CancelChangesButton id='CancelButton' design='secondary' onClick={() => handleCancleNo()}>{tRef.current('No')}</CancelChangesButton>
          <CancelChangesButton id='YesButton' onClick={() => handleCancel(setModalOpen)}>{tRef.current('Yes')}</CancelChangesButton>
        </ModalButtonContainer>
      </CancelModalContainer>
    );
  }, [handleCancel, handleCancleNo]);

  const handleOnChange = useCallback((key: key) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const newExclusionZonesData = cloneDeep(reverseRunData);
    if(key === 'lineReference'){
      const newAreaSelectOptions = [...reverseSelectOptions];
      newAreaSelectOptions[selectedAreaIndex] = value;
      setAreaSelectOptions(newAreaSelectOptions);
      setSelectedArea(selectedAreaIndex + ': ' + value);
      newExclusionZonesData!.reverse_run_settings[selectedAreaIndex - 1].name = value;
    } else if(key === 'direction'){
      if (value.length === 0) {
        newExclusionZonesData!.reverse_run_settings[selectedAreaIndex - 1].direction = '';
      } else {
        let val = parseInt(value, 10);
        if (isNaN(val) || value.length > 3 || val > 360) {
          return;
        }
        if (val < 0) {
          val = 350;
        } else if (val === 360) {
          val = 0;
        }
        newExclusionZonesData!.reverse_run_settings[selectedAreaIndex - 1].direction = val;
      }
    } else if(key === 'threshold'){
      
      if(parseInt(value) === 100){
        value = '99';
      }
      const t = value;
      value = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3)) : t;
      if (value.length > 5) {
        return;
      } else if (/^\d{0,2}(\.\d{0,2})?$/.test(value)) {
      newExclusionZonesData!.reverse_run_settings[selectedAreaIndex - 1].threshold = parseFloat(value);
      }
      
    }
    onChangeReverseRunData(newExclusionZonesData); 
  }, [reverseSelectOptions, selectedAreaIndex, reverseRunData, onChangeReverseRunData]);

  const onLineChange = useCallback((state: IPointSet[]) => {
    if (selectedAreaIndex === 0) return;
    const newReverseRunSettings = cloneDeep(reverseRunData);
    const selectedLines = state.filter(line => line.showPointHandle);
    newReverseRunSettings!.reverse_run_settings[selectedAreaIndex - 1] = {
      ...newReverseRunSettings!.reverse_run_settings[selectedAreaIndex - 1],
      points: selectedLines[0].points,
      styling: arePointsCrossingLines(selectedLines[0].points) ? 'danger' : 'primary'
    };

    onChangeReverseRunData({ ...newReverseRunSettings });
  }, [selectedAreaIndex, reverseRunData, onChangeReverseRunData, arePointsCrossingLines]);

  const onAddArea = useCallback(() => {
    const filteredAreaNumbers=[1,2,3,4,5].filter(num=>reverseRunAreaSettings.findIndex(d=>parseInt(d.name.trim()[d.name.trim().length-1]) === num)===-1);
    filteredAreaNumbers.sort();
    const newZoneDetail = {
      index: reverseRunAreaSettings?.length + 1,
      name: i18n.language === 'ja' ? 'エリア ' + filteredAreaNumbers[0].toString() : 'Area ' + filteredAreaNumbers[0].toString(),
      points: [{'x':38 * filteredAreaNumbers[0],'y':38* filteredAreaNumbers[0]},{'x':44* filteredAreaNumbers[0],'y':165* filteredAreaNumbers[0]},{'x':155* filteredAreaNumbers[0],'y':166* filteredAreaNumbers[0]},{'x':148* filteredAreaNumbers[0],'y':40* filteredAreaNumbers[0]}],
      threshold: 20,
      direction: 30
    };
    const updatedSettings = [...reverseRunData?.reverse_run_settings, newZoneDetail];
    const addNewZone = {
      ...reverseRunData,
      reverse_run_settings: updatedSettings
    };
    // eslint-disable-next-line no-useless-concat
    onLineSelect(i18n.language === 'ja' ? `${newZoneDetail.index}: エリア${newZoneDetail.index}` : `${newZoneDetail.index}: Area ${newZoneDetail.index}`);
    setReverseRunAreaSettings(prev => [...prev, newZoneDetail]);
    setAreaSelectOptions(prev => [...prev, i18n.language === 'ja' ? 'エリア' + filteredAreaNumbers[0].toString() : 'Area ' + filteredAreaNumbers[0].toString()]);
    onChangeReverseRunData(addNewZone);     
    sendNotificationRef.current({type: 'neutral', message: tRef.current('New area added'), icon: 'FeaturePolyUi'});
  },[reverseRunData, reverseRunAreaSettings, onLineSelect, onChangeReverseRunData]);

  const onRemoveArea = useCallback(() => {
    const updatedData = {
      ...reverseRunData,
      reverse_run_settings: reverseRunData?.reverse_run_settings.filter(
        (item) => item.index !== selectedAreaIndex
      ).map((item, index) => ({ ...item, index: index + 1 }))
    };
    setReverseRunAreaSettings(updatedData.reverse_run_settings);

    const remZoneSelectOptions = updatedData?.reverse_run_settings.map(item => item.name);
    remZoneSelectOptions.unshift(NEW_AREA);
    setAreaSelectOptions(remZoneSelectOptions);
    setSelectedAreaIndex(remZoneSelectOptions.length - 1);
    if (remZoneSelectOptions[remZoneSelectOptions.length - 1] === NEW_AREA) {
      setSelectedArea(NEW_AREA);
    } else {
      setSelectedArea(`${remZoneSelectOptions.length - 1}: ${remZoneSelectOptions[remZoneSelectOptions.length - 1]}`);
    }
    onChangeReverseRunData(updatedData);  
    sendNotificationRef.current({ type: 'neutral', message: tRef.current('Area removed'), icon: 'FeaturePolyUi'});
  },[reverseRunData, onChangeReverseRunData, selectedAreaIndex]);

  const validateExclusionZoneData = useCallback((reverseRunData) => {
    let returnValue = true;
    const areaNames = new Set();

    for (const item of reverseRunData.reverse_run_settings) {
      if (item.name === '') {
        sendNotificationRef.current({ type: 'error', message: tRef.current('Please enter the area reference for area #{X}').replace('{X}', (item.index)) });
        returnValue = false;
        break;
      }
      if (isNaN(item.threshold)) {
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Trigger duration threshold should be > 0 for {X}').replace('{X}', (item.name))}` });
        returnValue = false;
        break;
      }

      if (item.threshold === 0) {
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Trigger duration threshold should be > 0 for {X}').replace('{X}', (item.name))}` });
        returnValue = false;
        break;
      }

      if (isNaN(item.direction)) {
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Please enter the normal direction of travel for {X}').replace('{X}', (item.name))}` });
        returnValue = false;
        break;
      }

      if (item.direction === ''|| item.direction === 0) {
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Please enter the normal direction of travel for {X}').replace('{X}', (item.name))}` });
        returnValue = false;
        break;
      }

      if (areaNames.has(item.name)) {
        sendNotificationRef.current({type: 'error', message: tRef.current('{X} already exists').replace('{X}', (item.name))});
        returnValue = false;
        break;
      }
      areaNames.add(item.name);
      const isConvex = arePointsCrossingLines(item.points);
      if(isConvex){
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('{X} shape is not appropriate for analysis').replace('{X}', (item.name))}` });
        returnValue = false;
        break;
      }
    }
    return returnValue;
  }, [arePointsCrossingLines]);

  const onClickSave = useCallback((data = reverseRunData) => {
    const isValidData = validateExclusionZoneData(data); 
    if(isValidData){
      onChangeReverseRunData({ ...reverseRunData });
      setReverseSaveLoading(true);
      saveReverseRunSettings(streamName, data).then((res: { data: { status_code: number; }; }) => {
        if (res.data.status_code === 0) {
          setPrevReverseRunData(cloneDeep(reverseRunData));
          sendNotificationRef.current({ type: 'success', message: tRef.current('Settings saved successfully')});
          fetchCamera();
          getReverseData();
        } else { sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') }); }
        setReverseSaveLoading(false);
      }).catch(() => {
        setReverseSaveLoading(false);
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      });
    }
  }, [reverseRunData, validateExclusionZoneData, onChangeReverseRunData, streamName, fetchCamera, getReverseData]);

  const handleZoneShow = useCallback((e) => {
    setShowAllZone(!showAllZone);
    setShowAllOrSelectedOption(e);
  },[showAllZone]);

  const onLineClickCallback = useCallback((lineId: number) => {
    setSelectedAreaIndex(lineId + 1);
    setSelectedArea(lineId + 1 + ': ' + reverseRunData?.reverse_run_settings[lineId]?.name);
  },[reverseRunData]);

  const goToPreviousPage = useCallback(() =>{
    if(isEqual(reverseRunData.reverse_run_settings, prevValuesForCompare.reverse_run_settings)){
      push('/cameras/camera-configuration/' + streamName);
      return;
    }
    const CancelModal: ReactElement = getCancelModal(setModalOpen);
    createModal({ isCloseEnable: false, width: '400px', padding: false, customComponent: CancelModal });
  },[createModal, getCancelModal, push, prevValuesForCompare, reverseRunData, streamName, setModalOpen]);

  const handleKeyPressThreshold = useCallback((event) => {
    
    const decimalPart1 = event.target.value.split('.')[1];
    const decimalPart2 = event.target.value.split('.')[0];
    if(decimalPart1 !== undefined){
      if(event.key === '0' && decimalPart1.charAt(1)==='0'){
        event.preventDefault();
      }
    }
    if(decimalPart2 !== undefined){
      if(event.key === '0' && decimalPart2.charAt(0)==='0' && !event.target.value.includes('.')){
        event.preventDefault();
      }
    }
    
    if (event.key === '.' && event.target.value.charAt(0)==='' ) {
      event.preventDefault();
    }
    if (event.key === '-' || event.key === '+' ) {
      event.preventDefault();
    }
    
  }, []);

  const handleKeyPressDirection = useCallback((event) => {
    if (!/^[0-9]*$/.test(event.key)) {
      event.preventDefault();
    }
    if (event.key === '0' && event.target.value.charAt(0)==='0' ) {
      event.preventDefault();
    }
  }, []);

  const diableCallBack = useCallback((isEnabled: boolean) => {
    setModalOpen(false);
    onClickSave({...reverseRunData, enabled: isEnabled});
  }, [reverseRunData, setModalOpen, onClickSave]);

  const openConfirmationModal = useCallback(() => {
    if (reverseRunData.enabled) {
      onClickSave(reverseRunData);
    } else {
      const isValidData = validateExclusionZoneData(reverseRunData);
      if(isValidData){
        const ConfirmationModal: ReactElement = <DisableConfirmationModal onCallback={diableCallBack} />;
        createModal({ isCloseEnable: false, width: 'auto', padding: false, customComponent: ConfirmationModal });
      }
    }
  }, [reverseRunData, validateExclusionZoneData, createModal, onClickSave, diableCallBack]);

  const onExportSettings = useCallback(() => {
    const { reverse_run_settings } = prevReverseRunData;
    const exportLines = reverse_run_settings.map(({ index, styling, threshold, direction, points, ...rest }) => {
      return { ...rest, area: points, seconds: threshold, forward_direction_degrees: direction };
    });
    try {
      let modifiedCameraName = cameraData?.camera_name || '';
      if (modifiedCameraName.length > 50) {
        modifiedCameraName = modifiedCameraName.substring(0, 50) + '...';
      }
      const fileName = `${modifiedCameraName}_reverse_run.json`;
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({ reverse_run: exportLines }, null, 4));
      const link = document.createElement('a');
      link.setAttribute('href', dataStr);
      link.setAttribute('download', fileName);
      link.click();
      sendNotification({ type: 'success', message: t('Settings exported successfully')});
    } catch (error) {
      sendNotification({type: 'error',message: t('An error occurred while exporting data')});
    }
  }, [prevReverseRunData, cameraData, sendNotification, t]);

  const getLinePoints = useCallback((pointsArray: IVector2[]): ILinePoints => {
    const boundaryOffset = 0; // 48
    let changeLineStyle = false;

    if (image === '') {
      return { points: pointsArray, changeLineStyle };
    }
  
    // Initialize an array to store the adjusted points
    const adjustedPoints = pointsArray.map(point => {
      let adjustedX = point.x;
      let adjustedY = point.y;
  
      // Check and adjust X coordinate
      if (point.x < boundaryOffset) {
        adjustedX = boundaryOffset;
        changeLineStyle = true;
      } else if (point.x > imageDetails.x - boundaryOffset) {
        adjustedX = imageDetails.x - boundaryOffset;
        changeLineStyle = true;
      }
  
      // Check and adjust Y coordinate
      if (point.y < boundaryOffset) {
        adjustedY = boundaryOffset;
        changeLineStyle = true;
      } else if (point.y > imageDetails.y - boundaryOffset) {
        adjustedY = imageDetails.y - boundaryOffset;
        changeLineStyle = true;
      }
  
      return { x: adjustedX, y: adjustedY };
    });
  
    return { points: adjustedPoints, changeLineStyle };

  }, [image, imageDetails]);

  const importSettings = useCallback((importedTrafficCounterData: IReverseRunData) => {
    const { reverse_run_settings = [] } = importedTrafficCounterData;
    
    if (reverse_run_settings.length > 0) {
      const lineInfo: IReverseRunSettings[] = [];
      setAreaSelectOptions([NEW_AREA]);
      reverse_run_settings.map((item, index) => {
        const point = cloneDeep(item.points);
        const { points, changeLineStyle } = getLinePoints(point);
        const lineName = !item.name ? `Area ${index}` : item.name;

        setAreaSelectOptions(prev => [...prev, lineName]);

        lineInfo.push(
          {
            index: item.index,
            name: item.name,
            threshold: item.threshold,
            direction: item.direction,
            styling: changeLineStyle ? 'danger' : item.styling,
            points: points
          }
        );
        return {
          name: index + 1 + ': ' + lineName,
          points: points,
          readOnly: true,
          styling: changeLineStyle ? 'danger' : 'primary',
          showSmallDirectionMark: true
        };
      });
      setReverseRunAreaSettings(lineInfo);
      setReverseRunData({enabled: reverseRunData.enabled, reverse_run_settings: lineInfo});
    } else {
      setReverseRunAreaSettings([]);
      setReverseRunData({enabled: reverseRunData.enabled, reverse_run_settings: []});
    }
  }, [reverseRunData, getLinePoints]);

  const validateImporting = useCallback((importedTrafficCounterData) => {
    importedTrafficCounterData.reverse_run.forEach((item: {points: any; area: any; threshold: number; seconds: any; direction: number | string; forward_direction_degrees: any; index: number;}, index: number) => {
      item.index = index + 1;
      item.points = item.area;
      item.threshold = item.seconds;
      item.direction = item.forward_direction_degrees;
      delete item.area;
      delete item.seconds;
      delete item.forward_direction_degrees;
    });
  
    const newImportedData = {
      reverse_run_settings: importedTrafficCounterData.reverse_run,
      enabled: prevValuesForCompare.enabled
    };
    if(isEqual(prevValuesForCompare.reverse_run_settings, newImportedData.reverse_run_settings)){
      getReverseData();
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    } else {
      setSelectedAreaIndex(0);
      setSelectedArea(NEW_AREA);
      setReverseRunAreaSettings([]);
      setReverseRunData({enabled: reverseRunData.enabled, reverse_run_settings: []});
      importSettings({...newImportedData});
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    }
  }, [t, sendNotification, importSettings, setModalOpen, reverseRunData, prevValuesForCompare, getReverseData]);

  const isValidImportFile = useCallback((jsonData) => {
    let isLinesValid = false;
    const validReverseRunKeys: ReverseRunKeys[] = ['reverse_run'];
    const validAreaKeys: AreaKeys[] = ['name', 'area', 'seconds', 'forward_direction_degrees'];
    const validPointsKeys: PointsKeys[] = ['x', 'y'];

    try {
      let resKeys: ReverseRunKeys[] = [], areaKeys: AreaKeys[] = [], pointsKeys: PointsKeys[] = [];
      const resObj: ReverseRunRes = JSON.parse(jsonData);

      resKeys = Object.keys(resObj) as Array<keyof typeof resObj>;

      if (
        resKeys.every(key => validReverseRunKeys.includes(key)) &&
        validReverseRunKeys.every(key => (resKeys.includes(key) && checkType(key, resObj[key])))
      ) {

        if (resObj.reverse_run.length === 0) return true;
        if (resObj.reverse_run.length > 4){
          sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Maximum 4 areas can be imported')})));
          return false;
        }

        const isValidLineKeys = (areaKeys: AreaKeys[]) => {
          if(areaKeys.every(key => validAreaKeys.includes(key)) && validAreaKeys.every(key => areaKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeys = (pointsKeys: PointsKeys[]) => {
          if(pointsKeys.every(key => validPointsKeys.includes(key)) && validPointsKeys.every(key => pointsKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeyTypes = (pointsKeys: PointsKeys[], point: IPoints) => {
          if(pointsKeys.every((key) => checkType(key, point[key]))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidLineKeyTypes = (areaKeys: AreaKeys[], line: IReverseRunSettingsMapped) => {
          return areaKeys.every((key) => {
            if (key === 'area') {
              if(line[key].length > 3) {
                const isConvex = arePointsCrossingLines(line[key]);
                if(isConvex){
                  sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Area shape is not appropriate for analysis')})));
                  return false;
                }
                return Array.isArray(line[key]) && line[key].every((point) => isValidPointsKeyTypes(validPointsKeys, point));
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('A minimum of 4 points is required for an area')})));
                return false;
              }
            } else if (key === 'name') {
              if(typeof line[key] === 'string' && line[key] !== ''){
                if(line[key].length > 24){
                  sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name should not longer than 24 characters')})));
                  return false;
                } else {
                  return true; 
                }
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name is required')})));
                return false;
              }
            } else if (key === 'seconds') {
              if(typeof line[key] === 'number' && line[key] >= 1 && line[key] < 100){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Threshold should be > 0 and < 100')})));
                return false;
              }
            } else if (key === 'forward_direction_degrees') {
              if (typeof line[key] === 'number') {
                const directionValue = line[key] as number; // Type assertion to inform TypeScript that it is a number
                if ((directionValue < 350 || directionValue === 350) && directionValue >= 1) {
                  return true;
                } else {
                  sendNotification(JSON.parse(JSON.stringify({ type: 'error', message: t('Direction should be > 0 and ≤ 350') })));
                  return false;
                }
              } else {
                sendNotification(JSON.parse(JSON.stringify({ type: 'error', message: t('Direction should be a valid number') })));
                return false;
              }
            } else {
              if(checkType(key, line[key])){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid data types')})));
                return false;
              }
            }
          });
        };

        for (const line of resObj.reverse_run) {
          areaKeys = Object.keys(line) as Array<keyof typeof line>;
          if (isValidLineKeys(areaKeys) && isValidLineKeyTypes(areaKeys, line)) {
            for (const point of line.area) {
              pointsKeys = Object.keys(point) as Array<keyof IPoints>;
              if (isValidPointsKeys(pointsKeys) && isValidPointsKeyTypes(pointsKeys, point)) {
                isLinesValid = true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid point keys or their type')})));
                isLinesValid = false;
                break; // Exit the loop if any point is invalid
              }
            }
          } else {
            isLinesValid = false;
            break;
          }
        }
        return isLinesValid;
      } else {
        sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
        return false;
      }
    } catch (error) {
      sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid file')})));
      return false;
    }
  }, [sendNotification, t, arePointsCrossingLines]);

  const onImportCallback = useCallback((e) => {
    if (e !== null && isValidImportFile(e.result)) {
      validateImporting(JSON.parse(e.result as string));
    } else {
      setModalOpen(false);
    }
  }, [isValidImportFile, validateImporting, setModalOpen]);

  // Import Modal
  const getImportModal = useCallback(() => {
    return (
      <ImportSettingsModal {...{ onImportCallback, sendNotification }} />
    );
  }, [sendNotification, onImportCallback]);

  const onImportSettings = useCallback(() => {
    setModalOpen(true);
    const importModal: ReactElement = getImportModal();
    createModal({
      isCloseEnable: false,
      width: 'max-length',
      padding: false,
      customComponent: importModal,
    });
  }, [createModal, setModalOpen, getImportModal]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
        const isImageValid = await isMediaUrlValid(imgBase64, 'img');
        if (isImageValid === true) {
          setImage(imgBase64);
          const img = new Image();
          img.src = imgBase64;
          setImageDetails({x: img.width, y: img.height});
        }
        setIsImporting(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [streamName, isMediaUrlValid]);

  useEffect(() => {
    if(isImporting){
      getCameraImage();
    }
  },[isImporting, getCameraImage]);

  return (
    <>
      {
        (loading) ?
          <SpinnerContainer>
            <Spinner size='large' styling='primary' />
            <ResultText>{t('Loading')}</ResultText>
          </SpinnerContainer>
          :
          <MainContainer>
            <Container>
              <Header>
                <PageHeaderComponent>
                  <AreaContainer onClick={goToPreviousPage}>{!cameraData?.camera_name ? 'Camera Name' : cameraData?.camera_name}</AreaContainer>
                  <PageHeader
                    title={t('Reverse Run')}
                    icon='Analyse'
                    updateDocTitle={false}
                  />
                  <ToggleDetection state={toggleAnalysis ? 'disabled' : 'default'}>
                    <Switch checked={reverseRunData?.enabled} onChangeCallback={handleAnalysis} state={toggleAnalysis ? 'disabled' : 'default'} />
                    <SmallLabel>{reverseRunData?.enabled ? t('Enabled') : t('Disabled')}</SmallLabel>
                  </ToggleDetection>
                </PageHeaderComponent>
                <ButtonContainer>
                  <Button
                    design='secondary'
                    size='small'
                    onClick={goToPreviousPage}
                  >
                    {unSavedChanges === 0 ? t('Back') : t('Cancel')}
                  </Button>
                  <Button disabled={unSavedChanges === 0 || reverseSaveLoading} design='primary' size='small' onClick={openConfirmationModal}>
                    {t('Save Changes')}
                  </Button>
                  <UnsavedChangesText>{unSavedChanges > 0 ? t('{X} unsaved changes').replace('{X}', (unSavedChanges + '')) : t('No Changes')}</UnsavedChangesText>
                </ButtonContainer>
              </Header>
              
              <Divider />

              <ImportExportContainer gap='0 14px'>
                {t('Settings')}:
                <Button size='xsmall' design='secondary' disabled={false} onClick={onImportSettings}>
                  {t('Import')}
                </Button>
                <Button size='xsmall' design='secondary' disabled={false} onClick={onExportSettings}>
                  {t('Export')}
                </Button>
              </ImportExportContainer>

              <Divider />

              <LineSettingsContainer>
                <LineSelectFieldContainer>
                  <Icon icon='FeaturePolyUi' size={16} color='dimmed' />
                  <StyledSelectField
                    changeCallback={(e) => onLineSelect(e)}
                    disabled={false}
                    isCompact
                    value={selectedArea}
                  >
                    {
                      reverseSelectOptions.map((item, index) => {
                        return (
                          <option key={item + index} value={item === NEW_AREA ? NEW_AREA : `${index}: ${item}`}>
                            {item === NEW_AREA ? tRef.current(NEW_AREA) : `${index}: ${item.length > 24 ? item.slice(0, 24) : item}`}
                          </option>
                        );
                      })
                    }
                  </StyledSelectField>
                  <InputAndButtonContainer gap='0 10px' lang={i18n.language}>
                    <ButtonWithIcon icon='Add' position='left' size='small' design='secondary' disabled={!isMediaLoaded || reverseRunData?.reverse_run_settings.length === 4} onClick={onAddArea}>{tRef.current('Add Area')}</ButtonWithIcon>
                    <ButtonWithIcon icon='ActionRemove' position='left' size='small' design='secondary' disabled={!selectedAreaIndex} onClick={onRemoveArea}>{tRef.current('Remove Area')}</ButtonWithIcon>
                  </InputAndButtonContainer>
                </LineSelectFieldContainer>
                <LineUIContainer>
                  <LineUILeftPanel>
                    <SidePaddingDiv>
                      <StyledSmallInputWithLabel 
                        fieldState='default' 
                        label={tRef.current('Area Reference')} 
                        name='lineReference' 
                        length='100%' 
                        maxLength={24} 
                        value={selectedAreaIndex === 0 ? '' : reverseRunData?.reverse_run_settings[selectedAreaIndex - 1]?.name}
                        onChange={handleOnChange('lineReference')}
                        disabled={selectedAreaIndex === 0 ? true : false}
                      />
                    </SidePaddingDiv>
                    <DividerWithMargin />
                    <DurationContainer>
                      <SmallInput 
                        type='number' 
                        label={tRef.current('Trigger Duration Threshold')} 
                        name='threshold'
                        id='threshold'
                        min={0} 
                        step={10}
                        onChange={handleOnChange('threshold')}
                        value={selectedAreaIndex === 0 ? '' : reverseRunData?.reverse_run_settings[selectedAreaIndex - 1]?.threshold} 
                        unit={tRef.current('seconds')} 
                        disabled={selectedAreaIndex === 0 ? true : false}
                        onKeyPress={handleKeyPressThreshold}
                      />
                    </DurationContainer>
                    <DirectionContainer>
                      <SmallInput 
                        type='number' 
                        label={tRef.current('Normal Direction of Travel')} 
                        value={selectedAreaIndex === 0 ? '' : reverseRunData?.reverse_run_settings[selectedAreaIndex - 1]?.direction} 
                        unit={tRef.current('degrees')} 
                        maxLength={3}
                        step={10}
                        min={-10}
                        name='direction'
                        onChange={handleOnChange('direction')}
                        disabled={selectedAreaIndex === 0 ? true : false}
                        onKeyPress={handleKeyPressDirection}
                      />
                      <ForwardIcon language={i18n.language} direction={reverseRunData?.reverse_run_settings[selectedAreaIndex - 1]?.direction}><Icon icon='Forward' size={24} color='dimmed' /></ForwardIcon>
                    </DirectionContainer>
                  </LineUILeftPanel>
                  <LineUIRightPanel>
                    <LineViewer
                      streamName={streamName} 
                      latestSnapshotText={tRef.current('Latest Snapshot')}
                      zoomText={tRef.current('Zoom')}
                      rtcText={tRef.current('Live Feed')}
                      onLineChangeCallback={onLineChange}
                      onMediaLoadedCallback={onMediaLoadedCallback}
                      linesData={stateDetails}
                      showExclusionArea={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                      showExclusionAreaOnModal={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                      exclusionAreas={(exclusionAreas.enabled && exclusionAreas.areas.length > 0) ? exclusionAreas.areas : []}
                      options={options}
                      width='700px'
                      height='388px'
                      onLineClickCallback={onLineClickCallback}
                    />
                  </LineUIRightPanel>
                  {isMediaLoaded && reverseRunData?.reverse_run_settings.length !== 0 &&
                    <LineUIActionsDisplayContainer>
                      <IconWrapper>
                        <Icon icon='PasswordShow' size={16} color='dimmed' />
                      </IconWrapper>
                      <SelectZoneShow changeCallback={(e) => handleZoneShow(e)} disabled={false} isCompact value={showAllOrSelectedOption}>
                        {['Only Active Area', 'Show All'].map((item, index) => {
                          return (
                            <option key={index} value={item}>{tRef.current(item)}</option>);
                        })}
                      </SelectZoneShow>
                    </LineUIActionsDisplayContainer>}
                </LineUIContainer>
              </LineSettingsContainer>
            </Container>
          </MainContainer>
      }
    </>
  );
};

export default ReverseRunDetection;