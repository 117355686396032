import * as React from "react";
const SvgNotificationsSnooze = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <path d="M20.5 1h3l-3 3.75h3M14.5 7h3.75l-3.75 4.5h3.75M7.998 22a2.087 2.087 0 0 0 4.005 0M10 3.25V1" />
        <path d="M13.215 4A7.424 7.424 0 0 0 10 3.25a7.5 7.5 0 0 0-7.5 7.5C2.5 16.638 1 19 1 19h18a10.017 10.017 0 0 1-1.25-3.663" />
      </g>
    </svg>
  );
};
export default SvgNotificationsSnooze;
