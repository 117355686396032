import React, { HTMLAttributes, useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Icon, SelectField } from 'scorer-ui-kit';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: fit-content;
  margin-right: 10px;
  white-space: nowrap;
`;

const SelectFieldContainer  = styled.div`
  display: flex;
  position: relative;
  select{
    background-color: #fff;
  }
  label{
    margin-bottom: 0;
    font-size: 12px;
  }
  label > div> div {
    top: 50% !important;
    transform: translateY(-45%);
  }
`;

const PageLabel = styled.label<{ active: boolean }>`
  padding:0 10px 0 10px;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fontFamily.ui };;
  font-size: 12px;
  font-weight: 500;
  color: #a4adb5;
  cursor: pointer;
  pointer-events: ${({ active }) => active ? 'auto' : 'none'};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 10px;
`;

const IconButton = styled.div<{isDisabled: boolean}>`
  padding: 0 9px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid hsla(120,1.3%,85.3%,1.000);
  border-radius: 3px;
  cursor: pointer;
  background-color: #fff;

  ${({isDisabled}) => isDisabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

const PageSizeContainer = styled.div`
  display: flex;
  margin-right: 28px;
`;

interface OwnProps {
  totalPages: number,
  pageSizeOptions?: number[],
  defaultPage?: number,
  defaultPageSize: number,
  onPageSizeChange: (size: number) => void,
  onPageChange: (page: number) => void,
  pageSizeText?: string,
  prevPageText?: string,
  nextPageText?: string,
  firstPageText?: string,
  lastPageText?: string
  showPrevNextButtons?: boolean
}

type Props = OwnProps & HTMLAttributes<HTMLDivElement>

const Pagination: React.FC<Props> = (props) => {
  const {
    pageSizeOptions = [10, 20, 30, 50, 100],
    defaultPageSize = 10,
    defaultPage = 1,
    pageSizeText = 'Items Per Page',
    prevPageText,
    nextPageText,
    lastPageText,
    showPrevNextButtons = false,
    totalPages,
    onPageSizeChange,
    onPageChange
  } = props;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [activePage, setActivePage] = useState(defaultPage);

  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [defaultPageSize]);
  
  useEffect(() => {
    setActivePage(defaultPage);
  }, [defaultPage]);

  const handlePageSizeChange = useCallback((event:string)=> {
    onPageSizeChange(Number(event));
    onPageChange(1);
    setActivePage(1);
  },[onPageSizeChange, onPageChange]);
  
  const handlePageChange = (value: number) => {
    onPageChange(value);
  };

  return (
    totalPages ?
      <PaginationContainer>
        {
          pageSizeOptions &&
            <PageSizeContainer>
              <SelectFieldContainer>
                <SelectField
                  changeCallback={handlePageSizeChange}
                  defaultValue={pageSize}
                  label={{
                    htmlFor: pageSizeText,
                    text: pageSizeText,
                    isSameRow: true
                  }}
                  isCompact
                >
                  {
                    pageSizeOptions.map((size: number, index: number) => <option key={index} value={size}>{size}</option>)
                  }
                </SelectField>
              </SelectFieldContainer>
            </PageSizeContainer>
        }
        <SelectFieldContainer>
          <SelectField
            changeCallback={(event) => handlePageChange(parseInt(event))}
            defaultValue={activePage}
            value={activePage}
            label={{
              htmlFor: prevPageText ?? '',
              text: prevPageText ?? '',
              isSameRow: true
            }}
            isCompact
          >
            {
              Array.from(Array(totalPages), (e, i) => 
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              )
            }
          </SelectField>
        </SelectFieldContainer>
        {
          nextPageText &&
            <PageLabel onClick={() => handlePageChange(activePage + 1)} active={activePage !== totalPages}>{nextPageText}</PageLabel>
        }
        {
          lastPageText &&
            <PageLabel onClick={() => handlePageChange(totalPages)} active={activePage !== totalPages}>{lastPageText}</PageLabel>
        }
        {
          showPrevNextButtons ? 
            <ButtonContainer>
              <IconButton title='Prev' isDisabled={activePage === 1} onClick={() => handlePageChange(activePage - 1)}>
                <Icon icon='Left' size={10} color='dimmed' />
              </IconButton>
              <IconButton title='Next' isDisabled={activePage === (totalPages)} onClick={() => handlePageChange(activePage + 1)}>
                <Icon icon='Right' size={10} color='dimmed' />
              </IconButton>
            </ButtonContainer>
            : null
        }
      </PaginationContainer>
      : null
  );
};

export default Pagination;
