import * as React from "react";
const SvgNotificationsAlert = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <path d="M7.998 22a2.087 2.087 0 0 0 4.005 0M10 3.25V1m0 2.25a7.5 7.5 0 0 0-7.5 7.5C2.5 16.638 1 19 1 19h18a10.017 10.017 0 0 1-1.25-3.663m-.5-6.187a.2.2 0 1 0 0 .4.2.2 0 0 0 0-.4h0m0-1.6v-4" />
        <path d="M11.533 7.433a.966.966 0 0 1 0-1.366l5.034-5.034a.964.964 0 0 1 1.366 0l5.034 5.034a.966.966 0 0 1 0 1.366l-5.034 5.034a.966.966 0 0 1-1.366 0l-5.034-5.034z" />
      </g>
    </svg>
  );
};
export default SvgNotificationsAlert;
