import { ICameraDetails } from 'interface';
import { isEqual } from 'lodash';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Button, Content, PageHeader, Spinner, useModal, useNotification } from 'scorer-ui-kit';
import { getCamera, getHitPointDetails, saveHitPointDetails } from 'services/camerasService';
import styled from 'styled-components';
import { IHitPointData } from './types';
import HitPointSettings from './HitPointSettings';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 54px 0 54px 90px;
  @media screen and (min-width: 1440px) {
    padding: 54px 40px 54px 170px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 940px !important;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const ResultText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.data };
  color: #5a6269;
  margin-top: -3px;
  padding: 0;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 941px !important;
  justify-content: space-between;
  margin-top: -22px;
  padding: 0;
  p {
    margin-top: -14px;
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
  > div > div {
    > h1 {
      margin-left: -1px;
    }
    > :nth-child(2) {
      bottom: 7px;
      left: -45px;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 0;
  > button {
    width: 133px;
  }
  > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  & > :nth-child(2) {
    width: 135px;
    margin-right: -2px;
    padding: 0 18px;
  }
`;

const AreaContainer = styled.span`
  min-width: 200px;
  max-width: 750px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
`;

const Divider = styled.div<{margin: string}>`
  height: 1px;
  margin: ${({margin}) => margin};
  border-radius: 3px;
  background-color: #efefef;
`;

const CancelModalContainer = styled.div`
  padding: 20px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  > #NoButton:focus {
    outline: 2px solid #838383;
  }

  > #YesButton:focus {
    outline: 2px solid #838383;
  }
`;

const HitPointContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
`;

interface IParams {
  streamName: string;
}

interface IProps {

}

const HitPoint: React.FC<IProps> = ({}) => {

  const {t} = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const {push} = useHistory();
  const { streamName } = useParams<IParams>();
  const [hpLoading, setHpLoading] = useState(true);
  const [hpSaveLoading, setHpSaveLoading] = useState(false);
  const [cameraDetails, setCameraDetails] = useState<ICameraDetails>();
  const [hpDetails, setHpDetails] = useState<IHitPointData>({
    hit_point: {
      x: 0,
      y: 0
    }
  });
  const [prevHpDetails, setPrevHpDetails] = useState<IHitPointData>({
    hit_point: {
      x: 0,
      y: 0
    }
  });
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const { isModalOpen, createModal, setModalOpen } = useModal();

  const fetchCamera = useCallback(() => {
    getCamera(streamName).then((res) => {
      if (res.status === 200 && res.data.data) {
        setCameraDetails(res.data.data);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    }).catch(() => {
      sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  }, [streamName]);

  const fetchHitPointDetails = useCallback(async () => {
    try {
      const res = await getHitPointDetails(streamName);
      if (res.data.message === 'Success' && res.data.data.length > 0) {
        setHpDetails(res.data.data[0]);
        setPrevHpDetails(res.data.data[0]);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    } catch (error) {
      console.error(error);
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});  
    } finally {
      setHpLoading(false);
    }
  }, [streamName]);

  useEffect(() => {
    fetchHitPointDetails();
    fetchCamera();
  }, [fetchCamera, fetchHitPointDetails]);

  const onSave = useCallback(async () => {
    try {
      const payload = hpDetails;
      if (hpDetails.hit_point.x === '') {
        sendNotificationRef.current({type: 'error', message: tRef.current('Please enter horizontal point')});
        setHpSaveLoading(false);
        return;
      }
      if (hpDetails.hit_point.y === '') {
        sendNotificationRef.current({type: 'error', message: tRef.current('Please enter vertical point')});
        setHpSaveLoading(false);
        return;
      }
      const res = await saveHitPointDetails(streamName, payload);
      if (res.data.message === 'Success') {
        setPrevHpDetails(hpDetails);
        sendNotificationRef.current({ type: 'success', message: tRef.current('Settings saved successfully')});
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});  
      }
    } catch (error) {
      console.error(error);
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});  
    } finally {
      setHpSaveLoading(false);
    }
  }, [streamName, hpDetails]);
  
  const handleCancel = useCallback(() => {
    setHpDetails(prevHpDetails);
    setModalOpen(false);
  }, [prevHpDetails, setModalOpen]);

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('NoButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('NoButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'NoButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('NoButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('NoButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const getCancelModal = useCallback(() => {
    return (
      <CancelModalContainer>
        <CancelConfirmText>{tRef.current('Are you sure you want to cancel the changes?')}</CancelConfirmText>
        <ModalButtonContainer>
          <Button id='NoButton' design='secondary' onClick={() => {setModalOpen(false);}}>{tRef.current('No')}</Button>
          <Button id='YesButton' onClick={() => handleCancel()}>{tRef.current('Yes')}</Button>
        </ModalButtonContainer>
      </CancelModalContainer>
    );
  }, [handleCancel, setModalOpen]);

  const goToPreviousPage = useCallback(() => {
    if(isEqual(hpDetails, prevHpDetails)){
      push('/cameras/camera-configuration/' + streamName);
      return;
    }
    const CancelModal: ReactElement = getCancelModal();
    createModal({ isCloseEnable: false, width: '400px', padding: false, customComponent: CancelModal });
  }, [streamName, hpDetails, prevHpDetails, createModal, getCancelModal, push]);

  const onHitPointChange = useCallback((x: number | string, y: number | string) => {
    setHpDetails({
      hit_point: { x ,y }
    });
  }, []);

  return (
    <>
      {
        (hpLoading) ?
          <SpinnerContainer>
            <Spinner size='large' styling='primary' />
            <ResultText>{t('Loading')}</ResultText>
          </SpinnerContainer>
          :
          <MainContainer>
            <Container>
              <Header>
                <PageHeaderComponent>
                  <AreaContainer onClick={goToPreviousPage}>{!cameraDetails?.camera_name ? 'Camera Name' : cameraDetails?.camera_name}</AreaContainer>
                  <PageHeader
                    title={t('Hit Point')}
                    icon='Analyse'
                    updateDocTitle={false}
                  />
                </PageHeaderComponent>
                <ButtonContainer>
                  <Button
                    design='secondary'
                    size='small'
                    onClick={goToPreviousPage}
                  >
                    {isEqual(hpDetails, prevHpDetails) ? t('Back') : t('Cancel')}
                  </Button>
                  <Button disabled={isEqual(hpDetails, prevHpDetails) || hpSaveLoading} design='primary' size='small' onClick={onSave}>
                    {t('Save Changes')}
                  </Button>
                </ButtonContainer>
              </Header>
              <Divider margin='5px 0 15px' />
              <HitPointContainer>
                <HitPointSettings
                  hitPointChange={onHitPointChange}
                  x={hpDetails.hit_point.x as number}
                  y={hpDetails.hit_point.y as number}
                />
              </HitPointContainer>
            </Container>
          </MainContainer>
      }
    </>
  );
};

export default HitPoint;