import React, { useCallback, useEffect, useRef, useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Button, ButtonWithIcon, Content, Icon, PageHeader, SelectField, SmallInput, Spinner, Switch, useModal, useNotification, Input, useMediaModal } from 'scorer-ui-kit';
import { getCamera, getTrafficSettings, saveTrafficCounterSettings, toggleConfigurationAnalysis } from 'services/camerasService';
import styled, {css} from 'styled-components';
import LineViewer from 'components/LineViewer/configurationLineViewer';
import cloneDeep from 'lodash.clonedeep';
import { NEW_LINE, EDGE_API_BASE_URL } from '../../constants';
import isEqual from 'lodash.isequal';
import { IPointSet, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import i18n from 'i18n';
import DisableConfirmationModal from 'components/DisableConfirmationModal';
import {IToggleAnalysisPayload, ICameraDetails, ITrafficCounterData, ITrafficCountingResponse, ILinePoints, ITrafficCounterDataMapped, ITrafficCountingResponseMapped, ILinePointsMapped } from '../../interface';
import { IExclusionAreas } from 'pages/CameraConfiguration/types';
import ImportSettingsModal from './ConfigurationImportModal';
import { compareChanges } from 'utils';
import axios from 'axios';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 54px 0 54px 90px;
  @media screen and (min-width: 1440px) {
    padding: 54px 40px 54px 170px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 940px !important;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const ResultText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.data};
  color: #5a6269;
  margin-top: -3px;
  padding: 0;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 941px !important;
  justify-content: space-between;
  margin-top: -22px;
  padding: 0;
  p {
    margin-top: -14px;
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
  > div > div {
    > h1 {
      margin-left: -1px;
    }
    > :nth-child(2) {
      bottom: 2px;
      left: -45px;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 0;
  > button {
    width: 133px;
  }
  > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  & > :nth-child(2) {
    width: 135px;
    margin-right: -2px;
    padding: 0 18px;
  }
`;

const AreaContainer = styled.span`
  min-width: 200px;
  max-width: 750px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
`;

const UnsavedChangesText = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.ui};
`;

const ToggleDetection = styled.div<{ state?: string }>`
  display: flex;
  align-items: center;
  margin-top: -4px;
  gap: 0 12px;
  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
    `}
  margin-bottom: 15px;
`;

const SmallLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
`;

const InputAndButtonContainer = styled.div<{ gap: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  & > button {
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #efefef;
`;

const LineSettingsContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  margin: 4px 0;
`;

const LineSelectFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  position: relative;
  & > div:first-child {
    display: flex;
    position: absolute;
    top: 6px;
    left: -24px;
  }
`;

const StyledSelectField = styled(SelectField)`
  width: 229px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  } // to align drop down icon
`;

const ImportExportContainer = styled.div<{ gap: string; lang?: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 11px;
  margin-top: -15px;
  margin-bottom: 15px;

  > button {
    padding: 0 8px;
    > div > :first-child {
      padding-left: 12px;
    }
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const LineUIContainer = styled.div`
  width: 100%;
  min-height: 390px;
  margin: 30px 0 15px;
  display: flex;
  border-radius: 3px;
  border: solid 1px #eee;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.02);
  background-color: rgba(252, 252, 253, 0.85);
  position: relative;

  &::before {
    content: "";
    width: 14.5px;
    height: 51px;
    position: absolute;
    top: -28px;
    left: -16px;
    border-left: 2px solid #d9dad9;
    border-bottom: 2px solid #d9dad9;
  }
`;

const LineUILeftPanel = styled.div`
  flex-shrink: 0;
  width: 239px;
  min-height: 390px;
  padding: 14px 0;
`;

const SidePaddingDiv = styled.div`
  position: relative;
  padding: 0 14px;
  & > div > label > span {
    margin-bottom: 5px !important;
  }
  & > div > label > div {
    width: 211px !important;
  }
`;

const DirectionSelectFieldContainer = styled.div`
  > div > label {
    margin-bottom: 10px;
  }
`;
const DirectionSelectField = styled(SelectField)`
  background: #fff;
  &:disabled {
    opacity: 0.7;
    background-color: #EFEFEF4D;
  }
`;

const DirectionInputField = styled.div`
  position: relative;
  & > div {
    margin-bottom: 10px;
  }
`;

const Circle = styled.div<{ size: number }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%) scale(${({ size }) => size});
  background: hsla(203, 100%, 35%, 0.49);
  & > div {
    display: flex;
    margin-left: 0.4px;
  }
`;

const ColoredButton = styled(Button)`
  width: 100%;
  background: #e4edf4;
  :hover:enabled {
    background: #d4e4f0;
  }
  :disabled {
    background: #e9f0f6;
  }
`;

interface ISmallInput {
  length: string;
}

const DirectionLabel = styled.div`
  color: hsl(207, 5%, 57%);
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  margin-bottom: 3px;
`;

const StyledInput = styled(Input)<ISmallInput>`
  width: ${({ length }) => length};
  min-height: 30px;
  padding-left: 10px;
  padding-right: 30px;
  &:disabled {
    background-color: #EFEFEF4D;
  }
`;

const StyledSmallInputWithLabel = styled(SmallInput)<ISmallInput>`
  & > label {
    width: ${({ length }) => length};
    margin-bottom: 0;
  }
`;

const DividerWithMargin = styled(Divider)`
  margin: 12px 0 12px;
`;

const LineUIRightPanel = styled.div`
  flex: 1;
  flex-shrink: 0;
  border-radius: 3px;
`;

const CancelModalContainer = styled.div`
  padding: 18px 48px 22px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const LineUIActionsDisplayContainer = styled.div<{ lang?: string; isIncreaseWidth?: boolean }>`
  display: flex;
  justify-content: flex-end;
  width: ${({ lang, isIncreaseWidth }) => lang === 'ja' && isIncreaseWidth ? '442px' : '396.5px'};
  height: max-content;
  & > div:nth-child(1) {
    margin-top: -3px;
  }
  & > div:nth-child(2) {
    margin-top: -14px;
  }
  position: absolute;
  z-index: 10;
  bottom: -34px;
`;

const IconWrapper = styled.div``;

const SelectZoneShow = styled(SelectField)<{ lang?: string; isIncreaseWidth?: boolean }>`
  width: ${({ lang, isIncreaseWidth }) => lang === 'ja' && isIncreaseWidth ? '186px' : '138px'};
  height: 30px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 25px 0 8px !important;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-style: normal !important;
  color: #8b9196 !important;
  letter-spacing: -0.12px;
  & + div {
    right: 14.5px !important;
  }
`;

const CancelChangesButton = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const options: LineUIOptions = {
  showSetIndex: false,
  showPoint: true,
  showDirectionMark: true,
  showMoveHandle: false,
  showPointHandle: true,
  setIndexOffset: 0,
  boundaryOffset: 0
};

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

type TraficCounterRes = ITrafficCounterDataMapped;
type TraficCounterKeys = keyof TraficCounterRes;
type LineKeys = keyof ITrafficCountingResponseMapped;
type PointsKeys = keyof ILinePointsMapped;
type CheckTypeValue = number | string | boolean | ITrafficCountingResponseMapped[] | ILinePointsMapped | undefined;

const checkType = (key: string, value: CheckTypeValue): boolean => {
  switch (key) {
  case 'traffic_counting':
    return Array.isArray(value);
  case 'name':
  case 'forward_label':
  case 'backward_label':
    return typeof value === 'string';
  case 'x0':
  case 'x1':
  case 'y0':
  case 'y1':
    return typeof value === 'number';
  case 'reference_line':
    return true;
  case 'direction_label_mode':
    return typeof value === 'string';
  default:
    return false;
  }
};

const LinePoints = [
  {
    start_x: 42,
    start_y: 123,
    end_x: 141,
    end_y: 42
  },
  {
    start_x: 69,
    start_y: 221,
    end_x: 287,
    end_y: 59
  },
  {
    start_x: 91,
    start_y: 311,
    end_x: 371,
    end_y: 104
  },
  {
    start_x: 119,
    start_y: 453,
    end_x: 293,
    end_y: 287
  },
  {
    start_x: 355,
    start_y: 229,
    end_x: 510,
    end_y: 80
  },
  {
    start_x: 119,
    start_y: 580,
    end_x: 373,
    end_y: 329
  },
  {
    start_x: 448,
    start_y: 263,
    end_x: 629,
    end_y: 75
  },
  {
    start_x: 227,
    start_y: 616,
    end_x: 514,
    end_y: 336
  },
  {
    start_x: 594,
    start_y: 274,
    end_x: 775,
    end_y: 60
  },
  {
    start_x: 362,
    start_y: 645,
    end_x: 629,
    end_y: 380
  },
  {
    start_x: 706,
    start_y: 316,
    end_x: 887,
    end_y: 77
  },
  {
    start_x: 543,
    start_y: 653,
    end_x: 750,
    end_y: 406
  },
  {
    start_x: 819,
    start_y: 362,
    end_x: 1020,
    end_y: 77
  },
  {
    start_x: 717,
    start_y: 636,
    end_x: 867,
    end_y: 441
  },
  {
    start_x: 951,
    start_y: 373,
    end_x: 1130,
    end_y: 95
  },
  {
    start_x: 865,
    start_y: 653,
    end_x: 989,
    end_y: 490
  },
  {
    start_x: 1048,
    start_y: 421,
    end_x: 1194,
    end_y: 201
  },
  {
    start_x: 1011,
    start_y: 647,
    end_x: 1088,
    end_y: 545
  },
  {
    start_x: 1130,
    start_y: 505,
    end_x: 1209,
    end_y: 404
  },
  {
    start_x: 1130,
    start_y: 677,
    end_x: 1227,
    end_y: 574
  },
];

const TrafficCounter = () => {
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const { push } = useHistory();
  const stateRef = useRef<IPointSet[]>([]);
  const { streamName }: { streamName: string } = useParams();
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const { createModal, setModalOpen, isModalOpen } = useModal();
  const [loading, setLoading] = useState(false);
  const [cameraData, setCameraData] = useState<ICameraDetails>();
  const [directionOption, setDirectionOption] = useState<string>('Automatic');
  const [lineReference, setLineReference] = useState<string>('');
  const [directionLabel, setDirectionLabel] = useState<{ forward: string, backward: string }>({ forward: '', backward: '' });
  const [trafficCounterdata, setTrafficCounterdata] = useState<ITrafficCounterData>({analysis_enabled: false, traffic_counting_settings:[]});
  const [prevValuesForCompare, setPrevValuesForCompare] = useState<ITrafficCounterData>({analysis_enabled: false, traffic_counting_settings:[]});
  const [prevReverseRunData, setPrevReverseRunData] = useState<ITrafficCounterData>({analysis_enabled: false, traffic_counting_settings:[]});
  const [exclusionAreas, setExclusionAreas] = useState<IExclusionAreas>({enabled: false, areas: []});
  const [lineSelectOptions, setLineSelectOptions] = useState<string[]>([NEW_LINE]);
  const [unSavedChanges, setUnsavedChanges] = useState<number>(0);
  const [selectedLineIndex, setSelectedLineIndex] = useState<number>(0);
  const [selectedLine, setSelectedLine] = useState<string>(NEW_LINE);
  const [reverseSaveLoading, setReverseSaveLoading] = useState(false);
  const [showAllZone, setShowAllZone] = useState(true);
  const [showAllOrSelectedOption, setShowAllOrSelectedOption] = useState('Show All');
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [stateDetails, setStateDetails] = useState<IPointSet[]>([]); 
  const [toggleAnalysis, setToggleAnalysis] = useState(false);
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const [image, setImage] = useState('');
  const { isMediaUrlValid } = useMediaModal();
  const [isImporting, setIsImporting] = useState(true);
  const [isIncreaseWidth, setIsIncreaseWidth] = useState(false);

  // Update for every line change
  useEffect(() => {
    const state: IPointSet[] = [];
    if (showAllOrSelectedOption === 'Show All') {
      trafficCounterdata?.traffic_counting_settings?.forEach((item) => {      
        const val: string | undefined = item.name?.trim().match(/\d/g)?.join('');
        const valNum=val?parseInt(val):0;
        state.push({
          points: [
            { x: item.points.start_x, y: item.points.start_y },
            { x: item.points.end_x, y: item.points.end_y }
          ],
          showPointHandle: (item.idx === selectedLineIndex),
          name: item.name.length > 24 ? item.name.slice(0, 24) : item.name,
          styling: item.styling === 'danger' ? item.styling : (valNum % 2 === 0 ? 'secondary' : 'primary'),
          showSmallDirectionMark: true
        });
      });
    } else {
      if (selectedLineIndex !== 0) {
        const lines = trafficCounterdata?.traffic_counting_settings[selectedLineIndex - 1];
        const val: string | undefined = lines.name?.trim().match(/\d/g)?.join('');
        const valNum=val?parseInt(val):0;
        state.push({
          name: lines!.name.length > 24 ? lines!.name.slice(0, 24) : lines.name,
          points: [
            { x: lines!.points.start_x, y: lines!.points.start_y },
            { x: lines!.points.end_x, y: lines!.points.end_y }
          ],
          showPointHandle: true,
          styling: lines!.styling === 'danger' ? lines!.styling : (valNum % 2 === 0 ? 'primary' : 'secondary'),
          showSmallDirectionMark: true
        });
      }
    }
    setStateDetails(state);
    stateRef.current = state;
  }, [trafficCounterdata, selectedLineIndex, showAllOrSelectedOption]);

  // calculates unsaved changes
  useEffect(() => {
    const currentExclusionAreaSettings = trafficCounterdata?.traffic_counting_settings.map(obj => {
      const newObj = { ...obj };
      delete newObj.idx;
      delete newObj.showMoveHandle;
      delete newObj.showPointHandle;
      return newObj;
    });
    const prevExclusionAreaSettings = prevValuesForCompare?.traffic_counting_settings.map(obj => {
      const newObj = { ...obj };
      delete newObj.idx;
      delete newObj.showMoveHandle;
      delete newObj.showPointHandle;
      return newObj;
    });

    const updatedPrevExclusionSettings = prevExclusionAreaSettings.map(({direction_type_auto, ...rest}) => rest);
    
    const prevSettings = {
      analysis_enabled: trafficCounterdata?.analysis_enabled,
      traffic_counting_settings: updatedPrevExclusionSettings
    };

    const updatedCurrentExclusionSettings = currentExclusionAreaSettings.map(({direction_type_auto, ...rest}) => rest);

    const currentDetails:ITrafficCounterData = {analysis_enabled: trafficCounterdata?.analysis_enabled, traffic_counting_settings: updatedCurrentExclusionSettings};
    const changesCount = compareChanges(currentDetails, prevSettings, 'traffic_counting_settings') || 0;
    setUnsavedChanges(changesCount);
    return () => {};
  }, [trafficCounterdata, prevValuesForCompare]);

  const fetchCamera = useCallback(() => {
    getCamera(streamName).then((res) => {
      if (res.status === 200 && res.data.data) {
        setCameraData(res.data.data);
      } else {
        sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
      }
    }).catch(() => {
      sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  }, [streamName]);

  const getTrafficCountingData = useCallback(async () => {
    try {
      const res = await getTrafficSettings(streamName);
      if (res.data.status_code === 0 && res.data.data) {
        setUnsavedChanges(0);
        if (res.data.exclusion_areas) {
          const {enabled: enabledExclusion, exclusion_zone_settings = []} = res.data.exclusion_areas;
          setExclusionAreas({
            enabled: enabledExclusion,
            areas: exclusion_zone_settings.map(area => ({points: area.points}))
          });
        }
        setTrafficCounterdata(res.data.data);
        setPrevValuesForCompare(res.data.data);
        setPrevReverseRunData(cloneDeep(res.data.data));
        const zoneSelect = res.data.data?.traffic_counting_settings.map(area => area.name);

        zoneSelect.unshift(NEW_LINE);
        setLineSelectOptions(zoneSelect);
        setSelectedLineIndex(0);
        setSelectedLine(NEW_LINE);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    } catch (error) {
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
    } finally {
      setLoading(false);
    }
  }, [streamName]);

  useEffect(() => {
    fetchCamera();
    getTrafficCountingData();
  }, [streamName, push, fetchCamera, getTrafficCountingData]);

  const onLineSelect = useCallback((e: string) => {
    setSelectedLine(e);
    if (e === NEW_LINE || e === '［新規エリア］') {
      setSelectedLineIndex(0);
    } else {
      setSelectedLineIndex(parseInt(e.substring(0, e.indexOf(':'))));
    }
  },[]);

  const onChangeReverseRunData = useCallback((formData) => {
    setTrafficCounterdata({ ...formData });
  }, []);

  const handleAnalysis = useCallback((value)=>{
    setToggleAnalysis(true);
    const newExclusionZonesData = cloneDeep(trafficCounterdata);
    newExclusionZonesData!.analysis_enabled = value;
    onChangeReverseRunData(newExclusionZonesData);

    const payload: IToggleAnalysisPayload ={
      action: value ? 'enable' : 'disable',
      analysis_type: ['traffic_counter']
    };
    toggleConfigurationAnalysis(streamName, payload).then((res: { data: { status_code: number; }; }) => {
      if (res.data.status_code === 0) {
        sendNotificationRef.current({ type: 'success', message: value ? tRef.current('Analysis enabled successfully') : tRef.current('Analysis disabled successfully')});
        setToggleAnalysis(false);
      } else { 
        newExclusionZonesData!.analysis_enabled = !value;
        onChangeReverseRunData(newExclusionZonesData);
        sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        setToggleAnalysis(false); }
    }).catch(() => {
      newExclusionZonesData!.analysis_enabled = !value;
      onChangeReverseRunData(newExclusionZonesData);
      setToggleAnalysis(false);
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  },[onChangeReverseRunData, streamName, trafficCounterdata]);

  const onMediaLoadedCallback = useCallback(() => {
    setIsMediaLoaded(true);
  }, []);

  const handleCancel = useCallback(async (setModalOpen: (newStatus: boolean) => void) => {
    setSelectedLineIndex(0);
    await setModalOpen(false);
    if(prevReverseRunData) {
      setTrafficCounterdata(prev => ({...prevReverseRunData, analysis_enabled: prev.analysis_enabled}));
      const zoneSelect = prevReverseRunData.traffic_counting_settings.map(area => area.name);
      zoneSelect.unshift(NEW_LINE);
      setLineSelectOptions(zoneSelect);
      setSelectedLineIndex(0);
      setSelectedLine(NEW_LINE);
    }
  }, [prevReverseRunData]);

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('CancelButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('CancelButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'CancelButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('CancelButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('CancelButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const handleCancleNo = useCallback(() =>{
    setModalOpen(false);
  },[setModalOpen]);

  const getCancelModal = useCallback((setModalOpen: (newStatus: boolean) => void) => {
    return (
      <CancelModalContainer>
        <CancelConfirmText>{tRef.current('Are you sure you want to cancel the changes?')}</CancelConfirmText>
        <ModalButtonContainer>
          <CancelChangesButton id='CancelButton' design='secondary' onClick={() => handleCancleNo()}>{tRef.current('No')}</CancelChangesButton>
          <CancelChangesButton id='YesButton' onClick={() => handleCancel(setModalOpen)}>{tRef.current('Yes')}</CancelChangesButton>
        </ModalButtonContainer>
      </CancelModalContainer>
    );
  }, [handleCancel, handleCancleNo]);

  const onLineChange = useCallback((state: IPointSet[]) => {
    if (selectedLineIndex === 0) return;
    const newReverseRunSettings = cloneDeep(trafficCounterdata);
    const selectedLines = state.filter(line => line.showPointHandle);
    newReverseRunSettings!.traffic_counting_settings[selectedLineIndex - 1] = {
      ...newReverseRunSettings!.traffic_counting_settings[selectedLineIndex - 1],
      points: { 
        start_x: selectedLines[0].points[0].x, 
        start_y: selectedLines[0].points[0].y,
        end_x: selectedLines[0].points[1].x, 
        end_y: selectedLines[0].points[1].y }
    };

    onChangeReverseRunData({ ...newReverseRunSettings });
  }, [selectedLineIndex, trafficCounterdata, onChangeReverseRunData]);

  const onAddLine = useCallback(() => {
    const addedNumberList: any[] =[];
    trafficCounterdata.traffic_counting_settings.map(d=>{
      const val: string | undefined = d.name?.trim().match(/\d/g)?.join('');
      if(val){
        addedNumberList.push(parseInt(val));
      }
    });
    const filteredLineNumbers= [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].filter(num=>addedNumberList.indexOf(num)===-1).sort((a, b) => a - b);
   
   
    const newZoneDetail = {
      idx: trafficCounterdata.traffic_counting_settings?.length + 1,
      name: (`${t('Counter Line')} ` + filteredLineNumbers[0].toString()),
      direction_type: 'Automatic',
      fwd_direction_label: '',
      back_direction_label: '',
      points: LinePoints[filteredLineNumbers[0]-1] || {
        start_x: 0,
        start_y: 556,
        end_x: 1280,
        end_y: 720
      }
    };
    const updatedSettings = [...(trafficCounterdata?.traffic_counting_settings || []), newZoneDetail];
    const addNewZone = {
      ...trafficCounterdata,
      traffic_counting_settings: updatedSettings
    };
    // eslint-disable-next-line no-useless-concat
    onLineSelect(i18n.language === 'ja' ? `${trafficCounterdata.traffic_counting_settings?.length + 1}: エリア${trafficCounterdata.traffic_counting_settings?.length + 1}` : `${trafficCounterdata.traffic_counting_settings?.length + 1}: Line ${trafficCounterdata.traffic_counting_settings?.length + 1}`);
    setLineSelectOptions(prev => [...prev, i18n.language === 'ja' ? 'エリア' + (trafficCounterdata.traffic_counting_settings?.length + 1).toString() : 'Line ' + (trafficCounterdata.traffic_counting_settings?.length + 1).toString()]);
    onChangeReverseRunData(addNewZone);     
    sendNotificationRef.current({type: 'neutral', message: tRef.current('New line added'), icon: 'FeatureLineUi'});
  },[trafficCounterdata, onLineSelect, onChangeReverseRunData, t]);

  const onRemoveLine = useCallback(() => {
    const updatedData = {
      ...trafficCounterdata,
      traffic_counting_settings: trafficCounterdata?.traffic_counting_settings.filter(
        (item) => item.idx !== selectedLineIndex
      ).map((item, index) => ({ ...item, idx: index + 1 }))
    };
    const remZoneSelectOptions = updatedData?.traffic_counting_settings.map(item => item.name);
    remZoneSelectOptions.unshift(NEW_LINE);
    setLineSelectOptions(remZoneSelectOptions);
    setSelectedLineIndex(remZoneSelectOptions.length - 1);
    if (remZoneSelectOptions[remZoneSelectOptions.length - 1] === NEW_LINE) {
      setSelectedLine(NEW_LINE);
    } else {
      setSelectedLine(`${remZoneSelectOptions.length - 1}: ${remZoneSelectOptions[remZoneSelectOptions.length - 1]}`);
    }
    onChangeReverseRunData(updatedData);  
    sendNotificationRef.current({ type: 'neutral', message: tRef.current('Line removed'), icon: 'FeatureLineUi'});
  },[trafficCounterdata, onChangeReverseRunData, selectedLineIndex]);

  const validateTrafficCounterData = useCallback((data: ITrafficCounterData) => {
    const { traffic_counting_settings } = data;

    let idx = -1, type = '';
    const isEveryLineValid = traffic_counting_settings.every((item, index) => {
      if (!item.name) {
        type = 'name';
        idx = index;
        return false;
      } else if (item.direction_type === 'Manual' && !item.fwd_direction_label) {
        type = 'fwdLabel';
        idx = index;
        return false;
      } else if (item.direction_type === 'Manual' && !item.back_direction_label) {
        type = 'backLabel';
        idx = index;
        return false;
      } 
      return true;
    });
    if (!isEveryLineValid) {
      if (type === 'name') {
        sendNotification({type: 'error', message: t('Please enter line reference for line #{X}').replace('{X}', `${idx + 1}`)});
      } else if (type === 'backLabel') {
        sendNotification({type: 'error', message: t('Please enter backward label for {X}').replace('{X}', `${traffic_counting_settings[idx].name}`)});
      } else if (type === 'fwdLabel') {
        sendNotification({type: 'error', message: t('Please enter forward label for {X}').replace('{X}', `${traffic_counting_settings[idx].name}`)});
      } 
      return false;
    }
    
    const areaNames = new Set();
    for (const item of traffic_counting_settings) {
      if (areaNames.has(item.name)) {
        sendNotificationRef.current({type: 'error', message: tRef.current('{X} already exists').replace('{X}', (item.name))});
        return false;
      }
      areaNames.add(item.name);
    }
    return true;
  }, [t, sendNotification]);

  const onClickSave = useCallback((data = trafficCounterdata) => {
    if(validateTrafficCounterData(data)){
      onChangeReverseRunData({ ...trafficCounterdata });
      setReverseSaveLoading(true);
      saveTrafficCounterSettings(streamName, data).then((res: { data: { status_code: number; }; }) => {
        if (res.data.status_code === 0) {
          setPrevReverseRunData(cloneDeep(trafficCounterdata));
          sendNotificationRef.current({ type: 'success', message: tRef.current('Settings saved successfully')});
          fetchCamera();
          getTrafficCountingData();
        } else { sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') }); }
        setReverseSaveLoading(false);
      }).catch(() => {
        setReverseSaveLoading(false);
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      });
    }
  }, [trafficCounterdata, validateTrafficCounterData, onChangeReverseRunData, streamName, fetchCamera, getTrafficCountingData]);

  const handleZoneShow = useCallback((e) => {
    if(e === 'Only Active Line'){
      setIsIncreaseWidth(true);
    } else {
      setIsIncreaseWidth(false);
    }
    setShowAllZone(!showAllZone);
    setShowAllOrSelectedOption(e);
  },[showAllZone]);

  const onLineClickCallback = useCallback((lineId: number) => {
    setSelectedLineIndex(lineId + 1);
    setSelectedLine(lineId + 1 + ': ' + trafficCounterdata?.traffic_counting_settings[lineId]?.name);
  },[trafficCounterdata]);

  const goToPreviousPage = useCallback(() =>{
    if(isEqual(trafficCounterdata.traffic_counting_settings, prevValuesForCompare.traffic_counting_settings)){
      push('/cameras/camera-configuration/' + streamName);
      return;
    }
    const CancelModal: ReactElement = getCancelModal(setModalOpen);
    createModal({ isCloseEnable: false, width: '400px', padding: false, customComponent: CancelModal });
  },[createModal, getCancelModal, push, prevValuesForCompare, trafficCounterdata, streamName, setModalOpen]);

  const diableCallBack = useCallback((isEnabled: boolean) => {
    setModalOpen(false);
    onClickSave({...trafficCounterdata, analysis_enabled: isEnabled});
  }, [trafficCounterdata, setModalOpen, onClickSave]);

  const openConfirmationModal = useCallback(() => {
    if (trafficCounterdata.analysis_enabled) {
      onClickSave(trafficCounterdata);
    } else {
      const isValidData = validateTrafficCounterData(trafficCounterdata);
      if(isValidData){
        const ConfirmationModal: ReactElement = <DisableConfirmationModal onCallback={diableCallBack} />;
        createModal({ isCloseEnable: false, width: 'auto', padding: false, customComponent: ConfirmationModal });
      }
    }
  }, [trafficCounterdata, validateTrafficCounterData, createModal, onClickSave, diableCallBack]);

  // This will update line details on change of any detail like (name, large vehicle width, direction, swapping of line)
  useEffect(() => {
    if (selectedLineIndex !== 0) {
      const lineName = trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.name;
      setLineReference(lineName);
      setLineSelectOptions(prev => {
        const temp = JSON.parse(JSON.stringify(prev));
        temp[selectedLineIndex] = lineName;
        return temp;
      });
      onLineSelect(selectedLineIndex + ': ' + lineName);
    } else {
      setLineReference('');
      setDirectionOption('Automatic');
    }
  }, [selectedLineIndex, trafficCounterdata, onLineSelect]);

  useEffect(() => {
    if (selectedLineIndex !== 0) {
      if (trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.direction_type === 'Automatic') {
        setDirectionLabel({ forward: '', backward: '' });
      }
    } else {
      if (directionOption === 'Automatic') {
        setDirectionLabel({ forward: '', backward: '' });
      }
    }
  }, [selectedLineIndex, directionOption, trafficCounterdata]);

  const onDirectionSelect = useCallback((e) => {
    const newCongestionStopData = cloneDeep(trafficCounterdata);
    newCongestionStopData.traffic_counting_settings[selectedLineIndex - 1].direction_type = e;
    onChangeReverseRunData(newCongestionStopData); 
  }, [trafficCounterdata, selectedLineIndex, onChangeReverseRunData]);

  const onDirectionManualChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;

    const newCongestionStopData = cloneDeep(trafficCounterdata);
    if(name === 'forward'){
      newCongestionStopData.traffic_counting_settings[selectedLineIndex - 1].fwd_direction_label = value;
    } else {
      newCongestionStopData.traffic_counting_settings[selectedLineIndex - 1].back_direction_label = value;
    }
    onChangeReverseRunData(newCongestionStopData); 
  }, [trafficCounterdata, selectedLineIndex, onChangeReverseRunData]);

  const swapLineDirection = useCallback(() => {
    const newCongestionStopData = cloneDeep(trafficCounterdata);
    newCongestionStopData.traffic_counting_settings[selectedLineIndex - 1].points = {
      start_x: newCongestionStopData.traffic_counting_settings[selectedLineIndex - 1].points.end_x,
      end_x: newCongestionStopData.traffic_counting_settings[selectedLineIndex - 1].points.start_x,
      start_y: newCongestionStopData.traffic_counting_settings[selectedLineIndex - 1].points.end_y,
      end_y: newCongestionStopData.traffic_counting_settings[selectedLineIndex - 1].points.start_y,
    };
    onChangeReverseRunData(newCongestionStopData);
  }, [trafficCounterdata, selectedLineIndex, onChangeReverseRunData]);

  const onLineReferenceChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const newLineSelectOptions = [...lineSelectOptions];
    newLineSelectOptions[selectedLineIndex] = value;
    setLineSelectOptions(newLineSelectOptions);
    setSelectedLine(selectedLineIndex + ': ' + value);
    const newCongestionStopData = cloneDeep(trafficCounterdata);
    newCongestionStopData.traffic_counting_settings[selectedLineIndex - 1].name = value;
    onChangeReverseRunData(newCongestionStopData); 
  }, [selectedLineIndex, lineSelectOptions, trafficCounterdata, onChangeReverseRunData]);

  const onExportSettings = useCallback(() => {
    const { traffic_counting_settings } = trafficCounterdata;
    const exportLines = traffic_counting_settings.map(({ idx, direction_type_auto, points, direction_type, fwd_direction_label, back_direction_label, ...rest }) => {
      const reference_line: ILinePointsMapped = {
        x0: points.start_x,
        x1: points.end_x,
        y0: points.start_y,
        y1: points.end_y,
      };
      return { ...rest, reference_line, direction_label_mode: direction_type, forward_label: fwd_direction_label, backward_label: back_direction_label };
    });
    
    try {
      let modifiedCameraName = cameraData?.camera_name || '';
      if (modifiedCameraName.length > 50) {
        modifiedCameraName = modifiedCameraName.substring(0, 50) + '...';
      }
      const fileName = `${modifiedCameraName}_traffic_counting.json`;
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({ traffic_counting: exportLines }, null, 4));
      const link = document.createElement('a');
      link.setAttribute('href', dataStr);
      link.setAttribute('download', fileName);
      link.click();
      sendNotification({ type: 'success', message: t('Settings exported successfully')});
    } catch (error) {
      sendNotification({type: 'error', message: t('An error occurred while exporting data')});
    }
  }, [trafficCounterdata, cameraData, sendNotification, t]);

  const getLinePoints = useCallback((points: ILinePoints) => {
    let changeLineStyle = false;
    const boundaryOffset = 0;

    if (image === '') {
      return { points: points, changeLineStyle };
    }

    // checks if any point goes outside the boundary of image and trims those points
    if (
      (points.start_x < boundaryOffset || points.start_x > (imageDetails.x - boundaryOffset)) ||
      (points.start_y < boundaryOffset || points.start_y > (imageDetails.y - boundaryOffset)) ||
      (points.end_x < boundaryOffset || points.end_x > (imageDetails.x - boundaryOffset)) ||
      (points.end_y < boundaryOffset || points.end_y > (imageDetails.y - boundaryOffset))
    ) {
      changeLineStyle = true;
    }
    points.start_x = points.start_x < boundaryOffset ? boundaryOffset : points.start_x > (imageDetails.x - boundaryOffset) ? (imageDetails.x - boundaryOffset) : points.start_x;
    points.start_y = points.start_y < boundaryOffset ? boundaryOffset : points.start_y > (imageDetails.y - boundaryOffset) ? (imageDetails.y - boundaryOffset) : points.start_y;
    points.end_x = points.end_x < boundaryOffset ? boundaryOffset : points.end_x > (imageDetails.x - boundaryOffset) ? (imageDetails.x - boundaryOffset) : points.end_x;
    points.end_y = points.end_y < boundaryOffset ? boundaryOffset : points.end_y > (imageDetails.y - boundaryOffset) ? (imageDetails.y - boundaryOffset) : points.end_y;

    return {points, changeLineStyle};
  }, [image, imageDetails]);

  const importSettings = useCallback((importedTrafficCounterData: ITrafficCounterData) => {
    const { traffic_counting_settings = [] } = importedTrafficCounterData;
    if (traffic_counting_settings.length > 0) {
      const lineInfo: ITrafficCountingResponse[] = [];
      setLineSelectOptions(['[New Line]']);
      traffic_counting_settings.map((item, index) => {
        const point = cloneDeep(item.points);
        const { points, changeLineStyle } = getLinePoints(point);
        const lineName = !item.name ? `Line${index}` : item.name.slice(0, 24);
        const forward = item.direction_type === 'Manual' && !item.fwd_direction_label ? `FwdLabel-line${index}` : item.fwd_direction_label.slice(0, 64);
        const backward = item.direction_type === 'Manual' && !item.back_direction_label ? `BackLabel-line${index}` : item.back_direction_label.slice(0, 64);

        setLineSelectOptions(prev => [...prev, lineName]);

        lineInfo.push(
          {
            idx: item.idx,
            name: lineName,
            direction_type: item.direction_type,
            fwd_direction_label: forward,
            back_direction_label: backward,
            styling: changeLineStyle ? 'danger' : item.styling,
            points: {
              start_x: points.start_x,
              start_y: points.start_y,
              end_x: points.end_x,
              end_y: points.end_y
            }
          }
        );
        return {
          name: index + 1 + ': ' + lineName,
          points: [
            { x: points.start_x, y: points.start_y },
            { x: points.end_x, y: points.end_y }
          ],
          readOnly: true,
          styling: changeLineStyle ? 'danger' : 'primary',
          showSmallDirectionMark: true
        };
      });
      setTrafficCounterdata({...trafficCounterdata, traffic_counting_settings: lineInfo});
    } else {
      setTrafficCounterdata({...trafficCounterdata, traffic_counting_settings: []});
    }
  }, [trafficCounterdata, getLinePoints]);

  const validateImporting = useCallback((importedTrafficCounterData) => {
    importedTrafficCounterData.traffic_counting.forEach((item: { points: any; reference_line: any; direction_type: string; direction_label_mode: any; fwd_direction_label: string; forward_label: any; back_direction_label: string; backward_label: any; idx: number; }, index: number) => {
      const { reference_line, direction_label_mode, forward_label, backward_label } = item;
      item.idx = index + 1;
      item.points = {
        start_x: reference_line.x0,
        end_x: reference_line.x1,
        start_y: reference_line.y0,
        end_y: reference_line.y1,
      };
      item.direction_type = direction_label_mode;
      item.fwd_direction_label = forward_label;
      item.back_direction_label = backward_label;
      delete item.reference_line;
      delete item.direction_label_mode;
      delete item.forward_label;
      delete item.backward_label;
    });
    
  
    const newImportedData = {
      traffic_counting_settings: importedTrafficCounterData.traffic_counting,
      analysis_enabled: prevValuesForCompare.analysis_enabled
    };
    const updatedPrevValuesForCompare = prevValuesForCompare.traffic_counting_settings.map(({direction_type_auto, ...rest}) => rest);
    if(isEqual(updatedPrevValuesForCompare, newImportedData.traffic_counting_settings)){
      getTrafficCountingData();
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    } else {
      setSelectedLineIndex(0);
      setSelectedLine(NEW_LINE);
      setTrafficCounterdata({...trafficCounterdata, traffic_counting_settings: []});
      importSettings({...newImportedData});
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    }
  }, [t, sendNotification, importSettings, setModalOpen, trafficCounterdata, prevValuesForCompare, getTrafficCountingData]);

  const isValidImportFile = useCallback((jsonData) => {
    let isLinesValid = false;
    const validTraficCounterKeys: TraficCounterKeys[] = ['traffic_counting'];
    const validLineKeys: LineKeys[] = ['forward_label', 'backward_label', 'name', 'reference_line', 'direction_label_mode'];
    const validPointsKeys: PointsKeys[] = ['x0', 'x1', 'y0', 'y1'];

    try {
      let resKeys: TraficCounterKeys[] = [], lineKeys: LineKeys[] = [], pointsKeys: PointsKeys[] = [];
      const resObj: TraficCounterRes = JSON.parse(jsonData);

      resKeys = Object.keys(resObj) as Array<keyof typeof resObj>;

      if (
        resKeys.every(key => validTraficCounterKeys.includes(key)) &&
        validTraficCounterKeys.every(key => (resKeys.includes(key) && checkType(key, resObj[key])))
      ) {

        if (resObj.traffic_counting.length === 0) return true;
        if (resObj.traffic_counting.length > 20){
          sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Maximum 20 lines can be imported')})));
          return false;
        }

        const isValidLineKeys = (lineKeys: LineKeys[]) => {
          if(lineKeys.every(key => validLineKeys.includes(key)) && validLineKeys.every(key => lineKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeys = (pointsKeys: PointsKeys[]) => {
          if(pointsKeys.every(key => validPointsKeys.includes(key)) && validPointsKeys.every(key => pointsKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidLineKeyTypes = (lineKeys: LineKeys[], line: ITrafficCountingResponseMapped) => {
          return lineKeys.every((key) => {
            if (key === 'name') {
              if(typeof line[key] === 'string' && line[key] !== ''){
                if(line[key].length > 24){
                  sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name should not longer than 24 characters')})));
                  return false;
                } else {
                  return true; 
                }
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name is required')})));
                return false; // Check for non-empty string
              }
            } else if (key === 'direction_label_mode') {
              if (typeof line[key] === 'string' && line[key] === 'Manual') {
                if (
                  (line.forward_label === '' || line.forward_label === undefined) &&
                  (line.backward_label === '' || line.backward_label === undefined)
                ) {
                  sendNotification(JSON.parse(JSON.stringify({ type: 'error', message: t('Forward direction label & backward direction label should not be empty for Manual direction type') })));
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            } else {
              if(checkType(key, line[key])){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid data types')})));
                return false;
              }
            }
          });
        };

        const isValidPointsKeyTypes = (pointsKeys: PointsKeys[], points: ILinePointsMapped) => {
          if(pointsKeys.every((key) => checkType(key, points[key]))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        for (const line of resObj.traffic_counting) {
          lineKeys = Object.keys(line) as Array<keyof typeof line>;
          if (isValidLineKeys(lineKeys) && isValidLineKeyTypes(lineKeys, line)) {
            pointsKeys = Object.keys(line.reference_line) as Array<keyof typeof line.reference_line>;
            if (isValidPointsKeys(pointsKeys) && isValidPointsKeyTypes(pointsKeys, line.reference_line)) {
              isLinesValid = true;
            } else {
              sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid point keys or their type')})));
              isLinesValid = false;
              break;
            }
          } else {
            isLinesValid = false;
            break;
          }
        }
        return isLinesValid;
      } else {
        sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
        return false;
      }
    } catch (error) {
      sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid file')})));
      return false;
    }
  }, [sendNotification, t]);

  const onImportCallback = useCallback((e) => {
    if (e !== null && isValidImportFile(e.result)) {
      validateImporting(JSON.parse(e.result as string));
    } else {
      setModalOpen(false);
    }
  }, [isValidImportFile, validateImporting, setModalOpen]);

  // Import Modal
  const getImportModal = useCallback(() => {
    return (
      <ImportSettingsModal {...{ onImportCallback, sendNotification }} />
    );
  }, [sendNotification, onImportCallback]);

  const onImportSettings = useCallback(() => {
    setModalOpen(true);
    const importModal: ReactElement = getImportModal();
    createModal({
      isCloseEnable: false,
      width: 'max-length',
      padding: false,
      customComponent: importModal,
    });
  }, [createModal, setModalOpen, getImportModal]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
        const isImageValid = await isMediaUrlValid(imgBase64, 'img');
        if (isImageValid === true) {
          setImage(imgBase64);
          const img = new Image();
          img.src = imgBase64;
          setImageDetails({x: img.width, y: img.height});
        }
        setIsImporting(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [streamName, isMediaUrlValid]);

  useEffect(() => {
    if(isImporting){
      getCameraImage();
    }
  },[isImporting, getCameraImage]);

  return (
    <>
      {
        (loading) ?
          <SpinnerContainer>
            <Spinner size='large' styling='primary' />
            <ResultText>{t('Loading')}</ResultText>
          </SpinnerContainer>
          :
          <MainContainer>
            <Container>
              <Header>
                <PageHeaderComponent>
                  <AreaContainer onClick={goToPreviousPage}>{!cameraData?.camera_name ? 'Camera Name' : cameraData?.camera_name}</AreaContainer>
                  <PageHeader
                    title={t('Traffic Counting')}
                    icon='Analyse'
                    updateDocTitle={false}
                  />
                  <ToggleDetection state={toggleAnalysis ? 'disabled' : 'default'}>
                    <Switch checked={trafficCounterdata?.analysis_enabled} onChangeCallback={handleAnalysis} state={toggleAnalysis ? 'disabled' : 'default'} />
                    <SmallLabel>{trafficCounterdata?.analysis_enabled ? t('Enabled') : t('Disabled')}</SmallLabel>
                  </ToggleDetection>
                </PageHeaderComponent>
                <ButtonContainer>
                  <Button
                    design='secondary'
                    size='small'
                    onClick={goToPreviousPage}
                  >
                    {unSavedChanges === 0 ? t('Back') : t('Cancel')}
                  </Button>
                  <Button disabled={unSavedChanges === 0 || reverseSaveLoading} design='primary' size='small' onClick={openConfirmationModal}>
                    {t('Save Changes')}
                  </Button>
                  <UnsavedChangesText>{unSavedChanges > 0 ? t('{X} unsaved changes').replace('{X}', (unSavedChanges + '')) : t('No Changes')}</UnsavedChangesText>
                </ButtonContainer>
              </Header>
              
              <Divider />

              <ImportExportContainer gap='0 14px'>
                {t('Settings')}:
                <Button size='xsmall' design='secondary' disabled={false} onClick={onImportSettings}>
                  {t('Import')}
                </Button>
                <Button size='xsmall' design='secondary' disabled={false} onClick={onExportSettings}>
                  {t('Export')}
                </Button>
              </ImportExportContainer>

              <Divider />

              <LineSettingsContainer>
                <LineSelectFieldContainer>
                  <Icon icon='FeatureLineUi' size={16} color='dimmed' />
                  <StyledSelectField
                    changeCallback={(e) => onLineSelect(e)}
                    isCompact
                    value={selectedLine}
                  >
                    {
                      lineSelectOptions.map((item, index) => {
                        return (
                          <option key={item + index} value={item === '[New Line]' ? item : `${index}: ${item}`}>
                            {item === '[New Line]' ? t(item) : `${index}: ${item.length > 24 ? item.slice(0, 24) : item}`}
                          </option>
                        );
                      })
                    }
                  </StyledSelectField>
                  <InputAndButtonContainer gap='0 10px' lang={i18n.language}>
                    <ButtonWithIcon
                      icon='Add'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={onAddLine}
                      disabled={!isMediaLoaded || trafficCounterdata.traffic_counting_settings?.length === 20}
                    >
                      {t('Add Line')}
                    </ButtonWithIcon>
                    <ButtonWithIcon
                      icon='ActionRemove'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={onRemoveLine}
                      disabled={selectedLineIndex === 0}
                    >
                      {t('Remove Line')}
                    </ButtonWithIcon>
                  </InputAndButtonContainer>
                </LineSelectFieldContainer>
                <LineUIContainer>
                  <LineUILeftPanel>
                    <SidePaddingDiv>
                      <StyledSmallInputWithLabel
                        fieldState='default'
                        label={t('Line Reference')}
                        name='lineReference'
                        length='100%'
                        maxLength={24}
                        disabled={selectedLineIndex === 0}
                        value={selectedLineIndex === 0 ? lineReference : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.name}
                        onChange={(e) => onLineReferenceChange(e)}
                      />
                    </SidePaddingDiv>
                    <DividerWithMargin />
                    <SidePaddingDiv>
                      <DirectionSelectFieldContainer>
                        <DirectionSelectField
                          label={{
                            htmlFor: 'directionDetection',
                            text: t('Direction Names'),
                          }}
                          changeCallback={(e) => onDirectionSelect(e)}
                          value={selectedLineIndex === 0 ? directionOption : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.direction_type}
                          disabled={selectedLineIndex === 0}
                          isCompact
                        >
                          <option value='Automatic'>{t('Automatic')}</option>
                          <option value='Manual'>{t('Specify the labels')}</option>
                        </DirectionSelectField>
                      </DirectionSelectFieldContainer>
                      <DirectionLabel>{t('Forward Label')}</DirectionLabel>
                      <DirectionInputField>
                        <StyledInput
                          fieldState='default'
                          name='forward'
                          placeholder={selectedLineIndex === 0 ? ( directionOption === 'Automatic' ? 'forward' : t('Enter')) : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.direction_type === 'Automatic' ? 'forward' : t('Enter')}
                          maxLength={64}
                          disabled={selectedLineIndex === 0 ? directionOption === 'Automatic' : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.direction_type === 'Automatic'}
                          length='100%'
                          value={selectedLineIndex === 0 ? ( directionOption === 'Automatic' ? '' : directionLabel.forward) : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.direction_type === 'Automatic' ? '' : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.fwd_direction_label}
                          onChange={onDirectionManualChange}
                        />
                        <Circle size={0.65}>
                          <Icon color='inverse' icon='Up' size={12} weight='heavy' />
                        </Circle>
                      </DirectionInputField>
                      <DirectionLabel>{t('Backward Label')}</DirectionLabel>
                      <DirectionInputField>
                        <StyledInput
                          fieldState='default'
                          name='backward'
                          placeholder={selectedLineIndex === 0 ? ( directionOption === 'Automatic' ? 'backward' : t('Exit')) : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.direction_type === 'Automatic' ? 'backward' : t('Exit')}
                          maxLength={64}
                          disabled={selectedLineIndex === 0 ? directionOption === 'Automatic' : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.direction_type === 'Automatic'}
                          length='100%'
                          value={selectedLineIndex === 0 ? ( directionOption === 'Automatic' ? '' : directionLabel.backward) : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.direction_type === 'Automatic' ? '' : trafficCounterdata.traffic_counting_settings[selectedLineIndex - 1]?.back_direction_label}
                          onChange={onDirectionManualChange}
                        />
                        <Circle size={0.45}>
                          <Icon color='inverse' icon='Down' size={12} weight='heavy' />
                        </Circle>
                      </DirectionInputField>
                      <ColoredButton size='xsmall' design='secondary' onClick={() => swapLineDirection()} disabled={selectedLineIndex === 0}>
                        {t('Swap Direction')}
                      </ColoredButton>
                    </SidePaddingDiv>
                  </LineUILeftPanel>
                  <LineUIRightPanel>
                    <LineViewer
                      streamName={streamName} 
                      latestSnapshotText={tRef.current('Latest Snapshot')}
                      zoomText={tRef.current('Zoom')}
                      rtcText={tRef.current('Live Feed')}
                      onLineChangeCallback={onLineChange}
                      onMediaLoadedCallback={onMediaLoadedCallback}
                      linesData={stateDetails}
                      options={options}
                      width='700px'
                      height='388px'
                      onLineClickCallback={onLineClickCallback}
                      showExclusionArea={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                      showExclusionAreaOnModal={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                      exclusionAreas={(exclusionAreas.enabled && exclusionAreas.areas.length > 0) ? exclusionAreas.areas : []}
                    />
                  </LineUIRightPanel>
                  {isMediaLoaded &&
                    <LineUIActionsDisplayContainer lang={i18n.language} isIncreaseWidth={isIncreaseWidth}>
                      <IconWrapper>
                        <Icon icon='PasswordShow' size={16} color='dimmed' />
                      </IconWrapper>
                      <SelectZoneShow changeCallback={(e) => handleZoneShow(e)} disabled={false} isCompact value={showAllOrSelectedOption} lang={i18n.language} isIncreaseWidth={isIncreaseWidth}>
                        {['Only Active Line', 'Show All'].map((item, index) => {
                          return (
                            <option key={index} value={item}>{tRef.current(item)}</option>);
                        })}
                      </SelectZoneShow>
                    </LineUIActionsDisplayContainer>}
                </LineUIContainer>
              </LineSettingsContainer>
            </Container>
          </MainContainer>
      }
    </>
  );
};

export default TrafficCounter;