import React, { useCallback, useEffect, useRef, useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Button, SmallInput, ButtonWithIcon, ButtonWithLoading, Content, Icon, PageHeader, SelectField, Switch, useNotification, Label, Spinner, useModal, useMediaModal } from 'scorer-ui-kit';
import { getCamera, getExclusionZoneSettings, saveExclusionZoneSettings, toggleConfigurationAnalysis } from 'services/camerasService';
import styled, {css} from 'styled-components';
import LineViewer from 'components/LineViewer/configurationLineViewer';
import { IExclusionZoneData, IExclusionAreaSettings, IIExclusionZoneDataRes, IExclusionZoneDataMapped, IExclusionAreaSettingsMapped } from './types';
import clonedeep from 'lodash.clonedeep';
import { IVector2, IPointSet, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import { RemoveSVG, AddSVG } from '../../svg';
import { isEqual } from 'lodash';
import { NEW_ZONE, EDGE_API_BASE_URL } from '../../constants';
import DisableConfirmationModal from 'components/DisableConfirmationModal';
import {IToggleAnalysisPayload, ICameraDetails, IPoints} from '../../interface';
import ImportSettingsModal from './ConfigurationImportModal';
import cloneDeep from 'lodash.clonedeep';
import axios from 'axios';

const MainContainer = styled(Content)`
  @media screen and (min-width: 1440px) {
    padding: 60px 90px 60px 170px;
  }
  @media (max-width: 1400px) and (min-width: 1350px) {
    padding: 70px 0 70px 90px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 940px !important;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 941px !important;
  justify-content: space-between;
  margin-top: -22px;
  padding: 0;
  p {
    margin-top: -14px;
  }
`;

const PageHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  > div > div {
    > h1 {
      margin-left: -1px;
    }
    > :nth-child(2) {
      bottom: 2px;
      left: -45px;
    }
  }
`;

const AreaContainer = styled.div`
  min-width: 200px;
  max-width: 750px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
`;

const HeadTitle = styled.span`
  & div > div > div > div {
    margin-top: -8px;
  }
`;

const ToggleDetection = styled.div<{ state?: string }>`
  display: flex;
  align-items: center;
  margin-top: -4px;
  gap: 0 12px;
  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
    `}
  margin-bottom: 15px;
`;

const SmallLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
`;

const ButtonContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 0;
  > button {
    width: 133px;
  }
  > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  & > :nth-child(2) {
    width: 135px;
    margin-right: -2px;
    padding: 0 18px;
  }
`;

const UnsavedChangesContainer = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.ui};
`;

const Divider = styled.div`
  height: 1px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #efefef;
`;

const ImportExportContainer = styled.div<{ gap: string; lang?: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 11px;
  margin-top: -15px;
  margin-bottom: 15px;

  > button {
    padding: 0 8px;
    > div > :first-child {
      padding-left: 12px;
    }
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const LineSettingsContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  margin: 4px 0;
`;

const LineSelectFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  position: relative;
  & > div:first-child {
    display: flex;
    position: absolute;
    top: 6px;
    left: -24px;
  }
`;

const StyledSelectField = styled(SelectField)`
  width: 229px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  } // to align drop down icon
`;

const InputAndButtonContainer = styled.div<{ gap: string; lang?: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  & > button {
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const LineUIContainer = styled.div`
  width: 100%;
  min-height: 390px;
  margin: 30px 0 15px;
  display: flex;
  border-radius: 3px;
  border: solid 1px #eee;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.02);
  background-color: rgba(252, 252, 253, 0.85);
  position: relative;

  &::before {
    content: "";
    width: 14.5px;
    height: 51px;
    position: absolute;
    top: -28px;
    left: -16px;
    border-left: 2px solid #d9dad9;
    border-bottom: 2px solid #d9dad9;
  }
`;

const LineUIRightPanel = styled.div`
  flex: 1;
  flex-shrink: 0;
  border-radius: 3px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const ResultText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.data};
  color: #5a6269;
  margin-top: -3px;
  padding: 0;
`;

const LineUILeftPanel = styled.div`
  flex-shrink: 0;
  width: 239px;
  min-height: 390px;
  padding: 14px 0;
`;

const SidePaddingDiv = styled.div`
  position: relative;
  padding: 0 19px;
  & > div > label > span {
    margin-bottom: 5px !important;
  }
  & > div > label > div {
    width: 200px !important;
  }
`;

interface ISmallInput {
  length: string;
}

const StyledSmallInputWithLabel = styled(SmallInput)<ISmallInput>`
  & > label {
    width: ${({ length }) => length};
    margin-bottom: 0;
  }
  input:disabled {
    cursor: not-allowed;
  }
`;

const DividerWithMargin = styled(Divider)`
  margin: 14px 0 12px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 20px 0 20px;
`;

const LabelTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > label:nth-child(1) {
    margin-right: 6px;
  }
`;

const LabelText = styled(Label)`
  margin-top: 4px;
`;

const ZoneInfoButtonDiv = styled.div`
  display: flex;
  margin-top: -2px;
  button {
    margin-left: 10px;
  }
`;

const PointsButton = styled(Button)`
  width: 31px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const CancelModalContainer = styled.div`
  padding: 18px 48px 22px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const LineUIActionsDisplayContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 396.5px;
  height: max-content;
  & > div:nth-child(1) {
    margin-top: -3px;
  }
  & > div:nth-child(2) {
    margin-top: -14px;
  }
  position: absolute;
  z-index: 10;
  bottom: -34px;
`;

const SelectZoneShow = styled(SelectField)`
  width: 138px;
  height: 30px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 25px 0 8px !important;
  margin-top: 5px;
  font-family: 'Lato' !important;
  font-style: normal !important;
  color: #8b9196 !important;
  letter-spacing: -0.12px;
  & + div {
    right: 14.5px !important;
  }
`;

const IconWrapper = styled.div``;

const CancelChangesButton = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const options: LineUIOptions = {
  showSetIndex: false,
  showPoint: true,
  showDirectionMark: false,
  showMoveHandle: true,
  showPointHandle: true,
  setIndexOffset: 0,
  boundaryOffset: 0,
};

interface IImageResponse {
  data: ArrayBuffer;
  status: number;
}

interface ILinePoints {
  points: IVector2[];
  changeLineStyle: boolean;
}

type ExclusionZoneRes = IExclusionZoneDataMapped;
type ExclusionZoneKeys = keyof ExclusionZoneRes;
type ZoneKeys = keyof IExclusionAreaSettingsMapped;
type PointsKeys = keyof IPoints;
type CheckTypeValue = number | string | boolean | IExclusionAreaSettingsMapped[] | IPoints | undefined;

const checkType = (key: string, value: CheckTypeValue): boolean => {
  switch (key) {
  case 'exclusion_zone':
    return Array.isArray(value);
  case 'name':
    return typeof value === 'string';
  case 'x':
  case 'y':
    return typeof value === 'number';
  case 'zone':
    return true;
  default:
    return false;
  }
};

const ExclusionZones = () => {
  const { t, i18n } = useTranslation(['CommonDict']);
  const { streamName }: { streamName: string } = useParams();
  const [cameraDetails, setCameraDetails] = useState<ICameraDetails>();
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const { push } = useHistory();
  const [exclusionZonesData, setExclusionZonesData] = useState<IExclusionZoneData>({enabled: false, exclusion_zone_settings: []});
  const [prevExclusionZonesData, setPrevExclusionZonesData] = useState<IExclusionZoneData>({enabled: false, exclusion_zone_settings: []});
  const [prevValuesForCompare, setPrevValuesForCompare] = useState<IExclusionZoneData>({enabled: false, exclusion_zone_settings: []});
  const [exclusionZonesAreaSettings, setExclusionZonesAreaSettings] = useState<IExclusionAreaSettings[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedZone, setSelectedZone] = useState<string>(NEW_ZONE);
  const [zoneSelectOptions, setZoneSelectOptions] = useState<string[]>([NEW_ZONE]);
  const [selectedZoneIndex, setSelectedZoneIndex] = useState(0);
  const { createModal, setModalOpen, isModalOpen } = useModal();
  const [unSavedChanges, setUnsavedChanges] = useState<number>(0);
  const [exclusionSaveLoading, setExclusionSaveLoading] = useState(false);
  const [showAllZone, setShowAllZone] = useState(true);
  const [showAllOrSelectedOption, setShowAllOrSelectedOption] = useState('Show All');
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [stateDetails, setStateDetails] = useState<IPointSet[]>([]); 
  const stateRef = useRef<IPointSet[]>([]);
  const tRef = useRef(t);
  const [toggleAnalysis, setToggleAnalysis] = useState(false);
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const { isMediaUrlValid } = useMediaModal();
  const [image, setImage] = useState('');
  const [isImporting, setIsImporting] = useState(true);

  // Update for every zone change
  useEffect(() => {
    const state: IPointSet[] = [];
    if (showAllOrSelectedOption === 'Show All') {
      exclusionZonesData?.exclusion_zone_settings?.forEach((item) => {            
        state.push({
          points: item.points,
          styling: item.styling,
          readOnly: (item.index !== selectedZoneIndex),
          showPointHandle: (item.index === selectedZoneIndex),
          showMoveHandle: (item.index === selectedZoneIndex),
          areaFillColor: '#0B0B0B',
          areaTransparencyLevel: 50,
          areaName: item.name.length > 24 ? item.name.slice(0, 24) : item.name
        });
      });
    } else {
      if (selectedZoneIndex !== 0) {
        const lines = exclusionZonesData?.exclusion_zone_settings[selectedZoneIndex - 1];
        state.push({
          points: lines.points,
          styling: lines.styling,
          readOnly: false,
          showPointHandle: true,
          areaFillColor: '#0B0B0B',
          areaTransparencyLevel: 50,
          areaName: lines.name.length > 24 ? lines.name.slice(0, 24) : lines.name
        });
      }
    }
    setStateDetails(state);
    stateRef.current = state;
  }, [exclusionZonesData, selectedZoneIndex, showAllOrSelectedOption]);

  const zoneChangesCounter = (prevDetails: IExclusionZoneData, currentDetails: IExclusionZoneData) => {
    let counter = 0;
    
    // If any Zones has length 0
    if (currentDetails.exclusion_zone_settings.length === 0 || prevDetails.exclusion_zone_settings.length === 0) {
      counter = counter + currentDetails.exclusion_zone_settings.length + prevDetails.exclusion_zone_settings.length;
      return counter;
    }

    // count zone difference
    currentDetails.exclusion_zone_settings.forEach((lane1) => {
      let found = 1;
      for (const lane2 of prevDetails.exclusion_zone_settings) {
        if (isEqual(lane1, lane2)) {
          found = 0;
          break;
        }
      }
      counter += found;
    });
    
    if (currentDetails.exclusion_zone_settings.length < prevDetails.exclusion_zone_settings.length) {
      counter += Math.abs(currentDetails.exclusion_zone_settings.length - prevDetails.exclusion_zone_settings.length);
    }

    return counter;
  };

  useEffect(()=>{
    const currentExclusionAreaSettings = exclusionZonesData?.exclusion_zone_settings.map(obj => {
      const newObj = { ...obj };
      delete newObj.index;
      delete newObj.showMoveHandle;
      delete newObj.showPointHandle;
      return newObj;
    });
    const prevExclusionAreaSettings = prevValuesForCompare?.exclusion_zone_settings.map(obj => {
      const newObj = { ...obj };
      delete newObj.index;
      delete newObj.showMoveHandle;
      delete newObj.showPointHandle;
      return newObj;
    });
    const prevSettings = {
      ...prevValuesForCompare,
      exclusion_zone_settings: prevExclusionAreaSettings
    };

    const currentDetails:IExclusionZoneData = {enabled: exclusionZonesData?.enabled, exclusion_zone_settings: currentExclusionAreaSettings};
    let changes = 0;
    changes = zoneChangesCounter(prevSettings, currentDetails);
    setUnsavedChanges(changes);
  },[prevValuesForCompare, exclusionZonesData]);

  const fetchCamera = useCallback(() => {
    getCamera(streamName).then((res) => {
      if (res.status === 200 && res.data.data) {
        setCameraDetails(res.data.data);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    }).catch(() => {
      sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  }, [streamName]);

  const getExclusionData = useCallback(() => {
    getExclusionZoneSettings(streamName).then((res: { data: IIExclusionZoneDataRes }) => {
      if (res.data.status_code === 0 && res.data.data !== undefined ) {
        setExclusionZonesData(res?.data?.data);
        setPrevValuesForCompare(res.data.data);
        setPrevExclusionZonesData(clonedeep(res.data.data));
        setExclusionZonesAreaSettings(res.data.data?.exclusion_zone_settings);
        const zoneSelect = res.data.data?.exclusion_zone_settings.map(area => area.name);
        zoneSelect.unshift(NEW_ZONE);
        setZoneSelectOptions(zoneSelect);
        setSelectedZoneIndex(0);
        setSelectedZone(NEW_ZONE);
        setLoading(false);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
        setLoading(false);
      }
    }).catch(() => {
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      setLoading(false);
    });
  }, [streamName]);

  useEffect(()=>{
    fetchCamera();
    getExclusionData();
  },[fetchCamera, getExclusionData]);

  const onChangeExclusionZoneData = useCallback((formData) => {
    setExclusionZonesData({ ...formData });
  }, []);

  const handleExclusionAnalysis = useCallback((value) => {
    setToggleAnalysis(true);
    const newExclusionZonesData = clonedeep(exclusionZonesData);
    newExclusionZonesData.enabled = value;
    onChangeExclusionZoneData(newExclusionZonesData);

    const payload: IToggleAnalysisPayload ={
      action: value ? 'enable' : 'disable',
      analysis_type: ['exclusion_area']
    };
    toggleConfigurationAnalysis(streamName, payload).then((res: { data: { status_code: number; }; }) => {
      if (res.data.status_code === 0) {
        setToggleAnalysis(false);
        sendNotificationRef.current({ type: 'success', message: value ? tRef.current('Exclusion zone enabled successfully') : tRef.current('Exclusion zone disabled successfully')});
      } else { 
        setToggleAnalysis(false);
        newExclusionZonesData.enabled = !value;
        onChangeExclusionZoneData(newExclusionZonesData);
        sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') }); }
    }).catch(() => {
      setToggleAnalysis(false);
      newExclusionZonesData.enabled = !value;
      onChangeExclusionZoneData(newExclusionZonesData);
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  }, [exclusionZonesData, streamName, onChangeExclusionZoneData]);

  const validateExclusionZoneData = useCallback((exclusionZoneData) => {
    let returnValue = true;
    const areaNames = new Set();

    for (const item of exclusionZoneData?.exclusion_zone_settings) {
      if (item.name === '') {
        sendNotificationRef.current({type: 'error', message: tRef.current('Please enter the zone reference for zone #{X}').replace('{X}', (item.index))});
        returnValue = false;
        break;
      }
      if (item.name.length > 240) {
        sendNotificationRef.current({type: 'error', message: tRef.current('Zone reference should be < 255 character for zone #{X}').replace('{X}', (item.index))});
        returnValue = false;
        break;
      }
      if (areaNames.has(item.name)) {
        sendNotificationRef.current({type: 'error', message: tRef.current('{X} already exists').replace('{X}', (item.name))});
        returnValue = false;
        break;
      }
      areaNames.add(item.name);
    }
    return returnValue;
  },[]);

  const onClickSave = useCallback((data = exclusionZonesData) => {
    const isValidData = validateExclusionZoneData(data);
    if (isValidData) {
      onChangeExclusionZoneData({ ...exclusionZonesData });
      setExclusionSaveLoading(true);
      saveExclusionZoneSettings(streamName, data).then((res: { data: { status_code: number; }; }) => {
        if (res.data.status_code === 0) {
          setPrevExclusionZonesData(clonedeep(exclusionZonesData));
          sendNotificationRef.current({ type: 'success', message: tRef.current('Settings saved successfully')});
          fetchCamera();
          getExclusionData();
        } else { sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') }); }
        setExclusionSaveLoading(false);
      }).catch(() => {
        setExclusionSaveLoading(false);
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      });
    }
  }, [exclusionZonesData, fetchCamera, onChangeExclusionZoneData, streamName, getExclusionData, validateExclusionZoneData]);

  const onLineChange = useCallback((state: IPointSet[]) => {
    if (selectedZoneIndex === 0) return;
    const newExclusionSettings = clonedeep(exclusionZonesData);
    const selectedLines = state.filter(line => line.showPointHandle);
    newExclusionSettings.exclusion_zone_settings[selectedZoneIndex - 1] = {
      ...newExclusionSettings.exclusion_zone_settings[selectedZoneIndex - 1],
      points: selectedLines[0].points
    };

    onChangeExclusionZoneData({ ...newExclusionSettings });
  }, [selectedZoneIndex, exclusionZonesData, onChangeExclusionZoneData]);

  const getMidpoint = useCallback((pointA: IVector2, pointB: IVector2) => {
    return ({
      x: Math.round(pointA.x + (pointB.x - pointA.x) * 0.5),
      y: Math.round(pointA.y + (pointB.y - pointA.y) * 0.5)
    });
  }, []);

  const handleRemovePoint = useCallback(() => {
    const newExclusionZonesData = clonedeep(exclusionZonesData);
    newExclusionZonesData.exclusion_zone_settings[selectedZoneIndex - 1].points.splice(-1);
    onChangeExclusionZoneData(newExclusionZonesData);
  }, [exclusionZonesData, selectedZoneIndex, onChangeExclusionZoneData]);

  const handleAddPoint = useCallback(() => {
    const newExclusionZonesData = clonedeep(exclusionZonesData);
    const midPoint: IVector2 = getMidpoint(exclusionZonesData?.exclusion_zone_settings[selectedZoneIndex - 1]?.points[0], exclusionZonesData?.exclusion_zone_settings[selectedZoneIndex - 1]?.points[1]);
    newExclusionZonesData.exclusion_zone_settings[selectedZoneIndex - 1].points.splice(1, 0, midPoint);
    onChangeExclusionZoneData(newExclusionZonesData);
  }, [exclusionZonesData, getMidpoint, onChangeExclusionZoneData, selectedZoneIndex]);

  const handleZoneName = useCallback(({ target: { value } }) => {
    const newZoneSelectOptions = [...zoneSelectOptions];
    newZoneSelectOptions[selectedZoneIndex] = value;
    setZoneSelectOptions(newZoneSelectOptions);
    setSelectedZone(selectedZoneIndex + ': ' + value);
    const newExclusionZonesData = clonedeep(exclusionZonesData);
    newExclusionZonesData.exclusion_zone_settings[selectedZoneIndex - 1].name = value;
    onChangeExclusionZoneData(newExclusionZonesData); 
  }, [exclusionZonesData, selectedZoneIndex, zoneSelectOptions, onChangeExclusionZoneData]);

  const onLineSelect = useCallback((e) => {
    setSelectedZone(e);
    if (e === NEW_ZONE || e === '［新しいゾーン］') {
      setSelectedZoneIndex(0);
    } else {
      setSelectedZoneIndex(parseInt(e.substring(0, e.indexOf(':'))));
    }
  },[]);

  const onAddZone = useCallback(() => {
    const filteredZoneNumbers=[1,2,3,4,5].filter(num=>exclusionZonesAreaSettings.findIndex(d=>parseInt(d.name.trim()[d.name.trim().length-1]) === num)===-1);
    filteredZoneNumbers.sort();
    const newZoneDetail = {
      index: exclusionZonesAreaSettings.length+1,
      name: i18n.language === 'ja' ? 'エリア' + filteredZoneNumbers[0].toString() : 'Zone ' + filteredZoneNumbers[0].toString(),
      points: [{'x':35 * filteredZoneNumbers[0],'y':33 * filteredZoneNumbers[0]},{'x':35 * filteredZoneNumbers[0],'y':102 * filteredZoneNumbers[0]},{'x':176 * filteredZoneNumbers[0],'y':102 * filteredZoneNumbers[0]},{'x':172 * filteredZoneNumbers[0],'y':31 * filteredZoneNumbers[0]}],
    };
    const updatedSettings = [...exclusionZonesData?.exclusion_zone_settings,newZoneDetail];
    const addNewZone = {
      ...exclusionZonesData,
      exclusion_zone_settings: updatedSettings
    };
    onLineSelect(i18n.language === 'ja' ? `${newZoneDetail.index}: エリア${newZoneDetail.index}` : `${newZoneDetail.index}: Zone ${newZoneDetail.index}`);
    setExclusionZonesAreaSettings(prev => [...prev, newZoneDetail]);
    setZoneSelectOptions(prev => [...prev, i18n.language === 'ja' ? 'エリア' + filteredZoneNumbers[0].toString() : 'Zone ' + filteredZoneNumbers[0].toString()]);
    onChangeExclusionZoneData(addNewZone);     
    sendNotificationRef.current({type: 'neutral', message: tRef.current('New zone added'), icon: 'FeaturePolyUi'});
  },[exclusionZonesAreaSettings, exclusionZonesData, onChangeExclusionZoneData, onLineSelect, i18n]);

  const onRemoveZone = useCallback(() => {
    const updatedData = {
      ...exclusionZonesData,
      exclusion_zone_settings: exclusionZonesData?.exclusion_zone_settings.filter(
        (item) => item.index !== selectedZoneIndex
      ).map((item,index)=>({...item,index:index+1}))
    };
    setExclusionZonesAreaSettings(updatedData.exclusion_zone_settings);

    const remZoneSelectOptions = updatedData?.exclusion_zone_settings.map(area => area.name);
    remZoneSelectOptions.unshift(NEW_ZONE);
    setZoneSelectOptions(remZoneSelectOptions);
    setSelectedZoneIndex(remZoneSelectOptions.length - 1);
    if (remZoneSelectOptions[remZoneSelectOptions.length - 1] === NEW_ZONE) {
      setSelectedZone(NEW_ZONE);
    } else {
      setSelectedZone(`${remZoneSelectOptions.length - 1}: ${remZoneSelectOptions[remZoneSelectOptions.length - 1]}`);
    }
    onChangeExclusionZoneData(updatedData); 
    sendNotificationRef.current({ type: 'neutral', message: tRef.current('Zone removed'), icon: 'FeaturePolyUi'});
  },[exclusionZonesData, selectedZoneIndex, onChangeExclusionZoneData]);

  const onMediaLoadedCallback = useCallback(() => {
    setIsMediaLoaded(true);
  }, []);

  const onLineClickCallback = useCallback((lineId: number) => {
    setSelectedZoneIndex(lineId + 1);
    setSelectedZone(lineId + 1 + ': ' + exclusionZonesData?.exclusion_zone_settings[lineId]?.name);
  },[exclusionZonesData]);

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('CancelButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('CancelButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'CancelButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('CancelButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('CancelButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const handleCancel = useCallback(async (setModalOpen: (newStatus: boolean) => void) => {
    setSelectedZoneIndex(0);
    await setModalOpen(false);
    if (prevExclusionZonesData) {
      setExclusionZonesData(prev => ({...prevExclusionZonesData, enabled: prev.enabled}));
      setExclusionZonesAreaSettings(prevExclusionZonesData.exclusion_zone_settings);
      const zoneSelect = prevExclusionZonesData.exclusion_zone_settings.map(area => area.name);
      zoneSelect.unshift(NEW_ZONE);
      setZoneSelectOptions(zoneSelect);
      setSelectedZoneIndex(0);
      setSelectedZone(NEW_ZONE);
    }
  }, [prevExclusionZonesData]);

  const handleCancleNo = useCallback(() =>{
    setModalOpen(false);
  },[setModalOpen]);

  const getCancelModal = useCallback((setModalOpen: (newStatus: boolean) => void) => {
    return (
      <CancelModalContainer>
        <CancelConfirmText>{tRef.current('Are you sure you want to cancel the changes?')}</CancelConfirmText>
        <ModalButtonContainer>
          <CancelChangesButton design='secondary' id='CancelButton' onClick={() => handleCancleNo()}>{tRef.current('No')}</CancelChangesButton>
          <CancelChangesButton id='YesButton' onClick={() => handleCancel(setModalOpen)}>{tRef.current('Yes')}</CancelChangesButton>
        </ModalButtonContainer>
      </CancelModalContainer>
    );
  }, [handleCancleNo, handleCancel]);

  const goToPreviousPage = useCallback(() => {
    if(isEqual(exclusionZonesData.exclusion_zone_settings, prevValuesForCompare.exclusion_zone_settings)){
      push('/cameras/camera-configuration/' + streamName);
      return;
    }
    const CancelModal: ReactElement = getCancelModal(setModalOpen);
    createModal({ isCloseEnable: false, width: '400px', padding: false, customComponent: CancelModal });
  }, [createModal, getCancelModal, setModalOpen, push, exclusionZonesData, streamName, prevValuesForCompare]);

  const handleZoneShow = useCallback((e) => {
    setShowAllZone(!showAllZone);
    setShowAllOrSelectedOption(e);
  },[showAllZone]);

  const diableCallBack = useCallback((isEnabled: boolean) => {
    setModalOpen(false);
    onClickSave({...exclusionZonesData, enabled: isEnabled});
  }, [exclusionZonesData, setModalOpen, onClickSave]);

  const openConfirmationModal = useCallback(() => {
    if (exclusionZonesData.enabled) {
      onClickSave(exclusionZonesData);
    } else {
      const isValidData = validateExclusionZoneData(exclusionZonesData);
      if(isValidData){
        const ConfirmationModal: ReactElement = <DisableConfirmationModal onCallback={diableCallBack} />;
        createModal({ isCloseEnable: false, width: 'auto', padding: false, customComponent: ConfirmationModal });
      }
    }
  }, [exclusionZonesData, validateExclusionZoneData, createModal, onClickSave, diableCallBack]);  

  const onExportSettings = useCallback(() => {
    const { exclusion_zone_settings } = prevExclusionZonesData;
    const exportLines = exclusion_zone_settings.map(({ index, points, ...rest }) => {
      return { ...rest, zone: points };
    });

    try {
      let modifiedCameraName = cameraDetails?.camera_name || '';
      if (modifiedCameraName.length > 50) {
        modifiedCameraName = modifiedCameraName.substring(0, 50) + '...';
      }
      const fileName = `${modifiedCameraName}_exclusion_zone.json`;
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({ exclusion_zone: exportLines }, null, 4));
      const link = document.createElement('a');
      link.setAttribute('href', dataStr);
      link.setAttribute('download', fileName);
      link.click();
      sendNotification({ type: 'success', message: t('Settings exported successfully')});
    } catch (error) {
      sendNotification({type: 'error',message: t('An error occurred while exporting data')});
    }
  }, [prevExclusionZonesData, cameraDetails, sendNotification, t]);

  const getLinePoints = useCallback((pointsArray: IVector2[]): ILinePoints => {
    const boundaryOffset = 0;
    let changeLineStyle = false;

    if (image === '') {
      return { points: pointsArray, changeLineStyle };
    }
  
    // Initialize an array to store the adjusted points
    const adjustedPoints = pointsArray.map(point => {
      let adjustedX = point.x;
      let adjustedY = point.y;
  
      // Check and adjust X coordinate
      if (point.x < boundaryOffset) {
        adjustedX = boundaryOffset;
        changeLineStyle = true;
      } else if (point.x > imageDetails.x - boundaryOffset) {
        adjustedX = imageDetails.x - boundaryOffset;
        changeLineStyle = true;
      }
  
      // Check and adjust Y coordinate
      if (point.y < boundaryOffset) {
        adjustedY = boundaryOffset;
        changeLineStyle = true;
      } else if (point.y > imageDetails.y - boundaryOffset) {
        adjustedY = imageDetails.y - boundaryOffset;
        changeLineStyle = true;
      }
  
      return { x: adjustedX, y: adjustedY };
    });
  
    return { points: adjustedPoints, changeLineStyle };

  }, [image, imageDetails]);

  const importSettings = useCallback((importedTrafficCounterData: IExclusionZoneData) => {
    const { exclusion_zone_settings = [] } = importedTrafficCounterData;
    
    if (exclusion_zone_settings.length > 0) {
      const lineInfo: IExclusionAreaSettings[] = [];
      setZoneSelectOptions([NEW_ZONE]);
      exclusion_zone_settings.map((item, index) => {
        const point = cloneDeep(item.points);
        const { points, changeLineStyle } = getLinePoints(point);
        const lineName = !item.name ? `Area ${index}` : item.name;

        setZoneSelectOptions(prev => [...prev, lineName]);

        lineInfo.push(
          {
            index: item.index,
            name: item.name,
            styling: changeLineStyle ? 'danger' : item.styling,
            points: points
          }
        );
        return {
          name: index + 1 + ': ' + lineName,
          points: points,
          readOnly: true,
          styling: changeLineStyle ? 'danger' : 'primary',
          showSmallDirectionMark: true
        };
      });
      setExclusionZonesAreaSettings(lineInfo);
      setExclusionZonesData({enabled: exclusionZonesData.enabled, exclusion_zone_settings: lineInfo});
    } else {
      setExclusionZonesAreaSettings([]);
      setExclusionZonesData({enabled: exclusionZonesData.enabled, exclusion_zone_settings: []});
    }
  }, [exclusionZonesData, getLinePoints]);

  const validateImporting = useCallback((importedTrafficCounterData) => {
    importedTrafficCounterData.exclusion_zone.forEach((item: {points: any; zone: any; index: number;}, index: number) => {
      item.index = index + 1;
      item.points = item.zone;
      delete item.zone;
    });
  
    const newImportedData: IExclusionZoneData = {
      exclusion_zone_settings: importedTrafficCounterData.exclusion_zone,
      enabled: prevValuesForCompare.enabled
    };

    if(isEqual(prevValuesForCompare.exclusion_zone_settings, newImportedData.exclusion_zone_settings)){
      getExclusionData();
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    } else {
      setSelectedZoneIndex(0);
      setSelectedZone(NEW_ZONE);
      setExclusionZonesAreaSettings([]);
      setExclusionZonesData({enabled: exclusionZonesData.enabled, exclusion_zone_settings: []});
      importSettings({...newImportedData});
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    }
  }, [t, sendNotification, importSettings, setModalOpen, exclusionZonesData, prevValuesForCompare, getExclusionData]);

  const isValidImportFile = useCallback((jsonData) => {
    let isLinesValid = false;
    const validExclusionZoneKeys: ExclusionZoneKeys[] = ['exclusion_zone'];
    const validZoneKeys: ZoneKeys[] = ['name', 'zone'];
    const validPointsKeys: PointsKeys[] = ['x', 'y'];

    try {
      let resKeys: ExclusionZoneKeys[] = [], zoneKeys: ZoneKeys[] = [], pointsKeys: PointsKeys[] = [];
      const resObj: ExclusionZoneRes = JSON.parse(jsonData);

      resKeys = Object.keys(resObj) as Array<keyof typeof resObj>;

      if (
        resKeys.every(key => validExclusionZoneKeys.includes(key)) &&
        validExclusionZoneKeys.every(key => (resKeys.includes(key) && checkType(key, resObj[key])))
      ) {

        if (resObj.exclusion_zone.length === 0) return true;
        if (resObj.exclusion_zone.length > 5){
          sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Maximum 5 zones can be imported')})));
          return false;
        }

        const isValidLineKeys = (zoneKeys: ZoneKeys[]) => {
          if(zoneKeys.every(key => validZoneKeys.includes(key)) && validZoneKeys.every(key => zoneKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeys = (pointsKeys: PointsKeys[]) => {
          if(pointsKeys.every(key => validPointsKeys.includes(key)) && validPointsKeys.every(key => pointsKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeyTypes = (pointsKeys: PointsKeys[], point: IPoints) => {
          if(pointsKeys.every((key) => checkType(key, point[key]))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid point keys')})));
            return false;
          }
        };

        const isValidLineKeyTypes = (zoneKeys: ZoneKeys[], line: IExclusionAreaSettingsMapped) => {
          return zoneKeys.every((key) => {
            if (key === 'zone') {
              if(line[key].length > 2) {
                return Array.isArray(line[key]) && line[key].every((point) => isValidPointsKeyTypes(validPointsKeys, point));
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Minimum 3 points are required for a zone')})));
                return false;
              }
            } else if (key === 'name') {
              if(typeof line[key] === 'string' && line[key] !== ''){
                if(line[key].length > 24){
                  sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name should not longer than 24 characters')})));
                  return false;
                } else {
                  return true; 
                }
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name is required')})));
                return false; // Check for non-empty string
              }
            } else {
              if(checkType(key, line[key])){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid data types')})));
                return false;
              }
            }
          });
        };

        for (const line of resObj.exclusion_zone) {
          zoneKeys = Object.keys(line) as Array<keyof typeof line>;
          if (isValidLineKeys(zoneKeys) && isValidLineKeyTypes(zoneKeys, line)) {
            for (const point of line.zone) {
              pointsKeys = Object.keys(point) as Array<keyof IPoints>;
              if (isValidPointsKeys(pointsKeys) && isValidPointsKeyTypes(pointsKeys, point)) {
                isLinesValid = true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid point keys or their type')})));
                isLinesValid = false;
                break; // Exit the loop if any point is invalid
              }
            }
          } else {
            isLinesValid = false;
            break;
          }
        }
        return isLinesValid;
      } else {
        sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
        return false;
      }
    } catch (error) {
      sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid file')})));
      return false;
    }
  }, [sendNotification, t]);

  const onImportCallback = useCallback((e) => {
    if (e !== null && isValidImportFile(e.result)) {
      validateImporting(JSON.parse(e.result as string));
    } 
    else {
      setModalOpen(false);
    }
  }, [isValidImportFile, validateImporting, setModalOpen]);

  // Import Modal
  const getImportModal = useCallback(() => {
    return (
      <ImportSettingsModal {...{ onImportCallback, sendNotification }} />
    );
  }, [sendNotification, onImportCallback]);

  const onImportSettings = useCallback(() => {
    setModalOpen(true);
    const importModal: ReactElement = getImportModal();
    createModal({
      isCloseEnable: false,
      width: 'max-length',
      padding: false,
      customComponent: importModal,
    });
  }, [createModal, setModalOpen, getImportModal]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
        const isImageValid = await isMediaUrlValid(imgBase64, 'img');
        if (isImageValid === true) {
          setImage(imgBase64);
          const img = new Image();
          img.src = imgBase64;
          setImageDetails({x: img.width, y: img.height});
        }
        setIsImporting(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [streamName, isMediaUrlValid]);

  useEffect(() => {
    if(isImporting){
      getCameraImage();
    }
  },[isImporting, getCameraImage]);

  return (
    <MainContainer>
      <Container>
        <Header>
          <PageHeaderDiv>
            <AreaContainer onClick={goToPreviousPage}>{cameraDetails?.camera_name}</AreaContainer>
            <HeadTitle>
              <PageHeader title={tRef.current('Exclusion Zones')} icon='Analyse' updateDocTitle={false} />
            </HeadTitle>
            <ToggleDetection state={toggleAnalysis ? 'disabled' : 'default'}>
              <Switch checked={exclusionZonesData?.enabled} onChangeCallback={handleExclusionAnalysis} state={toggleAnalysis ? 'disabled' : 'default'} />
              <SmallLabel>{exclusionZonesData?.enabled ? t('Enabled') : t('Disabled')}</SmallLabel>
            </ToggleDetection>
          </PageHeaderDiv>
          <ButtonContainer>
            <Button size='small' design='secondary' onClick={goToPreviousPage}> {unSavedChanges === 0 ? t('Back') : t('Cancel')} </Button>
            <ButtonWithLoading size='small' loading={exclusionSaveLoading} disabled={unSavedChanges === 0 || exclusionSaveLoading} onClick={openConfirmationModal}> {tRef.current('Save Changes')} </ButtonWithLoading>
            {unSavedChanges > 0 ? <UnsavedChangesContainer>{tRef.current('{X} unsaved changes').replace('{X}', (unSavedChanges+ ''))}</UnsavedChangesContainer> : <UnsavedChangesContainer>{tRef.current('No Changes')}</UnsavedChangesContainer>}
          </ButtonContainer>
        </Header>
        
        <Divider />

        <ImportExportContainer gap='0 14px'>
          {t('Settings')}:
          <Button size='xsmall' design='secondary' disabled={false} onClick={onImportSettings}>
            {t('Import')}
          </Button>
          <Button size='xsmall' design='secondary' disabled={false} onClick={onExportSettings}>
            {t('Export')}
          </Button>
        </ImportExportContainer>

        <Divider />
              
        <LineSettingsContainer>
          <LineSelectFieldContainer>
            <Icon icon='FeaturePolyUi' size={16} color='dimmed' />
            <StyledSelectField changeCallback={(e) => onLineSelect(e)} disabled={false} isCompact value={selectedZone}>
              {zoneSelectOptions.map((item, index) => {
                return (
                  <option key={item + index} value={item === NEW_ZONE ? item : `${index}: ${item}`}>
                    {item === NEW_ZONE ? tRef.current(item) : `${index}: ${item.length > 24 ? item.slice(0, 24) : item}`}
                  </option>);
              })}
            </StyledSelectField>
            <InputAndButtonContainer gap='0 10px' lang={i18n.language}>
              <ButtonWithIcon icon='Add' position='left' size='small' design='secondary' onClick={onAddZone} disabled={!isMediaLoaded || exclusionZonesData?.exclusion_zone_settings.length === 5}>{tRef.current('Add Zone')}</ButtonWithIcon>
              <ButtonWithIcon icon='ActionRemove' position='left' size='small' design='secondary' onClick={onRemoveZone} disabled={!selectedZoneIndex}>{tRef.current('Remove Zone')}</ButtonWithIcon>
            </InputAndButtonContainer>
          </LineSelectFieldContainer>
          {loading ? 
            <SpinnerContainer>
              <Spinner size='large' styling='primary' />
              <ResultText>{tRef.current('Loading')}</ResultText>
            </SpinnerContainer>
            :
            <>
              <LineUIContainer>
                <LineUILeftPanel>
                  <SidePaddingDiv>
                    <StyledSmallInputWithLabel
                      fieldState='default'
                      label={tRef.current('Zone Reference')}
                      name='zoneReference'
                      length='100%'
                      maxLength={24}
                      value={selectedZoneIndex === 0 ? '' : exclusionZonesData?.exclusion_zone_settings[selectedZoneIndex - 1]?.name}
                      onChange={handleZoneName}
                      disabled={selectedZoneIndex === 0 ? true : false}
                    />
                  </SidePaddingDiv>
                  <DividerWithMargin />
                  <InputContainer>
                    <LabelTextContainer>
                      <LabelText htmlFor='' labelText={`${tRef.current('Points')}:`} />
                      <LabelText htmlFor='' labelText={String(selectedZoneIndex === 0 ? 4 : exclusionZonesData?.exclusion_zone_settings[selectedZoneIndex - 1]?.points.length)} />
                    </LabelTextContainer>
                    <ZoneInfoButtonDiv>
                      <PointsButton disabled={selectedZoneIndex === 0 ? true : (exclusionZonesData?.exclusion_zone_settings[selectedZoneIndex - 1]?.points.length === 3 ? true : false)} onClick={handleRemovePoint}><RemoveSVG /></PointsButton>
                      <PointsButton disabled={selectedZoneIndex === 0} onClick={handleAddPoint}><AddSVG /></PointsButton>
                    </ZoneInfoButtonDiv>
                  </InputContainer>
                </LineUILeftPanel>
                <LineUIRightPanel>
                  <LineViewer
                    streamName={streamName} 
                    latestSnapshotText={tRef.current('Latest Snapshot')}
                    zoomText={tRef.current('Zoom')}
                    rtcText={tRef.current('Live Feed')}
                    onLineChangeCallback={onLineChange}
                    onMediaLoadedCallback={onMediaLoadedCallback}
                    linesData={stateDetails}
                    options={options}
                    width='700px'
                    height='388px'
                    fromExclusionPage
                    onLineClickCallback={onLineClickCallback}
                  />
                </LineUIRightPanel>
                {isMediaLoaded && exclusionZonesData?.exclusion_zone_settings.length !== 0 &&
                  <LineUIActionsDisplayContainer>
                    <IconWrapper>
                      <Icon icon='PasswordShow' size={16} color='dimmed' />
                    </IconWrapper>
                    <SelectZoneShow changeCallback={(e) => handleZoneShow(e)} disabled={false} isCompact value={showAllOrSelectedOption}>
                      {['Only Active Zone', 'Show All'].map((item, index) => {
                        return (
                          <option key={index} value={item}>{tRef.current(item)}</option>);
                      })}
                    </SelectZoneShow>
                  </LineUIActionsDisplayContainer>}
              </LineUIContainer>
            </>}
        </LineSettingsContainer>
      </Container>
    </MainContainer>
  );
};

export default ExclusionZones;