import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ButtonWithIcon, Content, Icon, Label, PageHeader, Spinner, Tab, TabContent, TabList, Tabs, useModal, useNotification } from 'scorer-ui-kit';
import { getSingleCameraDetails } from '../services/apiConfig';
import { SpinnerContainer } from 'style';
import styled from 'styled-components';
import { Divider } from 'Styles';
import CameraDetailsFiles from './CameraDetailsFiles';
import TokenService from 'services/tokenService';
import { AlertIcon, CAMERA_DETAILS_STATUS_GET_INTERVAL, STATUS_ALERT_DETAILS, USB_STATUS_ALERT_DETAILS } from '../constants';
import { IAlertDetails } from 'interface';
import LineViewer from 'components/LineViewer/cameraDetailsLineViewer';
import { getStatusCategory, getStatusColor, getTimeSinceFromSeconds } from 'utils';
import CameraAlertsTab from './CameraAlertsTab';
import { getActiveAlerts, getAlertSupression, getAlertsHistory } from 'services/camerasService';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 62px 30px 62px 90px;
  @media screen and (min-width: 1440px) {
    padding: 62px 25px 62px 170px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 960px !important;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 1.2;
`;

const StyledDivider = styled(Divider)`
  max-width: 1010px;
  margin-bottom: 36px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px 0;
  & > button {
    width: 100%;
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const DetailsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  & tr, td {
    border: 1px solid #eee;
  }
  & > tbody > tr > td {
    height: 100px;
  }
  & > tbody > tr > td:first-child {
    vertical-align: middle;
    text-align: center;
  }
  & > tbody > tr > td:last-child {
    width: 83%;
    padding: 12px 5px 25px 30px;
  }
`;

const Heading = styled(Label)`
  margin-bottom: 10px;
  & > span {
    font-size: 16px;
    color: #5a6269;
  }
`;

const ReadMoreButton = styled.span`
  color: #4699d4;
  margin-left: 5px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const NotesModalHeading = styled.div`
  margin-bottom: 31px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 20px;
  font-weight: 500;
  color: #727980;
`;

const NotesContainer = styled.div`
  width: 100%;
  max-height: 276px;
  font-size: 14px;
  overflow-y: auto;
  overflow-wrap: anywhere;
  white-space: break-spaces;
`;

const NotesModalContainer = styled.div`
  width: 100%;
  height: 418px;
  padding: 39px 40px;
`;

const DescriptionDiv = styled.div<IDescriptionDiv>`
  font-size: 12px;
  position: relative;
  overflow-wrap: anywhere;
  white-space: break-spaces;
  ${({width}) => width && `width: ${width};`}
  ${({margin}) => margin && `margin: ${margin};`}
  & > label {
    margin-bottom: 0;
    & > span {
      margin-bottom: 0;
      color: #5a6269;
    }
  }
  
  ${({ isStatus, statusColor }) => isStatus && `
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      background: ${statusColor};
    }
  `}
`;

const DescriptionDivContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0 18px;
`;

const AnalysisTypeList = styled.pre`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.ui };
  font-weight: 500;
  font-size: 14px;
  color: #5a6269;
`;

const AreaContainer = styled.span`
  height: 15px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
  	&:hover {
      border-bottom: 1px solid #767676;;
      box-sizing: border-box;
      width: fit-content;
    }
`;

const CameraDetailsLeftPanel = styled.div`
  width: 382px;
`;

const ErrorBox = styled.div<{bgColor: string}>`
  padding: 10px 13px;
  margin-bottom: 14px;
  width: 100%;
  height: max-content;
  min-height: 46px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  background: ${({bgColor}) => bgColor};
  > div {
    display: flex;
  }
`;

const ErrorContainer = styled.div`
  flex-direction: column;
  gap: 0 3px;
  color: #fff;
`;

const ErrorHeading = styled.div`
  font-size: 12px;
`;

const ErrorDescription = styled.div`
  font-size: 10px;
`;

const CameraDetailsRightPanel = styled.div`
  width: 540px;
  max-height: 450px;
  @media screen and (max-width: 1400px) {
    width: 420px;
  }

`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
  
  h1 {
    max-width: 500px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    min-width: 200px !important;
  }
`;

const RightContainer = styled.div`
  display: flex;
`;

const ActivityAlertSuppContainer = styled.div<{width: string}>`
  width: ${({width})=>width};
  height: max-content;
  display: flex;
  justify-content: end;
  margin-right: 58px;
`;

const IconWrapper = styled.div`
  height: 20px;
  margin-left: -1px;
`;

const DetailsContainer = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`;

const SupressionLabel = styled.div`
  width: 98px;
  height: 14px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 600;
  color: #a0bfcb;
  word-wrap: break-word;
  margin-top: 2px;
  margin-bottom: 4px;
  overflow: hidden;
`;

const Activity = styled.span`
  display: flex;
  align-items: baseline;
  width: 90px;
  height: 17px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  letter-spacing: 0.18px;
  color: #949ea7;
  display: flex;
`;

const AlertSuppContainer = styled.div<{width: string}>`
  width: ${({width})=>width};
  height: 55px;
  padding: 0px 18px 0px 10px;
  margin-left: 5px;
  border-radius: 5px;
  border: solid 1px #eee;
  display: flex;
  align-items: center;
  @media (max-width:1438px) and (min-width:1280px) {
    padding-top: 5px;
  }
`;

const View = styled.span`
  width: auto;
  height: 14px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 600;
  color: #4db5ff;
  margin-left: 7px;
  cursor: pointer;
  margin-top: 2px;
  @media (max-width:1438px) and (min-width:1280px) {
    margin-top: 2px;
  }
`;

const OverviewContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const MainContent = styled.div`
  margin-top: 10px;
`;

interface IParams {
  streamName: string;
  tab: string;
}

interface IDescriptionDiv {
  isStatus?: boolean;
  statusColor?: string;
  width?: string;
  margin?: string;
}

interface ILine {
  name: string,
  idx: number,
  points: {
    end_x: number,
    end_y: number,
    start_y: number,
    start_x: number,
  }
}

interface ILineConfiguration {
  lines: ILine[],
  analysis_enabled: boolean
}

interface IUsbDeviceStatus {
  status_code: number
}

interface IAlgorithmConfig {
  [key: string]: {
    enabled: boolean,
    [key: string]: any,
  }
}

interface ICameraDetails {
  camera_name: string,
  stream_name: string,
  notes: string,
  line_configuration: ILineConfiguration[],
  enabled_analysis: string[]
  algorithm_configs: IAlgorithmConfig,
  usb_device_status?: IUsbDeviceStatus,
  status?: {
    status_code: number,
    status_category: 'Running' | 'Error' | 'Warning' | 'Disabled'
  },
  latest_activity_time?: number
}

interface ICameraDetailsStatus {
  status_code: number,
  status_category: 'Running' | 'Error' | 'Warning' | 'Disabled'
}

const initialCameraDetails: ICameraDetails = {
  camera_name: 'Camera Name',
  stream_name: '',
  notes: '-',
  algorithm_configs: {},
  line_configuration: [],
  enabled_analysis: []
};

export interface IDeviceDetailsParam{
  selectedTab: string | null
}

interface IAlgoTypes {
  [key: string] : string
}

const CameraDetails: React.FC = () => {
  const { t, i18n } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const history = useHistory();
  const { push } = useHistory();
  const { streamName, tab } = useParams<IParams>();
  const [activeAlertsCount, setActiveAlertsCount] = useState(0);
  const [alertSuppEnabled, setAlertSuppEnabled] = useState<boolean | undefined>(false);
  const [isAlertsCountFetched, setIsAlertsCountFetched] = useState<boolean>(false);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(true);
  const [cameraDetails, setCameraDetails] = useState<ICameraDetails>(initialCameraDetails);
  const [cameraDetailsStatus, setCameraDetailsStatus] = useState<ICameraDetailsStatus | undefined>(undefined);
  const [usbDeviceStatus, setUsbDeviceStatus] = useState<IUsbDeviceStatus | undefined>(undefined);
  const [cameraAnalysisStatus, setCameraAnalysisStatus] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>(tab);
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const updateDetailsTimer = useRef<ReturnType<typeof setInterval>>();
  const isMount = useRef(true);
  const [alert24, setAlert24] = useState<number>(0);

  const { createModal, setModalOpen } = useModal();

  const checkIsConfigured = useCallback((algoConf = {}, enabledAlgo = []) => {
    let isConfigured = false;
    const algoKeys = Object.keys(algoConf);

    for (const i in algoKeys) {
      const key = algoKeys[i];
      if (!enabledAlgo.includes(key)) continue;
      if (key === 'traffic_counter') {
        isConfigured = algoConf[key][key+'_setting'][0].lines.length > 0;
        if (isConfigured) break;
      } else {
        isConfigured = algoConf[key][key+'_setting'].length > 0;
        if (isConfigured) break;
      }
    }
    return isConfigured;
  }, []);

  useEffect(() => {
    const { status, usb_device_status, enabled_analysis = [], algorithm_configs = {} } = cameraDetails;

    let isConfigured = false;
    if (algorithm_configs !== undefined) {
      isConfigured = checkIsConfigured(algorithm_configs, enabled_analysis.map(item => item.replace('_enabled', '')));
    }

    if (enabled_analysis.length > 0) {
      if(usb_device_status?.status_code as number | undefined === 30700) {   //When camera is removed
        setCameraAnalysisStatus('Inactive');
      } else if (isConfigured) {
        setCameraAnalysisStatus('Active');
      } else {
        setCameraAnalysisStatus('Not Configured');
      }
    } else {
      setCameraAnalysisStatus('Inactive');
    }

    if (status) {
      setCameraDetailsStatus(status);
    }
    if (usb_device_status) {
      setUsbDeviceStatus(usb_device_status);
    }
    return () => {};
  }, [cameraDetails, checkIsConfigured]);

  const getDetails = useCallback(async () => {
    setIsDataFetched(false);
    try {
      const res = await getSingleCameraDetails(streamName);
      setCameraDetails(res.data.data);
      setIsDataFetched(true);
    } catch (error) {
      setIsDataFetched(true);
      sendNotificationRef.current({ 
        type: 'error',
        message: tRef.current('Failed to load camera details')
      });
      console.error(error);
    }
  }, [streamName]);

  useEffect(() => {
    getDetails();
    return () => {};
  }, [getDetails]);

  useEffect(() => {
    updateDetailsTimer.current = setInterval(async () => {
      try {
        const res = await getSingleCameraDetails(streamName);
        setCameraDetails(res.data.data);
        setIsDataFetched(true);
      } catch (error) {
        setIsDataFetched(true);
        console.error(error);
      }
    }, CAMERA_DETAILS_STATUS_GET_INTERVAL * 1000);

    return () => {
      if (updateDetailsTimer.current) {
        clearInterval(updateDetailsTimer.current);
      }
    };
  }, [streamName]);

  // Notes Modal
  const getNotesModal = useCallback(() => {
    return (
      <NotesModalContainer>
        <NotesModalHeading>{t('Notes')}</NotesModalHeading>
        <NotesContainer>{cameraDetails.notes}</NotesContainer>
      </NotesModalContainer>
    );
  }, [cameraDetails, t]);

  const handleReadMore = useCallback(() => {
    setModalOpen(true);
    const notesModal: ReactElement = getNotesModal();

    createModal({
      isCloseEnable: true,
      width: '580px',
      padding: false,
      closeText: t('CLOSE'),
      customComponent: notesModal,
    });
  }, [createModal, setModalOpen, getNotesModal, t]);

  const getErrorDetails = useCallback((): ReactElement | null => {
    if (cameraDetailsStatus && cameraDetailsStatus.status_code && cameraDetailsStatus.status_category) {
      let Alert: IAlertDetails = STATUS_ALERT_DETAILS.Unknown;
      const { status_code, status_category } = cameraDetailsStatus;
      if (getStatusCategory(status_code) === 'Ok') {
        return null;
      } else {
        Alert = STATUS_ALERT_DETAILS[getStatusCategory(status_code)];
        return (
          <ErrorBox bgColor={getStatusColor(status_category)}>
            <Icon size={24} icon={AlertIcon[Alert.type]} color='inverse' />
            <ErrorContainer>
              <ErrorHeading>{t(Alert.title)}</ErrorHeading>
              <ErrorDescription>{t(Alert.message)}</ErrorDescription>
            </ErrorContainer>
          </ErrorBox>
        );
      }
    }
    return null;
  }, [cameraDetailsStatus, t]);

  const getUsbWarning = useCallback((): ReactElement | null => {
    if (usbDeviceStatus) {
      const { status_code } = usbDeviceStatus;
      if (status_code) {
        if (getStatusCategory(status_code, 'USB') === 'Ok') {
          return null;
        }
        const Alert: IAlertDetails = USB_STATUS_ALERT_DETAILS[getStatusCategory(status_code, 'USB')];
        return (
          <ErrorBox bgColor={usbDeviceStatus?.status_code === 30700 ? getStatusColor('Error') : getStatusColor('Warning')}>
            <Icon size={24} icon={AlertIcon[Alert.type]} color='inverse' />
            <ErrorContainer>
              <ErrorHeading>{t(Alert.title)}</ErrorHeading>
              <ErrorDescription>{Alert.message.includes('{X}') ? t(Alert.message).replace('{X}', ':') : `${t(Alert?.message)}`}</ErrorDescription>
            </ErrorContainer>
          </ErrorBox>
        );
      } else {
        return null;
      }
    }
    return null;
  }, [usbDeviceStatus, t]);

  const onSelectTab = useCallback((tabName: string) => {
    setSelectedTab(tabName);
    history.replace(window.location.pathname.split(streamName+'/')[0] + (streamName + '/'+ tabName));
  }, [history, streamName]);

  const gotoExportPage = useCallback(() => {
    const hParam = new URLSearchParams(window.location.search);
    if (hParam.get('fromDate') !== null && hParam.get('toDate') !== null){
      history.push(`/export?streamName=${streamName}&cameraName=${cameraDetails.camera_name}&fromDate=${hParam.get('fromDate')}&toDate=${hParam.get('toDate')}`);
    } else {
      history.push(`/export?streamName=${streamName}&cameraName=${cameraDetails.camera_name}`);
    }
    
  },[streamName, cameraDetails.camera_name, history]);

  const getLineBreak = (data:string) =>{
    const separateLines = data.split(/\r?\n|\r|\n/g);
    if (separateLines?.length > 5) {
      return true;
    }
  };

  const fetchAlertSuppression = useCallback(async()=>{
    try{
      const { data: { status_code, data } } = await getAlertSupression(streamName);
      if(status_code === 0 && data.schedules !== undefined){
        setAlertSuppEnabled(data.enabled);
      }
    }catch(e){
      console.error(e);
    }
  },[streamName]);

  const fetchAlertHistory = useCallback(async(params, isRecall?: boolean)=>{
    if (!isRecall) {
      setIsAlertsCountFetched(false);
    }
    try{
      const { data } = await getAlertsHistory(streamName, params);
      setActiveAlertsCount(data.active_count as number);
    } catch (error) {
      console.error(error);
    } finally {
      setIsAlertsCountFetched(true);
    }
  },[streamName]);

  useEffect(() => {
    if (!isMount.current) return;
    fetchAlertHistory({});
    fetchAlertSuppression();
    isMount.current = false;
  }, [fetchAlertHistory, fetchAlertSuppression]);

  const goToPreviousPage = useCallback(() => {
    push('/cameras');
  }, [push]);
  
  const getEnabledAnalysis = useCallback((): string => {
    const algoNames: IAlgoTypes = {
      'traffic_counter_enabled': 'Traffic Counting',
      'congestion_and_stop_detection_enabled': 'Congestion & Stop',
      'low_speed_detection_enabled': 'Low Speed',
      'frequent_lane_change_enabled': 'Frequent Lane Change',
      // ...(ENABLE_ADAVANCE_LOW_SPEED_ANALYSIS ? {
      //   'advanced_low_speed_detection_enabled': 'Advanced - Low Speed'
      // } : {}),
      'reverse_run_enabled': 'Reverse Run'
    };

    if(cameraDetails?.enabled_analysis){
      if(cameraDetails.enabled_analysis.length > 0){
        const typeList = (
          cameraDetails.enabled_analysis.filter(item => item !== 'advanced_low_speed_detection_enabled')
        ).map(item => t(algoNames[item]));
        return (
          typeList.sort(function(x,y){ return x === 'Traffic Counting' ? -1 : y === 'Traffic Counting' ? 1 : 0; }).join('\n')
        );
      } else {
        return '-';
      }
    }
    return '-';
  }, [cameraDetails, t]);

  const fetchActiveAlerts = useCallback(async () => {
    try {
      const alert24 = await getActiveAlerts(0, 24, 0, streamName);
      if(alert24.data.status_code === 0){
        setAlert24(alert24.data.data.length !== 0 ? alert24.data.data[0].count : 0);
      }
    } catch (err) {
      console.error(err);
    }
  }, [streamName]);

  useEffect(()=>{
    fetchActiveAlerts();
  },[fetchActiveAlerts]);

  return (
    <MainContainer>
      {(isDataFetched && isAlertsCountFetched) ? (
        <Container>
          <Header>
            <PageHeaderComponent>
              <AreaContainer onClick={goToPreviousPage}>{t('Cameras')}</AreaContainer>
              <PageHeader
                title={cameraDetails.camera_name}
                icon='DevicesScorerCamera'
                updateDocTitle={false}
              />
            </PageHeaderComponent>
            <RightContainer>
              {TokenService.getUserType() &&
                <ActivityAlertSuppContainer width='160px'>
                  <AlertSuppContainer width='160px'>
                    <IconWrapper>
                      <Icon color='dimmed' icon='NotificationsSnooze' size={15} weight='regular' />
                    </IconWrapper>
                    <DetailsContainer>
                      <SupressionLabel>{t('Alert Suppression')}</SupressionLabel>
                      <Activity>{alertSuppEnabled===true ? t('Enabled') : t('Disabled')} <View onClick={()=>{push(`/cameras/camera-configuration/${streamName}/alert-suppression?fromPage=camera-details`);}}>{t('(View)')}</View>
                      </Activity>
                    </DetailsContainer>
                  </AlertSuppContainer>
                </ActivityAlertSuppContainer>}
              <ButtonContainer>
                {TokenService.getUserType() &&
                  <>
                    <ButtonWithIcon icon='Edit' size='small' position='left' design='secondary' onClick={() => history.push(`/edit-camera?camera-name=${streamName}`)}>{t('Edit Camera')}</ButtonWithIcon>
                    <ButtonWithIcon disabled={usbDeviceStatus?.status_code === 30700 ? true : false} icon='Analyse' size='small' position='left' design='secondary' onClick={() => history.push(`/cameras/camera-configuration/${streamName}`)}>{t('Configure Analysis')}</ButtonWithIcon>
                  </>}
                <ButtonWithIcon disabled={usbDeviceStatus?.status_code === 30700 ? true : false} icon='Download' size='small' position='left' design='secondary' onClick={() =>gotoExportPage()}>{t('Export Data')}</ButtonWithIcon>
              </ButtonContainer>
            </RightContainer>
          </Header>
          <MainContent>
            <Tabs>
              <TabList defaultTabId={selectedTab}>
                <Tab tabFor='overview' onClick={() => { onSelectTab('overview'); }}><div>{t('Overview')}</div></Tab>
                <Tab tabFor='files' onClick={() => { onSelectTab('files'); }}><div>{t('Files')}</div></Tab>
                <Tab tabFor='alerts' onClick={() => { onSelectTab('alerts'); }}><div> {t('Alerts [Active {X}]').replace('{X}', (activeAlertsCount+''))}</div></Tab>
              </TabList>
              <StyledDivider />

              <TabContent tabId='overview'>
                <OverviewContainer>
                  <CameraDetailsLeftPanel>
                    {usbDeviceStatus?.status_code === 30700 ? '' : getErrorDetails()}
                    {getUsbWarning()}
                    <DetailsTable>
                      <tbody>
                        <tr>
                          <td><Icon icon='DevicesScorerCamera' size={20} color='dimmed' /></td>
                          <td>
                            <Heading htmlFor='cameraDetails' labelText={t('Camera Details')} />
                            <DescriptionDivContainer>
                              <DescriptionDiv
                                isStatus width='85px' statusColor={
                                  (!cameraDetailsStatus || !cameraDetailsStatus.status_category) ? getStatusColor('')
                                    : getStatusColor(cameraDetailsStatus.status_category)
                                }
                              >
                                <div>{t('Status')}</div>
                                <Label
                                  htmlFor='statusCamera' labelText={
                                    !cameraDetailsStatus || !cameraDetailsStatus.status_category ? '-'
                                      : t(cameraDetailsStatus.status_category)
                                  }
                                />
                              </DescriptionDiv>
                              <DescriptionDiv isStatus={false}>
                                <div>{t('Latest Activity')}</div>
                                <Label
                                  htmlFor='statusLatest' labelText={
                                    !cameraDetails.latest_activity_time ? '-'
                                      : getTimeSinceFromSeconds(cameraDetails.latest_activity_time)
                                  }
                                />
                              </DescriptionDiv>
                            </DescriptionDivContainer>
                            <DescriptionDiv isStatus={false} margin='11px 0 0'>
                              <div>{t('Alerts (in 24h)')}</div>
                              <Label
                                htmlFor='alertsIn24'
                                labelText={i18n.language === 'ja' ? (alert24 + t(' Active')) : alert24 + ''}
                              />
                            </DescriptionDiv>
                          </td>
                        </tr>
                        <tr>
                          <td><Icon icon='Analyse' size={20} color='dimmed' /></td>
                          <td>
                            <Heading htmlFor='cameraDetails' labelText={t('Video Analysis')} />
                            <DescriptionDivContainer>
                              <DescriptionDiv isStatus width='85px' statusColor={cameraAnalysisStatus === 'Active' ? '#84df9e' : '#96acbc'}>
                                <div>{t('Status')}</div>
                                <Label htmlFor='statusAnalysis' labelText={!cameraAnalysisStatus ? '-' : t(cameraAnalysisStatus)} />
                              </DescriptionDiv>
                              <DescriptionDiv isStatus={false} statusColor={cameraAnalysisStatus === 'Active' ? '#84df9e' : '#96acbc'}>
                                <div>{t('AnalysisType').replace('Analysis', '')}</div>
                                <AnalysisTypeList title={getEnabledAnalysis()}>
                                  {getEnabledAnalysis()}
                                </AnalysisTypeList>
                              </DescriptionDiv>
                            </DescriptionDivContainer>
                          </td>
                        </tr>
                        <tr>
                          {cameraDetails.notes !== '' ?
                            <>
                              <td><Icon icon='Edit' size={20} color='dimmed' /></td>
                              <td>
                                <Heading htmlFor='notes' labelText={t('Notes')} />
                                <DescriptionDiv>
                                  <div>
                                    {`${(getLineBreak(cameraDetails?.notes) ? cameraDetails.notes.slice(0, 7) + '...' : (cameraDetails.notes.length > 120 ? cameraDetails.notes.slice(0, 120) + '...'  : cameraDetails.notes))}`}

                                    {
                                      (cameraDetails.notes.length > 120 || (getLineBreak(cameraDetails?.notes))) && <ReadMoreButton onClick={handleReadMore}>{t('[Read More]')}</ReadMoreButton>
                                    }
                                  </div>
                                </DescriptionDiv>
                              </td>
                            </> : null}
                        </tr>
                      </tbody>
                    </DetailsTable>
                  </CameraDetailsLeftPanel>
                  <CameraDetailsRightPanel>
                    <LineViewer
                      streamName={streamName} 
                      latestSnapshotText={t('Latest Snapshot')}
                      zoomText={t('Zoom')}
                      rtcText={t('Live Feed')}
                      linesData={[]}
                      width='100%'
                    />
                  </CameraDetailsRightPanel>
                </OverviewContainer>
              </TabContent>
              <TabContent tabId='files'>
                <CameraDetailsFiles streamName={streamName} selectedTab={selectedTab} cameraName={cameraDetails.camera_name} />
              </TabContent>
              <TabContent tabId='alerts'>
                <CameraAlertsTab selectedTab={selectedTab} camName={streamName} streamName={cameraDetails.camera_name} fetchActiveCountCallBack={fetchAlertHistory} />
              </TabContent>
            </Tabs>
          </MainContent>
        </Container>
      ) : (
        <Container>
          <SpinnerContainer>
            <Spinner size='large' styling='primary' />
            <Label htmlFor='loader' labelText={t('Loading') + '...'} />
          </SpinnerContainer>
        </Container>
      )}
    </MainContainer>
  );
};

export default CameraDetails;