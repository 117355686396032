import React, {useCallback} from 'react';
import styled from 'styled-components';
import {INotificationItem} from '../../interface';
import { useMediaModal } from 'scorer-ui-kit';
import { getTimeSinceFromSeconds } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { analysisTypeList } from '../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 20px 0 20px;
`;

const ImgWrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;
  height: 40px;
  width: 50px;
  flex-shrink: 0;
  margin-top: 5px;
  cursor: pointer;
`;

const EmptyImg = styled.div`
  background-color: hsl(210, 22%, 91%);
  width: 100%;
  height: 100%;
`;

const Image = styled.div<{ image?: string }>`
  width: 50px;
  height: 40px;
  background-image: url(${p => p.image});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; 
  display: ${p => p.image ? 'block' : 'none'};
`;

const InfoContainer = styled.div`
  margin-left: 20px;
`;

const Title = styled.div`
  width: 170px;
  font-size: 16px;
  font-weight: 500;
  color: hsl(0, 0%, 34%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Message = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: hsl(0, 0%, 34%);
  width: 195px;
  cursor: default;
  margin-top: -2px;
  margin-bottom: 10px;
`;

const TimeMsg = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #aaa;
  margin-top: 11px;
  cursor: default;
  margin-bottom: 13px;
`;

interface IAnalysisType {
  text: string,
  value: number
}

const NotificationItem: React.FC<INotificationItem> = ({ imgUrl, title, message, time, streamName, analysisName }) => {
  const { t } = useTranslation(['CommonDict']);
  const { createMediaModal } = useMediaModal();
  const {push} = useHistory();

  const onClickImage = useCallback(async() => {
    if(imgUrl){
      createMediaModal({ 
        src: imgUrl,
        mediaType: 'img',
        closeText: t('CLOSE'),
      });
    }
  },[createMediaModal, imgUrl, t]);

  const onClickCameraName = useCallback(() => {
    push('/cameras/camera-details/' + streamName + '/alerts?selectedTab=alerts');
  }, [push, streamName]);

  return (
    <Container>
      <ImgWrapper onClick={()=>onClickImage()}>
        {imgUrl
          ? <Image image={imgUrl} />
          : <EmptyImg />}
      </ImgWrapper>
      <InfoContainer>
        <Title
          title={`[${
            message.includes('#') ? message.split('#')[0]
              : t(((analysisTypeList as IAnalysisType[]).find(item => item.value === analysisName) as IAnalysisType).text)}] ${title}`}
          onClick={()=>onClickCameraName()}
        >
          {
            `[${
              message.includes('#') ? message.split('#')[0]
                : t(((analysisTypeList as IAnalysisType[]).find(item => item.value === analysisName) as IAnalysisType).text)}] ${title}`
          }
        </Title>
        <Message
          title={message.includes('#') ? message.split('#')[1] : message}
        >
          {message.includes('#') ? message.split('#')[1] : message}
        </Message>
        <TimeMsg>{getTimeSinceFromSeconds(time)}</TimeMsg>
      </InfoContainer>
    </Container>
  );
};

export default NotificationItem;