import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SmallInput,
  Button,
  Label,
} from 'scorer-ui-kit';
import styled from 'styled-components';

const LastExportBox = styled.div``;

const LastExportText = styled(Label)`
  font-size: 20px;
`;

const LastButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  & > button:first-child {
    margin-right: 10px;
    background: #e4edf4;
  }
`;

const ButtonFormat = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const ButtonWithIconFormat = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const PeriodicAndCameraEnabled = styled.div`
  display: flex;
  align-items: center;
  gap: 0 39px;
  margin-right: 10px;
  margin-top: 40px;
`;

const SnapshotSetting = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  & > div {
    & > label {
      margin-bottom: 0;
      & > span {
        margin-bottom: 0;
      }
    }
  }
  span::after {
    font-weight: bold;
    color: rgb(238, 75, 43);
  }
`;

const StyledSmallInput = styled(SmallInput)<ISmallInput>`
  width: ${({ length }) => length};
  min-height: 30px;
  & > label {
    margin-bottom: 0;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Formate = styled.div`
  display:flex;

`;

const AlignBox = styled.div`
  display:flex;
  margin-top:20px;
  gap:5px;
`;

const LabelFormatter =styled(Label)`
  span {
    font-size: 12px;
  }
`;

const LabelFormatterColon =styled(Label)`
  span {
    font-size: 12px;
  }
  margin-right:3px;
`;

const ErrorDiv = styled.div`
  font-size: 14px;
  color: rgb(239, 120, 120);
  height: 20px;
  margin-top: -5px;
`;

interface ISmallInput {
  length: string;
}

interface ISnapShot {
  periodicSnapshotCamera: number | undefined;
  setModalOpen:(data: boolean) => void;
  onSnapshotSaving: (data: boolean) => void;
  data: boolean;
  parentCallBack: (data: any) => void;
}

const SnpshotModal: FC<ISnapShot> = ({ periodicSnapshotCamera, setModalOpen, onSnapshotSaving, data, parentCallBack }) => {
  const { t } = useTranslation(['CommonDict']);
  const [snapShot, setSnapShot] = useState(periodicSnapshotCamera);
  const [isValid, setIsValid] = useState(true);

  const onPeriodicSnapshotChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const regExNumberBetween1to60 = /\b([1-9]|[1-5][0-9]|60)\b/;
    if (regExNumberBetween1to60.exec(value) || value === '') {
      setSnapShot(parseInt(value));
      parentCallBack(parseInt(value));
      setIsValid(true);
    }
  }, [setSnapShot, parentCallBack, setIsValid]);

  const onSave = useCallback(() => {
    if(snapShot) {
      onSnapshotSaving(data);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [data, onSnapshotSaving, snapShot, setIsValid]);

  return (
    <LastExportBox>
      <LastExportText htmlFor='' labelText={t('Enable Snapshot Saving')} />
      <PeriodicAndCameraEnabled>
        <SnapshotSetting>
          {t('Periodic Snapshot Interval')} :
          <StyledSmallInput
            fieldState={isValid ? 'default' : 'invalid'}
            name='periodicSnapshot'
            type='number'
            length='90px'
            min={1}
            max={60}
            value={snapShot}
            label=''
            unit={t('mins')}
            onChange={onPeriodicSnapshotChange}
          />
        </SnapshotSetting>
      </PeriodicAndCameraEnabled>
      <AlignBox>
        <Formate>
          <LabelFormatter htmlFor='' labelText={t('Minimum')} />
          <LabelFormatterColon htmlFor='' labelText=':'  />
          <LabelFormatter htmlFor='' labelText={t(' 1 mins,')} />
        </Formate>
        <Formate>
          <LabelFormatter htmlFor='' labelText={t('Maximum')} />
          <LabelFormatterColon htmlFor='' labelText=':' />
          <LabelFormatter htmlFor='' labelText={t(' 60 mins')} />
        </Formate>
      </AlignBox>
      {isValid ? <ErrorDiv /> : <ErrorDiv>{t('Please enter the periodic snapshot interval.')}</ErrorDiv>}
      <LastButtonBox>
        <ButtonFormat
          autoFocus
          id='CancelButton'
          design='secondary'
          onClick={() => setModalOpen(false)}
        >
          {t('Close')}
        </ButtonFormat>
        <ButtonWithIconFormat
          tabIndex={0}
          design='primary'
          onClick={() => onSave()}
        >
          {t('Enable')}
        </ButtonWithIconFormat>
      </LastButtonBox>
    </LastExportBox>
  );
};

export default SnpshotModal;