import React, { useState } from 'react';
import { Label } from 'scorer-ui-kit';
import styled from 'styled-components';
import { detectBrowser } from '../utils/index';

export const Span = styled.span`
  height: 17px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
  margin-left: -30px;
  margin-bottom: -4px;
`;

const TextField = styled.input<{propsData: string} >`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-weight: 500;
  font-size: 1.0em;
  padding: 6px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  margin: 0;
  border: 1px solid #e9e3e3;
  letter-spacing: 1px;
  text-indent: 5px;
  &:disabled {
    color: #000000;
    background: #f2f2f2;
  }
  &:focus {
    outline: none;
    border-color: none;
  }
  &:read-only:: placeholder {
    color: #f2f2f2;
  }
  -webkit-text-security: ${({ propsData }) => propsData==='showPassword' ? 'disc;' : 'none;'}
  input {
    color: rgb(139, 145, 150);
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-weight: 500;
  }
  color: rgb(139, 145, 150);

`;

const Container =styled.div`
  input {
    width: 300px;
  }
`;

const TextBasicConfig = styled(Label)`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: hsl(207,5%,57%);
  font-weight: 500;
  background: #f9fafb;
  z-index: 1;
  padding-right: 18px;
  white-space: nowrap;
  span {
    margin-bottom: 0;
  }
  span::after {
    font-weight: bold;
    color: rgb(238, 75, 43);
  }
  `;

function SvgPasswordHide() {
  return (
    <svg width={26} height={24} viewBox='0 0 24 24' style={{ cursor: 'pointer', marginBottom:'-8px', marginLeft: '-2px', paddingRight:'5px'}}>
      <path
        d='M2.783 20l18.75-18M8.964 18.051A9.985 9.985 0 0012 18.5c4.1.069 8.258-2.813 10.824-5.637.57-.633.57-1.593 0-2.226A20.568 20.568 0 0019.75 7.88m-5.337-2.598A9.549 9.549 0 0012 5c-4.031-.067-8.2 2.752-10.821 5.635a1.663 1.663 0 000 2.226 20.8 20.8 0 002.6 2.4M8.25 11.75A3.749 3.749 0 0112 8m3.75 3.749A3.75 3.75 0 0112 15.5'
        fill='none'
        fillRule='evenodd'
        stroke='rgb(78, 156, 211)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
    </svg>
  );
}

function SvgPasswordShow() {
  return (
    <svg width={22} height={22} viewBox='0 0 24 24' style={{ cursor: 'pointer', marginBottom:'-6px' , marginLeft: '-2px'}}>
      <g
        fill='none'
        fillRule='evenodd'
        stroke='rgb(78, 156, 211)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        vectorEffect='non-scaling-stroke'
      >
        <path d='M12 5.251C7.969 5.183 3.8 8 1.179 10.885a1.663 1.663 0 000 2.226C3.743 15.935 7.9 18.817 12 18.748c4.1.069 8.258-2.813 10.824-5.637.57-.633.57-1.593 0-2.226C20.2 8 16.031 5.183 12 5.251z' />
        <path d='M15.75 12a3.75 3.75 0 11-7.5-.002 3.75 3.75 0 017.5.002z' />
      </g>
    </svg>
  );
}

function UsePasswordField(props:any) {
  const [passwordType, setpasswordType] = useState('password');
  const browserType = useState(() => detectBrowser());

  const onClickIcon = () => {
    setpasswordType( passwordType === 'text' ? 'password' : 'text');
  };

  const handleOnChange = (event:any) => {
    props.setInput(event);
  };

  return (
    <Container>
      <TextBasicConfig htmlFor='' labelText={`${(props.label)}`} />
      <TextField type={browserType[0] === 'Chrome' ? 'text' : passwordType} id={props.id} propsData={passwordType === 'password' ? 'showPassword' : 'hidePassword'} name={props.name} value={props.value} style={{ paddingRight: '30px', width: props.width, height:props.height }} onChange={handleOnChange} disabled={props.readOnly} autoComplete='off' />
      <Span onClick={onClickIcon}> {passwordType === 'text' ? <SvgPasswordShow /> : <SvgPasswordHide />} </Span>
    </Container>
  );
}

UsePasswordField.defaultProps = {
  width: '222px',
};

export default UsePasswordField;