import React, { useCallback, useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, SmallInput} from 'scorer-ui-kit';
import styled from 'styled-components';
import car from '../../svg/carBackground.jpg';

const HitPointContainer = styled.div`
  margin: 30px 0 15px;
  display: flex;
  border-radius: 3px;
  border: solid 1px #eee;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.02);
  background-color: rgba(252, 252, 253, 0.85);
`;

const HitPointDragAreaContainer = styled.div`
  width: 700px;
  height: 390px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #4d4d4d
`;

const BlurredDiv = styled.div`
  width: 98%;
  height: 96%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-image: url(${car});
  
  /* Center and scale the image nicely */
  background-position: 50% 54%;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
  filter: blur(4px);
`;

const HitPointDragArea = styled.div`
  width: 436px;
  height: 334px;
  margin-left: 2px;
  box-shadow: 0 0 0 5px #2c00ff;
  position: relative;
`;

const Marker = styled.div<{left: string, top: string}>`
  border-radius: 100%;
  background: transparent;
  position: absolute;
  top: ${({top}) => top === '0px' ? '-2.1px' : top};
  left: ${({left}) => left === '0px' ? '-2px' : left};;
`;

const MarkerIconContainer = styled.div<{isMouseGrabbed: boolean}>`
  padding: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  cursor: pointer;
  transform: translate(-50%,-50%);
  background: ${({isMouseGrabbed}) => isMouseGrabbed ? 'rgba(0, 208, 255, 0.65)' : 'transparent'};
  box-shadow: ${({isMouseGrabbed}) => isMouseGrabbed ? '0 0 0 4px #c7e8ff, 0 0 0 5px rgba(192, 217, 234, 0.5)' : 'none'};

  :hover {
    background: rgba(0, 208, 255, 0.65);
    box-shadow: 0 0 0 4px #c7e8ff, 0 0 0 5px rgba(192, 217, 234, 0.5);
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg > g {
      stroke: red;
    }
  }
`;

const HitPointInputFields = styled.div`
  width: 239px;
  flex-shrink: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
`;

const PaddingDiv = styled.div`
  padding: 0 20px 0 19px;
  width: 100%;
  label {
    width: 100%;
  }
`;

const Divider = styled.div<{margin: string}>`
  height: 1px;
  width: 100%;
  margin: ${({margin}) => margin};
  border-radius: 3px;
  background-color: #efefef;
`;

const ColoredButton = styled(Button)`
  width: 100%;
  background: #e4edf4;
  :hover:enabled {
    background: #d4e4f0;
  }
  :disabled {
    background: #e9f0f6;
  }
`;

const ResetButton = styled(ColoredButton)``;

interface IProps {
  x: number,
  y: number,
  hitPointChange: (x: number | string, y: number | string) => void
}

interface IHitPoint {
  x: number | string,
  y: number | string
}

const HitPointSettings: React.FC<IProps> = ({x = 0, y = 0, hitPointChange = () => {}}) => {

  const { t } = useTranslation(['CommonDict']);
  const [isMouseGrabbed, setIsMouseGrabbed] = useState(false);
  const [isMount, setIsMount] = useState(true);
  const [hitPointVal, setHitPointVal] = useState<IHitPoint>({
    x: Math.round(((x * 439) + Number.EPSILON)),
    y: Math.round(((y * 336) + Number.EPSILON))
  });
  const markerContainerRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<HTMLDivElement>(null);
  const offset = useRef({
    x: Math.round(((x * 439) + Number.EPSILON)),
    y: Math.round(((y * 336) + Number.EPSILON))
  });

  useEffect(() => {
    if (isMount) {
      setHitPointVal({
        x: Math.round(((x * 439) + Number.EPSILON)),
        y: Math.round(((y * 336) + Number.EPSILON))
      });
      setIsMount(false);
    }
  }, [x, y, isMount]);
  
  const move = useCallback((e: MouseEvent) => {
    e.preventDefault();
    const {clientX, clientY} = e;
    if (markerRef.current !== null) {
      // update
      let mLeftUpdateVal = clientX - offset.current.x;
      let mTopUpdateVal = clientY - offset.current.y;
      
      if (markerContainerRef.current !== null) {
        if (mLeftUpdateVal <= 0) {
          mLeftUpdateVal = 0;
        } else if (mLeftUpdateVal >= 439) {
          mLeftUpdateVal = 439;
        }
        if (mTopUpdateVal <= 0) {
          mTopUpdateVal = 0;
        } else if (mTopUpdateVal >= 336) {
          mTopUpdateVal = 336;
        }
        setHitPointVal({
          x: mLeftUpdateVal,
          y: mTopUpdateVal
        });
      }
    }
  }, []);

  const mouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsMouseGrabbed(true);
    const {clientX, clientY} = e;
    if (markerRef.current !== null) {
      // initialize
      offset.current.x = clientX - markerRef.current.offsetLeft;
      offset.current.y = clientY - markerRef.current.offsetTop;
    }
    
    if (markerContainerRef.current !== null) {
      window.addEventListener('mousemove', move);
    }
  }, [move]);

  const mouseUp = useCallback(() => {
    if (markerContainerRef.current !== null) {
      setIsMouseGrabbed(false);
      window.removeEventListener('mousemove', move);
    }
  }, [move]);

  useEffect(() => {
    window.addEventListener('mouseup', mouseUp);
    
    return () => {
      window.removeEventListener('mouseup', mouseUp);
    };
  }, [mouseUp]);

  const onInputChange = useCallback((value: string, type: 'x'|'y') => {
    const parsedVal = parseFloat(value);
    const regex = /^0\.[0-9][0-9]{0,1}$|^01$|^0$|^1$|^1\.0$/;
    
    if (isNaN(parsedVal) || value.length === 0) {
      setHitPointVal(prev => {
        return {
          ...prev,
          [type]: ''
        };
      });
    } else if (regex.test(value)) {
      setHitPointVal(prev => ({
        ...prev,
        [type]: Math.round(((parsedVal * (type === 'x' ? 439 : 336)) + Number.EPSILON))
      }));
    }

    // This will remove multiple zeroes from input field
    if (/^0\.[0-9][0-9]0+$/.test(value)) {
      (document.getElementById(type === 'x' ? 'horizontalPoint' : 'verticalPoint') as HTMLInputElement).value = value.slice(0, 4);
    } else if (/^1\.0+$/.test(value)) {
      (document.getElementById(type === 'x' ? 'horizontalPoint' : 'verticalPoint') as HTMLInputElement).value = value.slice(0, 3);
    } else if (/^00+$/.test(value)) {
      (document.getElementById(type === 'x' ? 'horizontalPoint' : 'verticalPoint') as HTMLInputElement).value = value.slice(0, 0);
    }
  }, []);

  const onReset = useCallback(() => {
    setHitPointVal({
      x: Math.round(((0.5 * 439) + Number.EPSILON)),
      y: Math.round(((1 * 336) + Number.EPSILON))
    });
  }, []);
  
  useEffect(() => {
    if (isMouseGrabbed === true) return;
    if (hitPointVal.x !== '' && hitPointVal.y !== '') {
      const x = (Math.round((((hitPointVal.x as number) / 439) + Number.EPSILON) * 100) / 100);
      const y = (Math.round((((hitPointVal.y as number) / 336) + Number.EPSILON) * 100) / 100);
      hitPointChange(x, y);
    } else {
      hitPointChange('', '');
    }
  }, [isMouseGrabbed, hitPointVal, hitPointChange]);

  return (
    <HitPointContainer>
      <HitPointInputFields>
        <PaddingDiv>
          <SmallInput
            fieldState='default'
            id='horizontalPoint'
            name='horizontalPoint'
            label={t('Horizontal Position')}
            type='number'
            maxLength={4}
            step={0.01}
            min={0}
            max={1}
            unit={t('X')}
            value={hitPointVal.x === '' ? hitPointVal.x : (Math.round((((hitPointVal.x as number) / 439) + Number.EPSILON) * 100) / 100)}
            onChange={({target: {value}}) => onInputChange(value, 'x')}
            onPaste={(evt) => evt.preventDefault()}
            onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          />
        </PaddingDiv>
        <Divider margin='14px 0 15px' />
        <PaddingDiv>
          <SmallInput
            fieldState='default'
            id='verticalPoint'
            name='verticalPoint'
            label={t('Vertical Position')}
            type='number'
            maxLength={4}
            step={0.01}
            min={0}
            max={1}
            unit={t('Y')}
            value={hitPointVal.y === '' ? hitPointVal.y : (Math.round((((hitPointVal.y as number) / 336) + Number.EPSILON) * 100) / 100)}
            onChange={({target: {value}}) => onInputChange(value, 'y')}
            onPaste={(evt) => evt.preventDefault()}
            onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          />
        </PaddingDiv>
        <Divider margin='0 0 20px' />
        <PaddingDiv>
          <ResetButton size='small' design='secondary' disabled={x === 0.5 && y === 1} onClick={() => onReset()}>{t('Reset')}</ResetButton> 
        </PaddingDiv>
      </HitPointInputFields>
      <HitPointDragAreaContainer>
        <BlurredDiv />
        <HitPointDragArea ref={markerContainerRef}>
          <Marker
            ref={markerRef}
            left={hitPointVal.x === '' ? '-2px' : (hitPointVal.x + 'px')}
            top={hitPointVal.y === '' ? '-2.1px' : (hitPointVal.y + 'px')}
          >
            <MarkerIconContainer
              onMouseDown={mouseDown}
              isMouseGrabbed={isMouseGrabbed}
            >
              <Icon icon='CloseCompact' color='danger' size={35} weight='heavy' />
            </MarkerIconContainer>
          </Marker>
        </HitPointDragArea>
      </HitPointDragAreaContainer>
    </HitPointContainer>
  );
};

export default HitPointSettings;