import React, { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionButtons, Button, ButtonWithIcon, Content, Label, MultilineContent, PageHeader, Tab, TabContent, TabList, Tabs, TypeTable, useModal, useNotification, Switch, Spinner } from 'scorer-ui-kit';
import { ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled, { css } from 'styled-components';
import { DATE_FORMATT_WITH_HYPHEN } from '../../constants';
import { getAllUsers, getGuestUser, putGuestUpdate, EnableDisableUser } from 'services/apiConfig';
import ModelBox from 'components/ModelBox';
import AuthService from 'services/authService';
import { capitalizeWords } from 'utils';
import TokenService from 'services/tokenService';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 62px 90px;
  @media screen and (min-width: 1440px) {
    padding: 69px 0px 62px 169px;
  }
`;

const Container = styled.div`
  max-width: 940px !important;
`;

const HeaderBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 942px !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  & > div > div > div{
    bottom: 4px;
  }
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  & div > div > div:nth-child(1) {
    top: -14px;
  }
  & div > div > div:nth-child(3) {
    top: 3px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: -56px;
`;

const IntroductionText = styled.span`
  height: 60px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: normal;
  color: #7b8288;
  line-height: 1.43;
  letter-spacing: normal;
  margin-top: -10px;
`;

const NotesBox = styled.div`
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const UsernameBox = styled.div`
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const UserTypeBox = styled.div<{lang?: string}>`
  margin-left: ${({lang}) => lang === 'ja' ? '3px' : ''};
`;

const TableBox = styled.div`
  position: relative;
  z-index: 0;
  max-width: 970px;
`;

const EditButton = styled.div`
  width: 55px;
  margin-right: 7px;
`;

const GuestContainer = styled.div`
  display: flex;
  position: relative;
  top: 51px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 944px;
`;

const GuestBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

const GuestTextBox = styled.div`
  max-width: 539px;
`;

const GuestTitle = styled(Label)`
  font-size: 20px;
  color: #5a6269;
  margin-bottom: 0;
`;

const GuestButton = styled(Button)`
  margin-bottom: 8px;
  margin-top: -41px;
  flex-shrink: 0;
`;

const GuestTitleSubtitle = styled(Label)`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.79;
  margin-bottom: 0;
`;

const ModelText = styled(Label)`
  margin-bottom: 0;
  span{
    margin-bottom: 0;
  }
`;

const TabLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
  margin-top: -1.1px;
`;

const JSTFormat = styled.div`
  font-size: 12px;
  font-style: italic;
  text-align: right;
  color: rgba(120, 138, 144, 0.72);
  margin-left: 3px;
`;

const DateFormating =styled.div`
  display:flex;
  flex-direction:row;
`;

const StatusTextEnabled = styled(Label)`
  span {
    margin-bottom: -1px;  
  }
  font-size: 16px;
  color: #5a6269;
  margin: 0 0 8px 4px;
  color: rgb(92, 200, 121);
`;

const StatusTextDisabled = styled(Label)`
  span {
    margin-bottom: -1px;  
  }
  font-size: 16px;
  color: #5a6269;
  margin: 0 0 8px 4px;
  color: hsla(0,0%,55.3%,1.000);
`;

const AdvanceTextBox = styled.div`
  width: 518px;
`;

const Formatter = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
`;

const ToggleDetection = styled.div<{state?: string}>`
  width: 50px;
  margin-left: 25px;
  align-items: center;
  margin-top: -4px;
  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
    `}
`;

const CancelModalContainer = styled.div`
  padding: 18px 48px 22px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const CancelChangesButton = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 20vh;
  align-items: center;
  justify-content: center;
`;

interface IDevice {
  created: string
  last_login: string
  note: string
  role: string
  user_id: number
  username: string
  enable: boolean
}

const UserManagement: FC = () => {
  const [userData, setUserData] = useState([]);
  const [rows, setRows] = useState<ITypeTableData>([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const { push } = useHistory();
  const [guestStatus, setGuestUser] = useState(false);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const { createModal, setModalOpen, isModalOpen } = useModal();
  const [ selectedTab, setSelectedTab ] = useState<string>('users');
  const params = useLocation().search;
  const [ historyParams ] = useState<string>(params);
  const { replace } = useHistory();

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('CancelButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('CancelButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'CancelButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('CancelButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('CancelButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const fetchHistoryParams = useCallback(() => {
    const urlString = new URLSearchParams(historyParams);
    const tabName = urlString.get('tab');
    if(tabName){
      setSelectedTab(tabName);
    } else {
      setSelectedTab('users');
      replace(window.location.pathname + '?tab=users');
    }
  }, [ historyParams, setSelectedTab, replace ]);

  useEffect(() => {
    fetchHistoryParams();
  }, [ fetchHistoryParams ]);

  const fetchAllUserList = useCallback(async () => {
    try {
      const { data: { status, data } } = await getAllUsers();
      if (status === 200) {
        setUserData(data);
      } else if (status === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load user data') });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAllUserList();
    setLoading(true);
  }, [fetchAllUserList]);

  const columnConfig: ITableColumnConfig[] = useMemo(() => [
    {
      header: t('Username'),
      cellStyle: 'firstColumn',
      minWidth: 150,
    },
    {
      header: t('UserType').replace('User', ''),
      cellStyle: 'normalImportance',
      alignment: 'left',
      minWidth: 100,
    },
    {
      header: t('Created'),
      cellStyle: 'normalImportance',
      alignment: 'left',
      minWidth: 170,
    },
    {
      header: t('Last login'),
      cellStyle: 'normalImportance',
      alignment: 'left',
      minWidth: 170,
    },
    {
      header: t('Notes'),
      cellStyle: 'normalImportance',
      showUnit: true,
      alignment: 'left',
    },
    {
      header: t('Enabled'),
      cellStyle: 'normalImportance',
      alignment: 'center',
      minWidth: 80
    },
    {
      header: t('Edit'),
      cellStyle: 'normalImportance',
      alignment: 'center',
      minWidth: 80
    }
  ], [t]);

  const handleCancel = useCallback(async (setModalOpen: (newStatus: boolean) => void, username, enable) => {
    await setModalOpen(false);
    try {
      const res = await EnableDisableUser(username, !enable);
      if (res.status === 200) {
        sendNotification({ type: 'success', message: t(`User ${enable ? 'disabled' : 'enabled'} successfully`) });
        fetchAllUserList();
      } else {
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    } catch (error) {
      if (error instanceof Error) {
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    }
  }, [sendNotification, t, fetchAllUserList]);

  const handleCancleNo = useCallback(() =>{
    setModalOpen(false);
    setLoading(true);
    fetchAllUserList();
  },[setModalOpen, fetchAllUserList]);

  const getCancelModal = useCallback((setModalOpen: (newStatus: boolean) => void, username, enable) => {
    return (
      <CancelModalContainer>
        <CancelConfirmText>{tRef.current('Are you sure you want to disable the user?')}</CancelConfirmText>
        <ModalButtonContainer>
          <CancelChangesButton id='CancelButton' design='secondary' onClick={() => handleCancleNo()}>{tRef.current('No')}</CancelChangesButton>
          <CancelChangesButton id='YesButton' onClick={() => handleCancel(setModalOpen, username, enable)}>{tRef.current('Yes')}</CancelChangesButton>
        </ModalButtonContainer>
      </CancelModalContainer>
    );
  }, [handleCancel, handleCancleNo]);

  const handleToggleUser = useCallback((username, enable) =>{
    if(enable){
      const CancelModal: ReactElement = getCancelModal(setModalOpen, username, enable);
      createModal({ isCloseEnable: false, width: '400px', padding: false, customComponent: CancelModal });
    } else {
      handleCancel(setModalOpen, username, enable);
    }
  },[createModal, getCancelModal, setModalOpen, handleCancel]);

  const generateCreatedColumn = useCallback((data: string | null): ReactElement[] => {
    if (data) {
      return [<DateFormating key={data}><div key=''>{`${format(new Date(data), DATE_FORMATT_WITH_HYPHEN)}`}</div> <JSTFormat>JST</JSTFormat></DateFormating>];
    } else {
      return [<div key=''>&emsp; &nbsp; -</div>];
    }
  }, []);

  const generateUserNameColumn = useCallback((data = '-'): ReactElement[] => {
    if (data.trim() === '') {
      return [<UsernameBox key=''>-</UsernameBox>];
    }
    return [<UsernameBox key='' title={data}>{data}</UsernameBox>];
  }, []);

  const generateUserTypeColumn = useCallback((role): ReactElement[] => {
    return [<UserTypeBox key='' lang={i18n.language}>{`${t(capitalizeWords(role === 'admin' ? 'administrator' : role ))}`}</UserTypeBox>];
  }, [t, i18n]);

  const generateNotesColumn = useCallback((data = '-'): ReactElement[] => {
    if (data.trim() === '') {
      return [<NotesBox key=''>&emsp; &nbsp; -</NotesBox>];
    }
    return [<NotesBox key='' title={data}>{data}</NotesBox>];
  }, []);

  const generateToggleColumn = useCallback((username, enable): ReactElement[] => {
    return [
      <ToggleDetection key='' state={TokenService?.getUser() === username ? 'disabled' : 'default'}>
        <Switch checked={enable} onChangeCallback={() => handleToggleUser(username, enable)} state={TokenService?.getUser() === username ? 'disabled' : 'default'} />
      </ToggleDetection>
    ];
  }, [handleToggleUser]);

  const generateRowData = useCallback(() => {
    const data: ITypeTableData = userData.map((device: IDevice) => {
      const { user_id, username, created, role, note, last_login, enable } = device;
      const row = {
        columns: [
          {
            customComponent: (
              <MultilineContent
                contentArray={generateUserNameColumn(username)}
              />
            ),
          },
          { 
            customComponent: (
              <MultilineContent
                contentArray={generateUserTypeColumn(role)}
              />
            )
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateCreatedColumn(created)}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateCreatedColumn(last_login)}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateNotesColumn(note)}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateToggleColumn(username, enable)}
              />
            ),
          },
          { 
            customComponent: <EditButton><ActionButtons alignment='right' buttonsConfig={[{ icon: 'Edit', size:18, onClick: () => { push(`/settings/edit-user?user-id=${user_id}`); } }]} /></EditButton> 
          },
        ],
        id: user_id,
      };
      return row;
    });
    return data;
  }, [userData, push, generateUserNameColumn, generateCreatedColumn, generateNotesColumn, generateToggleColumn, generateUserTypeColumn]);

  useEffect(() => {
    setRows(generateRowData());
  }, [userData, generateRowData]);


  const fetchGuestData = useCallback(async () => {
    try {
      const { data: { data, status_code } } = await getGuestUser();
      if (status_code === 200) {
        setGuestUser(data.guest_enabled);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: t('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
        }
      }
      else {
        setGuestUser(false);
      }
    } catch (error) {
      setGuestUser(false);
      console.error((error as Error).message);
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    }
  }, [t]);

  useEffect(() => {
    fetchGuestData();
  }, [fetchGuestData]);

  const onGuestUser = useCallback(async () => {
    try {
      const { data: { status_code } } = await putGuestUpdate(!guestStatus);
      if (status_code === 200) {
        setGuestUser(!guestStatus);
        sendNotification({ type: 'success', message: t(`Guest Access ${guestStatus ? 'disabled' : 'enabled'} successfully`) });
        setModalOpen(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: t('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
        }
      }
      else {
        sendNotification({ type: 'error', message: t('Failed to load guest status') });
      }
    } catch (error) {
      console.error((error as Error).message);
      sendNotification({ type: 'error', message: t('Failed to communicate with the system') });
    }
  }, [setModalOpen, guestStatus, t, sendNotification]);

  const onClickGuestUser = useCallback(() => {
    createModal({
      isCloseEnable: false,
      width: '480px',
      padding: true,
      dismissCallback: () => { setModalOpen(false); },
      customComponent: (
        <ModelBox onConfirm={() => { onGuestUser(); }} modalOpen={setModalOpen} cancelText='No' confirmText='Yes' confirmStyle='primary'>
          <ModelText htmlFor='' labelText={t(`Are you sure you want to ${guestStatus ? 'disable': 'enable'} guest user?`)} />
        </ModelBox>),
    });
  }, [guestStatus, onGuestUser, createModal, setModalOpen, t]);

  const onClickTab = useCallback((tabName:string) => {
    setSelectedTab(tabName);
    replace(window.location.pathname + '?tab=' + tabName);
  },[replace]);

  return (
    <MainContainer>
      <Container>
        <HeaderBox>
          <PageHeaderWrapper>
            <PageHeader title={tRef.current('User Management')} areaTitle={tRef.current('Settings')} icon='ViewSettings' updateDocTitle={false} />
            <IntroductionText>{tRef.current('Manage the users that have access to this instance of the Traffic Counter system .')}</IntroductionText>
          </PageHeaderWrapper>
          <ButtonWrapper>
            <ButtonWithIcon design='primary' icon='Add' position='left' size='small' onClick={() => push('/settings/create-user')}>{t('Create New User')}</ButtonWithIcon>
          </ButtonWrapper>
        </HeaderBox>
        <Tabs>
          <TabList defaultTabId={selectedTab}>
            <Tab tabFor='users' onClick={()=>onClickTab('users')}>{t('Users')}</Tab>
            <Tab tabFor='guest' onClick={()=>onClickTab('guest')}>{t('Guest Access')}</Tab>
          </TabList>
          <TabLine />
          <TabContent tabId='users'>
            {loading ? 
              <SpinnerBox>
                <Spinner size='large' styling='primary' />
                <Label htmlFor='loader' labelText={t('Loading') + '...'} />
              </SpinnerBox>
              :
              <TableBox>
                <TypeTable
                  columnConfig={columnConfig}
                  rows={rows.length > 0 ? rows : [{ columns: [] }]}
                  isLoading={loading}
                  loadingText={t('Loading')}
                  emptyTableText={t('No Users Found')}
                  defaultAscending
                  hasHeaderGroups
                />
              </TableBox>}
          </TabContent>
          <TabContent tabId='guest'>
            <GuestContainer>
              <AdvanceTextBox>
                <Formatter>
                  <GuestTitle htmlFor='' labelText={t('Enable Guest Access{Heading}').replace('{Heading}', '')} />
                  {guestStatus ?
                    <StatusTextEnabled htmlFor='' labelText={t('Enabled')} />:
                    <StatusTextDisabled htmlFor='' labelText={t('Disabled')} />}
                </Formatter> 
              </AdvanceTextBox> 
              <GuestBox>
                <GuestTextBox>
                  <GuestTitleSubtitle htmlFor='' labelText={t('This will give anyone the read-only access to this service without needing a user name or password.')} />
                </GuestTextBox>
                <GuestButton design={guestStatus ? 'secondary' : 'primary'} onClick={onClickGuestUser}>{guestStatus ? t('Disable Guest Access') : t('Enable Guest Access')}</GuestButton>
              </GuestBox>
            </GuestContainer>
          </TabContent>
        </Tabs>
      </Container>
    </MainContainer>
  );
};

export default UserManagement;