import React, { useCallback, useEffect, useRef, useState, ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Button, ButtonWithIcon, ButtonWithLoading, Content, Icon, PageHeader, SelectField, SmallInput, Switch, useModal, useNotification, useMediaModal } from 'scorer-ui-kit';
import { getCamera, getLowSpeed, setLowSpeed, toggleConfigurationAnalysis } from 'services/camerasService';
import styled, {css} from 'styled-components';
import { IVector2, IPointSet, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import LineViewer from 'components/LineViewer/configurationLineViewer';
import { IExclusionAreas } from 'pages/CameraConfiguration/types';
import { NEW_SET } from '../../constants';
import { ILowSpeedDetails, ILowSpeedDetectionData, ICameraDetails, ILines, ILowSpeedDetectionDataMapped, ILowSpeedDetailsMapped, ILinesMapped } from 'interface';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import i18n from 'i18n';
import DisableConfirmationModal from 'components/DisableConfirmationModal';
import {IToggleAnalysisPayload} from '../../interface';
import ImportSettingsModal from './ConfigurationImportModal';
import { EDGE_API_BASE_URL } from '../../constants';
import axios from 'axios';

const MainContainer = styled(Content)`
  @media screen and (min-width: 1440px) {
    padding: 60px 90px 60px 170px;
  }
  @media (max-width: 1400px) and (min-width: 1350px) {
    padding: 70px 0 70px 90px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 940px !important;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 941px !important;
  justify-content: space-between;
  margin-top: -22px;
  padding: 0;
  p {
    margin-top: -14px;
  }
`;

const PageHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  > div > div {
    > h1 {
      margin-left: -1px;
    }
    > :nth-child(2) {
      bottom: 2px;
      left: -45px;
    }
  }
`;

const AreaContainer = styled.div`
  min-width: 200px;
  max-width: 750px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
`;

const HeadTitle = styled.span`
  & div > div > div > div {
    margin-top: -8px;
  }
`;

const ToggleDetection = styled.div<{ state?: string }>`
  display: flex;
  align-items: center;
  margin-top: -4px;
  gap: 0 12px;
  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
    `}
  margin-bottom: 15px;
`;

const SmallLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
`;

const ButtonContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 0;
  > button {
    width: 133px;
  }
  > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  & > :nth-child(2) {
    width: 135px;
    margin-right: -2px;
    padding: 0 18px;
  }
`;

const UnsavedChangesContainer = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.ui};
`;

const Divider = styled.div`
  height: 1px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #efefef;
`;

const ImportExportContainer = styled.div<{ gap: string; lang?: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 11px;
  margin-top: -15px;
  margin-bottom: 15px;

  > button {
    padding: 0 8px;
    > div > :first-child {
      padding-left: 12px;
    }
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const LineSettingsContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  margin: 4px 0;
`;

const LineSelectFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  position: relative;
  & > div:first-child {
    display: flex;
    position: absolute;
    top: 6px;
    left: -24px;
  }
`;

const StyledSelectField = styled(SelectField)`
  width: 229px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  } // to align drop down icon
`;

const InputAndButtonContainer = styled.div<{ gap: string; lang?: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  & > button {
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const LineUIContainer = styled.div`
  width: 100%;
  min-height: 390px;
  margin: 30px 0 15px;
  display: flex;
  border-radius: 3px;
  border: solid 1px #eee;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.02);
  background-color: rgba(252, 252, 253, 0.85);
  position: relative;

  &::before {
    content: "";
    width: 14.5px;
    height: 51px;
    position: absolute;
    top: -28px;
    left: -16px;
    border-left: 2px solid #d9dad9;
    border-bottom: 2px solid #d9dad9;
  }
`;

const LineUIRightPanel = styled.div`
  flex: 1;
  flex-shrink: 0;
  border-radius: 3px;
`;

const LineUILeftPanel = styled.div`
  padding: 15px 0;
  width: 239px;
  flex-shrink: 0;
  position: relative;
`;

const SidePaddingDiv = styled.div`
  position: relative;
  padding: 0 19px;
  & > div > label > span {
    margin-bottom: 4px !important;
  }
`;

interface ISmallInput {
  length: string;
}

const StyledSmallInputWithLabel = styled(SmallInput)<ISmallInput>`
  & > label {
    width: ${({ length }) => length};
    margin-bottom: 0;
  }
`;

const DividerWithMargin = styled(Divider)`
  margin: 14px 0 12px;
`;

const InputDiv = styled.div`
  padding: 8px 20px 0 19px;
  height: 78px;
  & > div > label > div > input {
    width: 135px !important;
  }
  & > div > label > span {
    margin-bottom: 4px !important;
  }
`;

const LowSpeedThreshold = styled.div`
  padding: 0 20px 0 19px;
  & > div > label > div {
    width: 135px !important;
  }
  & > div > label > span {
    margin-bottom: 4px !important;
  }
`;

const CancelModalContainer = styled.div`
  padding: 18px 48px 22px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const LineUIActionsDisplayDropdown = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 396.5px;
  height: max-content;
  & > div:nth-child(1) {
    margin-top: -4px;
  }
  & > div:nth-child(2) {
    margin-top: -15px;
  }
  position: absolute;
  z-index: 10;
  bottom: -34px;
`;

const IconWrapper = styled.div`
  padding-top: 4px;
`;

const SelectZoneShow = styled(SelectField)`
  width: 138px;
  height: 30px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 25px 0 8px !important;
  margin-top: 9px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-style: normal !important;
  color: #8b9196 !important;
  letter-spacing: -0.12px;
  & + div {
    right: 14.5px !important;
  }
`;

const CancelChangesButton = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

type key = 'lineReference' | 'threshold' | 'distance';

interface Iline {
    x: number,
    y: number
  }
  
interface ILineSets {
  index: number,
  line_set_name: string,
  lines: {
    line1: Iline[]
    line2: Iline[]
    line1_styling?: string
    line2_styling?: string
  },
}

const options: LineUIOptions = {
  showSetIndex: false,
  showPoint: true,
  showDirectionMark: false,
  showMoveHandle: false,
  showPointHandle: true,
  setIndexOffset: 0,
  boundaryOffset: 0
};

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

interface ILinePoints {
  points: IVector2[],
  changeLineStyle: boolean
}

type LowSpeedRes = ILowSpeedDetectionDataMapped;
type LowSpeedKeys = keyof LowSpeedRes;
type LineKeys = keyof ILowSpeedDetailsMapped;
type PointsKeys = keyof ILinesMapped;
type CheckTypeValue = number | string | boolean | ILowSpeedDetailsMapped[] | ILinesMapped | undefined;

const checkType = (key: string, value: CheckTypeValue): boolean => {
  switch (key) {
  case 'low_speed':
    return Array.isArray(value);
  case 'name':
    return typeof value === 'string';
  case 'km/h':
  case 'meters':
    return typeof value === 'number';
  case 'reference_lines':
    return true;
  case 'x0':
  case 'y0':
  case 'x1':
  case 'y1':
    return typeof value === 'number';
  default:
    return false;
  }
};

const LowSpeedDetection = () => {
  const { t } = useTranslation(['CommonDict']);
  const { streamName }: { streamName: string } = useParams();
  const [cameraData, setCameraData] = useState<ICameraDetails>();
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const { push } = useHistory();
  const [setOfLines , setSetOfLines] = useState<IPointSet[]>([]);
  const { createModal, setModalOpen, isModalOpen } = useModal();
  const [selectedSet, setSelectedSet] = useState<string>(NEW_SET);
  const [selectedSetsIndex, setSelectedSetsIndex] = useState<number>(0);
  const [lowSpeedData, setLowSpeedData] = useState<ILowSpeedDetectionData>({enabled: false, low_speed_settings: []});
  const [exclusionAreas, setExclusionAreas] = useState<IExclusionAreas>({enabled: false, areas: []});
  const [prevValuesForCompare, setPrevValuesForCompare] = useState<ILowSpeedDetectionData>({enabled: false, low_speed_settings: []});
  const [prevLowSpeedData, setPrevLowSpeedData] = useState<ILowSpeedDetectionData>({enabled: false, low_speed_settings: []});
  const [lowSpeedSetsSettings, setLowSpeedSetsSettings] = useState<ILowSpeedDetails[]>([]);
  const [lowSpeedSelectOptions, setLowSpeedSelectOptions] = useState<string[]>([NEW_SET]);
  const [unSavedChanges, setUnsavedChanges] = useState<number>(0);
  const [lineSets, setLineSets] = useState<ILineSets[]>([]);
  const [lowSpeedSaveLoading, setLowSpeedSaveLoading] = useState(false);
  const [selectZoneOption, setSelectZoneOption] = useState('Show All');
  const [showAllZone, setShowAllZone] = useState(false);
  const [previousData, setPreviousData] = useState(true);
  const tRef = useRef(t);
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [toggleAnalysis, setToggleAnalysis] = useState(false);
  const stateRef = useRef<IPointSet[]>([]);
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const { isMediaUrlValid } = useMediaModal();
  const [image, setImage] = useState('');
  const [isImporting, setIsImporting] = useState(true);
  const [callData, setCallData] = useState(true);

  const setChangesCounter = (prevDetails: ILowSpeedDetectionData, currentDetails: ILowSpeedDetectionData) => {
    let counter = 0;
    
    // If any Set has length 0
    if (currentDetails?.low_speed_settings.length === 0 || prevDetails?.low_speed_settings.length === 0) {
      counter = counter + currentDetails?.low_speed_settings.length + prevDetails?.low_speed_settings.length;
      return counter;
    }

    // count set difference
    currentDetails?.low_speed_settings.forEach((lane1) => {
      let found = 1;
      for (const lane2 of prevDetails?.low_speed_settings) {
        if (isEqual(lane1, lane2)) {
          found = 0;
          break;
        }
      }
      counter += found;
    });
    
    if (currentDetails?.low_speed_settings.length < prevDetails?.low_speed_settings.length) {
      counter += Math.abs(currentDetails?.low_speed_settings.length - prevDetails?.low_speed_settings.length);
    }

    return counter;
  };

  useEffect(()=>{
    const currentExclusionAreaSettings = lowSpeedData?.low_speed_settings.map(obj => {
      const newObj = { ...obj };
      return newObj;
    });
    const prevExclusionAreaSettings = prevValuesForCompare?.low_speed_settings.map(obj => {
      const newObj = { ...obj };
      return newObj;
    });
    const prevSettings = {
      ...prevValuesForCompare,
      low_speed_settings: prevExclusionAreaSettings
    };

    const currentDetails:ILowSpeedDetectionData = {enabled: lowSpeedData?.enabled, low_speed_settings: currentExclusionAreaSettings};
    const changes = setChangesCounter(prevSettings, currentDetails);
    setUnsavedChanges(changes);
  },[prevValuesForCompare, lowSpeedData]);

  const handleLowSpeed = useCallback(async()=>{
    try {
      const res = await getLowSpeed(streamName);
      if (res.data.status_code === 0 && res.data.data ) {
        setCallData(false);
        if (res.data.exclusion_areas) {
          const {enabled: enabledExclusion, exclusion_zone_settings = []} = res.data.exclusion_areas;
          setExclusionAreas({
            enabled: enabledExclusion,
            areas: exclusion_zone_settings.map(area => ({points: area.points}))
          });
        }
        setLowSpeedData(res.data.data);
        if(previousData){
          setPrevValuesForCompare(res.data.data);
        }
        setPrevLowSpeedData(cloneDeep(res.data.data));
        setLowSpeedSetsSettings(res.data.data?.low_speed_settings);
        setSelectedSet(NEW_SET);
        
        const areaSelect = res.data.data?.low_speed_settings.map(item => item?.line_set_name);
        areaSelect.unshift(NEW_SET);
        setSelectedSetsIndex(0);
        setLowSpeedSelectOptions(areaSelect);
        const lines: ILineSets[] = [];
        res.data.data.low_speed_settings.map((i:ILowSpeedDetails) => {
          const newLinesSets = {
            index: i.index,
            lines: i.lines,
            line_set_name: i?.line_set_name,
          };
          lines.push(newLinesSets);
          return i;
        });
        setLineSets(lines);
        setPreviousData(false);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    } catch (error) {
      console.error(error);
    }
  },[streamName, previousData]);

  useEffect(()=>{
    const state: IPointSet[] = [];
    lineSets.map((line: ILineSets) => {
      const val: string | undefined = line.line_set_name?.trim().match(/\d/g)?.join('');
      const valNum=val?parseInt(val):0;

      const lines1: IPointSet = {
        name: line?.line_set_name,
        points: line.lines.line1,
        styling: line.lines.line1_styling === 'danger' ? 'danger' : (valNum === 1 ? 'primary' : 'secondary'),
        showPointHandle: (line.index === selectedSetsIndex)
      };
      const lines2: IPointSet = {
        name: line?.line_set_name,
        points: line.lines.line2,
        styling: line.lines.line2_styling === 'danger' ? 'danger' : (valNum === 1 ? 'primary' : 'secondary'),
        showPointHandle: (line.index === selectedSetsIndex)
      };
      state.push(lines1);
      state.push(lines2);
      return line;
    });
    if(lowSpeedData?.low_speed_settings.length === 0){
      setSetOfLines([]);
    }else{
      setSetOfLines(state);
      stateRef.current = state;
    }
  },[lineSets, lowSpeedData, selectedSet, selectedSetsIndex]);

  const fetchCamera = useCallback(() => {
    getCamera(streamName).then((res) => {
      if (res.status === 200 && res.data.data) {
        setCameraData(res.data.data);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    }).catch(() => {
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  }, [streamName]);

  useEffect(()=>{
    fetchCamera();
  },[fetchCamera]);

  useEffect(() => {
    if(callData){
      handleLowSpeed();
    }
  },[handleLowSpeed, callData]);

  const onLineSelect = useCallback((e: string) => {
    setSelectedSet(e);
    if (e === NEW_SET || e === '［新しいセット］') {
      setSelectedSetsIndex(0);
    } else {
      lowSpeedSetsSettings.forEach((item)=>{
        if(item?.line_set_name === e){
          setSelectedSetsIndex(item.index);
        }
      });
    }
  },[lowSpeedSetsSettings]);

  const onChangeLowSpeedData = useCallback((formData) => {
    setLowSpeedData({ ...formData });
  }, []);

  const onLineChange = useCallback((linesData: IPointSet[])=>{
    if (selectedSetsIndex === 0) return;
    const newLowSpeedData = cloneDeep(lowSpeedData);
    const selectedLines = linesData.filter(line => line.showPointHandle);

    const line1: ILines[] = [];
    const line2: ILines[] = [];
    selectedLines.forEach((line, index) => {
      if (index % 2 === 0) {
        line1.push(...line.points.map(point => ({ x: point.x, y: point.y })));
      } else {
        line2.push(...line.points.map(point => ({ x: point.x, y: point.y })));
      }
    });
    newLowSpeedData.low_speed_settings[selectedSetsIndex - 1] = {
      ...newLowSpeedData.low_speed_settings[selectedSetsIndex - 1],
      lines: {
        line1,
        line2,
        line1_styling: selectedLines[0].styling,
        line2_styling: selectedLines[1].styling
      },
    };
    
    onChangeLowSpeedData({ ...newLowSpeedData });
  },[selectedSetsIndex, lowSpeedData, onChangeLowSpeedData]);

  const onLineClickCallback = useCallback((lineId: number) => {
    const lineName = stateRef.current[lineId].name === undefined ? '1' : stateRef.current[lineId].name;
    const setIndex = lowSpeedData?.low_speed_settings.findIndex((lane) => (lane.line_set_name === lineName));
    setSelectedSetsIndex(setIndex+1);
    setSelectedSet(lowSpeedData?.low_speed_settings[setIndex]?.line_set_name);
    onLineSelect(lowSpeedData?.low_speed_settings[setIndex]?.line_set_name);
  },[lowSpeedData, onLineSelect]);

  const onChangeSets = useCallback((key: key) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const newLowSpeedDataData = cloneDeep(lowSpeedData);
    if(key === 'lineReference'){
      const newAreaSelectOptions = [...lowSpeedSelectOptions];
      newAreaSelectOptions[selectedSetsIndex] = value;
      setLowSpeedSelectOptions(newAreaSelectOptions);
      setSelectedSet(value);
      if(value !== undefined){
        newLowSpeedDataData!.low_speed_settings[selectedSetsIndex - 1].line_set_name = value;
      }
    } else if(key === 'distance'){
      if(parseInt(value) === 1000){
        value = '999';
      }
      const t = value;
      value = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3)) : t;
      if(value.length > 6){
        return;
      }else if(value === '' || /^\d{0,3}(\.\d{0,2})?$/.test(value)){
        newLowSpeedDataData!.low_speed_settings[selectedSetsIndex - 1].distance = parseFloat(value.replace(/[^0-9.]/gi, ''));
      }
    } else if(key === 'threshold'){
      if(parseInt(value) === 100){
        value = '99';
      }
      const t = value;
      value = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3)) : t;
      if(value.length > 5){
        return;
      }else if(value === '' || /^\d{0,2}(\.\d{0,2})?$/.test(value)){
        newLowSpeedDataData!.low_speed_settings[selectedSetsIndex - 1].low_speed_threshold = parseFloat(value);
      }
    }
    onChangeLowSpeedData(newLowSpeedDataData); 
  }, [lowSpeedData, lowSpeedSelectOptions, onChangeLowSpeedData, selectedSetsIndex]);

  const onRemoveSets = useCallback(() => {
    const removeData = setOfLines.filter((item)=> {return item.name !== selectedSet;});
    setSetOfLines(removeData);
    const updatedData = {
      ...lowSpeedData,
      low_speed_settings: lowSpeedData?.low_speed_settings.filter(
        (item) => item.index !== selectedSetsIndex
      ).map((item, index) => ({ ...item, index: index + 1 }))
    };
    setLowSpeedSetsSettings(updatedData.low_speed_settings!);
    const remZoneSelectOptions = updatedData?.low_speed_settings!.map(item => item?.line_set_name);
    remZoneSelectOptions.unshift(NEW_SET);
    setLowSpeedSelectOptions(remZoneSelectOptions);
    setSelectedSetsIndex(remZoneSelectOptions.length - 1);
    if (remZoneSelectOptions[remZoneSelectOptions.length - 1] === NEW_SET) {
      setSelectedSet(NEW_SET);
    } else {
      setSelectedSet(`${remZoneSelectOptions.length - 1}: ${remZoneSelectOptions[remZoneSelectOptions.length - 1]}`);
    }
    onChangeLowSpeedData(updatedData); 
    sendNotificationRef.current({ type: 'neutral', message: tRef.current('Set removed'), icon: 'FeatureLineUi'});
  },[setOfLines, lowSpeedData, onChangeLowSpeedData, selectedSet, selectedSetsIndex]);

  const onAddSets = useCallback(() => {
    const addedNumberList: any[] =[];
    lowSpeedSetsSettings.map(d=>{
      const val: string | undefined = d.line_set_name?.trim().match(/\d/g)?.join('');
      if(val){
        addedNumberList.push(parseInt(val));
      }
    });
    const filteredLineNumbers= [1,2].filter(num=>addedNumberList.indexOf(num)===-1).sort((a, b) => a - b);
   

    const defaultData: ILowSpeedDetails = {
      index: lowSpeedSetsSettings.length + 1,
      styling: lowSpeedSetsSettings.length === 0 ? 'primary' : 'secondary',
      line_set_name: lowSpeedSetsSettings.length === 0 ? i18n.language === 'ja' ? ('基準線 ' + filteredLineNumbers[0]) : ('Set ' + filteredLineNumbers[0]) : i18n.language === 'ja' ? (lowSpeedSetsSettings[0]?.line_set_name === '基準線 ' + filteredLineNumbers[0] ?'基準線 1' : '基準線 ' + filteredLineNumbers[0]) : (lowSpeedSetsSettings[0]?.line_set_name === 'Set ' + filteredLineNumbers[0] ?'Set 1' : 'Set ' + filteredLineNumbers[0]) ,
      lines: {
        line1: [
          {
            x: filteredLineNumbers[0]-1 === 1 ? 1097 : 736,
            y: filteredLineNumbers[0]-1 === 1 ? 230 : 97
          },
          {
            x: filteredLineNumbers[0]-1 === 1 ? 1094 : 758,
            y: filteredLineNumbers[0]-1 === 1 ? 527 : 613
          }
        ],
        line2: [
          {
            x: filteredLineNumbers[0]-1 === 1 ? 428 : 215,
            y: filteredLineNumbers[0]-1 === 1 ? 228 : 109
          },
          {
            x: filteredLineNumbers[0]-1 === 1 ? 420 : 218,
            y: filteredLineNumbers[0]-1 === 1 ? 532 : 607
          }
        ]
      },
      low_speed_threshold: 20,
      direction: 'East',
      distance: 20
    };
    setSelectedSet(defaultData?.line_set_name);
    onLineSelect( defaultData?.line_set_name);
    setSelectedSetsIndex(defaultData.index);
    const lines: ILineSets[] = [];
    const low:ILowSpeedDetails[] = [...lowSpeedData?.low_speed_settings];
    
    low.push(defaultData);
    setLowSpeedSetsSettings(low);
    low.map((i:ILowSpeedDetails) => {
      const newLinesSets = {
        index: i.index,
        lines: i.lines,
        line_set_name: i?.line_set_name,
      };
      lines.push(newLinesSets);
      const areaSelect = low.map(item => item?.line_set_name);
      areaSelect.unshift(NEW_SET);
      setLowSpeedSelectOptions(areaSelect);
      return i;
    });
    
    setLineSets(lines);
    setLowSpeedData({enabled: lowSpeedData?.enabled, low_speed_settings: low});
    sendNotificationRef.current({type: 'neutral', message: tRef.current('New set added'), icon: 'FeatureLineUi'});
  },[lowSpeedSetsSettings, onLineSelect, lowSpeedData]);

  const onMediaLoadedCallback = useCallback(() => {
    setIsMediaLoaded(true);
  }, []);

  const handleCancel = useCallback(async (setModalOpen: (newStatus: boolean) => void) => {
    setSelectedSetsIndex(0);
    await setModalOpen(false);
    if(prevLowSpeedData) {
      setLowSpeedData(prev => ({...prevLowSpeedData, enabled: prev.enabled}));
      setPrevValuesForCompare(prev => ({...prevLowSpeedData, enabled: prev.enabled}));
      setLowSpeedSetsSettings(prevLowSpeedData.low_speed_settings);
      const zoneSelect = prevLowSpeedData.low_speed_settings.map(area => area.line_set_name);
      zoneSelect.unshift(NEW_SET);
      setLowSpeedSelectOptions(zoneSelect);
      setSelectedSetsIndex(0);
      setSelectedSet(NEW_SET);
    }
  }, [prevLowSpeedData]);

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('CancelButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('CancelButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'CancelButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('CancelButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('CancelButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const handleCancleNo = useCallback(() =>{
    setModalOpen(false);
  },[setModalOpen]);

  const getCancelModal = useCallback((setModalOpen: (newStatus: boolean) => void) => {
    return (
      <CancelModalContainer>
        <CancelConfirmText>{tRef.current('Are you sure you want to cancel the changes?')}</CancelConfirmText>
        <ModalButtonContainer>
          <CancelChangesButton id='CancelButton' design='secondary' onClick={() => handleCancleNo()}>{tRef.current('No')}</CancelChangesButton>
          <CancelChangesButton id='YesButton' onClick={() => handleCancel(setModalOpen)}>{tRef.current('Yes')}</CancelChangesButton>
        </ModalButtonContainer>
      </CancelModalContainer>
    );
  }, [handleCancel, handleCancleNo]);

  const goToPreviousPage = useCallback(() => {
    if(isEqual(lowSpeedData.low_speed_settings, prevValuesForCompare.low_speed_settings)){
      push('/cameras/camera-configuration/' + streamName);
      return;
    }
    const CancelModal: ReactElement = getCancelModal(setModalOpen);
    createModal({ isCloseEnable: false, width: '400px', padding: false, customComponent: CancelModal });
  }, [lowSpeedData, prevValuesForCompare, streamName, getCancelModal, setModalOpen, createModal, push]);

  const handleAnalysis = useCallback((value)=>{
    setToggleAnalysis(true);
    const newLowSpeedData = cloneDeep(lowSpeedData);
    newLowSpeedData!.enabled = value;
    onChangeLowSpeedData(newLowSpeedData);

    const payload: IToggleAnalysisPayload ={
      action: value ? 'enable' : 'disable',
      analysis_type: ['low_speed_detection']
    };
    toggleConfigurationAnalysis(streamName, payload).then((res: { data: { status_code: number; }; }) => {
      if (res.data.status_code === 0) {
        sendNotificationRef.current({ type: 'success', message: value ? tRef.current('Analysis enabled successfully') : tRef.current('Analysis disabled successfully')});
        setToggleAnalysis(false);
      } else { 
        newLowSpeedData!.enabled = !value;
        onChangeLowSpeedData(newLowSpeedData);
        sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        setToggleAnalysis(false); }
    }).catch(() => {
      newLowSpeedData!.enabled = !value;
      onChangeLowSpeedData(newLowSpeedData);
      setToggleAnalysis(false);
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  },[lowSpeedData, streamName, onChangeLowSpeedData]);

  const validateExclusionZoneData = useCallback((lowSpeedData) => {
    let returnValue = true;
    for (const item of lowSpeedData.low_speed_settings) {
      if (item?.line_set_name === '') {
        sendNotificationRef.current({type: 'error', message: tRef.current('Please enter set reference for set #{X}').replace('{X}', (item.index))});
        returnValue = false;
        break;
      }
      if (isNaN(item.low_speed_threshold)) {
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Low speed threshold should be > 0 for {X}').replace('{X}', (item.line_set_name))}` });
        returnValue = false;
        break;
      }

      if (item.low_speed_threshold > 99.99) {
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Low speed threshold should be > 0 for {X}').replace('{X}', (item.line_set_name))}` });
        returnValue = false;
        break;
      }

      if (item.low_speed_threshold <= 0) {
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Low speed threshold should be > 0 for {X}').replace('{X}', (item.line_set_name))}` });
        returnValue = false;
        break;
      }

      if (isNaN(item.distance)) {
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Line distance should be > 0 for {X}').replace('{X}', (item.line_set_name))}` });
        returnValue = false;
        break;
      }
      if(item.distance > 999.99 ){
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Line distance should be > 0 and <= 99.99 km/h for {X}').replace('{X}', (item.line_set_name))}` });
        returnValue = false;
        break;
      } 
      if(item.distance <= 0){
        sendNotificationRef.current({ type: 'error', message: `${tRef.current('Line distance should be > 0 and <= 99.99 km/h for {X}').replace('{X}', (item.line_set_name))}` });
        returnValue = false;
        break;
      } 
      if(lowSpeedData.low_speed_settings.length === 2){
        if(lowSpeedData.low_speed_settings[0]?.line_set_name === lowSpeedData.low_speed_settings[1]?.line_set_name){
          sendNotificationRef.current({type: 'error', message: tRef.current('{X} already exists').replace('{X}', (item?.line_set_name))});
          returnValue = false;
        }
      }
    }
    return returnValue;
  }, []);

  const onClickSave = useCallback(async(data = lowSpeedData) => {
    const isValidData = validateExclusionZoneData(data);

    const updatedLowSpeedSettings = data.low_speed_settings.map((item: { [x: string]: any; line1_styling: any; line2_styling: any; }) => {
      const { lines, ...rest } = item;
      const { line1_styling, line2_styling, ...linesRest } = lines;
      return {
        ...rest,
        lines: linesRest,
      };
    });

    const updatedPayload: ILowSpeedDetectionData = {
      low_speed_settings: updatedLowSpeedSettings,
      enabled: data.enabled
    };

    if(isValidData){
      onChangeLowSpeedData({ ...lowSpeedData });
      setLowSpeedSaveLoading(true);
      try {
        const res = await setLowSpeed(streamName, updatedPayload);
        if(res.data.status_code === 0){
          setCallData(true);
          setPrevLowSpeedData(cloneDeep(lowSpeedData));
          sendNotificationRef.current({ type: 'success', message: tRef.current('Settings saved successfully')});
          setPreviousData(true);
          fetchCamera();
          handleLowSpeed();
        } else { sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') }); }
        setLowSpeedSaveLoading(false);
      }catch (error) {
        console.error(error);
        setLowSpeedSaveLoading(false);
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    }
    
  }, [handleLowSpeed, lowSpeedData, validateExclusionZoneData, onChangeLowSpeedData, streamName, fetchCamera]);

  const handleZoneShow = useCallback((e) => {
    setShowAllZone(!showAllZone);
    setSelectZoneOption(e);
  },[showAllZone]);

  useEffect(()=>{
    const lines: ILineSets[] = [];
    if(selectZoneOption === 'Only Active Set'){
      lowSpeedData?.low_speed_settings.forEach((item)=>{
        if(item?.line_set_name === selectedSet){
          const newLinesSets = {
            index: item.index,
            lines: item.lines,
            line_set_name: item?.line_set_name,
          };
          lines.push(newLinesSets);
        }
        setLineSets(lines);
      });
    } else{
      lowSpeedData?.low_speed_settings.forEach((item)=>{
        const newLinesSets = {
          index: item.index,
          lines: item.lines,
          line_set_name: item?.line_set_name,
        };
        lines.push(newLinesSets);
        setLineSets(lines);
      });
    }
  },[lowSpeedData?.low_speed_settings, selectZoneOption, selectedSet]);

  const diableCallBack = useCallback((isEnabled: boolean) => {
    setModalOpen(false);
    onClickSave({...lowSpeedData, enabled: isEnabled});
  }, [lowSpeedData, setModalOpen, onClickSave]);

  const openConfirmationModal = useCallback(() => {
    if (lowSpeedData.enabled) {
      onClickSave(lowSpeedData);
    } else {
      const isValidData = validateExclusionZoneData(lowSpeedData);
      if(isValidData){
        const ConfirmationModal: ReactElement = <DisableConfirmationModal onCallback={diableCallBack} />;
        createModal({ isCloseEnable: false, width: 'auto', padding: false, customComponent: ConfirmationModal });
      }
    }
  }, [lowSpeedData, validateExclusionZoneData, createModal, onClickSave, diableCallBack]);

  const onExportSettings = useCallback(() => {
    const { low_speed_settings } = prevLowSpeedData;
    const exportLines = low_speed_settings.map(({ index, styling, line_set_name, low_speed_threshold, distance, direction, lines, ...rest }) => {
      const linesMapped: ILinesMapped[] = [
        {
          x0: lines.line1[0].x,
          y0: lines.line1[0].y,
          x1: lines.line1[1].x,
          y1: lines.line1[1].y,
        },
        {
          x0: lines.line2[0].x,
          y0: lines.line2[0].y,
          x1: lines.line2[1].x,
          y1: lines.line2[1].y,
        },
      ];
      return { ...rest, name: line_set_name, reference_lines: linesMapped, 'km/h': low_speed_threshold, meters: distance };
    });
    try {
      let modifiedCameraName = cameraData?.camera_name || '';
      if (modifiedCameraName.length > 50) {
        modifiedCameraName = modifiedCameraName.substring(0, 50) + '...';
      }
      const fileName = `${modifiedCameraName}_low_speed.json`;
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({ low_speed: exportLines }, null, 4));
      const link = document.createElement('a');
      link.setAttribute('href', dataStr);
      link.setAttribute('download', fileName);
      link.click();
      sendNotification({ type: 'success', message: t('Settings exported successfully')});
    } catch (error) {
      sendNotification({type: 'error',message: t('An error occurred while exporting data')});
    }
  }, [cameraData, prevLowSpeedData, sendNotification, t]);

  const getLinePoints = useCallback((pointsArray: IVector2[]): ILinePoints => {
    const boundaryOffset = 0;
    let changeLineStyle = false;

    if (image === '') {
      return { points: pointsArray, changeLineStyle };
    }
  
    // Initialize an array to store the adjusted points
    const adjustedPoints = pointsArray.map(point => {
      let adjustedX = point.x;
      let adjustedY = point.y;
  
      // Check and adjust X coordinate
      if (point.x < boundaryOffset) {
        adjustedX = boundaryOffset;
        changeLineStyle = true;
      } else if (point.x > imageDetails.x - boundaryOffset) {
        adjustedX = imageDetails.x - boundaryOffset;
        changeLineStyle = true;
      }
  
      // Check and adjust Y coordinate
      if (point.y < boundaryOffset) {
        adjustedY = boundaryOffset;
        changeLineStyle = true;
      } else if (point.y > imageDetails.y - boundaryOffset) {
        adjustedY = imageDetails.y - boundaryOffset;
        changeLineStyle = true;
      }
  
      return { x: adjustedX, y: adjustedY };
    });
  
    return { points: adjustedPoints, changeLineStyle };

  }, [image, imageDetails]);

  const importSettings = useCallback((importedTrafficCounterData: ILowSpeedDetectionData) => {
    const { low_speed_settings = [] } = importedTrafficCounterData;
    if (low_speed_settings.length > 0) {
      const lineInfo: ILowSpeedDetails[] = [];
      setLowSpeedSelectOptions([NEW_SET]);
      low_speed_settings.map((item, index) => {
        const lineName = !item.line_set_name ? `Area ${index}` : item.line_set_name;

        setLowSpeedSelectOptions(prev => [...prev, lineName]);

        const line1Point = cloneDeep(item.lines.line1);
        const line2Point = cloneDeep(item.lines.line2);

        const { points: points1, changeLineStyle: changeLineStyle1 } = getLinePoints(line1Point);
        const { points: points2, changeLineStyle: changeLineStyle2 } = getLinePoints(line2Point);

        lineInfo.push(
          {
            index: item.index,
            line_set_name: item.line_set_name,
            styling: changeLineStyle1 || changeLineStyle2 ? 'danger' : item.styling,
            lines: {
              line1: points1,
              line2: points2,
              line1_styling: changeLineStyle1 ? 'danger' : item.styling,
              line2_styling: changeLineStyle2 ? 'danger' : item.styling
            },
            low_speed_threshold: item.low_speed_threshold,
            direction: item.direction,
            distance: item.distance
          }
        );
        return {
          name: index + 1 + ': ' + lineName,
          points: points1.concat(points2),
          readOnly: true,
          styling: changeLineStyle1 || changeLineStyle2 ? 'danger' : 'primary',
          showSmallDirectionMark: true
        };
      });
      setLowSpeedSetsSettings(lineInfo);
      setLowSpeedData({enabled: lowSpeedData.enabled, low_speed_settings: lineInfo});
    } else {
      setLowSpeedSetsSettings([]);
      setLowSpeedData({enabled: lowSpeedData.enabled, low_speed_settings: []});
    }
  }, [lowSpeedData, getLinePoints]);

  const validateImporting = useCallback((importedTrafficCounterData) => {
    importedTrafficCounterData.low_speed.forEach((item: { lines: any; reference_lines: any; name: any; line_set_name: string; low_speed_threshold: number; 'km/h': any; distance: number; meters: any; index: number; }, index: number) => {
      const { reference_lines, meters } = item;
      item.index = index + 1;
      item.line_set_name = item.name;
      item.lines = {
        line1: [
          {
            x: reference_lines[0].x0,
            y: reference_lines[0].y0,
          },
          {
            x: reference_lines[0].x1,
            y: reference_lines[0].y1,
          }
        ],
        line2: [
          {
            x: reference_lines[1].x0,
            y: reference_lines[1].y0,
          },
          {
            x: reference_lines[1].x1,
            y: reference_lines[1].y1,
          }
        ]
      };
      item.low_speed_threshold = item['km/h'];
      item.distance = meters;
      delete item.name;
      delete item['km/h'];
      delete item.meters;
      delete item.reference_lines;
    });
  
    const newImportedData: ILowSpeedDetectionData = {
      low_speed_settings: importedTrafficCounterData.low_speed,
      enabled: prevValuesForCompare.enabled
    };
    const updatedPrevValuesForCompare = prevValuesForCompare?.low_speed_settings.map(obj => {
      const newObj = { ...obj };
      delete newObj.styling;
      delete newObj.direction;
      return newObj;
    });
    if(isEqual(updatedPrevValuesForCompare, newImportedData.low_speed_settings)){
      handleLowSpeed();
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    } else {
      setSelectedSetsIndex(0);
      setSelectedSet(NEW_SET);
      setLowSpeedSetsSettings([]);
      setLowSpeedData({enabled: lowSpeedData.enabled, low_speed_settings: []});
      importSettings({...newImportedData});
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    }
  }, [t, sendNotification, importSettings, setModalOpen, lowSpeedData, prevValuesForCompare, handleLowSpeed]);

  const isValidImportFile = useCallback((jsonData) => {
    let isLinesValid = false;
    const validLowSpeedKeys: LowSpeedKeys[] = ['low_speed'];
    const validLineKeys: LineKeys[] = ['name', 'km/h', 'meters', 'reference_lines'];
    const validPointsKeys: PointsKeys[] = ['x0', 'y0', 'x1', 'y1'];

    try {
      let resKeys: LowSpeedKeys[] = [], lineKeys: LineKeys[] = [];
      const resObj: LowSpeedRes = JSON.parse(jsonData);

      resKeys = Object.keys(resObj) as Array<keyof typeof resObj>;

      if (
        resKeys.every(key => validLowSpeedKeys.includes(key)) &&
        validLowSpeedKeys.every(key => (resKeys.includes(key) && checkType(key, resObj[key])))
      ) {

        if (resObj.low_speed.length === 0) return true;
        if (resObj.low_speed.length > 2){
          sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Maximum 2 line sets can be imported')})));
          return false;
        }

        const isValidLineKeys = (lineKeys: LineKeys[]) => {
          if(lineKeys.every(key => validLineKeys.includes(key)) && validLineKeys.every(key => lineKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeys = (pointsKeys: PointsKeys[]) => {
          if(pointsKeys.every(key => validPointsKeys.includes(key)) && validPointsKeys.every(key => pointsKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeyTypes = (pointsKeys: PointsKeys[], point: ILinesMapped) => {
          if(pointsKeys.every((key) => checkType(key, point[key]))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid point keys')})));
            return false;
          }
        };

        const isValidLineKeyTypes = (lineKeys: LineKeys[], line: ILowSpeedDetailsMapped) => {
          return lineKeys.every((key) => {
            if (key === 'reference_lines') {
              if(line[key].length > 2){
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Maximum 2 lines are accepted in a set')})));
                return false;
              } else {
                return true;
              }
            } else if (key === 'name') {
              if(typeof line[key] === 'string' && line[key] !== ''){
                if(line[key].length > 24){
                  sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name should not longer than 24 characters')})));
                  return false;
                } else {
                  return true; 
                }
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name is required')})));
                return false;
              }
            } else if(key === 'km/h'){
              if(typeof line[key] === 'number' && !isNaN(line[key]) && line[key] <= 99.99 && line[key] >= 1){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Low speed threshold should be > 0 and < 100 km/h')})));
                return false;
              }
            } else if(key === 'meters'){
              if(typeof line[key] === 'number' && !isNaN(line[key]) && line[key] <= 999.99 && line[key] >= 1){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Line distance should be > 0 and < 1000 m')})));
                return false;
              }
            } else {
              if(checkType(key, line[key])){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid data types')})));
                return false;
              }
            }
          });
        };

        for (const line of resObj.low_speed) {
          lineKeys = Object.keys(line) as Array<keyof typeof line>;
          if (isValidLineKeys(lineKeys) && isValidLineKeyTypes(lineKeys, line)) {
            for (const lineItem of line.reference_lines) {
              const pointsKeys = Object.keys(lineItem) as Array<keyof typeof lineItem>;
              if (isValidPointsKeys(pointsKeys) && isValidPointsKeyTypes(pointsKeys, lineItem)) {
                isLinesValid = true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({ type: 'error', message: t('Invalid point keys or their type') })));
                isLinesValid = false;
                break;
              }
            }
          } else {
            isLinesValid = false;
            break;
          }
        }
        return isLinesValid;
      } else {
        sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
        return false;
      }
    } catch (error) {
      sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid file')})));
      return false;
    }
  }, [sendNotification, t]);

  const onImportCallback = useCallback((e) => {
    if (e !== null && isValidImportFile(e.result)) {
      validateImporting(JSON.parse(e.result as string));
    } 
    else {
      setModalOpen(false);
    }
  }, [isValidImportFile, validateImporting, setModalOpen]);

  // Import Modal
  const getImportModal = useCallback(() => {
    return (
      <ImportSettingsModal {...{ onImportCallback, sendNotification }} />
    );
  }, [sendNotification, onImportCallback]);

  const onImportSettings = useCallback(() => {
    setModalOpen(true);
    const importModal: ReactElement = getImportModal();
    createModal({
      isCloseEnable: false,
      width: 'max-length',
      padding: false,
      customComponent: importModal,
    });
  }, [createModal, setModalOpen, getImportModal]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
        const isImageValid = await isMediaUrlValid(imgBase64, 'img');
        if (isImageValid === true) {
          setImage(imgBase64);
          const img = new Image();
          img.src = imgBase64;
          setImageDetails({x: img.width, y: img.height});
        }
        setIsImporting(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [streamName, isMediaUrlValid]);

  useEffect(() => {
    if(isImporting){
      getCameraImage();
    }
  },[isImporting, getCameraImage]);

  return (
    <MainContainer>
      <Container>
        <Header>
          <PageHeaderDiv>
            <AreaContainer onClick={goToPreviousPage}>{cameraData?.camera_name}</AreaContainer>
            <HeadTitle>
              <PageHeader title={tRef.current('Low Speed')} icon='Analyse' updateDocTitle={false} />
            </HeadTitle>
            <ToggleDetection state={toggleAnalysis ? 'disabled' : 'default'}>
              <Switch checked={lowSpeedData?.enabled} onChangeCallback={handleAnalysis} state={toggleAnalysis ? 'disabled' : 'default'} />
              <SmallLabel>{lowSpeedData?.enabled ? tRef.current('Enabled') : tRef.current('Disabled')}</SmallLabel>
            </ToggleDetection>
          </PageHeaderDiv>
          <ButtonContainer>
            <Button size='small' design='secondary' onClick={goToPreviousPage}> {unSavedChanges === 0 ? t('Back') : t('Cancel')} </Button>
            <ButtonWithLoading size='small' loading={lowSpeedSaveLoading} disabled={isEqual(lowSpeedData.low_speed_settings, prevValuesForCompare.low_speed_settings) ?  true : false} onClick={openConfirmationModal}> {tRef.current('Save Changes')} </ButtonWithLoading>
            {unSavedChanges > 0 ? <UnsavedChangesContainer>{tRef.current('{X} unsaved changes').replace('{X}', (unSavedChanges+ ''))}</UnsavedChangesContainer> : <UnsavedChangesContainer>{tRef.current('No Changes')}</UnsavedChangesContainer>}
          </ButtonContainer>
        </Header>
        <Divider />
        <ImportExportContainer gap='0 14px'>
          {t('Settings')}:
          <Button size='xsmall' design='secondary' disabled={false} onClick={onImportSettings}>
            {t('Import')}
          </Button>
          <Button size='xsmall' design='secondary' disabled={false} onClick={onExportSettings}>
            {t('Export')}
          </Button>
        </ImportExportContainer>
        <Divider />
        <LineSettingsContainer>
          <LineSelectFieldContainer>
            <Icon icon='FeatureLineUi' size={16} color='dimmed' />
            <StyledSelectField disabled={false} isCompact changeCallback={(e) => onLineSelect(e)} value={selectedSet}>
              {
                lowSpeedSelectOptions.map((item, index) => {
                  return (
                    <option key={item + index} value={item === NEW_SET ? NEW_SET : `${item}`}>
                      {item === NEW_SET ? tRef.current(NEW_SET) : `${index}: ${item}`}
                    </option>
                  );
                })
              }
            </StyledSelectField>
            <InputAndButtonContainer lang={i18n.language} gap='0 10px'>
              <ButtonWithIcon icon='Add' position='left' size='small' design='secondary' disabled={!isMediaLoaded || lowSpeedData?.low_speed_settings.length === 2} onClick={onAddSets}>{tRef.current('Add Set')}</ButtonWithIcon>
              <ButtonWithIcon icon='ActionRemove' position='left' size='small' design='secondary' disabled={selectedSet === NEW_SET} onClick={onRemoveSets}>{tRef.current('Remove Set')}</ButtonWithIcon>
            </InputAndButtonContainer>
          </LineSelectFieldContainer>
          <LineUIContainer>
            <LineUILeftPanel>
              <SidePaddingDiv>
                <StyledSmallInputWithLabel
                  fieldState='default'
                  label={tRef.current('Line Set Reference')}
                  name='setReference'
                  id='setReference'
                  length='100%'
                  maxLength={24}
                  onChange={onChangeSets('lineReference')}
                  value={selectedSetsIndex === 0 ? '' : lowSpeedData?.low_speed_settings[selectedSetsIndex - 1]?.line_set_name || ''}
                  disabled={selectedSet === NEW_SET}
                />
              </SidePaddingDiv>
              <DividerWithMargin />
              <InputDiv>
                <SmallInput 
                  type='number' 
                  name='distance' 
                  id='distance' 
                  onChange={onChangeSets('distance')} 
                  label={tRef.current('Line Distance')} 
                  unit={tRef.current('m')} 
                  step={10}
                  min={10}
                  value={selectedSetsIndex === 0 ? '' : lowSpeedData?.low_speed_settings[selectedSetsIndex - 1]?.distance || ''}
                  disabled={selectedSet === NEW_SET}
                  onPaste={(evt) => evt.preventDefault()}
                  onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                />
              </InputDiv>
              <LowSpeedThreshold>
                <SmallInput 
                  type='number' 
                  name='threshold' 
                  id='threshold' 
                  onChange={onChangeSets('threshold')} 
                  label={tRef.current('Low Speed Threshold')} 
                  unit='km/h' 
                  step={10} 
                  min={10}
                  value={selectedSetsIndex === 0 ? '' : lowSpeedData?.low_speed_settings[selectedSetsIndex - 1]?.low_speed_threshold || ''}
                  disabled={selectedSet === NEW_SET}
                  onPaste={(evt) => evt.preventDefault()}
                  onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                />
              </LowSpeedThreshold>
            </LineUILeftPanel>
            <LineUIRightPanel>
              <LineViewer
                streamName={streamName}
                linesData={setOfLines}
                showExclusionArea={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                showExclusionAreaOnModal={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                exclusionAreas={(exclusionAreas.enabled && exclusionAreas.areas.length > 0) ? exclusionAreas.areas : []}
                options={options}
                latestSnapshotText={tRef.current('Latest Snapshot')}
                rtcText={tRef.current('Live Feed')}
                zoomText={tRef.current('Zoom')}
                selectedLine={selectedSet}
                onLineSelect={onLineSelect}
                onLineChangeCallback={onLineChange}
                onMediaLoadedCallback={onMediaLoadedCallback}
                onLineClickCallback={onLineClickCallback}
                fromExclusionPage
              />
            </LineUIRightPanel>
            {isMediaLoaded && lowSpeedData?.low_speed_settings.length !== 0 &&
              <LineUIActionsDisplayDropdown>
                <IconWrapper>
                  <Icon icon='PasswordShow' size={16} color='dimmed' />
                </IconWrapper>
                <SelectZoneShow changeCallback={(e) => handleZoneShow(e)} disabled={false} isCompact value={selectZoneOption}>
                  {['Only Active Set', 'Show All'].map((item, index) => {
                    return (
                      <option key={index} value={item}>{tRef.current(item)}</option>);
                  })}
                </SelectZoneShow>
              </LineUIActionsDisplayDropdown>}
            
          </LineUIContainer>
        </LineSettingsContainer>
      </Container>
    </MainContainer>
  );
};

export default LowSpeedDetection;