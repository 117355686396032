import React, { useCallback } from 'react';
import { Label, SmallInput, Switch } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import { IDetection } from './types';
import styled, {css} from 'styled-components';
import clonedeep from 'lodash.clonedeep';

const DetectionContainer = styled.div`
  margin-top: 13px;
  padding: 0 20px;
`;

const DetectionText = styled(Label)`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 16px;
  font-weight: 500;
`;

const EnableContainer = styled.div`
  display: flex;
  margin-top: -7px;
`;

const SwitchContainerDefault = styled.div<{state?: string}>`
  width: 74px;
  display: flex;
  flex-direction: column;
  /* CSS to disable pointer events when the switch is disabled */
  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
    `}
`;

const DetectionLabelText = styled(Label)`
  margin-bottom: 5px;
`;

const InputDiv = styled.div`
  width: 150px;
  margin-left: 10px;
`;

const DetectionThreshold: React.FC<IDetection> = ({ detection, unit, enabled, threshold, congestionStopData, onChangeCongestionStopData, selectedAreaIndex }) => {
  const { t } = useTranslation(['CommonDict']);

  const handleEnabled = useCallback((value) => {
    const newCongestionStopData = clonedeep(congestionStopData);
    newCongestionStopData.congestion_and_stop_settings[selectedAreaIndex-1][detection === 'Congestion Detection' ? 'congestion_enabled' : 'stop_enabled'] = value;
    onChangeCongestionStopData(newCongestionStopData);
  }, [congestionStopData, detection, selectedAreaIndex, onChangeCongestionStopData]);

  const handleThreshold = useCallback(({ target: { value } }) => {
    const newCongestionStopData = clonedeep(congestionStopData);
    if (value.length >= 5) {
      return;
    }
    const t = value.toString();
    value = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3)) : t; //If the string contains a decimal point, it ensures that there are at most three digits after the decimal point. This line limits the input to a maximum of two decimal places.
    if(value.length > 6){ //If the length of the value is greater than 6, the function returns early. This is another constraint on the length of the input
      return;
    }else if(value === '' || /^\d{0,4}(\.\d{0,2})?$/.test(value)){ //Checks if the value is an empty string or matches the regular expression /^\d{0,4}(.\d{0,2})?$/. This regular expression allows for up to four digits before the decimal point and up to two digits after the decimal point.
      newCongestionStopData.congestion_and_stop_settings[selectedAreaIndex - 1][detection === 'Congestion Detection' ? 'congestion_threshold' : 'stop_threshold'] = parseFloat(value.replace(/[^0-9.]/gi, ''));
    }
    onChangeCongestionStopData(newCongestionStopData);
  }, [congestionStopData, detection, selectedAreaIndex, onChangeCongestionStopData]);

  return (
    <DetectionContainer>
      <DetectionText htmlFor='' labelText={t(detection)} />
      <EnableContainer>
        <SwitchContainerDefault state={selectedAreaIndex === 0 ? 'disabled' : 'default'}>
          <DetectionLabelText htmlFor='' labelText={enabled ? t('Enable') : t('Disable')} />
          <Switch checked={enabled} onChangeCallback={handleEnabled} state={selectedAreaIndex === 0 ? 'disabled' : 'default'} />
        </SwitchContainerDefault>
        <InputDiv>
          <SmallInput
            type='number'
            label={t('Threshold')}
            unit={t(unit)}
            value={threshold || ''}
            onChange={handleThreshold}
            step={10}
            disabled={selectedAreaIndex === 0 ? true : false}
            onPaste={(evt) => evt.preventDefault()}
            onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          />
        </InputDiv>
      </EnableContainer>
    </DetectionContainer>
  );
};

export default DetectionThreshold;