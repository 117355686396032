import { IAlertSupp, ISchedules } from 'interface';
import React, { useCallback, useEffect, useState, useRef, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonWithIcon, DateInterval, Icon, FilterDropdownContainer, Switch, useNotification, Spinner, useClickOutside, Content, PageHeader, ButtonWithLoading, useModal, Checkbox } from 'scorer-ui-kit';
import styled from 'styled-components';
import TimePicker from 'components/TimePicker';
import { format } from 'date-fns';
import { TIME_FORMAT } from '../../../constants';
import { addAlertSuppRules, getAlertSupression, getCamera } from '../../../services/camerasService';
import i18n from 'i18n';
import {isEqual, omit} from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router';
import { ICameraData } from '../types';

const MainContainer = styled(Content)`
  @media screen and (min-width: 1440px) {
    padding: 60px 90px 60px 170px;
  }
  @media (max-width:1400px) and (min-width:1350px) {
    padding: 70px 0 70px 90px;
  }
`;

const UpperContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 942px !important;
  justify-content: space-between;
  margin-top: -21px;
  p {
    margin-top: -14px;
  }
`;

const PageHeaderDiv = styled.div`
   & > div > div > a {
    width: 162px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > span:nth-child(2){
    line-height: 1.3 !important;
  }
  & > span:nth-child(2) > div > div > h1{
    margin: 0 0 15px !important;
  }
  margin-bottom: 23px;
`;

const AreaContainer = styled.div`
  min-width: 200px;
  max-width: 750px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
`;

const HeadTitle = styled.span`
  & div > div > div > div {
    margin-top: -8px;
  }
`;

const DividerLine = styled.div`
  height: 1px;
  width: 100%;
  border-radius: 3px;
  background-color: #efefef;
  margin-left: 0px;
  margin-top: 20px;
`;

const Container = styled.div<{isEditAction:boolean}>`
  display: flex;
  flex-direction: column;
  width: ${({isEditAction}) => isEditAction? '560px':'494px'};
`;

const Heading = styled.span`
  margin-top: 52px;
  color: #5a6269;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  width: 209px;
  height: 19px;
`;

const IntroText = styled.span`
  width: 621px;
  height: 50px;
  margin-top: 11px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #8b9196;
`;

const EnableContainer = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 2px;
`;

const EditButton =styled(Button)<{lang?:string, editAction?:boolean}>`
  height: 20px;
  border-radius: 3px;
  font-size: 12px;
  width: ${({lang, editAction}) => lang==='ja' ? editAction ? '80px' : '43px' : editAction ? '60px' : '43px'};
  padding: 0 !important;
  ${({editAction})=>!editAction?'':`
  background: #e4edf4 !important;
  &:hover:enabled {
    background: #d4e4f0 !important;
  }`}
`;

const EmptyRuleContainer = styled.span`
 margin-left: 9px;
 margin-top: -2px;
 font-size: 14px;
`;

const DetailsContainer = styled.div`
  display: flex;
  height: 40px;
  border: 1px solid #eee;
  padding: 9px;
  border-radius: 5px;
  margin-bottom: 11px;
`;

const DetailsContainerOuter = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const IconContainer = styled.button<{isEditAction:boolean}>`
  border: none;
  background-color: ${({isEditAction}) => isEditAction? '#ffffff':'#f9fafb'};;
  height: 16px;
`;

const ArrowContainer = styled.div`
  margin-top: 1px;
`;

const ScheduleArrowContainer = styled.div``;

const AddRuleButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 560px;
  gap: 20px;
`;

const RuleRowContainer = styled.div<{isEditAction:boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: ${({isEditAction}) => isEditAction? '560px':'494px'};
  height:  40px;
  margin-bottom: 10px;
  ${({isEditAction}) => isEditAction? 'background-color: #fff':''};
  border: 1px solid #eee;
  border-radius: 5px;
  padding-left: 7px;
`;

const RuleContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 550px;
  height: 40px;
  background-color: #fff;
  padding-left: 7px;
  border-radius: 5px;
`;

const DayWrapper=styled.span`
  margin-left: 7px;
`;

const Days = styled.div`
  width: 210px;
  height: 15px;
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  margin-top: 5px;
  line-height: 1.07;
  color: #8b9196;
`;

const Divider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: #eee;
  width: 210px;
  margin: 3px 0 0 0px;
`;

const RuleDaysDivider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: #eee;
  width: 210px;
  margin: 1px 0 0 0px;
`;

const DividerFull = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: #eee;
  width: 560px;
  margin: 5px 0 14px 0;
`;

const TimeContainer = styled.div<{isEditAction?:boolean}>`
  width: 170px;
  display: flex;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  margin-left: ${({isEditAction})=> isEditAction ? '76px' : '66px'};
`;

const Time = styled.span`
  margin-right: 10px;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 1px;
`;

const EndTime = styled.span`
  margin-right: 10px;
  font-size: 14px;
  margin-left: 8px;
  margin-top: 1px;
`;

const TimeDivider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: #eee;
  width: 37px;
  margin: 0px 0 0 0;
`;

const TimeDividerEdit = styled(TimeDivider)`
  margin: 0 0 0 8px;
`;

const JSTWrap = styled.div`
  font-size: 12px;
  font-style: italic;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 2px;
`;

const ActionsContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 9px;
  margin-top: 5px;
  gap: 13px;
`;

const IconWrap = styled.span`
  cursor: pointer;
`;

const RuleContainer = styled.div<{validationError: boolean}>`
  display: flex;
  flex-direction: column;
  border: ${({validationError})=>validationError ? '1px solid #f34848 ': '1px solid #dcdcdc'};
  background-color: white;
  height: 130px;
  width: 560px;
  margin-bottom: 15px;
  border-radius: 5px;
`;

const DoneButton = styled(Button)`
  width: 51px;
  height: 20px;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
`;

const CancelButton = styled(Button)<{btnWidth: string}>`
  width: ${({btnWidth})=>btnWidth};
  height: 20px;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  background: #e4edf4 !important;
  &:hover:enabled {
    background: #d4e4f0 !important;
  }
`;

const SelectDaysWrapper = styled.span`
  font-size: 14px;
  margin-left: 9px;
`;

const CheckWrapper = styled.div`
  width: 61px;
  display: flex;
  height: 20px;
`;

const DayLabel = styled.div`
  margin-left: 12px;
`;

const DaysContainer = styled.div`
  display: flex;
  width: 555px;
  height: 36px;
  margin-top: 9px;
  background-color: #fff;
  padding-left: 10px;
  justify-content: space-between;
  font-size: 14px;
  > div:nth-child(5){
    position: relative;
    left: 5px;
  }
`;

const TimeView = styled.div`
  width: 54px;
  height: 20px;
  background-color: white;
  opacity: 100%;
  color: #8b9196;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.data};
`;

const CamEnableBlock = styled.div`
  width: 160px;
  display: flex;
`;

const TimeWrapper = styled.div`
  cursor: pointer;
`;

const RuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RollOverWrap = styled.div`
  font-size: 12px;
  font-style: italic;
  color: rgba(120, 138, 144, 0.72);
  width: 50px;
  margin-top: 3px;
`;

const SwitchLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
  margin-left: 12px;
  margin-top: 1px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 130px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const LoadingText = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
  margin: 0;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 90px;
  & > button:nth-child(2) {
    width: 132px;
    margin-bottom: 11px;
  }
  & > button:nth-child(1) {
    background: #e4edf4 !important;
    width: 132px;
    &:hover:enabled {
      background: #d4e4f0 !important;
    }
  }
  & > button:nth-child(1) {
    margin-top: -2px;
  }
`;

const CancelModalContainer = styled.div`
  padding: 18px 48px 22px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
`;

const NoButton = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

interface IProps {
  streamName: string;
}

interface IWeeks {
  id:number;
  day:string;
}

const weekDays:{[key: number]: string} ={1:'Sun',2:'Mon',3:'Tue',4:'Wed',5:'Thu',6:'Fri',7:'Sat'};
const weekDaysOne = [{id:1,day:'Sun'},{id:2,day:'Mon' },{ id:3, day:'Tue'},{id:4, day:'Wed'},{id:5,day:'Thu'},{id:6,day:'Fri'},{id:7,day:'Sat'}, ];
 
const AlertSuppression: React.FC<IProps> = () => {
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const {sendNotification} = useNotification();
  const notifictaionRef = useRef(sendNotification);
  const [ checkedDays, setCheckedDays ] = useState<number[]>([]);
  const currDateTime = new Date().setMinutes(0);
  const [ startTime, setStartTime ] = useState(new Date(currDateTime));
  const [ endTime, setEndTime ] = useState(new Date(currDateTime + 60*60*1000));
  const [ showTimePickerTypeStart, setShowTimePickerTypeStart ] = useState(false);
  const [ showTimePickerTypeEnd, setShowTimePickerTypeEnd ] = useState(false);
  const [ showAddRuleView, setShowAddRuleView ] = useState(false);
  const [ showEditRuleView, setShowEditRuleView ] = useState(false);
  const [ editAction, setEditAction ] = useState(false);
  const [ isValidationError, setValidationError ] = useState(false);
  const [editBoxID, setEditBoxID]=useState<number>();
  const [schedules, setSchedules]=useState<ISchedules[]>([]);
  const [enableRule, setEnableRule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addEditDoneRule , setAddEditDoneRule]=useState(false);
  const startTimeRef = useRef<HTMLDivElement>(null);
  const endTimeRef = useRef<HTMLDivElement>(null);
  useClickOutside(startTimeRef, () => setShowTimePickerTypeStart(false));
  useClickOutside(endTimeRef, () => setShowTimePickerTypeEnd(false));
  const [cameraData, setCameraData] = useState<ICameraData>();
  const { streamName }: { streamName: string } = useParams();
  const { push } = useHistory();
  const { search } = useLocation();
  const sendNotificationRef = useRef(sendNotification);
  const [scheduleData , setScheduleData] = useState<IAlertSupp>({
    enabled: false,
    schedules: []
  });
  const [prevScheduleData , setPrevScheduleData] = useState<IAlertSupp>({
    enabled: false,
    schedules: []
  });
  const [ruleSaveLoading, setRuleSaveLoading] = useState(false);
  const { createModal, setModalOpen, isModalOpen } = useModal();

  const fetchCamera = useCallback(() => {
    getCamera(streamName).then((res: { status: number; data: { data: ICameraData }; }) => {
      if (res.status === 200 && res.data.data) {
        setCameraData(res.data.data);
      } else {
        sendNotificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    }).catch(() => {
      sendNotificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    });
  }, [streamName, t]);

  useEffect(()=>{
    fetchCamera();
  },[fetchCamera]);

  const goToPreviousPage = useCallback(() =>{
    if (search && search.includes('fromPage=camera-details')) {
      push(`/cameras/camera-details/${streamName}/overview`);
    } else {
      push('/cameras/camera-configuration/' + streamName);
    }
  },[push, streamName, search]);

  const fetchAlertSuppression = useCallback(async()=>{
    try{
      const { data: { status_code, data } } = await getAlertSupression(streamName);
      if(status_code === 0 && data.schedules !== undefined){
        setScheduleData(data);
        setPrevScheduleData(data);
        setSchedules(data.schedules);
      }
      setEnableRule(data?.enabled ? true : false);
      setLoading(false);
    }catch(e){
      console.error(e);
      notifictaionRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
      setLoading(false);
    }
  },[streamName]);

  const addSchedules = useCallback(async(scheduleList: ISchedules[], ruleEnabled, action: string)=>{
    setSchedules(scheduleList);
    setScheduleData({enabled: ruleEnabled, schedules: scheduleList});
    if(scheduleList.length > 0 && action!=='enable-disable'){
      setEditAction(true);
    }else{
      setEditAction(false);
    }
    setCheckedDays([]);
    setValidationError(false);
    setEditBoxID(undefined);
  },[]);

  const onClickSave = useCallback(async(data)=>{
    setRuleSaveLoading(true);
    try {
      let isShowEnableDiableMessage = false;
      if (isEqual(omit(prevScheduleData, 'enabled'), omit(data, 'enabled'))) {
        isShowEnableDiableMessage = true;
      }
      const res = await addAlertSuppRules(streamName, data);
      if(res.data.status_code===0){
        fetchAlertSuppression();
        if (isShowEnableDiableMessage) {
          if (data.enabled) {
            // notifictaionRef.current({ type: 'success', message: t('Alert suppression schedule enabled successfully')});
            notifictaionRef.current({ type: 'success', message: t('Alert suppression setting saved successfully')});
          } else {
            notifictaionRef.current({ type: 'success', message: t('Alert suppression schedule disabled successfully')});
          }
        } else {
          notifictaionRef.current({ type: 'success', message: t('Alert suppression setting saved successfully')});
        }
      }else{
        setRuleSaveLoading(false);
      }
    } catch (error) {
      setRuleSaveLoading(false);
      console.error(error);
      notifictaionRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    }
    setEditAction(false);
    setRuleSaveLoading(false);
  },[fetchAlertSuppression, prevScheduleData, streamName, t]);

  const onChangeStartTime = useCallback((date: Date | DateInterval) => {
    setStartTime(date as Date);
  }, []);

  const onChangeEndTime = useCallback((date: Date | DateInterval) => {
    setEndTime(date as Date);
  }, []);
 
  const getSelectedDays = useCallback((data:{[key: number]: string}, key?: number[] ) => {
    const days: string[] = [];
    let formatted = '';
    if(key!== undefined) {
      Object.values(key).map((i :number)=>{
        return days.push(data[i] !== undefined ? t(`${data[i]}`) : '');
      });
      formatted = days.join(', ');
    } else {
      formatted = '';
    }
    return formatted;
  },[t]);

  const showTimePicker = useCallback((pickerFor) => {
    switch (pickerFor) {
    case 'start':
      setShowTimePickerTypeStart(prev => !prev);
      break;
    case 'end':
      setShowTimePickerTypeEnd(prev => !prev);
      break;
    default:
      setShowTimePickerTypeStart(false);
      setShowTimePickerTypeEnd(false);
    }
  }, []);

  const onClickAddRule = () => {
    setAddEditDoneRule(false);
    const currDateTime = new Date().setMinutes(0);
    setStartTime(new Date(currDateTime));
    setEndTime(new Date(currDateTime + 60*60*1000));
    setShowAddRuleView(true);
  };

  const cancleRule = useCallback(() =>{
    if(schedules.length === 0){
      setEditAction(true);
    }
    setShowAddRuleView(false);
    setShowEditRuleView(false);
    setCheckedDays([]);
    setValidationError(false);
    setEditBoxID(undefined);
    setEditAction(prev => (!prev));
  },[schedules]);

  const onClickEditButton = () => {
    setEditAction(prev => (!prev));
    setEditBoxID(undefined);
    setShowEditRuleView(false);
    setShowAddRuleView(false);
  };

  const handleDays = useCallback((e: boolean, index:number) => {
    setValidationError(false);
    if(e){
      const updatedList = [...checkedDays, index];
      setCheckedDays(updatedList.sort());
    } else {
      const updatedList = [...checkedDays];
      updatedList.splice(checkedDays.indexOf(index), 1);
      setCheckedDays(updatedList.sort());
    }
  },[checkedDays]);

  const doneRules = useCallback(() => {
    const rules={start_time: format(startTime, 'HH:mm'), end_time: format(endTime, 'HH:mm'), days: checkedDays};
    if(addEditDoneRule===false){
      for(let i=0; i<schedules.length; i++){
        if(isEqual(schedules[i], rules)){
          notifictaionRef.current({ type: 'error', message: t('Rule already exists')});
          return;
        }
      }
    }
    
    // const timeDiff = Math.floor((endTime.getTime() - startTime.getTime())/(1000*60));
    // if(timeDiff <= 0){
    //   // Day is rollover so calculate the time differnce of end time and start time
    //   const finalEndTime = 24 - parseInt(format(startTime, 'HH')) + parseInt(format(endTime, 'HH'));
    //   if(finalEndTime >= 24) {
    //     notifictaionRef.current({ type: 'error', message: t('Time difference should be less than 24 hours')});
    //     return;
    //   }
    // }

    if(checkedDays?.length!==0){
      if(editBoxID !== undefined){
        const tempSchedules = [...schedules];
        tempSchedules[editBoxID] = rules;
        addSchedules(tempSchedules, enableRule, 'edit');
      } else {
        const tempSchedules = [...schedules, rules];
        addSchedules(tempSchedules, true, 'add');
      }
      setValidationError(false);
      setShowAddRuleView(false);
      setShowEditRuleView(false);
    } else {
      setValidationError(true);
      notifictaionRef.current({ type: 'error', message: t('Please select a day to schedule alert suppression')});
    }
  },[startTime, endTime, checkedDays, editBoxID, schedules, t, addSchedules, enableRule, addEditDoneRule]);

  const editRules = (index:number)=>{
    setAddEditDoneRule(true);
    setShowAddRuleView(false);
    const items = schedules.filter((ele, i)=>{
      return i === index;
    });
    const editItem = items[0];
    if(editItem){
      const tempStartDate = new Date();
      const startTimeArr = editItem.start_time.split(':');
      tempStartDate.setHours(parseInt(startTimeArr[0]));
      tempStartDate.setMinutes(parseInt(startTimeArr[1]));
      setStartTime(tempStartDate);
      const tempEndDate = new Date();
      const endTimeArr = editItem.end_time.split(':');
      tempEndDate.setHours(parseInt(endTimeArr[0]));
      tempEndDate.setMinutes(parseInt(endTimeArr[1]));
      setEndTime(tempEndDate);
      setCheckedDays(editItem.days);
      setEditBoxID(index);
      setShowEditRuleView(true);
    }
  };

  const removeRules = useCallback((index:number) =>{
    const updatedSchedules = schedules?.filter((ele, i)=>{
      return i !== index;
    });
    addSchedules(updatedSchedules, updatedSchedules.length > 0 ? enableRule : false, 'remove');
    setShowAddRuleView(false);
    setShowEditRuleView(false);
  },[addSchedules, schedules, enableRule]);

  const getRollOverDate = useCallback((date_str) => {
    const tempDate = new Date();
    const timeArr = date_str.split(':');
    tempDate.setHours(parseInt(timeArr[0]));
    tempDate.setMinutes(parseInt(timeArr[1]));
    tempDate.setSeconds(0);
    return tempDate;
  },[]);

  const onClickEnableSuppressionRule = useCallback((checked: boolean) => {
    setEnableRule(checked);
    if(editAction){
      addSchedules(schedules, checked, 'add');
    }else{
      addSchedules(schedules, checked, 'enable-disable');
    }
    
  },[addSchedules, editAction, schedules]);

  useEffect(() => {
    setLoading(true);
    fetchAlertSuppression();
  }, [fetchAlertSuppression]);

  const handleCancel = useCallback(async (setModalOpen: (newStatus: boolean) => void) => {
    await setModalOpen(false);
    setScheduleData(prevScheduleData);
    if (prevScheduleData.schedules) {
      setSchedules(prevScheduleData.schedules);
    }
    if (prevScheduleData.enabled) {
      setEnableRule(prevScheduleData.enabled);
    }
  }, [prevScheduleData]);

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();
      if (e.key === 'ArrowLeft') {
        document.getElementById('CancelButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('CancelButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'CancelButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('CancelButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('NoButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const handleCancleNo = useCallback(() =>{
    setModalOpen(false);
  },[setModalOpen]);

  const getCancelModal = useCallback((setModalOpen: (newStatus: boolean) => void) => {
    return (
      <CancelModalContainer>
        <CancelConfirmText>{tRef.current('Are you sure you want to cancel the changes?')}</CancelConfirmText>
        <ModalButtonContainer>
          <NoButton design='secondary' id='NoButton' onClick={() => handleCancleNo()}>{tRef.current('No')}</NoButton>
          <Button onClick={() => handleCancel(setModalOpen)}>{tRef.current('Yes')}</Button>
        </ModalButtonContainer>
      </CancelModalContainer>
    );
  }, [handleCancel, handleCancleNo]);

  const onClickCancel = useCallback(() => {
    if(isEqual(scheduleData, prevScheduleData)) {
      if (search && search.includes('fromPage=camera-details')) {
        push(`/cameras/camera-details/${streamName}/overview`);
      } else {
        push('/cameras/camera-configuration/' + streamName);
      }
      return;
    }
    const CancelModal: ReactElement = getCancelModal(setModalOpen);
    createModal({ isCloseEnable: false, width: '400px', padding: false, customComponent: CancelModal });
  }, [scheduleData, prevScheduleData, streamName, search, getCancelModal, setModalOpen, createModal, push]);
  
  const getRuleContainer = useCallback(() => {
    return(
      <RuleContainer validationError={isValidationError}>
        <RuleContainerWrapper>
          <IconContainer isEditAction={editAction}> <Icon color='dimmed' icon='Time' size={15} weight='regular' /> </IconContainer>
          <DayWrapper>
            <Days>{getSelectedDays(weekDays, checkedDays)}</Days>
            <RuleDaysDivider />
          </DayWrapper>
          <TimeContainer>
            <TimeView ref={startTimeRef}>
              <TimeWrapper onClick={() => showTimePicker('start')}>
                <Time>{format(startTime, TIME_FORMAT)}</Time>
              </TimeWrapper>
              <TimeDividerEdit />
              <TimeWrapper ref={startTimeRef}>
                {(showTimePickerTypeStart) &&
                  <FilterDropdownContainer>
                    <TimePicker time={startTime} onSave={onChangeStartTime} showTime={showTimePicker} hourIncrementBy={1} />
                  </FilterDropdownContainer>}
              </TimeWrapper>
            </TimeView>
            <ScheduleArrowContainer>
              <Icon color='dimmed' icon='Right' size={9} weight='regular' />
            </ScheduleArrowContainer>
            <TimeView ref={endTimeRef}>
              <TimeWrapper onClick={() => showTimePicker('end')}>
                <Time>{format(endTime, TIME_FORMAT)}</Time>
              </TimeWrapper>
              <TimeDividerEdit />
              <TimeWrapper ref={endTimeRef}>
                {(showTimePickerTypeEnd) &&
                  <FilterDropdownContainer>
                    <TimePicker time={endTime} onSave={onChangeEndTime} showTime={showTimePicker} hourIncrementBy={1} />
                  </FilterDropdownContainer>}
              </TimeWrapper>
            </TimeView>
          </TimeContainer>
          <CancelButton design='secondary' disabled={showTimePickerTypeStart || showTimePickerTypeEnd} btnWidth={i18n.language === 'ja' ? '110px' : '60px'} onClick={()=>cancleRule()}>{t('Cancel')}</CancelButton> 
          <DoneButton onClick={doneRules} disabled={showTimePickerTypeStart || showTimePickerTypeEnd}>{t('Done')}</DoneButton>
        </RuleContainerWrapper>
        <DividerFull />
        <SelectDaysWrapper>{t('Select Days')}</SelectDaysWrapper>
        <DaysContainer>
          {weekDaysOne.map((item: IWeeks) => {
            return (
              <>
                <CheckWrapper key={item.id}>
                  <Checkbox checked={checkedDays.includes(item.id) ? true : false} onChangeCallback={(e)=>handleDays(e, item.id)} />
                  <DayLabel>{t(`${item.day}`)}</DayLabel>
                </CheckWrapper>
              </>
            );
          })}
        </DaysContainer>
      </RuleContainer>
    );
  },[isValidationError, checkedDays, editAction, doneRules, cancleRule, endTime, getSelectedDays, handleDays, onChangeEndTime, onChangeStartTime, showTimePicker, showTimePickerTypeStart,  showTimePickerTypeEnd, startTime, t ]);

  return (
    <MainContainer>
      <UpperContainer>
        <PageHeaderDiv>
          <AreaContainer title={!cameraData?.camera_name ? '-' : cameraData?.camera_name} onClick={goToPreviousPage}>{!cameraData?.camera_name ? '-' : cameraData?.camera_name}</AreaContainer>
          <HeadTitle title={!cameraData?.camera_name ? '-' : cameraData?.camera_name}>
            <PageHeader title={t('Alert Suppression')} icon='Analyse' updateDocTitle={false} />
          </HeadTitle>
        </PageHeaderDiv>
        <ButtonContainer>
          <Button size='small' design='secondary' onClick={onClickCancel}> {isEqual(scheduleData, prevScheduleData) ? t('Back') : t('Cancel')} </Button>
          <ButtonWithLoading loading={ruleSaveLoading} size='small' disabled={isEqual(scheduleData, prevScheduleData) ?  true : false} onClick={() => onClickSave(scheduleData)}> {t('Save Changes')} </ButtonWithLoading>
        </ButtonContainer>
      </UpperContainer>
      <DividerLine />
      <div>
        {loading ?
          <SpinnerContainer>
            <Spinner size='large' styling='primary' />
            <LoadingText>{t('Loading')}</LoadingText>
          </SpinnerContainer>:
          <Container isEditAction={editAction}>
            <>
              <Heading>{t('Alert Suppression Schedule')}</Heading>
              <IntroText>{t('This allows you to set times that new alerts will be suppressed. One example may be to suppress alerts at known peak times where traffic is known to build up.')}</IntroText>
              <EnableContainer>
                <CamEnableBlock>
                  <Switch checked={enableRule} state={schedules.length > 0 ? 'default':'disabled'} onChangeCallback={(checked) => { onClickEnableSuppressionRule(checked);}} />
                  <SwitchLabel>{enableRule? t('Enabled') : t('Disabled')}</SwitchLabel>
                </CamEnableBlock>
                {schedules.length > 0 &&
                  <EditButton editAction={editAction} lang={i18n.language} onClick={() => onClickEditButton()} design={editAction ? 'secondary' : 'primary'}> {editAction ? t('Cancel') : t('Edit')} </EditButton>}
              </EnableContainer>
              {(schedules.length === 0 && editAction === false && showAddRuleView === false) ?
                <DetailsContainerOuter id='noRuleMessage'>
                  <DetailsContainer>
                    <IconContainer isEditAction={editAction}> <Icon color='dimmed' icon='Time' size={15} weight='regular' /> </IconContainer>
                    <EmptyRuleContainer>
                      {t('No Rule Set')}
                      <Divider />
                    </EmptyRuleContainer>
                  </DetailsContainer>
                  {showAddRuleView ||
                    <ButtonWrapper>
                      <ButtonWithIcon design='primary' icon='Add' position='left' size='small' onClick={()=>onClickAddRule()}>{t('Add Rule')}</ButtonWithIcon>
                    </ButtonWrapper>}
                </DetailsContainerOuter>: ''}
              {schedules.length > 0 && 
                <JSTWrap>{t('Timezone')}: JST</JSTWrap>}
              {schedules?.map((item,index)=>{
                return(
                  <RuleWrapper key={index + 'Rule'}>
                    {editBoxID === index ?
                      getRuleContainer()
                      :
                      <RuleRowContainer key={index} isEditAction={editAction}>
                        <IconContainer isEditAction={editAction}>
                          <Icon color='dimmed' icon='Time' size={15} weight='regular' />
                        </IconContainer>
                        <DayWrapper>
                          <Days>{getSelectedDays(weekDays, item.days)}</Days>
                          <Divider />
                        </DayWrapper>
                        {(getRollOverDate(item.start_time).getTime() >= getRollOverDate(item.end_time).getTime()) ?
                          <TimeContainer isEditAction={editAction}>
                            <Time>{item.start_time}<TimeDivider /></Time>
                            <ArrowContainer>
                              <Icon color='dimmed' icon='Right' size={9} weight='regular' />
                            </ArrowContainer>
                            <EndTime> {item.end_time} <TimeDivider /> </EndTime>
                            <RollOverWrap>+1 {t('day')}</RollOverWrap>
                          </TimeContainer>:
                          <TimeContainer isEditAction={editAction}>
                            <Time>{item.start_time}<TimeDivider /></Time>
                            <ArrowContainer>
                              <Icon color='dimmed' icon='Right' size={9} weight='regular' />
                            </ArrowContainer>
                            <EndTime> {item.end_time} <TimeDivider /> </EndTime>
                            <RollOverWrap> </RollOverWrap>
                          </TimeContainer>}
                        {editAction &&
                          <ActionsContainer>
                            <IconWrap onClick={()=>editRules(index)}>
                              <Icon color='dimmed' icon='Edit' size={15} weight='regular' />
                            </IconWrap>
                            <IconWrap onClick={()=>removeRules(index)}>
                              <Icon color='dimmed' icon='Close' size={11} weight='regular' />
                            </IconWrap>
                          </ActionsContainer>}
                      </RuleRowContainer>}
                  </RuleWrapper>
                );
              })}
              {showAddRuleView &&
            getRuleContainer()}
              <AddRuleButtonContainer>
                {editAction && !showAddRuleView && !showEditRuleView &&
                  <ButtonWithIcon design='primary' icon='Add' position='left' size='small' onClick={()=>onClickAddRule()}>{t('Add Rule')}</ButtonWithIcon>}
              </AddRuleButtonContainer>
            </>
          </Container>}
      </div>
    </MainContainer>
  );
};

export default AlertSuppression;
