import React, { useCallback, useEffect, useRef, useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Button, SmallInput, ButtonWithIcon, ButtonWithLoading, Content, Icon, PageHeader, SelectField, Switch, useNotification, Label, Spinner, useModal, useMediaModal } from 'scorer-ui-kit';
import { getCamera, getCongestionSettings, saveCongestionSettings, toggleConfigurationAnalysis } from 'services/camerasService';
import styled, {css} from 'styled-components';
import LineViewer from 'components/LineViewer/configurationLineViewer';
import { ICongestionStopData, IDetectionAreaSettings, IExclusionAreas, ICongestionStopDataMapped, IDetectionAreaSettingsMapped } from './types';
import clonedeep from 'lodash.clonedeep';
import { IVector2, IPointSet, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import { RemoveSVG, AddSVG } from '../../svg';
import { isEqual } from 'lodash';
import { NEW_AREA, DEFAULT_CONGESTION_THRESHOLD, DEFAULT_STOP_THRESHOLD, EDGE_API_BASE_URL } from '../../constants';
import DetectionThreshold from '../CameraConfiguration/DetectionThreshold';
import DisableConfirmationModal from 'components/DisableConfirmationModal';
import {IToggleAnalysisPayload, ICameraDetails, IPoints} from '../../interface';
import ImportSettingsModal from './ConfigurationImportModal';
import cloneDeep from 'lodash.clonedeep';
import axios from 'axios';

const MainContainer = styled(Content)`
  @media screen and (min-width: 1440px) {
    padding: 60px 90px 60px 170px;
  }
  @media (max-width: 1400px) and (min-width: 1300px) {
    padding: 70px 10px 70px 90px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 940px !important;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 941px !important;
  justify-content: space-between;
  margin-top: -22px;
  padding: 0;
  p {
    margin-top: -14px;
  }
`;

const PageHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  > div > div {
    > h1 {
      margin-left: -1px;
    }
    > :nth-child(2) {
      bottom: 2px;
      left: -45px;
    }
  }
`;

const AreaContainer = styled.div`
  min-width: 200px;
  max-width: 750px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
`;

const HeadTitle = styled.span`
  & div > div > div > div {
    margin-top: -8px;
  }
`;

const ToggleDetection = styled.div<{ state?: string }>`
  display: flex;
  align-items: center;
  margin-top: -4px;
  gap: 0 12px;
  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
    `}
  margin-bottom: 15px;
`;

const SmallLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
`;

const ButtonContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 0;
  > button {
    width: 133px;
  }
  > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  & > :nth-child(2) {
    width: 135px;
    margin-right: -2px;
    padding: 0 18px;
  }
`;

const UnsavedChangesContainer = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.ui};
`;

const Divider = styled.div`
  height: 1px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #efefef;
`;

const ImportExportContainer = styled.div<{ gap: string; lang?: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 11px;
  margin-top: -15px;
  margin-bottom: 15px;

  > button {
    padding: 0 8px;
    > div > :first-child {
      padding-left: 12px;
    }
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const LineSettingsContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  margin: 4px 0;
`;

const LineSelectFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  position: relative;
  & > div:first-child {
    display: flex;
    position: absolute;
    top: 6px;
    left: -24px;
  }
`;

const StyledSelectField = styled(SelectField)`
  width: 229px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  } // to align drop down icon
`;

const InputAndButtonContainer = styled.div<{ gap: string; lang?: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  & > button {
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const LineUIContainer = styled.div`
  width: 100%;
  min-height: 390px;
  margin: 30px 0 15px;
  display: flex;
  border-radius: 3px;
  border: solid 1px #eee;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.02);
  background-color: rgba(252, 252, 253, 0.85);
  position: relative;

  &::before {
    content: "";
    width: 14.5px;
    height: 51px;
    position: absolute;
    top: -28px;
    left: -16px;
    border-left: 2px solid #d9dad9;
    border-bottom: 2px solid #d9dad9;
  }
`;

const LineUIRightPanel = styled.div`
  flex: 1;
  flex-shrink: 0;
  border-radius: 3px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const ResultText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.data};
  color: #5a6269;
  margin-top: -3px;
  padding: 0;
`;

const LineUILeftPanel = styled.div`
  padding: 14px 0;
  width: 240px;
  height: 390px;
  flex-shrink: 0;
  position: relative;
`;

const SidePaddingDiv = styled.div`
  position: relative;
  padding: 0 20px;
  & > div > label > span {
    margin-bottom: 5px !important;
    margin-left: -2px;
  }
`;

interface ISmallInput {
  length: string;
}

const StyledSmallInputWithLabel = styled(SmallInput)<ISmallInput>`
  & > label {
    width: ${({ length }) => length};
    margin-bottom: 0;
  }
  input:disabled {
    cursor: not-allowed;
  }
`;

const DividerWithMargin = styled(Divider)`
  margin: 14px 0 12px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 20px 0 20px;
`;

const LabelTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > label:nth-child(1) {
    margin-right: 6px;
  }
`;

const LabelText = styled(Label)`
  margin-top: 4px;
  margin-bottom: 0;
`;

const AreaInfoButtonDiv = styled.div`
  display: flex;
  margin-top: -2px;
  button {
    margin-left: 10px;
  }
`;

const PointsButton = styled(Button)`
  width: 31px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const CancelModalContainer = styled.div`
  padding: 18px 48px 22px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const LineUIActionsDisplayContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 396.5px;
  height: max-content;
  & > div:nth-child(1) {
    margin-top: -3px;
  }
  & > div:nth-child(2) {
    margin-top: -14px;
  }
  position: absolute;
  z-index: 10;
  bottom: -34px;
`;

const SelectAreaShow = styled(SelectField)`
  width: 138px;
  height: 30px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 25px 0 8px !important;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-style: normal !important;
  color: #8b9196 !important;
  letter-spacing: -0.12px;
  & + div {
    right: 14.5px !important;
  }
`;

const IconWrapper = styled.div``;

const AreaInfoDivider = styled.div`
  height: 1px;
  width: 100%;
  border-radius: 3px;
  background-color: #efefef;
  margin-left: 0;
  margin-bottom: 15px;
`;

const CancelChangesButton = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const Options: LineUIOptions = {
  showSetIndex: false,
  showPoint: true,
  showDirectionMark: false,
  showMoveHandle: true,
  showPointHandle: true,
  setIndexOffset: 0,
  boundaryOffset: 0
};

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

interface ILinePoints {
  points: IVector2[],
  changeLineStyle: boolean
}

type CongestionRes = ICongestionStopDataMapped;
type CongestionKeys = keyof CongestionRes;
type AreaKeys = keyof IDetectionAreaSettingsMapped;
type PointsKeys = keyof IPoints;
type CheckTypeValue = number | string | boolean | IDetectionAreaSettingsMapped[] | IPoints | undefined;

const checkType = (key: string, value: CheckTypeValue): boolean => {
  switch (key) {
  case 'congestion_and_stop':
    return Array.isArray(value);
  case 'name':
    return typeof value === 'string';
  case 'congestion_alert':
    return typeof value === 'boolean';
  case 'congestion_count':
    return typeof value === 'number';
  case 'stop_alert':
    return typeof value === 'boolean';
  case 'stop_seconds':
    return typeof value === 'number';
  case 'area':
    return true;
  case 'x':
  case 'y':
    return typeof value === 'number';
  default:
    return false;
  }
};

const CongestionStopDetection = () => {
  const { t, i18n } = useTranslation(['CommonDict']);
  const { streamName }: { streamName: string } = useParams();
  const [cameraDetails, setCameraDetails] = useState<ICameraDetails>();
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const { push } = useHistory();
  const [congestionStopData, setCongestionStopData] = useState<ICongestionStopData>({analysis_enabled: false, congestion_and_stop_settings: []});
  const [prevCongestionStopData, setPrevCongestionStopData] = useState<ICongestionStopData>({analysis_enabled: false, congestion_and_stop_settings: []});
  const [exclusionAreas, setExclusionAreas] = useState<IExclusionAreas>({enabled: false, areas: []});
  const [prevValuesForCompare, setPrevValuesForCompare] = useState<ICongestionStopData>({analysis_enabled: false, congestion_and_stop_settings: []});
  const [congestionStopAreaSettings, setCongestionStopAreaSettings] = useState<IDetectionAreaSettings[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedArea, setSelectedArea] = useState<string>(NEW_AREA);
  const [areaSelectOptions, setAreaSelectOptions] = useState<string[]>([NEW_AREA]);
  const [selectedAreaIndex, setSelectedAreaIndex] = useState(0);
  const { createModal, setModalOpen, isModalOpen } = useModal();
  const [unSavedChanges, setUnsavedChanges] = useState<number>(0);
  const [congestionSaveLoading, setCongestionSaveLoading] = useState(false);
  const [showAllArea, setShowAllArea] = useState(true);
  const [showAllOrSelectedOption, setShowAllOrSelectedOption] = useState('Show All');
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [stateDetails, setStateDetails] = useState<IPointSet[]>([]); 
  const stateRef = useRef<IPointSet[]>([]);
  const tRef = useRef(t);
  const [toggleAnalysis, setToggleAnalysis] = useState(false);
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const { isMediaUrlValid } = useMediaModal();
  const [image, setImage] = useState('');
  const [isImporting, setIsImporting] = useState(true);

  // Update for every area change
  useEffect(() => {
    const state: IPointSet[] = [];
    if (showAllOrSelectedOption === 'Show All') {
      congestionStopData?.congestion_and_stop_settings?.forEach((item) => {            
        state.push({
          points: item.points,
          styling: item.styling,
          readOnly: (item.index !== selectedAreaIndex),
          showPointHandle: (item.index === selectedAreaIndex),
          showMoveHandle: (item.index === selectedAreaIndex),
          areaFillColor: '#1684cf',
          areaTransparencyLevel: 20,
          areaName: item.name.length > 24 ? item.name.slice(0, 24) : item.name
        });
      });
    } else {
      if (selectedAreaIndex !== 0) {
        const lines = congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1];
        state.push({
          points: lines.points,
          styling: lines.styling,
          readOnly: false,
          showPointHandle: true,
          areaFillColor: '#1684cf',
          areaTransparencyLevel: 20,
          areaName: lines.name.length > 24 ? lines.name.slice(0, 24) : lines.name
        });
      }
    }
    setStateDetails(state);
    stateRef.current = state;
  }, [congestionStopData, selectedAreaIndex, showAllOrSelectedOption]);


  const zoneChangesCounter = (prevDetails: ICongestionStopData, currentDetails: ICongestionStopData) => {
    let counter = 0;
    
    // If any Zones has length 0
    if (currentDetails.congestion_and_stop_settings.length === 0 || prevDetails.congestion_and_stop_settings.length === 0) {
      counter = counter + currentDetails.congestion_and_stop_settings.length + prevDetails.congestion_and_stop_settings.length;
      return counter;
    }

    // count zone difference
    currentDetails.congestion_and_stop_settings.forEach((lane1) => {
      let found = 1;
      for (const lane2 of prevDetails.congestion_and_stop_settings) {
        if (isEqual(lane1, lane2)) {
          found = 0;
          break;
        }
      }
      counter += found;
    });
    
    if (currentDetails.congestion_and_stop_settings.length < prevDetails.congestion_and_stop_settings.length) {
      counter += Math.abs(currentDetails.congestion_and_stop_settings.length - prevDetails.congestion_and_stop_settings.length);
    }

    return counter;
  };

  useEffect(()=>{
    const currentExclusionAreaSettings = congestionStopData?.congestion_and_stop_settings.map(obj => {
      const newObj = { ...obj };
      delete newObj.index;
      delete newObj.showMoveHandle;
      delete newObj.showPointHandle;
      return newObj;
    });
    const prevExclusionAreaSettings = prevValuesForCompare?.congestion_and_stop_settings.map(obj => {
      const newObj = { ...obj };
      delete newObj.index;
      delete newObj.showMoveHandle;
      delete newObj.showPointHandle;
      return newObj;
    });
    const prevSettings = {
      ...prevValuesForCompare,
      congestion_and_stop_settings: prevExclusionAreaSettings
    };

    const currentDetails:ICongestionStopData = {analysis_enabled: congestionStopData?.analysis_enabled, congestion_and_stop_settings: currentExclusionAreaSettings};
    const changes = zoneChangesCounter(prevSettings, currentDetails);
    setUnsavedChanges(changes);
  },[prevValuesForCompare, congestionStopData]);

  const fetchCamera = useCallback(() => {
    getCamera(streamName).then((res) => {
      if (res.status === 200 && res.data.data) {
        setCameraDetails(res.data.data);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    }).catch(() => {
      sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  }, [streamName]);

  const getCongestionData = useCallback(async () => {
    try {
      const res = await getCongestionSettings(streamName);
      if (res.data.status_code === 0 && res.data.data) {
        if (res.data.exclusion_areas) {
          const {enabled: enabledExclusion, exclusion_zone_settings = []} = res.data.exclusion_areas;
          if(exclusion_zone_settings !== null){
            setExclusionAreas({
              enabled: enabledExclusion,
              areas: exclusion_zone_settings.map(area => ({points: area.points}))
            });
          }
        }
        setCongestionStopData(res.data.data);
        setPrevValuesForCompare(res.data.data);
        setPrevCongestionStopData(clonedeep(res.data.data));
        setCongestionStopAreaSettings(res.data.data?.congestion_and_stop_settings);
        const zoneSelect = res.data.data?.congestion_and_stop_settings.map(area => area.name);
        zoneSelect.unshift(NEW_AREA);
        setAreaSelectOptions(zoneSelect);
        setSelectedAreaIndex(0);
        setSelectedArea(NEW_AREA);
        setLoading(false);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
        setLoading(false);
      }
    } catch (error) {
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      setLoading(false);
    }
  }, [streamName]);

  useEffect(()=>{
    fetchCamera();
    getCongestionData();
  },[fetchCamera, getCongestionData]);

  const onChangeCongestionStopData = useCallback((formData) => {
    setCongestionStopData({ ...formData });
  }, []);

  const handleCongestionAnalysis = useCallback((value) => {
    setToggleAnalysis(true);
    const newCongestionStopData = clonedeep(congestionStopData);
    newCongestionStopData.analysis_enabled = value;
    onChangeCongestionStopData(newCongestionStopData);

    const payload: IToggleAnalysisPayload ={
      action: value ? 'enable' : 'disable',
      analysis_type: ['congestion_and_stop_detection']
    };
    toggleConfigurationAnalysis(streamName, payload).then((res: { data: { status_code: number; }; }) => {
      if (res.data.status_code === 0) {
        sendNotificationRef.current({ type: 'success', message: value ? tRef.current('Analysis enabled successfully') : tRef.current('Analysis disabled successfully')});
        setToggleAnalysis(false);
      } else { 
        newCongestionStopData.analysis_enabled = !value;
        onChangeCongestionStopData(newCongestionStopData);
        sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        setToggleAnalysis(false);
      }
    }).catch(() => {
      newCongestionStopData.analysis_enabled = !value;
      onChangeCongestionStopData(newCongestionStopData);
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      setToggleAnalysis(false);
    });
  }, [congestionStopData, streamName, onChangeCongestionStopData]);

  const validateCongestionStopData = useCallback((congestionStopData) => {
    let returnValue = true;
    const areaNames = new Set();
    for (const item of congestionStopData?.congestion_and_stop_settings) {
      if (item.name === '') {
        sendNotificationRef.current({type: 'error', message: tRef.current('Please enter the area reference for area #{X}').replace('{X}', (item.index))});
        returnValue = false;
        break;
      }
      if (item.congestion_enabled && item.stop_enabled) {
        if ((item.congestion_threshold === undefined || item.congestion_threshold < 1 || Number.isNaN(item.congestion_threshold)) && (item.stop_threshold === undefined || item.stop_threshold < 1 || Number.isNaN(item.stop_threshold))) {
          sendNotificationRef.current({ type: 'error', message: tRef.current('Congestion and stop detection threshold should be > 0 for {X}').replace('{X}', (item.name)) });
          returnValue = false;
          break;
        }
      }
      if (item.congestion_enabled) {
        if (item.congestion_threshold === undefined || item.congestion_threshold < 1 || Number.isNaN(item.congestion_threshold)) {
          sendNotificationRef.current({ type: 'error', message: tRef.current('Congestion detection threshold should be > 0 for {X}').replace('{X}', (item.name)) });
          returnValue = false;
          break;
        }
      }
      if (item.stop_enabled) {
        if (item.stop_threshold === undefined || item.stop_threshold < 1 || Number.isNaN(item.stop_threshold)) {
          sendNotificationRef.current({ type: 'error', message: tRef.current('Stop detection threshold should be > 0 for {X}').replace('{X}', (item.name)) });
          returnValue = false;
          break;
        }
      }

      if (areaNames.has(item.name)) {
        sendNotificationRef.current({type: 'error', message: tRef.current('{X} already exists').replace('{X}', (item.name))});
        returnValue = false;
        break;
      }
      areaNames.add(item.name);
    }
    return returnValue;
  },[]);

  const onClickSave = useCallback((data = congestionStopData) => {
    const isValidData = validateCongestionStopData(data);
    if (isValidData) {
      const settings = congestionStopData?.congestion_and_stop_settings;
      settings?.forEach((area: IDetectionAreaSettings, index) => {
        if (area.congestion_threshold === undefined) {
          settings[index].congestion_threshold = DEFAULT_CONGESTION_THRESHOLD;
        }
        if (area.stop_threshold === undefined) {
          settings[index].stop_threshold = DEFAULT_STOP_THRESHOLD;
        }
        if (area.congestion_enabled === undefined) {
          settings[index].congestion_enabled = false;
        }
        if (area.stop_enabled === undefined) {
          settings[index].stop_enabled = false;
        }
      });
      onChangeCongestionStopData({ ...congestionStopData, congestion_and_stop_settings: settings });
      setCongestionSaveLoading(true);
      saveCongestionSettings(streamName, data).then((res: { data: { status_code: number; }; }) => {
        if (res.data.status_code === 0) {
          setPrevCongestionStopData(clonedeep(congestionStopData));
          sendNotificationRef.current({ type: 'success', message: tRef.current('Settings saved successfully')});
          fetchCamera();
          getCongestionData();
        } else { sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') }); }
        setCongestionSaveLoading(false);
      }).catch(() => {
        setCongestionSaveLoading(false);
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      });
    }
  }, [congestionStopData, fetchCamera, onChangeCongestionStopData, streamName, getCongestionData, validateCongestionStopData]);

  const onLineChange = useCallback((state: IPointSet[]) => {
    if (selectedAreaIndex === 0) return;
    const newCongestionStopData = clonedeep(congestionStopData);
    
    const selectedLines = state.filter(line => line.showPointHandle);
    newCongestionStopData.congestion_and_stop_settings[selectedAreaIndex - 1] = {
      ...newCongestionStopData.congestion_and_stop_settings[selectedAreaIndex - 1],
      points: selectedLines[0].points
    };
    onChangeCongestionStopData({ ...newCongestionStopData });
  },[selectedAreaIndex, congestionStopData, onChangeCongestionStopData]);

  const getMidpoint = useCallback((pointA: IVector2, pointB: IVector2) => {
    return ({
      x: Math.round(pointA.x + (pointB.x - pointA.x) * 0.5),
      y: Math.round(pointA.y + (pointB.y - pointA.y) * 0.5)
    });
  }, []);

  const handleRemovePoint = useCallback(() => {
    const newCongestionStopData = clonedeep(congestionStopData);
    newCongestionStopData.congestion_and_stop_settings[selectedAreaIndex - 1].points.splice(-1);
    onChangeCongestionStopData(newCongestionStopData);
  }, [congestionStopData, selectedAreaIndex, onChangeCongestionStopData]);

  const handleAddPoint = useCallback(() => {
    const newCongestionStopData = clonedeep(congestionStopData);
    const midPoint: IVector2 = getMidpoint(congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1]?.points[0], congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1]?.points[1]);
    newCongestionStopData.congestion_and_stop_settings[selectedAreaIndex - 1].points.splice(1, 0, midPoint);
    onChangeCongestionStopData(newCongestionStopData);
  }, [congestionStopData, getMidpoint, onChangeCongestionStopData, selectedAreaIndex]);

  const handleAreaName = useCallback(({ target: { value } }) => {
    const newAreaSelectOptions = [...areaSelectOptions];
    newAreaSelectOptions[selectedAreaIndex] = value;
    setAreaSelectOptions(newAreaSelectOptions);
    setSelectedArea(selectedAreaIndex + ': ' + value);
    const newCongestionStopData = clonedeep(congestionStopData);
    newCongestionStopData.congestion_and_stop_settings[selectedAreaIndex - 1].name = value;
    onChangeCongestionStopData(newCongestionStopData); 
  }, [congestionStopData, selectedAreaIndex, areaSelectOptions, onChangeCongestionStopData]);

  const onLineSelect = useCallback((e) => {
    setSelectedArea(e);
    if (e === NEW_AREA || e === '［新規エリア］') {
      setSelectedAreaIndex(0);
    } else {
      setSelectedAreaIndex(parseInt(e.substring(0, e.indexOf(':'))));
    }
  },[]);

  const onAddArea = useCallback(() => {
    const filteredAreaNumbers=[1,2,3,4,5].filter(num=>congestionStopAreaSettings.findIndex(d=>parseInt(d.name.trim()[d.name.trim().length-1]) === num)===-1);
    filteredAreaNumbers.sort();
    const newAreaDetail = {
      index: congestionStopAreaSettings?.length + 1,
      name: i18n.language === 'ja' ? 'エリア' + filteredAreaNumbers[0].toString() : 'Area ' + filteredAreaNumbers[0].toString(),
      points: [{'x':38 * filteredAreaNumbers[0],'y':38 * filteredAreaNumbers[0]},{'x':44 * filteredAreaNumbers[0],'y':165 * filteredAreaNumbers[0]},{'x':155 * filteredAreaNumbers[0],'y':166 * filteredAreaNumbers[0]},{'x':148 * filteredAreaNumbers[0],'y':40 * filteredAreaNumbers[0]}],
      congestion_threshold: 30,
      stop_threshold: 30,
      congestion_enabled: true,
      stop_enabled: true,
    };
    const updatedSettings = [...congestionStopData?.congestion_and_stop_settings, newAreaDetail];
    const addNewArea = {
      ...congestionStopData,
      congestion_and_stop_settings: updatedSettings
    };
    onLineSelect(i18n.language === 'ja' ? `${newAreaDetail.index}: エリア${newAreaDetail.index}` : `${newAreaDetail.index}: Area ${newAreaDetail.index}`);
    setCongestionStopAreaSettings(prev => [...prev, newAreaDetail]);
    setAreaSelectOptions(prev => [...prev, i18n.language === 'ja' ? 'エリア' + filteredAreaNumbers[0].toString() : 'Area ' + filteredAreaNumbers[0].toString()]);
    onChangeCongestionStopData(addNewArea);     
    sendNotificationRef.current({type: 'neutral', message: tRef.current('New area added'), icon: 'FeaturePolyUi'});
  },[congestionStopAreaSettings, congestionStopData, onChangeCongestionStopData, onLineSelect, i18n]);

  const onRemoveArea = useCallback(() => {
    const updatedData = {
      ...congestionStopData,
      congestion_and_stop_settings: congestionStopData?.congestion_and_stop_settings.filter(
        (item) => item.index !== selectedAreaIndex
      ).map((item, index) => ({ ...item, index: index + 1 }))
    };
    setCongestionStopAreaSettings(updatedData.congestion_and_stop_settings);

    const remAreaSelectOptions = updatedData?.congestion_and_stop_settings.map(area => area.name);
    remAreaSelectOptions.unshift(NEW_AREA);
    setAreaSelectOptions(remAreaSelectOptions);
    setSelectedAreaIndex(remAreaSelectOptions.length - 1);
    if (remAreaSelectOptions[remAreaSelectOptions.length - 1] === NEW_AREA) {
      setSelectedArea(NEW_AREA);
    } else {
      setSelectedArea(`${remAreaSelectOptions.length - 1}: ${remAreaSelectOptions[remAreaSelectOptions.length - 1]}`);
    }
    onChangeCongestionStopData(updatedData); 
    sendNotificationRef.current({ type: 'neutral', message: tRef.current('Area removed'), icon: 'FeaturePolyUi'});
  },[congestionStopData, selectedAreaIndex, onChangeCongestionStopData]);

  const onMediaLoadedCallback = useCallback(() => {
    setIsMediaLoaded(true);
  }, []);

  const onLineClickCallback = useCallback((lineId: number) => {
    setSelectedAreaIndex(lineId + 1);
    setSelectedArea(lineId + 1 + ': ' + congestionStopData?.congestion_and_stop_settings[lineId]?.name);
  },[congestionStopData]);

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('CancelButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('CancelButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'CancelButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('CancelButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('CancelButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const handleCancel = useCallback(async (setModalOpen: (newStatus: boolean) => void) => {
    setSelectedAreaIndex(0);
    await setModalOpen(false);
    if(prevCongestionStopData) {
      setCongestionStopData(prev => ({...prevCongestionStopData, analysis_enabled: prev.analysis_enabled}));
      setPrevValuesForCompare(prev => ({...prevCongestionStopData, analysis_enabled: prev.analysis_enabled}));
      setCongestionStopAreaSettings(prevCongestionStopData.congestion_and_stop_settings);
      const zoneSelect = prevCongestionStopData.congestion_and_stop_settings.map(area => area.name);
      zoneSelect.unshift(NEW_AREA);
      setAreaSelectOptions(zoneSelect);
      setSelectedAreaIndex(0);
      setSelectedArea(NEW_AREA);
    }
  }, [prevCongestionStopData]);

  const handleCancleNo = useCallback(() =>{
    setModalOpen(false);
  },[setModalOpen]);

  const getCancelModal = useCallback((setModalOpen: (newStatus: boolean) => void) => {
    return (
      <CancelModalContainer>
        <CancelConfirmText>{tRef.current('Are you sure you want to cancel the changes?')}</CancelConfirmText>
        <ModalButtonContainer>
          <CancelChangesButton id='CancelButton' design='secondary' onClick={() => handleCancleNo()}>{tRef.current('No')}</CancelChangesButton>
          <CancelChangesButton id='YesButton' onClick={() => handleCancel(setModalOpen)}>{tRef.current('Yes')}</CancelChangesButton>
        </ModalButtonContainer>
      </CancelModalContainer>
    );
  }, [handleCancleNo, handleCancel]);

  const goToPreviousPage = useCallback(() => {
    if(isEqual(congestionStopData.congestion_and_stop_settings, prevValuesForCompare.congestion_and_stop_settings)){
      push('/cameras/camera-configuration/' + streamName);
      return;
    }
    const CancelModal: ReactElement = getCancelModal(setModalOpen);
    createModal({ isCloseEnable: false, width: '400px', padding: false, customComponent: CancelModal });
  }, [push, createModal, getCancelModal, setModalOpen, congestionStopData, prevValuesForCompare, streamName]);

  const handleAreaShow = useCallback((e) => {
    setShowAllArea(!showAllArea);
    setShowAllOrSelectedOption(e);
  },[showAllArea]);

  const diableCallBack = useCallback((isEnabled: boolean) => {
    setModalOpen(false);
    onClickSave({...congestionStopData, analysis_enabled: isEnabled});
  }, [congestionStopData, setModalOpen, onClickSave]);

  const openConfirmationModal = useCallback(() => {
    if (congestionStopData.analysis_enabled) {
      onClickSave(congestionStopData);
    } else {
      const isValidData = validateCongestionStopData(congestionStopData);
      if(isValidData){
        const ConfirmationModal: ReactElement = <DisableConfirmationModal onCallback={diableCallBack} />;
        createModal({ isCloseEnable: false, width: 'auto', padding: false, customComponent: ConfirmationModal });
      }
    }
  }, [congestionStopData, validateCongestionStopData, createModal, onClickSave, diableCallBack]);

  const onExportSettings = useCallback(() => {
    const { congestion_and_stop_settings } = prevCongestionStopData;
    const exportLines = congestion_and_stop_settings.map(({ index, styling, congestion_enabled, stop_enabled, congestion_threshold, stop_threshold, points, ...rest }) => {
      return { ...rest, area: points, congestion_alert: congestion_enabled, congestion_count: congestion_threshold, stop_alert: stop_enabled, stop_seconds: stop_threshold };
    });
    try {
      let modifiedCameraName = cameraDetails?.camera_name || '';
      if (modifiedCameraName.length > 50) {
        modifiedCameraName = modifiedCameraName.substring(0, 50) + '...';
      }
      const fileName = `${modifiedCameraName}_congestion_and_stop.json`;
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({ congestion_and_stop: exportLines }, null, 4));
      const link = document.createElement('a');
      link.setAttribute('href', dataStr);
      link.setAttribute('download', fileName);
      link.click();
      sendNotification({ type: 'success', message: t('Settings exported successfully')});
    } catch (error) {
      sendNotification({type: 'error',message: t('An error occurred while exporting data')});
    }
  }, [prevCongestionStopData, cameraDetails, sendNotification, t]);

  const getLinePoints = useCallback((pointsArray: IVector2[]): ILinePoints => {
    const boundaryOffset = 0; // 48
    let changeLineStyle = false;

    if (image === '') {
      return { points: pointsArray, changeLineStyle };
    }
  
    // Initialize an array to store the adjusted points
    const adjustedPoints = pointsArray.map(point => {
      let adjustedX = point.x;
      let adjustedY = point.y;
  
      // Check and adjust X coordinate
      if (point.x < boundaryOffset) {
        adjustedX = boundaryOffset;
        changeLineStyle = true;
      } else if (point.x > imageDetails.x - boundaryOffset) {
        adjustedX = imageDetails.x - boundaryOffset;
        changeLineStyle = true;
      }
  
      // Check and adjust Y coordinate
      if (point.y < boundaryOffset) {
        adjustedY = boundaryOffset;
        changeLineStyle = true;
      } else if (point.y > imageDetails.y - boundaryOffset) {
        adjustedY = imageDetails.y - boundaryOffset;
        changeLineStyle = true;
      }
  
      return { x: adjustedX, y: adjustedY };
    });
  
    return { points: adjustedPoints, changeLineStyle };

  }, [image, imageDetails]);

  const importSettings = useCallback((importedTrafficCounterData: ICongestionStopData) => {
    const { congestion_and_stop_settings = [] } = importedTrafficCounterData;
    
    if (congestion_and_stop_settings.length > 0) {
      const lineInfo: IDetectionAreaSettings[] = [];
      setAreaSelectOptions([NEW_AREA]);
      congestion_and_stop_settings.map((item, index) => {
        const point = cloneDeep(item.points);
        const { points, changeLineStyle } = getLinePoints(point);
        const lineName = !item.name ? `Area ${index}` : item.name;

        setAreaSelectOptions(prev => [...prev, lineName]);

        lineInfo.push(
          {
            index: item.index,
            name: item.name,
            styling: changeLineStyle ? 'danger' : item.styling,
            points: points,
            congestion_enabled: item.congestion_enabled,
            congestion_threshold: item.congestion_threshold,
            stop_enabled: item.stop_enabled,
            stop_threshold: item.stop_threshold
          }
        );
        return {
          name: index + 1 + ': ' + lineName,
          points: points,
          readOnly: true,
          styling: changeLineStyle ? 'danger' : 'primary',
          showSmallDirectionMark: true
        };
      });
      setCongestionStopAreaSettings(lineInfo);
      setCongestionStopData({analysis_enabled: congestionStopData.analysis_enabled, congestion_and_stop_settings: lineInfo});
    } else {
      setCongestionStopAreaSettings([]);
      setCongestionStopData({analysis_enabled: congestionStopData.analysis_enabled, congestion_and_stop_settings: []});
    }
  }, [congestionStopData, getLinePoints]);

  const validateImporting = useCallback((importedTrafficCounterData) => {
    importedTrafficCounterData.congestion_and_stop.forEach((item: {points: any; area: any; congestion_enabled: boolean; congestion_alert: any; stop_enabled: boolean; stop_alert: any; congestion_threshold: number; congestion_count: any; stop_threshold: number; stop_seconds: any; index: number;}, index: number) => {
      item.index = index + 1;
      item.points = item.area;
      item.congestion_enabled = item.congestion_alert;
      item.stop_enabled = item.stop_alert;
      item.congestion_threshold = item.congestion_count;
      item.stop_threshold = item.stop_seconds;
      delete item.area;
      delete item.congestion_alert;
      delete item.stop_alert;
      delete item.congestion_count;
      delete item.stop_seconds;
    });
  
    const newImportedData = {
      congestion_and_stop_settings: importedTrafficCounterData.congestion_and_stop,
      analysis_enabled: prevValuesForCompare.analysis_enabled
    };
    if(isEqual(prevValuesForCompare.congestion_and_stop_settings, newImportedData.congestion_and_stop_settings)){
      getCongestionData();
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    } else {
      setSelectedAreaIndex(0);
      setSelectedArea(NEW_AREA);
      setCongestionStopAreaSettings([]);
      setCongestionStopData({analysis_enabled: congestionStopData.analysis_enabled, congestion_and_stop_settings: []});
      importSettings({...newImportedData});
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    }
  }, [t, sendNotification, importSettings, setModalOpen, congestionStopData, prevValuesForCompare, getCongestionData]);

  const isValidImportFile = useCallback((jsonData) => {
    let isLinesValid = false;
    const validCongestionKeys: CongestionKeys[] = ['congestion_and_stop'];
    const validAreaKeys: AreaKeys[] = ['name', 'area', 'congestion_alert', 'congestion_count', 'stop_alert', 'stop_seconds'];
    const validPointsKeys: PointsKeys[] = ['x', 'y'];

    try {
      let resKeys: CongestionKeys[] = [], areaKeys: AreaKeys[] = [], pointsKeys: PointsKeys[] = [];
      const resObj: CongestionRes = JSON.parse(jsonData);
      resKeys = Object.keys(resObj) as Array<keyof typeof resObj>;

      if (
        resKeys.every(key => validCongestionKeys.includes(key)) &&
        validCongestionKeys.every(key => (resKeys.includes(key) && checkType(key, resObj[key])))
      ) {

        if (resObj.congestion_and_stop.length === 0) return true;
        if (resObj.congestion_and_stop.length > 4){
          sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Maximum 4 areas can be imported')})));
          return false;
        }

        const isValidLineKeys = (areaKeys: AreaKeys[]) => {
          if(areaKeys.every(key => validAreaKeys.includes(key)) && validAreaKeys.every(key => areaKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeys = (pointsKeys: PointsKeys[]) => {
          if(pointsKeys.every(key => validPointsKeys.includes(key)) && validPointsKeys.every(key => pointsKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeyTypes = (pointsKeys: PointsKeys[], point: IPoints) => {
          if(pointsKeys.every((key) => checkType(key, point[key]))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidLineKeyTypes = (areaKeys: AreaKeys[], line: IDetectionAreaSettingsMapped) => {
          return areaKeys.every((key) => {
            if (key === 'area') {
              if(line[key].length > 2) {
                return Array.isArray(line[key]) && line[key].every((point) => isValidPointsKeyTypes(validPointsKeys, point));
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Minimum 3 points are required for an area')})));
                return false;
              }
            } else if (key === 'name') {
              if(typeof line[key] === 'string' && line[key] !== ''){
                if(line[key].length > 24){
                  sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name should not longer than 24 characters')})));
                  return false;
                } else {
                  return true; 
                }
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name is required')})));
                return false; // Check for non-empty string
              }
            } else if(key === 'congestion_count'){
              if(typeof line[key] === 'number' && line[key] >= 1 && line[key] < 10000){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Congestion detection threshold should be > 0 and < 10000  cars')})));
                return false; // Check for non-empty string
              }
            } else if(key === 'stop_seconds'){
              if(typeof line[key] === 'number' && line[key] >= 1 && line[key] < 10000){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Stop detection threshold should be > 0 and < 10000 sec')})));
                return false; // Check for non-empty string
              }
            } else {
              if(checkType(key, line[key])){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid data types')})));
                return false;
              }
            }
          });
        };

        for (const line of resObj.congestion_and_stop) {
          areaKeys = Object.keys(line) as Array<keyof typeof line>;
          if (isValidLineKeys(areaKeys) && isValidLineKeyTypes(areaKeys, line)) {
            for (const point of line.area) {
              pointsKeys = Object.keys(point) as Array<keyof IPoints>;
              if (isValidPointsKeys(pointsKeys) && isValidPointsKeyTypes(pointsKeys, point)) {
                isLinesValid = true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid point keys or their type')})));
                isLinesValid = false;
                break; // Exit the loop if any point is invalid
              }
            }
          } else {
            isLinesValid = false;
            break;
          }
        }
        return isLinesValid;
      } else {
        sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
        return false;
      }
    } catch (error) {
      sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid file')})));
      return false;
    }
  }, [sendNotification, t]);

  const onImportCallback = useCallback((e) => {
    if (e !== null && isValidImportFile(e.result)) {
      validateImporting(JSON.parse(e.result as string));
    } else {
      setModalOpen(false);
    }
  }, [isValidImportFile, validateImporting, setModalOpen]);

  // Import Modal
  const getImportModal = useCallback(() => {
    return (
      <ImportSettingsModal {...{ onImportCallback, sendNotification }} />
    );
  }, [sendNotification, onImportCallback]);

  const onImportSettings = useCallback(() => {
    setModalOpen(true);
    const importModal: ReactElement = getImportModal();
    createModal({
      isCloseEnable: false,
      width: 'max-length',
      padding: false,
      customComponent: importModal,
    });
  }, [createModal, setModalOpen, getImportModal]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
        const isImageValid = await isMediaUrlValid(imgBase64, 'img');
        if (isImageValid === true) {
          setImage(imgBase64);
          const img = new Image();
          img.src = imgBase64;
          setImageDetails({x: img.width, y: img.height});
        }
        setIsImporting(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [streamName, isMediaUrlValid]);

  useEffect(() => {
    if(isImporting){
      getCameraImage();
    }
  },[isImporting, getCameraImage]);

  return (
    <MainContainer>
      <Container>
        <Header>
          <PageHeaderDiv>
            <AreaContainer onClick={goToPreviousPage}>{cameraDetails?.camera_name}</AreaContainer>
            <HeadTitle>
              <PageHeader title={tRef.current('Congestion & Stop')} icon='Analyse' updateDocTitle={false} />
            </HeadTitle>
            <ToggleDetection state={toggleAnalysis ? 'disabled' : 'default'}>
              <Switch checked={congestionStopData?.analysis_enabled} onChangeCallback={handleCongestionAnalysis} state={toggleAnalysis ? 'disabled' : 'default'} />
              <SmallLabel>{congestionStopData?.analysis_enabled ? tRef.current('Enabled') : tRef.current('Disabled')}</SmallLabel>
            </ToggleDetection>
          </PageHeaderDiv>
          <ButtonContainer>
            <Button size='small' design='secondary' onClick={goToPreviousPage}> {unSavedChanges === 0 ? t('Back') : t('Cancel')} </Button>
            <ButtonWithLoading size='small' loading={congestionSaveLoading} disabled={unSavedChanges === 0 || congestionSaveLoading} onClick={openConfirmationModal}> {tRef.current('Save Changes')} </ButtonWithLoading>
            {unSavedChanges > 0 ? <UnsavedChangesContainer>{tRef.current('{X} unsaved changes').replace('{X}', (unSavedChanges+ ''))}</UnsavedChangesContainer> : <UnsavedChangesContainer>{tRef.current('No Changes')}</UnsavedChangesContainer>}
          </ButtonContainer>
        </Header>

        <Divider />

        <ImportExportContainer gap='0 14px'>
          {t('Settings')}:
          <Button size='xsmall' design='secondary' disabled={false} onClick={onImportSettings}>
            {t('Import')}
          </Button>
          <Button size='xsmall' design='secondary' disabled={false} onClick={onExportSettings}>
            {t('Export')}
          </Button>
        </ImportExportContainer>

        <Divider />
        <LineSettingsContainer>
          <LineSelectFieldContainer>
            <Icon icon='FeaturePolyUi' size={16} color='dimmed' />
            <StyledSelectField changeCallback={(e) => onLineSelect(e)} disabled={false} isCompact value={selectedArea}>
              {areaSelectOptions.map((item, index) => {
                return (
                  <option key={item + index} value={item === NEW_AREA ? item : `${index}: ${item}`}>
                    {item === NEW_AREA ? tRef.current(item) : `${index}: ${item.length > 24 ? item.slice(0, 24) : item}`}
                  </option>);
              })}
            </StyledSelectField>
            <InputAndButtonContainer gap='0 10px' lang={i18n.language}>
              <ButtonWithIcon icon='Add' position='left' size='small' design='secondary' onClick={onAddArea} disabled={!isMediaLoaded || congestionStopData?.congestion_and_stop_settings.length === 4}>{tRef.current('Add Area')}</ButtonWithIcon>
              <ButtonWithIcon icon='ActionRemove' position='left' size='small' design='secondary' onClick={onRemoveArea} disabled={!selectedAreaIndex}>{tRef.current('Remove Area')}</ButtonWithIcon>
            </InputAndButtonContainer>
          </LineSelectFieldContainer>
          {loading ? 
            <SpinnerContainer>
              <Spinner size='large' styling='primary' />
              <ResultText>{tRef.current('Loading')}</ResultText>
            </SpinnerContainer>
            :
            <>
              <LineUIContainer>
                <LineUILeftPanel>
                  <SidePaddingDiv>
                    <StyledSmallInputWithLabel
                      fieldState='default'
                      label={tRef.current('Area Reference')}
                      name='zoneReference'
                      length='100%'
                      maxLength={24}
                      value={selectedAreaIndex === 0 ? '' : congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1]?.name}
                      onChange={handleAreaName}
                      disabled={selectedAreaIndex === 0 ? true : false}
                    />
                  </SidePaddingDiv>
                  <DividerWithMargin />
                  <InputContainer>
                    <LabelTextContainer>
                      <LabelText htmlFor='' labelText={`${tRef.current('Points')}:`} />
                      <LabelText htmlFor='' labelText={String(selectedAreaIndex === 0 ? 4 : congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1]?.points.length)} />
                    </LabelTextContainer>
                    <AreaInfoButtonDiv>
                      <PointsButton disabled={selectedAreaIndex === 0 ? true : (congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1]?.points.length === 3 ? true : false)} onClick={handleRemovePoint}><RemoveSVG /></PointsButton>
                      <PointsButton disabled={selectedAreaIndex === 0} onClick={handleAddPoint}><AddSVG /></PointsButton>
                    </AreaInfoButtonDiv>
                  </InputContainer>
                  <AreaInfoDivider />
                  <DetectionThreshold isReadOnly={false} index={0} detection='Congestion Detection' unit='cars' enabled={congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1]?.congestion_enabled} threshold={selectedAreaIndex === 0 ? 30 : congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1]?.congestion_threshold} {...{ congestionStopData, onChangeCongestionStopData, selectedAreaIndex }} />
                  <AreaInfoDivider />
                  <DetectionThreshold isReadOnly={false} index={0} detection='Stop Detection' unit='seconds' enabled={congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1]?.stop_enabled} threshold={selectedAreaIndex === 0 ? 30 : congestionStopData?.congestion_and_stop_settings[selectedAreaIndex - 1]?.stop_threshold} {...{ congestionStopData, onChangeCongestionStopData, selectedAreaIndex }} />
                </LineUILeftPanel>
                <LineUIRightPanel>
                  <LineViewer
                    streamName={streamName} 
                    latestSnapshotText={tRef.current('Latest Snapshot')}
                    zoomText={tRef.current('Zoom')}
                    rtcText={tRef.current('Live Feed')}
                    onLineChangeCallback={onLineChange}
                    onMediaLoadedCallback={onMediaLoadedCallback}
                    linesData={stateDetails}
                    showExclusionArea={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                    showExclusionAreaOnModal={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                    exclusionAreas={(exclusionAreas.enabled && exclusionAreas.areas.length > 0) ? exclusionAreas.areas : []}
                    options={Options}
                    width='700px'
                    height='388px'
                    fromExclusionPage
                    onLineClickCallback={onLineClickCallback}
                  />
                </LineUIRightPanel>
                {isMediaLoaded && congestionStopData?.congestion_and_stop_settings.length !== 0 &&
                  <LineUIActionsDisplayContainer>
                    <IconWrapper>
                      <Icon icon='PasswordShow' size={16} color='dimmed' />
                    </IconWrapper>
                    <SelectAreaShow changeCallback={(e) => handleAreaShow(e)} disabled={false} isCompact value={showAllOrSelectedOption}>
                      {['Only Active Area', 'Show All'].map((item, index) => {
                        return (
                          <option key={index} value={item}>{tRef.current(item)}</option>);
                      })}
                    </SelectAreaShow>
                  </LineUIActionsDisplayContainer>}
              </LineUIContainer>
            </>}
        </LineSettingsContainer>
      </Container>
    </MainContainer>
  );
};

export default CongestionStopDetection;