import React, { FC, ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { EDGE_API_BASE_URL, ENABLE_ALERTS, LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL, STATUS_ALERT_DETAILS } from '../constants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Icon, LineReducer, LineSetContext, LineUI, usePoll, useMediaModal, Spinner} from 'scorer-ui-kit';
import { IPointSet, IVector2, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import styled, { css, keyframes } from 'styled-components';
import { getStatusCategory, getStatusColor } from 'utils';
import i18n from 'i18n';
import AwaitingStreamEn from '../svg/img_awaiting_stream_en.jpg';
import AwaitingStreamJp from '../svg/img_awaiting_stream_jp.jpg';
import BoxModal from './BoxModal';
import isEqual from 'lodash.isequal';

type IStatusDot = 'caution' | 'danger' | 'good' | 'neutral' | 'highlight';

const AnimateOne = keyframes`
  50% {box-shadow: 0.2px 0.2px 0.2px 8px rgb(239, 120, 120);}
  100% { box-shadow: 0.2px 0.2px 0.2px 8px rgb(239, 120, 120);}
`;

const AnimateTwo=keyframes`
  50%{box-shadow: 0.2px 0.2px 0.2px 8px rgb(239, 120, 120);}
  100%{box-shadow: 0.2px 0.2px 0.2px 8px white;}
`;

const Container = styled.div<{ disabled: boolean, blinking: number | null }>`
  height: 303px;
  width: 460px;
  position: relative;
  object-fit: cover;
  box-shadow: 1px 1px #cccc;
  border-radius: 3px;
  overflow: hidden;
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
  ${({blinking}) => blinking === 40001 && css `
    animation : ${AnimateOne} 2s linear 2,${AnimateTwo} 2s linear infinite;
  `}
  @media screen and (max-width:1367px) {
    height: 269px;
    width: 400px;
  }
`;

const ImageBox = styled.div<{ showPointer:boolean }>`
  width: 100%;
  height: 259px;
  min-width: 400px;
  position: relative;
  background: #ddd;
  margin: 0;
  padding: 0;
  @media screen and (max-width:1280px) {
    height: 226px;
  }
  border-radius: 3px 3px 0 0;
  > div {
    height: inherit;
    > img {
      height: inherit;
      object-fit: cover;
    }
  }
  ${({ showPointer }) => showPointer && css`
    cursor: pointer;
  `}
`;

const LineUIImageWrapperModal = styled.div`
  width: 100%;
  height: inherit;
  position: relative;
  & > div {
    height: inherit;
    > img {
      max-height: 80vh;
    }
  }
  background-color: hsl(202deg 15% 91%);
`;

const ImageFormatter = styled.div`
  position: relative;
  img{
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 100px);
  }
  svg{
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 100px);
  }
`;

const DetailsBox = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 40px;
  background: white;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  z-index: 1;
  @media (max-width:1438px) and (min-width:1280px) {
    min-width: 400px;
    box-shadow: 1px 1px #cccc;
  }
`;

const LabelText = styled.p`
  font-size: 16px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  margin: 0px;
`;

const LabelTextSmall = styled(LabelText)`
  font-size: 10px;
  color: #ffffff;
`;
const LabelTextMedium = styled(LabelText)`
  font-size: 12px;
  color: #ffffff;
`;

const StatusBar = styled.div<{ color: string }>`
  width: 100%;
  height: 4px;
  background: ${props => props.color};
  position: relative;
  z-index: 1;
  @media (max-width:1438px) and (min-width:1280px) {
    min-width: 400px;
  }
`;

const LineAndNotificationIcon = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 100%;
  padding: 11px;
  border-left: 1px solid #cccc;
  cursor: pointer;
`;

const NotificationsIconContainer = styled(IconContainer)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  > :nth-child(1) {
    width: 70%;
    height: 70%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f3f4;
    border-radius: 3px;
  }
`;

const StatusDot = styled.div<{ color?: IStatusDot }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
  ${({theme:{animation}}) => css`
    transition: background-color ${animation.speed.slow} ${animation.easing.primary.easeInOut};
  `}
  background-color: ${({ theme, color }) => color ? theme.colors.status[color] : 'hsla(0, 0%, 91.8%, 0)'};
`;

const WarningBox = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height:46px;
  bottom: 40px;
  background-color: ${({ color }) => color};
  opacity: 0.65;
  cursor: pointer;
  @media (max-width:1438px) and (min-width:1280px) {
    min-width: 400px;
  }
  z-index: 1;
`;

const StatusText = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  color: white;
`;

const StatusIconBox = styled.div`
  padding: 10px;
  width: 33px;
  height: 42px;
`;

const CameraTextBox = styled.div`
  flex: 1;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SpinnerContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor? backgroundColor: '#a6a6a6'}
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  @media (max-width:1438px) and (min-width:1280px) {
    height: 253px;
  }
`;

const FormatterSvg = styled.div`
  @media (max-width:1438px) and (min-width:1280px) {
    svg {
      width: 90%;
    }
  }
  display: flex;
    justify-content: space-around;
`;

const ImageNone = styled.img`
  height: 300px;
  min-width: 400px;
  object-fit: cover;
`;

const ShapePath = styled.path<{ fillColor: string }>`
  fill: ${({ fillColor }) => fillColor };
  fill-opacity: 0.45;
  stroke-linejoin: round;
`;

const ShapeSVG = styled.svg<{width: string, height: string}>`
  width: ${({width}) => width};
  height: ${({height}) => height};
  overflow: visible;
`;

const ShapeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: 1;
  pointer-events: none;
`;

interface IAlgoConfig {
  [key: string]: any
}

interface ICameraData {
    camera_name: string,
    camera_type: string,
    stream_name: string,
    alert_status: null | number,
    video_configuration: any,
    status: {
      status_category: string
      status_code: number
    }
    status_code: number
    algorithm_configs: IAlgoConfig
  }

interface ICard {
  options?: LineUIOptions;
  data: ICameraData | any;
}

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

const isPolygon = (algorithm: string): boolean => {
  switch (algorithm) {
  case 'congestion_and_stop_detection':
  case 'reverse_run':
  case 'exclusion_area':
    return true;
    
  case 'frequent_lane_change':
  case 'low_speed_detection':
  case 'traffic_counter':
  default:
    return false;
  }
};

interface IImageDetails {
  x: number,
  y: number
}

const Card: FC<ICard> = ({ options = {}, data }) => {

  const [showLine, setShowLine] = useState<boolean>(false);
  const [state, dispatch] = useReducer(LineReducer, []);
  const [image, setImage] = useState('');
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const [prevImageDetails, setPrevImageDetails] = useState<IImageDetails>();
  const [noImage, setNoImage] = useState(false);
  const { t } = useTranslation(['CommonDict']);
  const { push } = useHistory();
  const { isMediaUrlValid } = useMediaModal();
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const lang = i18n.language === 'ja' ? 'ja' : 'en';
  const [hasConfigEnabled, setConfigEnabled] = useState<boolean>(false);
  const [lineLoadingState, setLineLoadingState] = useState<IPointSet[]>([]);
  const {alert_status = null, algorithm_configs = {}} = data as ICameraData;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scalingFactor, setScalingFactor] = useState(1);

  useEffect(() => {
    const cameraName = localStorage.getItem('showLine_' + data?.stream_name.toString());
    Object.entries(localStorage).map((i: string[]) => {
      if(i[0] === 'showLine_'+ data?.camera_name.toString()) {
        setShowLine(cameraName === 'true');
      }
      return i;
    });
  },[data]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}/stacks/${data.stream_name}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        if (res.data.byteLength > 5) {
          const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
          const isImageValid = await isMediaUrlValid(imgBase64, 'img');
          if (isImageValid === true) {
            const img = new Image();
            img.src = imgBase64;
            setImage(imgBase64);
            setImageDetails({
              x: img.naturalWidth,
              y: img.naturalHeight
            });
            setPrevImageDetails({
              x: img.naturalWidth,
              y: img.naturalHeight
            });
            setNoImage(false);
            setImageLoading(false);
          }
        } else {
          setImage('');
          setNoImage(true);
          setImageLoading(false);
        }
      } else {
        setNoImage(true);
        setImageLoading(false);
        setImageLoading(false);
      }
    } catch (err) {
      setNoImage(true);
      console.error(err);
    }
  }, [data, isMediaUrlValid]);

  usePoll(async () => {
    await getCameraImage();
  }, (LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL * 1000));

  const moveExlusionAreasBack = useCallback((state: IPointSet[]) => {
    const exclustionZones = state.filter(item => item.areaFillColor !== undefined);
    const restState = state.filter(item => item.areaFillColor === undefined);
    return ([...exclustionZones, ...restState]);
  }, []);

  const getScaledLine = useCallback((state: IPointSet[]): IPointSet[] => {
    let doScale = false;
    doScale = state.some(({points}) => {
      return points.some(point => (point.x > imageDetails.x || point.y > imageDetails.y));
    });

    if (!doScale) return state;

    const scalingFactor = imageDetails.x/1920;
    setScalingFactor(scalingFactor);

    const scaledState = state.map(line => {
      return ({
        ...line,
        points: line.points.map(point => {
          return ({
            x: point.x * scalingFactor,
            y: point.y * scalingFactor
          });
        })
      });
    });
    return scaledState;
  }, [imageDetails]);

  useEffect(() => {
    const state: IPointSet[] = [];
    try {
      if (algorithm_configs !== undefined && Object.keys(algorithm_configs).length > 0) {
        let isConfigEnabled = false;
        for (let index = 0; index < Object.keys(algorithm_configs).length; index++) {
          const element = Object.values(algorithm_configs)[index];
          if (element.enabled === true) {
            setConfigEnabled(true);
            isConfigEnabled = true;
            break;
          }
        }

        if (isConfigEnabled === false) return;

        Object.keys(algorithm_configs).forEach(key => {
          const selectedConf = algorithm_configs[key];
          if (selectedConf !== undefined) {
            const config = Object.values(selectedConf)[0] as any[];
            if (isPolygon(key)) {
              if (config.length > 0 && selectedConf.enabled) {
                const areaState = config.map(({points}) => ({
                  points,
                  styling: 'primary',
                  readOnly: true,
                  ...((key !== 'exclusion_area') ? {} : {
                    areaFillColor: '#0B0B0B',
                    areaTransparencyLevel: 50
                  })
                }));
                state.push(...areaState);
              }
            } else {
              if (key === 'frequent_lane_change') {
                if (config.length > 0 && selectedConf.enabled) {
                  const fState: IPointSet[] = [];
                  config.forEach((set, index) => {
                    Object.values(set.lines).forEach((item: any) => {
                      const line = item.points as IVector2[];
                      fState.push({
                        points: line,
                        styling: index%2 === 0 ? 'primary' : 'secondary',
                        readOnly: true,
                      });
                    });
                  });
                  state.push(...fState);
                }
              }
              else if (key === 'traffic_counter') {
                const tState: IPointSet[] = [];
                if (config.length > 0 && selectedConf.enabled) {
                  config.forEach(set => {
                    Object.values(set.lines).forEach((line: any, index) => {
                      tState.push({
                        points: [{x: line.points.start_x, y: line.points.start_y}, {x: line.points.end_x, y: line.points.end_y}],
                        styling: index%2 === 0 ? 'primary' : 'secondary',
                        readOnly: true,
                      });
                    });
                  });
                }
                state.push(...tState);
              }
              else if (key === 'low_speed_detection') {
                const fState: IPointSet[] = [];
                if (config.length > 0 && selectedConf.enabled) {
                  config.forEach((set, index) => {
                    Object.values(set.lines).forEach((item) => {
                      const line = item as IVector2[];
                      fState.push({
                        points: line,
                        styling: index%2 === 0 ? 'primary' : 'secondary',
                        readOnly: true,
                      });
                    });
                  });
                }
                state.push(...fState);
              }
            }
          }
        });
      }
      setConfigEnabled(state.length > 0);
      const updatedState: IPointSet[] = moveExlusionAreasBack(state);
      if (isEqual(prevImageDetails, imageDetails)) {
        const scaledLine: IPointSet[] = getScaledLine(updatedState);
        setLineLoadingState(scaledLine);
      }
    } catch (error) {
      setLineLoadingState([]);
      console.error(error);
    }
  }, [showLine, algorithm_configs, imageDetails, prevImageDetails, moveExlusionAreasBack, getScaledLine]);

  const onClickToShowLine = useCallback(() => {
    if (!noImage) {
      const showLineStatus = !showLine;
      localStorage.setItem('showLine_' + data?.stream_name, showLineStatus.toString());
      setShowLine(prev => !prev);
    } else {
      const showLineStatus = false;
      localStorage.setItem('showLine_' + data?.stream_name, showLineStatus.toString());
      setShowLine(false);
      return false;
    }
  }, [noImage, showLine, data]);

  const goToCameraDetail = useCallback(() => {
    push(`/cameras/camera-details/${data.stream_name}/overview`);
  }, [data.stream_name, push]);

  const getIcon = (statusCategory: string) => {
    if(statusCategory === 'warning') {
      return 'BigWarning';
    } else if(statusCategory === 'Error') {
      return 'Critical';
    } else {
      return 'Warning';
    }
  };

  const getBannerDetails = useCallback((): ReactElement | null => {
    if (data.status.status_category && data.status.status_code) {
      if (getStatusCategory(data.status.status_code) === 'Ok') {
        return null;
      } else {
        const message = STATUS_ALERT_DETAILS[getStatusCategory(data.status.status_code)];
        return (
          <WarningBox color={getStatusColor(data.status.status_category)} onClick={goToCameraDetail}>
            <StatusIconBox>
              <Icon size={18} color='inverse' icon={getIcon(data?.status.status_category)} />
            </StatusIconBox>
            <StatusText>
              <LabelTextMedium>{t(message.title)}</LabelTextMedium>
              <LabelTextSmall>{t(message.message)}</LabelTextSmall>
            </StatusText>
          </WarningBox>
        );
      }
    }
    return null;
  }, [data, t, goToCameraDetail]);

  const getNotificationIcon = useCallback((alert_status: number | null): string => {
    let icon = 'Notifications';
    switch (alert_status) {
    case 40002:
      icon = 'NotificationsAlert';
      break;
    case 40003:
      icon = 'NotificationsSnooze';
      break;
    default:
      break;
    }
    return icon;
  }, []);

  const handleOnNotificationClick = useCallback(() => {
    push('/cameras/camera-details/' + data.stream_name + '/alerts?selectedTab=alerts');
  }, [push, data.stream_name]);

  return (
    <Container blinking={alert_status} disabled={data.status_code === 20100}>
      <ImageBox
        showPointer={!noImage}
        onClick={() => { (!noImage) && setIsModalOpen(true); }}
      >
        {noImage ?
          <FormatterSvg>
            {lang==='ja' ?
              <ImageNone src={AwaitingStreamJp} /> : <ImageNone src={AwaitingStreamEn} />}
          </FormatterSvg>
          : 
          <>
            {imageLoading ?
              <SpinnerContainer>
                <Spinner size='large' styling='primary' />
              </SpinnerContainer> :
              <LineSetContext.Provider value={{ state, dispatch }}>
                <LineUI 
                  options={{...options, boundaryOffset: Math.round(imageDetails.x * 2.5 / 100)}} 
                  src={image} 
                  hasClickSensingBorder={false}
                />
                {
                  (showLine && hasConfigEnabled && image !== '') ?
                    <ShapeContainer>
                      <ShapeSVG width='100%' height='100%' viewBox={`0 0 ${imageDetails.x} ${imageDetails.y}`}>
                        {
                          lineLoadingState.map(({points, areaFillColor, styling}, index) => {
                            return (
                              <>
                                <ShapePath
                                  key={index}
                                  fillColor={areaFillColor ? areaFillColor : 'rgb(255, 255, 255, 0.4)'}
                                  d={`M${points.map((point) => `${point.x},${point.y}`).join(' ')}z`}
                                  stroke={areaFillColor ? 'transparent' : 'rgba(0, 0, 0, 0.6)'}
                                  strokeWidth={20 * scalingFactor}
                                />
                                <ShapePath
                                  key={index}
                                  fillColor={areaFillColor ? areaFillColor : 'rgb(255, 255, 255, 0.4)'}
                                  d={`M${points.map((point) => `${point.x},${point.y}`).join(' ')}z`}
                                  stroke={areaFillColor ? 'transparent' : styling === 'secondary' ? '#75f075' : '#00b9ff'}
                                  strokeWidth={12 * scalingFactor}
                                />
                              </>
                            );
                          })
                        }
                      </ShapeSVG>
                    </ShapeContainer>
                    : null
                }
                {(isModalOpen) &&
                  <BoxModal 
                    closeText={t('CLOSE')}
                    isOpen={isModalOpen}
                    onDismiss={() => {setIsModalOpen(false);}}
                    customeComponent={
                      <LineUIImageWrapperModal>
                        <div>
                          <LineSetContext.Provider value={{ state, dispatch }}>
                            <ImageFormatter>
                              <LineUI
                                options={options}
                                src={image==='' ? lang==='ja' ? AwaitingStreamJp : AwaitingStreamEn : image}
                                hasClickSensingBorder={false}
                              />
                              {
                                (showLine && hasConfigEnabled && image !== '') ?
                                  <ShapeContainer>
                                    <ShapeSVG width='100%' height='100%' viewBox={`0 0 ${imageDetails.x} ${imageDetails.y}`}>
                                      {
                                        lineLoadingState.map(({points, areaFillColor, styling}, index) => {
                                          return (
                                            <>
                                              <ShapePath
                                                key={index}
                                                fillColor={areaFillColor ? areaFillColor : 'rgb(255, 255, 255, 0.4)'}
                                                d={`M${points.map((point) => `${point.x},${point.y}`).join(' ')}z`}
                                                stroke={areaFillColor ? 'transparent' : 'rgba(0, 0, 0, 0.6)'}
                                                strokeWidth={11 * scalingFactor}
                                              />
                                              <ShapePath
                                                key={index}
                                                fillColor={areaFillColor ? areaFillColor : 'rgb(255, 255, 255, 0.4)'}
                                                d={`M${points.map((point) => `${point.x},${point.y}`).join(' ')}z`}
                                                stroke={areaFillColor ? 'transparent' : styling === 'secondary' ? '#75f075' : '#00b9ff'}
                                                strokeWidth={5 * scalingFactor}
                                              />
                                            </>
                                          );
                                        })
                                      }
                                    </ShapeSVG>
                                  </ShapeContainer>
                                  : null
                              }
                            </ImageFormatter>
                          </LineSetContext.Provider>
                        </div>
                      </LineUIImageWrapperModal>  
                    }
                  />}
              </LineSetContext.Provider>}
          </>}
      </ImageBox>
      {getBannerDetails()}
      <StatusBar color={(data.status.status_category === 'Running' ? '#87d58f' : data.status.status_category === 'Error' ? '#ef7878' : '#96acbc')} />
      <DetailsBox>
        <CameraTextBox onClick={goToCameraDetail}>
          <LabelText title={t(data?.camera_name)}>{t(data?.camera_name)}</LabelText>
        </CameraTextBox>
        <LineAndNotificationIcon>
          <IconContainer onClick={onClickToShowLine} title={t('Click')}>
            <Icon size={16} color={showLine ? 'primary' : 'dimmed'} icon='FeatureLineUi' />
          </IconContainer>
          {
            (ENABLE_ALERTS && alert_status !== null) && 
              <NotificationsIconContainer onClick={handleOnNotificationClick}>
                <Icon size={16} color='dimmed' icon={getNotificationIcon(alert_status)} />
                {alert_status === 40001 && <StatusDot color='highlight' />}
              </NotificationsIconContainer>
          }
        </LineAndNotificationIcon>

      </DetailsBox>
    </Container>
  );
};

export default Card;