import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import Fonts from './Fonts';
import Style from './style';
import { ThemeProvider } from 'styled-components';
import { ModalProvider, NotificationProvider, } from 'scorer-ui-kit';
import { customTheme } from 'utils/customTheme';
import AlertDrawerProvider from 'Context/AlertDrawerProvider';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <ModalProvider>
        <NotificationProvider>
          <AlertDrawerProvider>
            <Router>
              <App />
            </Router>
          </AlertDrawerProvider>
          <Fonts />
          <Style />
        </NotificationProvider>
      </ModalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
