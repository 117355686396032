import React, {useState, useRef, useEffect, useCallback, ReactElement} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {Button, ButtonWithIcon, Content, Icon, PageHeader, SelectField, Switch, useNotification, SmallInput, useModal, Spinner, useMediaModal} from 'scorer-ui-kit';
import styled, {css} from 'styled-components';
import { isEqual, cloneDeep } from 'lodash';
import { IExclusionAreas, IFrequentLaneData, IFrequentLaneDetails, IFrequentLaneLines, IFrequentLaneDataMapped, IFrequentLaneDetailsMapped, ILinePointsMapped } from 'pages/CameraConfiguration/types';
import { getCamera, getFrequentLaneSettings, saveFrequentLaneSettings, toggleConfigurationAnalysis } from 'services/camerasService';
import { IVector2, IPointSet, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import LineViewer from 'components/LineViewer/configurationLineViewer';
import { laneDetailsChangesCounter } from 'utils';
import DisableConfirmationModal from 'components/DisableConfirmationModal';
import {IToggleAnalysisPayload, ICameraDetails} from '../../interface';
import ImportSettingsModal from './ConfigurationImportModal';
import { EDGE_API_BASE_URL } from '../../constants';
import axios from 'axios';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 54px 0 54px 90px;
  @media screen and (min-width: 1440px) {
    padding: 54px 40px 54px 170px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 940px !important;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const ResultText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.data};
  color: #5a6269;
  margin-top: -3px;
  padding: 0;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 941px !important;
  justify-content: space-between;
  margin-top: -22px;
  padding: 0;
  p {
    margin-top: -14px;
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
  > div > div {
    > h1 {
      margin-left: -1px;
    }
    > :nth-child(2) {
      bottom: 2px;
      left: -45px;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 0;
  > button {
    width: 133px;
  }
  > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  & > :nth-child(2) {
    width: 135px;
    margin-right: -2px;
    padding: 0 18px;
  }
`;

const AreaContainer = styled.span`
  min-width: 200px;
  max-width: 750px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
`;

const UnsavedChangesText = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.ui};
`;

const ToggleDetection = styled.div<{ state?: string }>`
  display: flex;
  align-items: center;
  margin-top: -4px;
  gap: 0 12px;
  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
    `}
  margin-bottom: 15px;
`;

const SmallLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
`;

const DividerHeader = styled.div`
  height: 1px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #efefef;
`;

const Divider = styled.div<{ margin: string }>`
  height: 1px;
  margin: ${({ margin }) => margin};
  border-radius: 3px;
  background-color: #efefef;
`;

const LineSelectFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  position: relative;
  & > div:first-child {
    display: flex;
    position: absolute;
    top: 6px;
    left: -24px;
  }
`;

const StyledSelectField = styled(SelectField)`
  width: 229px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  } // to align drop down icon
`;

const SetAddRemoveButtons = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 11px;

  > button {
    padding: 0 8px;
    > div > :first-child {
      padding-left: 12px;
    }
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const LineConfigContainer = styled.div`
  width: 100%;
  min-height: 390px;
  margin: 30px 0 15px;
  display: flex;
  border-radius: 3px;
  border: solid 1px #eee;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.02);
  background-color: rgba(252, 252, 253, 0.85);
  position: relative;

  &::before {
    content: "";
    width: 14.5px;
    height: 51px;
    position: absolute;
    top: -28px;
    left: -16px;
    border-left: 2px solid #d9dad9;
    border-bottom: 2px solid #d9dad9;
  }
`;

const LineConfigLeft = styled.div`
  flex-shrink: 0;
  width: 239px;
  min-height: 390px;
`;

const PaddingDiv = styled.div`
  padding: 0 20px 0 19px;
`;

const StyledSmallInputWithLabel = styled(SmallInput)`
  margin-top: 15px;
  > label {
    width: 100%;
    margin-bottom: 0;
    > span {
      margin-bottom: 4px;
    }
  }
`;

const ReferenceInput = styled(StyledSmallInputWithLabel)`
  > label {
    > span {
      letter-spacing: -0.02px;
    }
  }
  input:disabled {
    cursor: not-allowed;
  }
`;

const ThreholdInput = styled(StyledSmallInputWithLabel)<{ lang: string }>`
  margin-top: 20px;
  > label {
    > span {
      font-weight: bold;
      margin-bottom: 5px;
    }
    > div > :last-child {
      margin-right: -3px;
      margin-top: 1px;
    }
  }
  input {
    padding: ${({ lang }) => lang === 'ja' ? '0 130px 1px 9px' : '0 106px 1px 9px'};
  }
`;

const DurationInput = styled(StyledSmallInputWithLabel)<{ lang: string }>`
  margin-top: 14.5px;
  > label {
    > span {
      margin-bottom: 5px;
      margin-left: 1px;
    }
    > div > :last-child {
      margin-right: -3px;
      margin-top: 1px;
    }
  }
  input {
    padding: ${({ lang }) => lang === 'ja' ? '0 35px 1px 9px' : '0 60px 1px 9px'};
  }
`;

const DividerSmallLabel = styled(SmallLabel)`
  margin-top: 1px;
  margin-left: 1px;
  > span {
    margin-left: 4px;
    font-weight: bold;
  }
`;

const IconButton = styled(Button)`
  width: 31px;
  height: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
  }
`;

const DividerButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;

  > :last-child {
    width: 29px;
  }
`;

const DividerInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LineConfigRight = styled.div`
  flex: 1;
  flex-shrink: 0;
  border-radius: 3px;
`;

const LineSettingsContainer = styled.div`
  width: 100%;
`;

const CancelModalContainer = styled.div`
  padding: 20px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  > #NoButton:focus {
    outline: 2px solid #838383;
  }

  > #YesButton:focus {
    outline: 2px solid #838383;
  }
`;

const LineUIActionsDisplayContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 396.5px;
  height: max-content;
  & > div:nth-child(1) {
    margin-top: -3px;
  }
  & > div:nth-child(2) {
    margin-top: -14px;
  }
  position: absolute;
  z-index: 10;
  bottom: -34px;
`;

const SelectZoneShow = styled(SelectField)`
  width: 138px;
  height: 30px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 25px 0 8px !important;
  margin-top: 5px;
  font-family: 'Lato' !important;
  font-style: normal !important;
  color: #8b9196 !important;
  letter-spacing: -0.12px;
  & + div {
    right: 14.5px !important;
  }
`;

const InputAndButtonContainer = styled.div<{ gap: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: ${({ gap }) => gap};
  & > button {
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const IconWrapper = styled.div``;

interface IParams {
  streamName: string;
}

interface IDict {
  [key: string]: string;
}

interface IProps {}

const options: LineUIOptions = {
  showSetIndex: false,
  showPoint: true,
  showDirectionMark: false,
  showMoveHandle: false,
  showPointHandle: true,
  setIndexOffset: 0,
  boundaryOffset: 0
};

interface IImageResponse {
  data: ArrayBuffer;
  status: number;
}

interface ILinePoints {
  points: IVector2[];
  changeLineStyle: boolean;
}

type FrequentLaneRes = IFrequentLaneDataMapped;
type FrequentLaneKeys = keyof FrequentLaneRes;
type LaneKeys = keyof IFrequentLaneDetailsMapped;
type PointsKeys = keyof ILinePointsMapped;
type CheckTypeValue = number | string | boolean | IFrequentLaneDetailsMapped[] | ILinePointsMapped | undefined;

const checkType = (key: string, value: CheckTypeValue): boolean => {
  switch (key) {
  case 'frequent_lane_change':
    return Array.isArray(value);
  case 'name':
    return typeof value === 'string';
  case 'divider_crossing_count':
    return typeof value === 'number';
  case 'happen_within_seconds':
    return typeof value === 'number';
  case 'lane':
    return true;
  case 'x0':
  case 'y0':
  case 'x1':
  case 'y1':
    return typeof value === 'number';
  default:
    return false;
  }
};

const LinesSets=[
  {
    line1:[
      {
        x: 100,
        y: 50
      },
      {
        x: 100,
        y: 300
      }
    ],
    line2: [
      {
        x: 200,
        y: 50
      },
      {
        x: 200,
        y: 300
      }
    ]
  },
  {
    line1:[
      {
        x: 300,
        y: 50
      },
      {
        x: 300,
        y: 300
      }
    ],
    line2: [
      {
        x: 400,
        y: 50
      },
      {
        x: 400,
        y: 300
      }
    ]
  },
  {
    line1:[
      {
        x: 500,
        y: 50
      },
      {
        x: 500,
        y: 300
      }
    ],
    line2: [
      {
        x: 600,
        y: 50
      },
      {
        x: 600,
        y: 300
      }
    ]
  },
  {
    line1:[
      {
        x: 700,
        y: 50
      },
      {
        x: 700,
        y: 300
      }
    ],
    line2: [
      {
        x: 800,
        y: 50
      },
      {
        x: 800,
        y: 300
      }
    ]
  },
  {
    line1:[
      {
        x: 900,
        y: 50
      },
      {
        x: 900,
        y: 300
      }
    ],
    line2: [
      {
        x: 1000,
        y: 50
      },
      {
        x: 1000,
        y: 300
      }
    ]
  },
  {
    line1:[
      {
        x: 1100,
        y: 50
      },
      {
        x: 1100,
        y: 300
      }
    ],
    line2: [
      {
        x: 1200,
        y: 50
      },
      {
        x: 1200,
        y: 300
      }
    ]
  },
  {
    line1:[
      {
        x: 100,
        y: 350
      },
      {
        x: 100,
        y: 600
      }
    ],
    line2: [
      {
        x: 200,
        y: 350
      },
      {
        x: 200,
        y: 600
      }
    ]
  },
  {
    line1:[
      {
        x: 300,
        y: 350
      },
      {
        x: 300,
        y: 600
      }
    ],
    line2: [
      {
        x: 400,
        y: 350
      },
      {
        x: 400,
        y: 600
      }
    ]
  },
  {
    line1:[
      {
        x: 500,
        y: 350
      },
      {
        x: 500,
        y: 600
      }
    ],
    line2: [
      {
        x: 600,
        y: 350      },
      {
        x: 600,
        y: 600
      }
    ]
  },
  {
    line1:[
      {
        x: 700,
        y: 350
      },
      {
        x: 700,
        y: 600
      }
    ],
    line2: [
      {
        x: 800,
        y: 350
      },
      {
        x: 800,
        y: 600
      }
    ]
  }
];

const FrequentLane: React.FC<IProps> = () => {
  const { t, i18n } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const {push} = useHistory();
  const { sendNotification } = useNotification();
  const { isModalOpen, createModal, setModalOpen } = useModal();
  const sendNotificationRef = useRef(sendNotification);
  const [unSavedChanges, setUnSaveChanges] = useState<number>(0);
  const { streamName } = useParams<IParams>();
  const [cameraDetails, setCameraDetails] = useState<ICameraDetails>();
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [isDetectionEnabled, setIsDetectionEnabled] = useState(false);
  const [selectedSetIndex, setSelectedSetIndex] = useState(-1);
  const [showAllOrSelected, setShowAllOrSelected] = useState('Show All');
  const [dividersCount, setDividersCount] = useState(2);
  const [setReference, setSetReference] = useState('');
  const [laneDetails, setLaneDetails] = useState<IFrequentLaneDetails[]>([]);
  const [exclusionAreas, setExclusionAreas] = useState<IExclusionAreas>({enabled: false, areas: []});
  const [prevLaneDetails, setPrevLaneDetails] = useState<IFrequentLaneData>({enabled: false, frequent_lane_change_settings: []});
  const [stateDetails, setStateDetails] = useState<IPointSet[]>([]); 
  const stateRef = useRef<IPointSet[]>([]);
  const [toggleAnalysis, setToggleAnalysis] = useState(false);
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const { isMediaUrlValid } = useMediaModal();
  const [image, setImage] = useState('');
  const [isImporting, setIsImporting] = useState(true);

  const fetchCamera = useCallback(() => {
    getCamera(streamName).then((res) => {
      if (res.status === 200 && res.data.data) {
        setCameraDetails(res.data.data);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    }).catch(() => {
      sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system')});
    });
  }, [streamName]);

  const getLaneDetails = useCallback(async () => {
    try {
      const res = await getFrequentLaneSettings(streamName);
      if (res.data.status_code === 0 && res.data.data) {
        const {enabled, frequent_lane_change_settings} = res.data.data;
        if (res.data.exclusion_areas) {
          const {enabled: enabledExclusion, exclusion_zone_settings = []} = res.data.exclusion_areas;
          setExclusionAreas({
            enabled: enabledExclusion,
            areas: exclusion_zone_settings.map(area => ({points: area.points}))
          });
        }
        const lanes = frequent_lane_change_settings.map(lane => {
          if (lane.index !== undefined) {
            const {index, ...rest} = lane;
            return ({...rest});
          } else {
            return lane;
          }
        });
        setPrevLaneDetails({enabled, frequent_lane_change_settings: lanes});
        setIsDetectionEnabled(enabled);
        setLaneDetails(lanes);
      } else {
        sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      }
    } catch (error) {
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
    } finally {
      setLoading(false);
    }
  }, [streamName]);

  useEffect(() => {
    fetchCamera();
    getLaneDetails();
  }, [fetchCamera, getLaneDetails]);
  
  // compare changes
  useEffect(() => {
    const currentDetails:IFrequentLaneData = {enabled: isDetectionEnabled, frequent_lane_change_settings: laneDetails};
    let changes = 0;
    changes = laneDetailsChangesCounter(prevLaneDetails, currentDetails);
    setUnSaveChanges(changes);
  }, [isDetectionEnabled, laneDetails, prevLaneDetails]);

  const handleCancel = useCallback(() => {
    setSelectedSetIndex(-1);
    setModalOpen(false);
    setLaneDetails(prevLaneDetails.frequent_lane_change_settings);
  }, [prevLaneDetails, setModalOpen]);

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('NoButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('NoButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'NoButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('NoButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('NoButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const getCancelModal = useCallback(() => {
    return (
      <CancelModalContainer>
        <CancelConfirmText>{tRef.current('Are you sure you want to cancel the changes?')}</CancelConfirmText>
        <ModalButtonContainer>
          <Button id='NoButton' design='secondary' onClick={() => {setModalOpen(false);}}>{tRef.current('No')}</Button>
          <Button id='YesButton' onClick={() => handleCancel()}>{tRef.current('Yes')}</Button>
        </ModalButtonContainer>
      </CancelModalContainer>
    );
  }, [handleCancel, setModalOpen]);

  const goToPreviousPage = useCallback(() => {
    const currentLaneDetails:IFrequentLaneData = {enabled: isDetectionEnabled, frequent_lane_change_settings: laneDetails};
    if(isEqual(currentLaneDetails.frequent_lane_change_settings, prevLaneDetails.frequent_lane_change_settings)){
      push('/cameras/camera-configuration/' + streamName);
      return;
    }
    const CancelModal: ReactElement = getCancelModal();
    createModal({ isCloseEnable: false, width: '400px', padding: false, customComponent: CancelModal });
  }, [streamName, prevLaneDetails, isDetectionEnabled, laneDetails, createModal, getCancelModal, push]);

  // Find duplicated Set Reference
  const firstDuplicateIndex = useCallback((list) => {
    const dict: IDict = {};
    for (const [index, value] of list.entries()) {
      if (Object.prototype.hasOwnProperty.call(dict, value)) {
        return dict[value];
      }
      dict[value] = index;
    }
    return -1;
  }, []);

  const validateData = useCallback(() => {
    let returnValue = true;

    for (let index = 0; index < laneDetails.length; index++) {
      const {line_set_name, threshold, duration} = laneDetails[index];
      
      if (line_set_name === '') {
        sendNotificationRef.current({type: 'error', message: t('Please enter set reference for set #{X}').replace('{X}', (index + 1 + ''))});
        returnValue = false;
        break;
      }

      if (threshold === ''|| threshold === 0) {
        sendNotificationRef.current({ type: 'error', message: `${t('Trigger detection should be > 0 for {X}').replace('{X}', (line_set_name + ''))}` });
        returnValue = false;
        break;
      }
      
      if (duration === ''|| duration === 0) {
        sendNotificationRef.current({ type: 'error', message: `${t('Happen within should be > 0 for {X}').replace('{X}', (line_set_name + ''))}` });
        returnValue = false;
        break;
      }
    }

    const duplicateIndex = firstDuplicateIndex(laneDetails.map(set => set.line_set_name));

    if (duplicateIndex !== -1) {
      sendNotificationRef.current({ type: 'error', message: `${t('{X} already exists').replace('{X}', laneDetails[parseInt(duplicateIndex)].line_set_name)}` });
      returnValue = false;
    }

    return returnValue;
  }, [laneDetails, t, firstDuplicateIndex]);

  const onSave = useCallback((isDetectionEnabled: boolean) => {
    if (!validateData()) return;
    setSaveLoading(true);
    const updatedData = laneDetails.map((item) => {
      if (item.styling === 'danger') {
        item.styling = 'primary';
      }
      return item;
    });

    const payload: IFrequentLaneData = {
      enabled: isDetectionEnabled,
      frequent_lane_change_settings: updatedData.map((lane, index) => ({index: (index+1), ...lane}))
    };

    saveFrequentLaneSettings(streamName, payload).then((res: { data: { status_code: number; }; }) => {
      if (res.data.status_code === 0) {
        setPrevLaneDetails({
          enabled: isDetectionEnabled,
          frequent_lane_change_settings: updatedData
        });
        sendNotificationRef.current({ type: 'success', message: tRef.current('Settings saved successfully')});
        fetchCamera();
        getLaneDetails();
      } else { 
        sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') }); }
      setSaveLoading(false);
      setSelectedSetIndex(-1);
    }).catch(() => {
      setSaveLoading(false);
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
    });

  }, [streamName, laneDetails, validateData, fetchCamera, getLaneDetails]);
  
  const diableCallBack = useCallback((isEnabled: boolean) => {
    if (isEnabled) {
      setIsDetectionEnabled(isEnabled);
    }
    setModalOpen(false);
    onSave(isEnabled);
  }, [setModalOpen, onSave]);

  const openConfirmationModal = useCallback(() => {
    if (isDetectionEnabled) {
      onSave(true);
    } else {
      const isValidData = validateData();
      if(isValidData){
        const ConfirmationModal: ReactElement = <DisableConfirmationModal onCallback={diableCallBack} />;
        createModal({ isCloseEnable: false, width: 'auto', padding: false, customComponent: ConfirmationModal });
      }
    }
  }, [isDetectionEnabled, validateData, createModal, onSave, diableCallBack]);

  const onAddSet = useCallback(() => {
    const addedNumberList: any[] =[];
    laneDetails.map(d=>{
      const val: string | undefined = d.line_set_name?.trim().match(/\d/g)?.join('');
      if(val){
        addedNumberList.push(parseInt(val));
      }
    });
    const filteredLineNumbers= [1,2,3,4,5,6,7,8,9,10].filter(num=>addedNumberList.indexOf(num)===-1).sort((a, b) => a - b);
   
    const laneNumber = filteredLineNumbers[0];
    const newLane: IFrequentLaneDetails = {
      duration: 1,
      threshold: 1,
      line_set_name: (selectedSetIndex === -1 && setReference !== '') ? setReference : i18n.language === 'ja' ? ('車線 ' + laneNumber) : (`${t('Lane')} ` + laneNumber),
      lines: [
        {
          name: (selectedSetIndex === -1 && setReference !== '') ? setReference : i18n.language === 'ja' ? ('車線 ' + laneNumber) : (`${t('Lane')} ` + laneNumber),
          points: LinesSets[laneNumber-1].line1
        },
        {
          name: (selectedSetIndex === -1 && setReference !== '') ? setReference : i18n.language === 'ja' ? ('車線 ' + laneNumber) : (`${t('Lane')} ` + laneNumber),
          points: LinesSets[laneNumber-1].line2
        }
      ]
    };
    setLaneDetails(prev => ([...prev, newLane]));
    setSelectedSetIndex(laneNumber - 1);
    setSetReference('');
    sendNotificationRef.current({type: 'neutral', message: tRef.current('New line set added'), icon: 'FeatureLineUi'});
  }, [laneDetails, setReference, selectedSetIndex, t, i18n]);

  const onRemove = useCallback((laneIndex) => {
    setLaneDetails(prev => {
      setSelectedSetIndex(() => {
        if ((prev.length - 1) === 0) {
          return (-1);
        } else {
          return (prev.length - 2);
        }
      });
      const temp = prev.filter((_, index) => (laneIndex !== index));
      return temp;
    });
    sendNotificationRef.current({ type: 'neutral', message: tRef.current('Line set removed'), icon: 'FeatureLineUi'});
  }, []);

  const onSetSelect = useCallback((selectedSet) => {
    setSelectedSetIndex(parseInt(selectedSet));
  }, []);

  const onSetReferenceChange = useCallback((setName) => {
    if (selectedSetIndex === -1) {
      setSetReference(setName);
    } else {
      setLaneDetails(prev => {
        const temp = cloneDeep(prev);
        temp[selectedSetIndex].line_set_name = setName;
        return temp;
      });
    }
  }, [selectedSetIndex]);

  const onDividerAdd = useCallback(() => {
    setLaneDetails(prev => {
      const temp = cloneDeep(prev);
      const lineCount = temp[selectedSetIndex].lines.length;
      const setName = temp[selectedSetIndex].line_set_name;
      temp[selectedSetIndex].lines.push({
        name: setName,
        points: [
          {
            x: 100 + (lineCount * 110),
            y: 150
          },
          {
            x: 100 + (lineCount * 110),
            y: 500
          }
        ]
      });
      return temp;
    });
  }, [selectedSetIndex]);

  const onDividerRemove = useCallback(() => {
    setLaneDetails(prev => {
      const temp = cloneDeep(prev);
      temp[selectedSetIndex].lines.pop();
      return temp;
    });
  }, [selectedSetIndex]);

  const onThresholdChange = useCallback((value) => {
    const num = parseInt(value, 10);
    setLaneDetails(prev => {
      const temp = cloneDeep(prev);
      if (num === 100) {
        temp[selectedSetIndex].threshold = 99;
        return temp;
      } else if (num === 0) {
        temp[selectedSetIndex].threshold = 1;
        return temp;
      }
      if (num > 99) {
        return prev;
      }
      if (!isNaN(num) && num > 0) {
        temp[selectedSetIndex].threshold = num;
      } else if (value.length === 0) {
        temp[selectedSetIndex].threshold = '';
      }
      return temp;
    });
  }, [selectedSetIndex]);

  const onDurationChange = useCallback((value) => {
    const num = parseInt(value, 10);
    setLaneDetails(prev => {
      const temp = cloneDeep(prev);
      if (num === 1000) {
        temp[selectedSetIndex].duration = 999;
        return temp;
      } else if (num === 0) {
        temp[selectedSetIndex].duration = 1;
        return temp;
      }
      if (num > 999) {
        return prev;
      }
      if (!isNaN(num) && num > 0) {
        temp[selectedSetIndex].duration = num;
      } else if (value.length === 0) {
        temp[selectedSetIndex].duration = '';
      }
      return temp;
    });
  }, [selectedSetIndex]);

  // Update for every set change
  useEffect(() => {
    const state: IPointSet[] = [];
    if (showAllOrSelected === 'Show All') {
      laneDetails.forEach((lane, setIndex) => {
        const {lines, line_set_name} = lane;
        const val: string | undefined = line_set_name?.trim().match(/\d/g)?.join('');
        const valNum=val?parseInt(val):0;
        lines.forEach(({points, styling}) => {
         
          state.push({
            name: line_set_name,
            points,
            styling: styling === 'danger' ? styling : (valNum % 2 === 0 ? 'secondary' : 'primary'),
            readOnly: (setIndex !== selectedSetIndex),
            showPointHandle: (setIndex === selectedSetIndex)
          });
        });
      });
    } else {
      if (selectedSetIndex !== -1) {
        const {lines, line_set_name} = laneDetails[selectedSetIndex];
        const val: string | undefined = line_set_name?.trim().match(/\d/g)?.join('');
        const valNum=val?parseInt(val):0;
        lines.forEach(({points, styling}) => {
          state.push({
            name: line_set_name,
            points,
            styling: styling === 'danger' ? styling : ((valNum-1) % 2 === 0 ? 'primary' : 'secondary'),
            readOnly: false,
            showPointHandle: true
          });
        });
      }
    }

    setStateDetails(state);
    stateRef.current = state;
  }, [laneDetails, selectedSetIndex, showAllOrSelected]);

  // Update laneDetails when line position change
  const onLineMoveEnd = useCallback((state: IPointSet[]) => {
    if (selectedSetIndex === -1) return;
    const selectedLines: IPointSet[] = state.filter(line => line.showPointHandle);
    const lines: IFrequentLaneLines[] = selectedLines.map(({points, name, styling}) => ({name: name === undefined ? '' : name, points, styling}));
    setLaneDetails(prev => {
      const temp = cloneDeep(prev);
      temp[selectedSetIndex].lines = lines;
      return temp;
    });
  }, [selectedSetIndex]);

  const onLineSelect = useCallback((lineIndex) => {
    const lineName = stateRef.current[lineIndex].name === undefined ? '1' : stateRef.current[lineIndex].name;
    const setIndex = laneDetails.findIndex((lane) => (lane.line_set_name === lineName));
    onSetSelect(setIndex);
  }, [laneDetails, onSetSelect]);

  // below two functions can update for live feed
  const onMediaLoadedCallback = useCallback(() => {
    setIsMediaLoaded(true);
  }, []);

  const handleLaneShow = useCallback((e) => {
    setShowAllOrSelected(e);
  }, []);

  const handleAnalysis = useCallback((value)=>{
    setToggleAnalysis(true);
    const payload: IToggleAnalysisPayload ={
      action: value ? 'enable' : 'disable',
      analysis_type: ['frequent_lane_change']
    };
    toggleConfigurationAnalysis(streamName, payload).then((res: { data: { status_code: number; }; }) => {
      if (res.data.status_code === 0) {
        setIsDetectionEnabled(value);
        sendNotificationRef.current({ type: 'success', message: value ? tRef.current('Analysis enabled successfully') : tRef.current('Analysis disabled successfully')});
        setToggleAnalysis(false);
      } else { 
        setIsDetectionEnabled(!value);
        sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        setToggleAnalysis(false); }
    }).catch(() => {
      setIsDetectionEnabled(!value);
      sendNotificationRef.current({type: 'error', message: tRef.current('Failed to communicate with the system')});
      setToggleAnalysis(false);
    });
  },[streamName]);

  const onExportSettings = useCallback(() => {
    const { frequent_lane_change_settings } = prevLaneDetails;
    const exportLines = frequent_lane_change_settings.map(({ index, styling, line_set_name, threshold, duration, lines, ...rest }) => {
      const linesMapped: ILinePointsMapped[] = lines.map((line) => ({
        x0: line.points[0].x,
        y0: line.points[0].y,
        x1: line.points[1].x,
        y1: line.points[1].y,
      }));
      return { ...rest, name: line_set_name, lane: linesMapped, divider_crossing_count: threshold, happen_within_seconds: duration };
    });
    
    try {
      let modifiedCameraName = cameraDetails?.camera_name || '';
      if (modifiedCameraName.length > 50) {
        modifiedCameraName = modifiedCameraName.substring(0, 50) + '...';
      }
      const fileName = `${modifiedCameraName}_frequent_lane_change.json`;
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({ frequent_lane_change: exportLines }, null, 4));
      const link = document.createElement('a');
      link.setAttribute('href', dataStr);
      link.setAttribute('download', fileName);
      link.click();
      sendNotification({type: 'success', message: t('Settings exported successfully')});
    } catch (error) {
      sendNotification({type: 'error',message: t('An error occurred while exporting data')});
    }
  }, [prevLaneDetails, cameraDetails, sendNotification, t]);

  const getLinePoints = useCallback((pointsArray: IVector2[]): ILinePoints => {
    const boundaryOffset = 0; // 48
    let changeLineStyle = false;

    if (image === '') {
      return { points: pointsArray, changeLineStyle };
    }
  
    // Initialize an array to store the adjusted points
    const adjustedPoints = pointsArray.map(point => {
      let adjustedX = point.x;
      let adjustedY = point.y;
  
      // Check and adjust X coordinate
      if (point.x < boundaryOffset) {
        adjustedX = boundaryOffset;
        changeLineStyle = true;
      } else if (point.x > imageDetails.x - boundaryOffset) {
        adjustedX = imageDetails.x - boundaryOffset;
        changeLineStyle = true;
      }
  
      // Check and adjust Y coordinate
      if (point.y < boundaryOffset) {
        adjustedY = boundaryOffset;
        changeLineStyle = true;
      } else if (point.y > imageDetails.y - boundaryOffset) {
        adjustedY = imageDetails.y - boundaryOffset;
        changeLineStyle = true;
      }
  
      return { x: adjustedX, y: adjustedY };
    });
  
    return { points: adjustedPoints, changeLineStyle };

  }, [image, imageDetails]);

  const importSettings = useCallback((importedTrafficCounterData: IFrequentLaneData) => {
    const { frequent_lane_change_settings = [] } = importedTrafficCounterData;
    
    if (frequent_lane_change_settings.length > 0) {
      const lineInfo: IFrequentLaneDetails[] = [];
      setSelectedSetIndex(-1);
      frequent_lane_change_settings.map((item) => {
        lineInfo.push(
          {
            line_set_name: item.line_set_name,
            threshold: item.threshold,
            duration: item.duration,
            lines: item.lines.map((key) => {
              const point = cloneDeep(key.points);
              const { points, changeLineStyle } = getLinePoints(point);
              return {
                name: item.line_set_name,
                points: points,
                styling: changeLineStyle ? 'danger' : 'primary',
              };
            })
          }
        );
        item.lines.map((key) => {
          const point = cloneDeep(key.points);
          const { points, changeLineStyle } = getLinePoints(point);
          return {
            name: item.line_set_name,
            points: points,
            readOnly: true,
            styling: changeLineStyle ? 'danger' : 'primary',
            showSmallDirectionMark: true
          };
        });
      });
      setLaneDetails(lineInfo);  
    } else {
      setLaneDetails([]);
    }
  }, [getLinePoints]);

  const validateImporting = useCallback((importedTrafficCounterData) => {
    importedTrafficCounterData.frequent_lane_change.forEach((item: {lines: any; lane: any; line_set_name: string; name: any; threshold: number; divider_crossing_count: any; duration: number | string; happen_within_seconds: any; index: number;}) => {
      item.line_set_name = item.name;
      item.threshold = item.divider_crossing_count;
      item.duration = item.happen_within_seconds;
      item.lines = item.lane.map((line: { x0: number; y0: number; x1: number; y1: number; }) => ({
        points: [
          { x: line.x0, y: line.y0 },
          { x: line.x1, y: line.y1 },
        ],
        name: item.line_set_name
      }));
      delete item.lane;
      delete item.name;
      delete item.divider_crossing_count;
      delete item.happen_within_seconds;
    });
  
    const newImportedData = {
      frequent_lane_change_settings: importedTrafficCounterData.frequent_lane_change,
      enabled: prevLaneDetails.enabled
    };
  
    if(isEqual(prevLaneDetails.frequent_lane_change_settings, newImportedData.frequent_lane_change_settings)){
      getLaneDetails();
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    } else {
      setSelectedSetIndex(-1);
      setLaneDetails([]);
      importSettings({...newImportedData});
      setModalOpen(false);
      sendNotification({type: 'success', message: t('Settings imported successfully')});
    }
  }, [t, sendNotification, importSettings, setModalOpen, getLaneDetails, prevLaneDetails]);
  
  const isValidImportFile = useCallback((jsonData) => {
    let isLinesValid = false;
    const validFrequentLaneKeys: FrequentLaneKeys[] = ['frequent_lane_change'];
    const validLaneKeys: LaneKeys[] = ['name', 'lane', 'divider_crossing_count', 'happen_within_seconds'];
    const validPointsKeys: PointsKeys[] = ['x0', 'y0', 'x1', 'y1'];
  
    try {
      let resKeys: FrequentLaneKeys[] = [], laneKeys: LaneKeys[] = [];
      const resObj: FrequentLaneRes = JSON.parse(jsonData);
  
      resKeys = Object.keys(resObj) as Array<keyof typeof resObj>;
  
      if (
        resKeys.every(key => validFrequentLaneKeys.includes(key)) &&
        validFrequentLaneKeys.every(key => (resKeys.includes(key) && checkType(key, resObj[key])))
      ) {
  
        if (resObj.frequent_lane_change.length === 0) return true;
        if (resObj.frequent_lane_change.length > 10){
          sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Maximum 10 sets can be imported')})));
          return false;
        }
  
        const isValidLaneKeys = (laneKeys: LaneKeys[]) => {
          if(laneKeys.every(key => validLaneKeys.includes(key)) && validLaneKeys.every(key => laneKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeys = (pointsKeys: PointsKeys[]) => {
          if(pointsKeys.every(key => validPointsKeys.includes(key)) && validPointsKeys.every(key => pointsKeys.includes(key))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
            return false;
          }
        };

        const isValidPointsKeyTypes = (pointsKeys: PointsKeys[], point: ILinePointsMapped) => {
          if(pointsKeys.every((key) => checkType(key, point[key]))){
            return true;
          } else {
            sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid point keys')})));
            return false;
          }
        };

        const isValidLaneKeyTypes = (laneKeys: LaneKeys[], line: IFrequentLaneDetailsMapped) => {
          return laneKeys.every((key) => {
            if (key === 'lane') {
              if(line[key].length === 0){
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Please add at least one line to the set')})));
                return false;
              } else {
                return true;
              }
            } else if (key === 'name') {
              if(typeof line[key] === 'string' && line[key] !== ''){
                if(line[key].length > 24){
                  sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name should not longer than 24 characters')})));
                  return false;
                } else {
                  return true; 
                }
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Name is required')})));
                return false;
              }
            } else if(key === 'divider_crossing_count'){
              if (typeof line[key] === 'number') {
                const thresholdValue = line[key] as number;
                if (thresholdValue >= 1 && thresholdValue < 100) {
                  return true;
                } else {
                  sendNotification(JSON.parse(JSON.stringify({ type: 'error', message: t('Trigger detection should be > 0 and < 100') })));
                  return false;
                }
              } else {
                sendNotification(JSON.parse(JSON.stringify({ type: 'error', message: t('Trigger detection should be > 0 and < 100') })));
                return false;
              }
            } else if(key === 'happen_within_seconds'){
              if (typeof line[key] === 'number') {
                const durationValue = line[key] as number; // Type assertion to inform TypeScript that it is a number
                if (durationValue >= 1 && durationValue < 1000) {
                  return true;
                } else {
                  sendNotification(JSON.parse(JSON.stringify({ type: 'error', message: t('Happen within should be > 0 and < 1000 sec') })));
                  return false;
                }
              } else {
                sendNotification(JSON.parse(JSON.stringify({ type: 'error', message: t('Happen within should be > 0 and < 1000 sec') })));
                return false;
              }
            } else {
              if(checkType(key, line[key])){
                return true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid data types')})));
                return false;
              }
            }
          });
        };
  
        for (const line of resObj.frequent_lane_change) {
          laneKeys = Object.keys(line) as Array<keyof typeof line>;
          if (isValidLaneKeys(laneKeys) && isValidLaneKeyTypes(laneKeys, line)) {
            for (const lineItem of line.lane) {
              const pointsKeys = Object.keys(lineItem) as Array<keyof typeof lineItem>;
              if (isValidPointsKeys(pointsKeys) && isValidPointsKeyTypes(pointsKeys, lineItem)) {
                isLinesValid = true;
              } else {
                sendNotification(JSON.parse(JSON.stringify({ type: 'error', message: t('Invalid point keys or their type') })));
                isLinesValid = false;
                break;
              }
            }
          } else {
            isLinesValid = false;
            break;
          }
        }
        return isLinesValid;
      } else {
        sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid keys')})));
        return false;
      }
    } catch (error) {
      sendNotification(JSON.parse(JSON.stringify({type: 'error', message: t('Invalid file')})));
      return false;
    }
  }, [sendNotification, t]);  

  const onImportCallback = useCallback((e) => {
    if (e !== null && isValidImportFile(e.result)) {
      validateImporting(JSON.parse(e.result as string));
    } else {
      setModalOpen(false);
    }
  }, [isValidImportFile, validateImporting, setModalOpen]);
  
  // Import Modal
  const getImportModal = useCallback(() => {
    return (
      <ImportSettingsModal {...{ onImportCallback, sendNotification }} />
    );
  }, [sendNotification, onImportCallback]);
  
  const onImportSettings = useCallback(() => {
    setModalOpen(true);
    const importModal: ReactElement = getImportModal();
    createModal({
      isCloseEnable: false,
      width: 'max-length',
      padding: false,
      customComponent: importModal,
    });
  }, [createModal, setModalOpen, getImportModal]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
        const isImageValid = await isMediaUrlValid(imgBase64, 'img');
        if (isImageValid === true) {
          setImage(imgBase64);
          const img = new Image();
          img.src = imgBase64;
          setImageDetails({x: img.width, y: img.height});
        }
        setIsImporting(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [streamName, isMediaUrlValid]);

  useEffect(() => {
    if(isImporting){
      getCameraImage();
    }
  },[isImporting, getCameraImage]);

  return (
    <>
      {
        (loading) ?
          <SpinnerContainer>
            <Spinner size='large' styling='primary' />
            <ResultText>{t('Loading')}</ResultText>
          </SpinnerContainer>
          :
          <MainContainer>
            <Container>
              <Header>
                <PageHeaderComponent>
                  <AreaContainer onClick={goToPreviousPage}>{!cameraDetails?.camera_name ? 'Camera Name' : cameraDetails?.camera_name}</AreaContainer>
                  <PageHeader
                    title={t('Frequent Lane Change')}
                    icon='Analyse'
                    updateDocTitle={false}
                  />
                  <ToggleDetection state={toggleAnalysis ? 'disabled' : 'default'}>
                    <Switch checked={isDetectionEnabled} onChangeCallback={handleAnalysis} state={toggleAnalysis ? 'disabled' : 'default'} />
                    <SmallLabel>{isDetectionEnabled ? t('Enabled') : t('Disabled')}</SmallLabel>
                  </ToggleDetection>
                </PageHeaderComponent>
                <ButtonContainer>
                  <Button
                    design='secondary'
                    size='small'
                    onClick={goToPreviousPage}
                  >
                    {unSavedChanges === 0 ? t('Back') : t('Cancel')}
                  </Button>
                  <Button disabled={unSavedChanges === 0 || saveLoading} design='primary' size='small' onClick={openConfirmationModal}>
                    {t('Save Changes')}
                  </Button>
                  <UnsavedChangesText>{unSavedChanges > 0 ? t('{X} unsaved changes').replace('{X}', (unSavedChanges + '')) : t('No Changes')}</UnsavedChangesText>
                </ButtonContainer>
              </Header>
              <Divider margin='5px 0 15px' />
              <InputAndButtonContainer gap='0 14px'>
                {t('Settings')}:
                <Button size='xsmall' design='secondary' disabled={false} onClick={onImportSettings}>
                  {t('Import')}
                </Button>
                <Button size='xsmall' design='secondary' disabled={false} onClick={onExportSettings}>
                  {t('Export')}
                </Button>
              </InputAndButtonContainer>
              <DividerHeader />
              <LineSettingsContainer>
                <LineSelectFieldContainer>
                  <Icon icon='FeatureLineUi' size={16} color='dimmed' />
                  <StyledSelectField
                    changeCallback={onSetSelect}
                    isCompact
                    value={selectedSetIndex === -1 ? -1 : selectedSetIndex}
                  >
                    <option value={-1}>{t('[New Lane]')}</option>
                    {
                      laneDetails.map(({line_set_name}, index) => {
                        return (
                          <option key={index} value={index}>{`${index+1}: ${line_set_name}`}</option>
                        );
                      })
                    }
                  </StyledSelectField>
                  <SetAddRemoveButtons>
                    <ButtonWithIcon
                      icon='Add'
                      position='left'
                      size='small'
                      design='secondary'
                      disabled={!isMediaLoaded || laneDetails.length === 10}
                      onClick={onAddSet}
                    >
                      {t('Add Lane')}
                    </ButtonWithIcon>
                    <ButtonWithIcon
                      icon='ActionRemove'
                      position='left'
                      size='small'
                      design='secondary'
                      onClick={(e) => {e.stopPropagation(); onRemove(selectedSetIndex);}}
                      disabled={selectedSetIndex === -1}
                    >
                      {t('Remove Lane')}
                    </ButtonWithIcon>
                  </SetAddRemoveButtons>
                </LineSelectFieldContainer>

                <LineConfigContainer>
                  <LineConfigLeft>
                    <PaddingDiv>
                      <ReferenceInput
                        fieldState='default'
                        label={t('Lane Reference')}
                        name='lineReference'
                        maxLength={24}
                        disabled={selectedSetIndex === -1}
                        value={selectedSetIndex !== -1 ? laneDetails[selectedSetIndex].line_set_name : setReference}
                        onChange={({target: {value}}) => onSetReferenceChange(value)}
                      />
                    </PaddingDiv>
                    <Divider margin='14px 0 15px' />

                    <PaddingDiv>
                      <DividerInputContainer>
                        <DividerSmallLabel>{t('Dividers')}: <span>{selectedSetIndex === -1 ? dividersCount : laneDetails[selectedSetIndex].lines.length}</span></DividerSmallLabel>
                        <DividerButtons>
                          <IconButton
                            disabled={selectedSetIndex === -1 || (selectedSetIndex === -1 ? dividersCount === 1 : (laneDetails[selectedSetIndex].lines.length === 1))}
                            onClick={(e) => {
                              e.stopPropagation();
                              selectedSetIndex === -1 ? setDividersCount(prev => (prev - 1)) : onDividerRemove();
                            }}
                          >
                            <Icon icon='ActionRemove' color='inverse' size={15} />
                          </IconButton>
                          <IconButton
                            disabled={selectedSetIndex === -1 || laneDetails[selectedSetIndex].lines.length === 9}
                            onClick={(e) => {
                              e.stopPropagation();
                              selectedSetIndex === -1 ? setDividersCount(prev => (prev + 1)) : onDividerAdd();
                            }}
                          >
                            <Icon icon='Add' color='inverse' size={15} />
                          </IconButton>
                        </DividerButtons>
                      </DividerInputContainer>
                    </PaddingDiv>
                    <Divider margin='14px 0 15px' />

                    <PaddingDiv>
                      <ThreholdInput
                        lang={i18n.language}
                        fieldState='default'
                        label={t('Trigger Detection When…')}
                        name='threshold'
                        type='number'
                        maxLength={2}
                        step={10}
                        min={0}
                        unit={t('divider crossings')}
                        disabled={selectedSetIndex === -1}
                        value={selectedSetIndex === -1 ? '' : laneDetails[selectedSetIndex].threshold}
                        onChange={({target: {value}}) => onThresholdChange(value)}
                        onPaste={(evt) => evt.preventDefault()}
                        onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      />
                    </PaddingDiv>
                    
                    <PaddingDiv>
                      <DurationInput
                        lang={i18n.language}
                        fieldState='default'
                        label={t('…happen within')}
                        name='duration'
                        type='number'
                        maxLength={3}
                        step={10}
                        min={0}
                        unit={t('seconds')}
                        disabled={selectedSetIndex === -1}
                        value={selectedSetIndex === -1 ? '' : laneDetails[selectedSetIndex].duration}
                        onChange={({target: {value}}) => onDurationChange(value)}
                        onPaste={(evt) => evt.preventDefault()}
                        onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      />
                    </PaddingDiv>
                  </LineConfigLeft>

                  <LineConfigRight>
                    <LineViewer
                      streamName={streamName} 
                      latestSnapshotText={t('Latest Snapshot')}
                      zoomText={t('Zoom')}
                      rtcText={t('Live Feed')}
                      linesData={stateDetails}
                      showExclusionArea={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                      showExclusionAreaOnModal={(exclusionAreas.enabled && exclusionAreas.areas.length > 0)}
                      exclusionAreas={(exclusionAreas.enabled && exclusionAreas.areas.length > 0) ? exclusionAreas.areas : []}
                      options={options}
                      width='700px'
                      height='390px'
                      hasClickSensingBorder
                      onLineChangeCallback={onLineMoveEnd}
                      onMediaLoadedCallback={onMediaLoadedCallback}
                      onLineClickCallback={onLineSelect}
                    />
                  </LineConfigRight>
                  {isMediaLoaded && laneDetails.length !== 0 &&
                    <LineUIActionsDisplayContainer>
                      <IconWrapper>
                        <Icon icon='PasswordShow' size={16} color='dimmed' />
                      </IconWrapper>
                      <SelectZoneShow changeCallback={(e) => handleLaneShow(e)} disabled={false} isCompact value={showAllOrSelected}>
                        {['Only Active Set', 'Show All'].map((item, index) => {
                          return (
                            <option key={index} value={item}>{tRef.current(item)}</option>);
                        })}
                      </SelectZoneShow>
                    </LineUIActionsDisplayContainer>}
                </LineConfigContainer>
              </LineSettingsContainer>
            </Container>
          </MainContainer>
      }
    </>
  );
};

export default FrequentLane;