import { STATUS_ALERT_DETAILS, TIMEZONE, USB_STATUS_ALERT_DETAILS } from '../constants';
import { format, formatDistance, formatDistanceStrict } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import { enUS, ja } from 'date-fns/locale';
import i18n from 'i18n';
import { ITrafficCounterData } from 'interface';
import { isEqual, omit } from 'lodash';
import { IFrequentLaneData } from 'pages/CameraConfiguration/types';
import Wrong_Answer from '../assets/Wrong-Answer.mp3';
import Interface from '../assets/Interface.mp3';
import Twinkle_Sound from '../assets/Twinkle-Sound.mp3';
import Ding_Idea from '../assets/Ding-Idea.mp3';
import { DateInterval, IFilterItem } from 'scorer-ui-kit';

export const getTimeSince = (date: string) => {
  const lang = i18n.language === 'ja' ? ja : enUS;
  try {
    if (lang === ja) {
      const result = formatDistanceStrict(
        new Date(date), new Date(),
        { locale: ja, addSuffix: false }
      );
      return result.includes('秒') ? '1分未満' : result + '前';
    } else {
      const result = formatDistance(
        new Date(date), new Date(),
        { locale: lang, addSuffix: true, includeSeconds: true }
      );
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
  } catch (err) {
    console.error(err);
  }
  return '-';
};

export const getTimeSinceFromSeconds = (date: number) => {
  if (!date || date === 0)
    return '-';
  const lang = i18n.language === 'ja' ? ja : enUS;
  try {
    if (lang === ja) {
      const result = formatDistanceStrict(
        new Date(date*1000), new Date(),
        { locale: ja, addSuffix: false }
      );
      return result.includes('秒') ? '1分未満' :  result + '前';
    } else {
      const result = formatDistance(
        new Date(date*1000), new Date(),
        { locale: lang, addSuffix: true, includeSeconds: true }
      );
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
  } catch (err) {
    console.error(err);
  }
  return '-';
};

export const formatDate = (date: number, dateFormat: string) => {
  try {
    if(date){
      return format(new Date(date*1000), dateFormat);
    }
  } catch (err) {
    console.error(err);
  }
  return '-';
};


export const dateFormat = (strDate: Date) => {
  try {
    const zonedTargetDate = convertToTimeZone(strDate, { timeZone: TIMEZONE });
    return zonedTargetDate;
  } catch (err) {
    console.error(err);
  }
  return new Date();
};

export const JapaneseDateFormat = (strDate: Date) => {     
  try {
    const zonedTargetDate = convertToTimeZone(strDate, { timeZone: 'Asia/Tokyo' });
    return zonedTargetDate;
  } catch (err) {
    console.error(err);
    return strDate;
  }
};

export const downloadFile = async (url: string) => {
  try {
    if (url !== null && url !== undefined) {
      const a = document.createElement('a');
      a.href = url;
      a.download = url.substr(url.lastIndexOf('/') + 1);
      a.click();
      a.remove();
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const downloadZipFile = async (url: string) => {
  try{
    if (url !== null && url !== undefined) {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.substr(url.lastIndexOf('/') + 1);
      link.click();
      return true;
    }
  } catch (err) {
    console.error(err);
  }
  return false;
};

export const capitalizeWords = (string: string) => {
  return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
};

export const unique = (value: string, index: number, self: string []) => {
  return self.indexOf(value) === index;
};

export const compareChanges = (updatedData: ITrafficCounterData, previousData: ITrafficCounterData, key: 'traffic_counting_settings') => {
  let changesCount = 0;
  if (key !== 'traffic_counting_settings') {
    if (updatedData[key] !== previousData[key]) {
      changesCount += 1;
    }
    return changesCount;
  } else {
    // If any lines has length 0
    if (updatedData.traffic_counting_settings.length === 0 || previousData.traffic_counting_settings.length === 0) {
      changesCount = changesCount + updatedData.traffic_counting_settings.length + previousData.traffic_counting_settings.length;
      return changesCount;
    }
    // count lines difference
    const updatedLinesArr = updatedData.traffic_counting_settings;
    const previousLinesArr = previousData.traffic_counting_settings;
    updatedLinesArr.forEach((line1) => {
      let found = 1;
      for (const line2 of previousLinesArr) {
        if (
          isEqual(omit(line1, ['idx']), omit(line2, ['idx']))
        ) {
          found = 0;
          break;
        }
      }
      changesCount += found;
    });
    if (updatedLinesArr.length < previousLinesArr.length) {
      changesCount += Math.abs(updatedLinesArr.length - previousLinesArr.length);
    }
    return changesCount;
  }
};

export const getStatusCategory = (statusCode: number, type?: string): string => {
  const alertObject = type === 'USB' ? USB_STATUS_ALERT_DETAILS : STATUS_ALERT_DETAILS;
  if (String(statusCode).charAt(0) === '1') {
    return 'Ok';
  } else if (Object.keys(alertObject).includes(statusCode + '')) {
    return (statusCode + '');
  } else {
    return 'Unknown';
  }
};

export const getStatusColor = (category: string) => {
  switch (category) {
  case 'Running':
    return '#84df9e';
  case 'Error':
    return '#ef7878';
  case 'Warning':
    return '#96acbc';
  case 'Disabled':
    return '#96acbc';
  default:
    return '#96acbc';
  }
};

export const detectBrowser = () => { 
  if((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1 ) {
    return 'Opera';
  } else if(navigator.userAgent.indexOf('Chrome') !== -1 ) {
    return 'Chrome';
  } else if(navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if(navigator.userAgent.indexOf('Firefox') !==-1 ){
    return 'Firefox';
  } else {
    return 'Unknown';
  }
};

export const laneDetailsChangesCounter = (prevDetails: IFrequentLaneData, currentDetails: IFrequentLaneData) => {
  let counter = 0;
  
  // If any lines has length 0
  if (currentDetails.frequent_lane_change_settings.length === 0 || prevDetails.frequent_lane_change_settings.length === 0) {
    counter = counter + currentDetails.frequent_lane_change_settings.length + prevDetails.frequent_lane_change_settings.length;
    return counter;
  }
  
  // count lanes difference
  currentDetails.frequent_lane_change_settings.forEach((lane1) => {
    let found = 1;
    for (const lane2 of prevDetails.frequent_lane_change_settings) {
      if (isEqual(lane1, lane2)) {
        found = 0;
        break;
      }
    }
    counter += found;
  });
  
  if (currentDetails.frequent_lane_change_settings.length < prevDetails.frequent_lane_change_settings.length) {
    counter += Math.abs(currentDetails.frequent_lane_change_settings.length - prevDetails.frequent_lane_change_settings.length);
  }

  return counter;
  
};

export const getAudioAlertName = (audioName: string) => {
  switch (audioName) {
  case 'Wrong-Answer':
    return Wrong_Answer;
  case 'Interface':
    return Interface;
  case 'Twinkle-Sound':
    return Twinkle_Sound;
  case 'Ding-Idea':
    return Ding_Idea;
  default:
    return Twinkle_Sound;
  }
};

export const getSelectedDate = (value: any) => {
  if (value) {
    const { start = undefined, end = undefined } = value;
    if (start === undefined || end === undefined) {
      return undefined;
    }
    return ({
      start: new Date(start),
      end: new Date(end)
    });
  }
  return undefined;
};

interface IAppFilter {
  [key: string]: DateInterval | string | number | undefined | Date | IFilterItem[] | boolean
}

export const readParams = <T extends {[key: string]: any} = IAppFilter,U extends keyof T = string>(historyParams: string, initialFilterValues: T) => {
  const params = new URLSearchParams(historyParams);
  const filters = params.toString()?.split('&').reduce((filterValues: T, param: string) => {
    const result: string[] = param.split('=');
    if (result[0] in initialFilterValues) {
      if (result[0] === 'startDate' || result[0] === 'endDate') {
        filterValues[result[0] as U] = result[1] ? JSON.parse(decodeURIComponent(result[1])).replace('+', ' ') : undefined;
      } else if (result[0] === 'pageSize') {
        let pageSize = result[1] ? JSON.parse(decodeURIComponent(result[1])) : undefined;
        if (pageSize) {
          switch (true) {
          case (pageSize <= 10):
            pageSize = 10;
            break;
          case (pageSize <= 20):
            pageSize = 20;
            break;
          case (pageSize <= 50):
            pageSize = 50;
            break;
          default:
            pageSize = 100;
            break;
          }
        }
        filterValues[result[0] as U] = pageSize;
      } else {
        filterValues[result[0] as U] = result[1] ? JSON.parse(decodeURIComponent(result[1])) : undefined;
      }
    }
    return filterValues;
  }, { ...initialFilterValues });
  return ({ ...filters, paramsLoaded: true });
};

export const updateParams = <T extends {[key: string]: any} = IAppFilter,>(selectedFilterValues: T) => {
  let params = Object.keys(selectedFilterValues).reduce((params: string, key: string) => {
    if (key === 'paramsLoaded') return params;
    params += selectedFilterValues[key] !== undefined ? `${key}=${JSON.stringify(selectedFilterValues[key])}&` : '';
    return params;
  }, '');
  params = params.slice(0, -1);
  params = params ? '?' + params : '';
  return params;
};