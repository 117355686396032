import React, {FC, ReactElement, useCallback, useEffect,useMemo, useRef, useState,} from 'react';
import { DATE_FORMAT_FILE_TAB_API, DATE_FORMAT_WITHOUT_SEC, DATE_FORMAT, BASE_API_URL } from '../constants';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { ActionButtons, Button, ButtonWithIcon, DateInterval, DatePicker, FilterButton,FilterDropdownContainer, Icon, Label, 
  MultilineContent, SortDropdown, TypeTable,useClickOutside, useModal, useNotification,} from 'scorer-ui-kit';
import styled from 'styled-components';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import Pagination from 'components/Pagination';
import TokenService from 'services/tokenService';
import ModelBox from 'components/ModelBox';
import { getDelCameraFile, getDetailsFiles, getDownloadFilesUrl } from 'services/apiConfig';
import { dateFormat, downloadZipFile, unique } from 'utils';
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';

const Container = styled.div`
  max-width: 800px;
  min-height: 70vh;
`;

const ContainerFilter = styled.div``;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 28px 0 8px 0;
`;

const FilterWrapper = styled.div`
  flex-grow: 1;
`;

const SortBox = styled.div`
  display: flex;
  align-self:center;
  justify-content: space-between;
  align-items: flex-end;
  white-space: nowrap;
  button > div > div:nth-child(2){
    min-width: 150px;
  }
`;

const FilterBarText = styled(Label) <{ marginLeft?: string, fontWeight?:string, color?:string, margin?:string }>`
  font-size: 12px;
  margin:${({ margin }) => margin};
  font-weight:${({fontWeight}) => fontWeight};
  margin-left: ${({ marginLeft }) => marginLeft};
  color:${({ color }) => color};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  height:14px;
  letter-spacing:-0.2px;
`;

const ActionText = styled(FilterBarText)`
  position: relative;
  z-index: 2;
`;

const DatePickerBox = styled.div`
  position: relative;
  margin-top:-6px;
`;

const FilterDropdown = styled(FilterDropdownContainer)`
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 100;
`;

const ResultsBox = styled.div`
  display: flex;
  align-self:center;
`;

const ClearResult = styled.div`
  display: inherit;
  cursor: pointer;
  height: 18px;
  margin-top: 0 !important;
  &::before {
    content: '';
    border-left: 1px solid rgba(33, 33, 33, 0.16);
    padding-left: 11px;
  }
`;

const DateShowResult = styled.div`
  height: 18px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0px 1px 3px 8px;
  margin-left: 3px;
  color: rgb(140, 146, 151);
  font-family: ${({ theme }) => theme.fontFamily.data};
`;

const DateValueBox = styled.div`
  padding: 0px 15px 0px 9px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FileSelectedBox = styled.div`
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: -6%;
  margin-top:-6px;
  z-index: 1;
`;

const SelectedBox = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  min-width: ${({ width }) => width};
  & > button {
    margin-right: 10px;
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const BlurredDiv = styled.div`
  height: 59px;
  position: absolute;
  bottom: 0;
  top: -30px;
  left: -100%;
  right: -5%;
  opacity: 0.8;
  filter: blur(1px);
  background-color: rgb(248 249 250);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: -1;
`;

const PaginationContainer = styled.div`
  margin-bottom: 30px;
`;

const ModelText = styled(Label)`
  margin-bottom: 0;
  span{
    margin-bottom: 0;
  }
`;

const TypeTableWrapper = styled.div`
  margin: 33px 0 0 -28px;
  table-layout:fixed;
  div {
    z-index: 0 !important;
  };
`;

const JSTFormat = styled.div`
  font-size: 12px;
  font-style: italic;
  text-align: right;
  color: rgba(120, 138, 144, 0.72);
  margin-left: 3px;
`;

const DateFormating =styled.div`
  display:flex;
  flex-direction:row;
`;

interface ICameraDetailsFiles {
  streamName: string;
  selectedTab:string
  cameraName:string
}

export interface IFilesDetailsParams {
  sort_by?: string;
  sort_order: string;
  page_size: number;
  page: number;
  start_date?: string;
  end_date?: string;
  action:string
}

interface IFilterData {
  time:string;
  paramsLoaded:string
  pageSize:string
  totalPages:string
}

interface INewParams {
  time?:string | null;
  sortBy?: string | null;
  selectedTab?:string | null; 
  startDate?: string | null;
  endDate?: string | null;
  pageSize?: string | null;
  totalPages?: string | null;
  currentPage?: string |null
}

export interface IFileTabDevice {
  event_type: string;
  display_name: string;
  file_type: string;
  id: number;
  image_size: string;
  message: string;
  stream_name: string;
  timestamp: string;
  video_name: null;
  video_status?: null | string | undefined;
}

const FILTER_DATA: IFilterData = { time:'' , paramsLoaded: '', pageSize:'', totalPages:'' };

const CameraDetailsFiles: FC<ICameraDetailsFiles> = ({ streamName, cameraName }) => {

  const [initialLoad] = useState(true);
  const initialLoadRef = useRef(initialLoad);
  const [filesData, setFilesData] = useState([]);
  const [sorting, setSorting] = useState({ value: 'timestamp', ascending: false, text: 'Sort By Event Time' });
  const currDate = dateFormat(new Date());
  const [eventTime, setEventTime] = useState<DateInterval>({ start: currDate, end: currDate});
  const [rows, setRows] = useState<ITypeTableData>([]);
  const [loading, setLoader] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [showDate, setShowDate] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(10);
  const sizeOptions = useMemo(() => [10, 20, 50, 100], []);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation(['CommonDict']);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const { createModal, setModalOpen } = useModal();
  const [ totalNumberPages , setTotalNumberPages ] = useState<number>(10);
  const ref = useRef(null);
  const [ filterChanged, setFilterChanged ] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState({ ...FILTER_DATA });
  const params = useLocation().search;
  const { replace } = useHistory();
  const token = TokenService.getLocalAccessToken();
  const userName = TokenService.getUser();
  const [ historyParams ] = useState<string>(params);
  const setParams = useCallback(() => {
    const selectedStartDate = format(eventTime.start, DATE_FORMAT_WITHOUT_SEC);
    const selectedEndDate = format(eventTime.end, DATE_FORMAT_WITHOUT_SEC);
    const params =
      `${ initialLoadRef.current ? '' : '&fromDate=' + selectedStartDate }` +
      `${ initialLoadRef.current ? '' : '&toDate=' + selectedEndDate }` +
      `${ pageSize === 10  ? '' : '&pageSize=' + pageSize.toString() }` +
      `${ currentPage === 1 ? '' : '&currentPage=' + currentPage.toString() }` +
      `${!sorting.ascending && sorting.value === 'timestamp' ? '' : '&sortBy=' + JSON.stringify(sorting)}`;
    if (params === '?') {
      return '';
    }
    return params;
  }, [sorting, eventTime, pageSize, currentPage]);

  useEffect(()=> {
    setSelected([]);
  },[t]);
  
  useEffect(() => {
    if (filterChanged) {
      replace(window.location.pathname + setParams());
    }
  }, [replace, setParams, filterValues, filterChanged]);

  const fetchHistoryParams = useCallback(() => {
    const hParam = new URLSearchParams(historyParams);
    const newParams: INewParams = {};
    newParams.sortBy = hParam.get('sortBy');
    newParams.selectedTab = hParam.get('selectedTab');
    newParams.startDate = hParam.get('fromDate');
    newParams.endDate = hParam.get('toDate'); 
    newParams.pageSize = hParam.get('pageSize'); 
    newParams.currentPage = hParam.get('currentPage');

    const searchFilter = { ...FILTER_DATA };
    searchFilter.time = newParams.time ?? '';
    setSorting(prev => (newParams.sortBy ? JSON.parse(newParams.sortBy) : prev));
    if(newParams.pageSize){
      setPageSize(Number(newParams.pageSize));
    }else {
      if(userName){
        const pageSize = localStorage.getItem(`preferredPageSize_${userName}`);
        const sizeNum = parseInt(pageSize as string);
        if(sizeNum){
          setPageSize(sizeNum);
        }
      }else {
        setPageSize(10);
      }
    }
    if(newParams.currentPage){
      setCurrentPage(Number(newParams.currentPage));
    }
    if (newParams.endDate) {
      initialLoadRef.current = false;
      setEventTime({
        start: new Date(newParams.startDate as string),
        end: new Date(newParams.endDate),
      });
    }
    setFilterValues(searchFilter);
    setFilterChanged(true);
    searchFilter.paramsLoaded = 'true';
  }, [historyParams, userName]);

  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);

  const sortByList = [
    {
      value: 'timestamp',
      text: t('Sort By Event Time'),
    }
  ];

  const columnConfig: ITableColumnConfig[] = [
    {
      header: t('Event Time'),
      cellStyle: 'firstColumn',
      minWidth: 200,
    },
    {
      header: t('Type'),
      cellStyle: 'normalImportance',
      minWidth: 90,
    },
    {
      header: t('Status'),
      cellStyle: 'normalImportance',
      alignment: 'center',
      minWidth: 90,
    },
    {
      header: t('File Type'),
      cellStyle: 'normalImportance',
      alignment: 'center',
      minWidth: 120,
    },
    {
      header: t('File Size'),
      cellStyle: 'normalImportance',
      alignment: 'center',
      minWidth: 120,
    },
    {
      header: ' ',
      cellStyle: 'normalImportance',
      minWidth: 100,
      alignment: 'right',
    },
  ];

  const createRequestData = useCallback(() => {
    let param: IFilesDetailsParams = {
      page_size: pageSize,
      page: currentPage,
      start_date:'',
      end_date:'',
      sort_by: sorting.value,
      sort_order: sorting.ascending ? 'acs' :'desc',
      action: 'getlist'
    };

    const hParam = new URLSearchParams(window.location.search);
    const startDate = hParam.get('fromDate') === null ? '' : format(eventTime.start, DATE_FORMAT_FILE_TAB_API);
    let endDate = hParam.get('toDate') === null ? '' : format(eventTime.end, DATE_FORMAT_FILE_TAB_API);
    endDate = endDate.substring(0, endDate.length - 2);
    endDate = endDate + '59';
    if (startDate !== '' && endDate !== ''){
      param = { ...param, start_date: startDate , end_date: endDate };
    }
    return param;
  }, [pageSize, currentPage, sorting, eventTime]);

  const fetchFilesDetails = useCallback(async(param: IFilesDetailsParams) => {
    setLoader(true);
    try {
      const resData = await getDetailsFiles(streamName, param);
      if (resData) {
        if(resData?.data?.data){
          setTotalNumberPages(resData?.data?.total_count);
          setFilesData(resData?.data?.data);
        }
      } else {
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    }catch(error) {
      console.error((error as Error).message);
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    }
    setFilterChanged(false);
    setLoader(false);
  }, [t, streamName]);

  useEffect(()=>{
    setTotalPages(Math.ceil(totalNumberPages/pageSize));
  }, [ pageSize, totalNumberPages, setTotalPages]);

  useEffect(() => {
    if(filterChanged){
      const param = createRequestData();
      fetchFilesDetails(param);
    }
  }, [ createRequestData, pageSize, fetchFilesDetails, filterChanged ]);

  const onDeleteSingle = useCallback(async(stream_name, delId) => { 
    const delData = [];
    delData.push(delId);
    const delParam = {
      event_list : delData,
      action:'delete'
    };
    try{
      const res = await getDelCameraFile(stream_name, delParam);
      setModalOpen(false);
      if (res.status === 200) {
        setSelected([]);
        notificationRef.current({ type: 'success', message: t('File deleted successfully') });
        setModalOpen(false);
        const param = createRequestData();
        fetchFilesDetails(param);
      } else if(res.status === 403) {
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      } else {
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    }catch (error) {
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      console.error((error as Error).message);
    }
  }, [createRequestData, fetchFilesDetails, setModalOpen, t]);

  const onClickDeleteSingle = useCallback((stream_name, delId) => {
    createModal({
      isCloseEnable: true,
      closeText: t('CLOSE'),
      width: '480px',
      padding: true,
      dismissCallback: () => { setModalOpen(false); },
      customComponent: (
        <ModelBox setSelected={setSelected} titleText={t('Delete selected files?')} onConfirm={() => { onDeleteSingle(stream_name, delId); }} modalOpen={setModalOpen} confirmText={t('Permanently Delete')}>
          <ModelText htmlFor='' labelText={t('Are you sure you wish to delete these files? Once deleted they cannot be recovered.')} />
        </ModelBox>)
    });
  }, [onDeleteSingle, createModal, setModalOpen, t]);

  const callBackFunction = useCallback((data) => {
    if(data.start.getTime() === data.end.getTime()){
      return;
    }
    initialLoadRef.current = false;
    setEventTime(data);
    setFilterChanged(true);
    //set filter values here,-----------
    const searchFilter = { ...FILTER_DATA };
    setFilterValues({ ...searchFilter, paramsLoaded: 'true' });
  }, []);

  const actionButton = useCallback((download, timestamp, delId, alertType) => {
    const onDownload = () => {
      axios.get<Blob>(download, {responseType: 'blob'}).then((res) => {
        if(res.data && res.data.type !== 'text/html'){
          const file = new Blob([res.data]);
          const _url = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = _url;
          link.setAttribute('download', `${
            cameraName.length > 180 ? cameraName.slice(0, 180) + '...' : cameraName
          }_${alertType}_${timestamp.replaceAll(':','-').replaceAll(' ','_').replaceAll('-','')}_JST.${'jpg'}`);
          document.body.appendChild(link);
          link.click();
        } else {
          notificationRef.current({
            type: 'error',
            message: t('Image not available')
          });
        }
      }).catch(() => {
        notificationRef.current({
          type: 'error',
          message: t('Image download error')
        });
      });
    };
    let config = [];
    if (TokenService.getUserType()) {
      config = [
        { icon: 'Delete', onClick: () => onClickDeleteSingle(streamName, delId) },
        { icon: 'Download', onClick: onDownload },
      ];
    } else {
      config = [
        { icon: 'Download', onClick: onDownload },
      ];
    }
    return <ActionButtons buttonsConfig={config} />;
  }, [onClickDeleteSingle, streamName, t, cameraName]);

  const generateEventTimeColumn = useCallback((data): ReactElement[] => {
    return [
      <DateFormating key={data}>
        <div key={data}>{`${format(new Date(data), DATE_FORMAT_WITHOUT_SEC)}`}</div>
        <JSTFormat>JST</JSTFormat>
      </DateFormating>];
  }, []);

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = filesData?.map((device: IFileTabDevice) => {
      const { id, timestamp, event_type, file_type, image_size, video_status } = device;
      const deleteId:number[]=[];
      deleteId.push(id);
      const imgUrl = `${BASE_API_URL}/events/images/${id}?access_token=${token}`;
      const row: IRowData = {
        columns: [
          { customComponent: (<MultilineContent contentArray={generateEventTimeColumn(timestamp)} />)},
          { text: t(event_type) },
          { text: t(video_status === null ? '-' : video_status as string) },
          { text: t(file_type) },
          { text: image_size + ' kb'},
          { customComponent: actionButton(imgUrl, timestamp, deleteId, t(event_type))},
        ],
        header: {image: imgUrl, mediaType: 'img',mediaUrl: imgUrl,},
        id: id,
      };
      return row;
    });
    return data;
  }, [filesData, t, actionButton, generateEventTimeColumn, token]);

  useEffect(() => {
    setRows(generateRowData());
  }, [generateRowData]);

  const selectCallback = useCallback((checked: boolean, id?: string | number) => {
    const newRows = [...rows];
    const targetRowIndex = newRows.findIndex((row) => row.id === id);
    newRows[targetRowIndex]._checked = checked;
    setRows(newRows);
    let tempArr = [...selected];
    tempArr.push(id as string);
    if(!checked){
      tempArr = tempArr.filter(function(item: string) {
        return item !== id;
      });
    }
    setSelected(tempArr);
  }, [rows, setRows, selected]);

  const toggleAllCallback = useCallback((checked: boolean) => {
    const newRows = [...rows];
    const selectedIds: string[] = [];
    newRows.forEach((row) => {
      row._checked = checked;
      selectedIds.push(row.id + '');
    });
    setRows(newRows);
    setSelected(selectedIds);
    if(!checked){
      setSelected([]);
      return;
    }
  }, [rows, setRows]);

  const dateResultValue = useCallback((date) => {
    const start = format(date.start, DATE_FORMAT);
    const end = format(date.end, DATE_FORMAT);
    return start + ' - ' + end;
  }, []);

  const onDelete = useCallback(async() => {
    const uniqueValues = selected.filter(unique);
    const delParam = {
      event_list : uniqueValues,
      action:'delete'
    };
    try{
      const res = await getDelCameraFile(streamName, delParam);
      setModalOpen(false);
      if (res.status === 200) {
        setSelected([]);
        notificationRef.current({ type: 'success', message: t('File deleted successfully') });
        setModalOpen(false);
        const param = createRequestData();
        fetchFilesDetails(param);
      } else if(res.status === 403) {
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      } else {
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    }catch (error) {
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      console.error((error as Error).message);
    }
  },[selected, createRequestData, fetchFilesDetails, setModalOpen, streamName, t]);

  const onDownload = useCallback(async() => {
    const uniqueValues = selected.filter(unique);
    const params = { 'event_list': uniqueValues, 'action':'download' };
    try{
      const resp = await getDownloadFilesUrl(params, streamName);
      const downloadStatus = await downloadZipFile(resp.data.data);
      if (resp.status === 200 && downloadStatus) {
        notificationRef.current({ type: 'success', message: t('Downloading started successfully') });
      } else if (resp.status === 403) {
        notificationRef.current({ type: 'error', message: t('Fail to download')});
      }else {
        notificationRef.current({ type: 'error', message: t('Fail to download')});
      }
    }catch (error) {
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      console.error((error as Error).message);
    }
  }, [selected, streamName, t]);

  const onFinished = useCallback((data) => {
    const newRows = [...data];
    newRows.forEach((row) => {
      row._checked = false;
    });
    setRows(newRows);
    setSelected([]);
  }, []);

  const onPageChange = useCallback((page: number) => {
    setFilterChanged(true);
    setCurrentPage(page);
  }, []);

  const onSizePerPageChange = useCallback((size: number) => {
    setFilterChanged(true);
    setPageSize(size);
    setCurrentPage(1);
    localStorage.setItem(`preferredPageSize_${userName}`, size.toString());
  }, [userName]);

  useClickOutside(ref, () => {
    setShowDate(false);
  });

  const onClickDelete = useCallback(() => {
    createModal({
      isCloseEnable: true,
      closeText: t('CLOSE'),
      width: '480px',
      padding: true,
      dismissCallback: () => { setModalOpen(false); },
      customComponent: (
        <ModelBox setSelected={setSelected} titleText={t('Delete {X} selected files?').replace('{X}', `${selected.length}`)} onConfirm={() => { onDelete(); }} modalOpen={setModalOpen} confirmText={t('Permanently Delete')}>
          <ModelText htmlFor='' labelText={t('Are you sure you wish to delete these files? Once deleted they cannot be recovered.')} />
        </ModelBox>)
    });
  }, [selected, onDelete, createModal, setModalOpen, t]);

  const clearResult = useCallback(() => {
    setSorting({ value: 'timestamp', ascending: false, text: 'Sort By Event Time' });
    const nowDate = dateFormat(new Date());
    setEventTime({ start: nowDate, end: nowDate});
    initialLoadRef.current = true;
    setFilterChanged(true);
  },[]);

  return (
    <>
      {
        selected.length > 0 ?
          <>
            <ActionText
              htmlFor=''
              marginLeft='1px'
              fontWeight='bold'
              labelText={t('Actions')+':'}
            />
            <FileSelectedBox>
              <SelectedBox width={i18n.language === 'ja' ? '416px' : '382px'}>
                {TokenService.getUserType() &&
                  <ButtonWithIcon
                    design='secondary'
                    icon='Delete'
                    position='left'
                    size='small'
                    onClick={() =>onClickDelete()}
                  >
                    {t('Delete')}
                  </ButtonWithIcon>}
                <ButtonWithIcon
                  design='secondary'
                  icon='Download'
                  position='left'
                  size='small'
                  onClick={() => onDownload()}
                >
                  {t('Download')}
                </ButtonWithIcon>
              </SelectedBox>
              <Button size='small' onClick={() => onFinished(rows)}>
                {t('Finish')}
              </Button>
              <BlurredDiv />
            </FileSelectedBox>
          </>
          : null 
      }
      <Container>
        <ContainerFilter id='container1'>
          <FilterWrapper id='container2'>
            {selected.length === 0 ?
              <>
                <FilterBarText
                  htmlFor=''
                  marginLeft='1px'
                  fontWeight='bold'
                  labelText={t('Filters')+':'}
                />
                <DatePickerBox ref={ref}>
                  <FilterButton
                    hasFlipArrow
                    icon='DateTime'
                    isOpen={showDate}
                    onClick={() => setShowDate(!showDate)}
                  >
                    {t('Sort By Event Time')}
                  </FilterButton>
                  {showDate && (
                    <FilterDropdown>
                      <DatePicker
                        initialValue={eventTime}
                        dateMode='interval'
                        timeMode='interval'
                        dateTimeTextUpper={t('From')}
                        dateTimeTextLower={t('To')}
                        updateCallback={callBackFunction}
                        lang={i18n.language === 'ja' ? 'ja' : 'en'}
                        timeZoneTitle={t('Timezone')}
                      />
                    </FilterDropdown>
                  )}
                </DatePickerBox>
              </>
              : null}
          </FilterWrapper>
          <FilterContainer>
            <ResultsBox>
              <FilterBarText
                htmlFor=''
                margin='-5px 0 0 0'
                fontWeight='normal'
                color='hsl(207,5%,57%)'
                labelText={selected.length > 0 ? t('Selected {x} of {y} Results').replace('{x}', '' + selected.length).replace('{y}', (rows.length + '')) : (t('Showing Results') + t(' ({X})').replace('{X}', '' + rows.length) + ':')}
              />
              {!isEqual(eventTime.start, eventTime.end) && selected.length === 0 && (
                <>
                  <DateShowResult>
                    <Icon icon='Date' size={12} color='dimmed' />
                    <DateValueBox title={dateResultValue(eventTime)}>{dateResultValue(eventTime)}</DateValueBox>
                  </DateShowResult>
                  <ClearResult onClick={() => {clearResult();}}>
                    <FilterBarText color='hsl(216,4%,77%)' htmlFor='' labelText={t('CLEAR')} />
                  </ClearResult>
                </>
              )}
            </ResultsBox>
            <SortBox>
              <SortDropdown
                ascendingText={t('Ascending')}
                buttonText={t(sorting.text)}
                descendingText={t('Descending')}
                isSortAscending={sorting.ascending}
                list={sortByList}
                onSelect={(event, ascending) => {
                  setSorting({
                    value: event.value.toString(),
                    text: event.text,
                    ascending,
                  });
                  setFilterChanged(true);
                }}
                selected={{ text: sorting.text, value: sorting.value }}
              />
            </SortBox>
          </FilterContainer>
        </ContainerFilter>
        <TypeTableWrapper>
          <TypeTable
            columnConfig={columnConfig}
            rows={rows.length > 0 ? rows : [{ columns: [] }]}
            isLoading={loading}
            loadingText={t('Loading')}
            emptyTableText={t('No Files Found')}
            defaultAscending
            hasThumbnail
            selectCallback={selectCallback}
            selectable
            toggleAllCallback={toggleAllCallback}
            closeText={t('CLOSE')}
          />
        </TypeTableWrapper>
        {rows.length > 0 && (
          <PaginationContainer>
            <Pagination
              pageSizeOptions={sizeOptions}
              totalPages={totalPages}
              defaultPage={currentPage}
              defaultPageSize={pageSize}
              onPageSizeChange={onSizePerPageChange}
              onPageChange={onPageChange}
              pageSizeText={t('Items Per Page')}
              prevPageText={t('Page')}
              showPrevNextButtons
            />
          </PaginationContainer>
        )}
      </Container>
    </>
  );
};

export default CameraDetailsFiles;
