import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useModal } from 'scorer-ui-kit';
import { Label } from 'scorer-ui-kit';
import { TypeButtonDesigns } from 'scorer-ui-kit/dist/Form';
import styled from 'styled-components';

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button{
    margin-left: 10px;
  }
`;

const TitleText = styled(Label)`
  font-size: 20px;
  font-weight: 600;
`;

const CancelButton = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const ButtonDel = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const HeaderButton = styled.div`
  & > button:first-child {
    background: #e4edf4;
  }
`;

interface IModelBox {
  titleText?: string;
  cancelText?: string;
  confirmText?: string;
  confirmStyle?: string;
  onConfirm: () => void;
  modalOpen: (newStatus: boolean) => void;
  setSelected?: (selected: string[]) => void ;
}

const ModelBox: FC<IModelBox> = ({ titleText = '', cancelText = 'Cancel', confirmText = 'Confirm Deletion', confirmStyle = 'danger', onConfirm = () => { }, modalOpen, children, setSelected = () => { }}) => {

  const { t } = useTranslation(['CommonDict']);
  const { isModalOpen } = useModal();
  
  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('CancelButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('CancelButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'CancelButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('CancelButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('CancelButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const cancleModal = useCallback(() => {
    modalOpen(false);
    setSelected([]);
  }, [modalOpen, setSelected]);

  return (
    <>
      {titleText !== '' && <TitleText htmlFor='' labelText={t(titleText)} />}
      {children}
      <ButtonBox>
        <HeaderButton>
          <CancelButton tabIndex={0} id='CancelButton' design='secondary' onClick={() => cancleModal()}>{t(cancelText)}</CancelButton>
        </HeaderButton>
        <ButtonDel tabIndex={0} id='YesButton' design={confirmStyle as TypeButtonDesigns} onClick={onConfirm}>{t(confirmText)}</ButtonDel>
      </ButtonBox>
    </>
  );
};

export default ModelBox;