import React, { Fragment, useState, useCallback, useEffect, useRef, useContext } from 'react';
import styled, { css } from 'styled-components';
import NotificationItem from './NotificationItem';
import { ButtonWithIcon, useNotification, ButtonWithLoading } from 'scorer-ui-kit';
import { useHistory, useLocation } from 'react-router-dom';
import {IAlertHistoryParams, INotificationItem, IGlobalAlertSettingsResponse} from '../../interface';
import {getAlerts, dismissedAlert, getGlobalAlertsSettings} from '../../services/camerasService';
import {ALERT_FETCH_INTERVAL, BASE_API_URL} from '../../constants';
import { useTranslation } from 'react-i18next';
import TokenService from 'services/tokenService';
import { getAudioAlertName } from '../../utils';
import { AlertDrawerContext } from 'Context/AlertDrawerProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const NotificationContainer = styled.div`
  flex: 1; /* This will make the NotificationContainer take available space */
  overflow-y: auto;
  overflow-x: hidden;
`;

const StatusContainer = styled.h2`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: hsl(0, 0%, 34%);
  margin: 0;
`;

const EmptyNotification = styled.h2`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  border-bottom: ${({ theme: { colors } }) => colors.divider} 1px solid;
  color: hsl(0, 0%, 34%);
  padding: 10px 0 10px 20px;
  margin: 0;
`;

const NotificationWrapper = styled.div`
  border-bottom: ${({ theme: { colors } }) => colors.divider} 1px solid;
  margin-top: 5px;
  margin-left: -5px;
`;

const DismissContainer = styled.div<{lang?: string, loading?: boolean}>`
  display: flex;
  justify-content: space-between;
  padding: 16px 10px 8px 10px;
  & > button {
    padding: 0;
    background-color: #e4edf4;
    width: ${({lang, loading}) => lang === 'ja' ? (loading ? '112px' : '98px') : (loading ? '90px' : '82px')} ;
    border-radius: 3px;
    padding-left: ${({loading}) => loading ? '24px' : '4px'};
  }
  ${({ loading }) =>
    loading &&
      css`
      & > button > div > div:first-child {
        padding: 0;
      }
  `};
`;

const ButtonConatiner = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0px;
  background: #ffffff;
  margin:6px 0;
  & > button {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const FetchingConatiner = styled.div`
  margin: 13px 28px 15px 28px;
  display: flex;
  justify-content: center;
  font-size: 12px;
`;

const ViewAllButton = styled(ButtonWithIcon)`
  margin: 5px 0 5px 0;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  border-radius: 3px;
  background-color: #efefef;
  margin-bottom: 8px;
`;

interface INotificationsHistory {
  noNotificationsText?: string;
  unreadNotificationsText?: string;
  getNotificationCount? : (arg: number, data: INotificationItem[])=>void;
  activeAlertTimePages:number;
}

const DetectionTypes = [
  { key: 'congestion_and_stop_audio_enabled', state: false, value: 0 },
  { key: 'low_speed_detection_audio_enabled', state: false, value: 1 },
  { key: 'advanced_low_speed_detection_audio_enabled', state: false, value: 2 },
  { key: 'reverse_run_detection_audio_enabled', state: false, value: 3 },
  { key: 'frequent_lane_changes_audio_enabled', state: false, value: 4 }
];

const NotificationsHistory: React.FC<INotificationsHistory> = ({ noNotificationsText, unreadNotificationsText, getNotificationCount}) => {
  const [showMore, setShowMore] = useState<Boolean>(true);
  const [showDismissAll, setShowDismissAll] = useState<Boolean>(true);
  const [itemToShow, setItemToShow] = useState<number>(10);
  const [readItems, setReadItems] = useState<INotificationItem[]>([]);
  const firstAlert = useRef<INotificationItem>();
  const {push} = useHistory();
  const { t, i18n } = useTranslation(['CommonDict']);
  const { sendNotification } = useNotification();
  const notifictaionRef = useRef(sendNotification);
  const token = TokenService.getLocalAccessToken();
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [previousCount, setPreviousCount] = useState(0);
  const [enablePolling, setEnablePolling] = useState(false);
  const {isAlertSwitchEnabled, isAlertSettingsUpdated, toggleAlertsSwitch, getNewAlertsSettings} = useContext(AlertDrawerContext);
  const [enableAlerts, setEnableAlerts] = useState(isAlertSwitchEnabled);
  const [volumeLevel, setVolumeLevel] = useState(50);
  const [audioName, setAudioName] = useState('');
  const [globalSettingsDetails, setGlobalSettingsDetails] = useState<IGlobalAlertSettingsResponse>();
  const updatedDetectionTypes = useRef([...DetectionTypes]);
  const alertsFetchTimer = useRef<any>();
  const optionListRef = useRef<HTMLDivElement | null>(null);
  const isLoadingMore = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if(globalSettingsDetails){
      Object.entries(globalSettingsDetails).forEach(([key, value]) => {
        const targetItem = updatedDetectionTypes.current.find((item) => item.key === key);
        if (targetItem && typeof value === 'boolean') {
          targetItem.state = value;
        }
      });
    }
  }, [globalSettingsDetails, updatedDetectionTypes]);


  useEffect(() => {
    setEnableAlerts(isAlertSwitchEnabled);
  }, [isAlertSwitchEnabled]);

  const loadMoreItems = async () => {
    setItemToShow((prevItemToShow) => prevItemToShow + 10);
    isLoadingMore.current = false;
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      const optionList = optionListRef.current;
      const handleScroll = async () => {
        if (isLoadingMore.current) return;
        if (optionList) {
          const { scrollTop, scrollHeight, clientHeight } = optionList;
          const buffer = 10; // Set your desired buffer value
          if(scrollTop + clientHeight >= scrollHeight - buffer){
            isLoadingMore.current = true;
            await loadMoreItems();
          }
        }
      };
      if (optionList) {
        optionList.addEventListener('scrollend', handleScroll);
      }
      return () => {
        if (optionList) {
          optionList.removeEventListener('scrollend', handleScroll);
        }
      };
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timerId);
  }, []);

  const getFirstTenAlerts = useCallback(async(itemToShow) => {
    const params: IAlertHistoryParams = {
      sort_order: 'desc',
      sort_by: 'timestamp',
      page_size: itemToShow,
      page: 0,
    };
    try {
      const resForFirstAlert = await getAlerts(params);
      if (resForFirstAlert && resForFirstAlert.data.data.length > 0) {
        firstAlert.current = {
          id: resForFirstAlert.data.data[0].id,
          imgUrl: BASE_API_URL + 'alerts/images/' + resForFirstAlert.data.data[0].id + '?access_token=' + token,
          message: resForFirstAlert.data.data[0].message,
          time: resForFirstAlert.data.data[0].timestamp,
          title: resForFirstAlert.data.data[0].display_name,
          streamName: resForFirstAlert.data.data[0].stream_name,
          analysisName: resForFirstAlert.data.data[0].analysis_name
        };
      } else {
        firstAlert.current = {
          id: 1,
          time: new Date().getTime(),
          imgUrl: '',
          message: 'Dummy',
          analysisName: 1,
          streamName: 'Dummy',
          title: 'Dummy',
        };
      }
      const res = await getAlerts({...params, status: 1});
      const alertArr: INotificationItem[] = [];
      if (res) {
        setPreviousCount(res.data.active_count);
        Object.keys(res.data.data).map((i:string) => {
          const item:  INotificationItem ={
            id: res.data.data[parseInt(i)].id,
            imgUrl: BASE_API_URL + 'alerts/images/' + res.data.data[parseInt(i)].id + '?access_token=' + token,
            message: res.data.data[parseInt(i)].message,
            time: res.data.data[parseInt(i)].timestamp,
            title: res.data.data[parseInt(i)].display_name,
            streamName: res.data.data[parseInt(i)].stream_name,
            analysisName: res.data.data[parseInt(i)].analysis_name
          };
          alertArr.push(item);
          return i;
        });
        if(getNotificationCount) {
          getNotificationCount((res.data.active_count as number), []);
        }
      }
      if(alertArr.length === 0) {
        setShowMore(false);
      }
      setReadItems(alertArr);
      isLoadingMore.current = false;
      setEnablePolling(true);
    } catch (err) {
      console.error(err);
    }
  },[token, getNotificationCount]);
  
  useEffect(() => {
    getFirstTenAlerts(itemToShow);
  },[getFirstTenAlerts, itemToShow]);

  const getGlobalAudioAlertSettings = useCallback(async() => {
    try {
      const res = await getGlobalAlertsSettings();
      if(res.data.status_code === 0) {
        setGlobalSettingsDetails(res.data.data.global_alert_audio_setting[0]);
        setEnableAlerts(res.data.data.global_alert_audio_setting[0].global_alert_enabled);
        toggleAlertsSwitch(res.data.data.global_alert_audio_setting[0].global_alert_enabled);
        setVolumeLevel(res.data.data.global_alert_audio_setting[0].volume);
        const audioName = getAudioAlertName(res.data.data.global_alert_audio_setting[0].audio_name);
        setAudioName(audioName);
      }
    } catch(err) {
      console.error(err);
      notifictaionRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    }
  },[t, toggleAlertsSwitch]);

  useEffect(() => {
    getGlobalAudioAlertSettings();
  },[getGlobalAudioAlertSettings]);

  useEffect(() => {
    if (isAlertSettingsUpdated) {
      getGlobalAudioAlertSettings();
      getNewAlertsSettings(false);
    }
  },[isAlertSettingsUpdated, getGlobalAudioAlertSettings, getNewAlertsSettings]);

  const alertsFetchPoll = useCallback(() => {
    alertsFetchTimer.current = setInterval(async () => {
      if(enablePolling){
        const params: IAlertHistoryParams = {
          sort_order: 'desc',
          sort_by: 'timestamp',
          page_size: itemToShow,
          page: 0,
          status: 1
        };
        try {
          const res = await getAlerts(params);
          if(res && res.data.data.length > 0 && previousCount !== res.data.active_count) {
            setPreviousCount(res.data.active_count);
  
            const playAudio = async (audioName: string) => {
              const audioContext = new AudioContext();
              const response = await fetch(audioName);
              const audioData = await response.arrayBuffer();
              const audioBuffer = await audioContext.decodeAudioData(audioData);
                
              const source = audioContext.createBufferSource();
              source.buffer = audioBuffer;
                
              const gainNode = audioContext.createGain();
              gainNode.gain.value = volumeLevel / 100; // Adjust the volume level here
                
              source.connect(gainNode); // Connect source to gain node
              gainNode.connect(audioContext.destination); // Connect gain node to destination
                
              source.start();
                
              return new Promise((resolve) => {
                source.onended = resolve;
              });
            };
            
            const newAlertsList = res.data.data.filter(item => {
              if (firstAlert.current) {
                return (item.timestamp >= firstAlert.current.time && item.id !== firstAlert.current.id);
              }
              return true;
            });

            const playNextAudio = async (index: number) => {
              if (index < newAlertsList.length) {
                const item = newAlertsList[index];
                const foundAlgorithm = updatedDetectionTypes.current.find(algorithmItem => algorithmItem.value === item.analysis_name);
                if(foundAlgorithm?.state){
                  if (audioName) {
                    await playAudio(audioName);
                  }
                }
                await playNextAudio(index + 1);
              }
            };
              
            if (enableAlerts) {
              playNextAudio(0);
            }          
  
            const alertArr: INotificationItem[] = [];
            setShowDismissAll(true);
            setShowMore(true);
            Object.keys(res.data.data).map((i:string) => {
              const item: INotificationItem ={
                id: res.data.data[parseInt(i)].id,
                imgUrl: BASE_API_URL + '/alerts/images/' + res.data.data[parseInt(i)].id + '?access_token=' + token,
                message: res.data.data[parseInt(i)].message,
                time: res.data.data[parseInt(i)].timestamp,
                title: res.data.data[parseInt(i)].display_name,
                streamName: res.data.data[parseInt(i)].stream_name,
                analysisName: res.data.data[parseInt(i)].analysis_name
              };
              alertArr.push(item);
              return i;
            });
            setReadItems(alertArr);
            if (firstAlert.current) {
              if (alertArr[0].time >= firstAlert.current.time && alertArr[0].id !== firstAlert.current.id) {
                firstAlert.current = alertArr[0];
              }
            }
            if(getNotificationCount) {
              getNotificationCount((res.data.active_count as number), alertArr);
            }
          } else if (res && res.data.data.length === 0 && previousCount !== res.data.active_count) {
            setReadItems([]);
            setPreviousCount(res.data.active_count);
            if(getNotificationCount) {
              getNotificationCount(0, []);
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    }, ALERT_FETCH_INTERVAL * 1000);
  }, [audioName, enableAlerts, enablePolling, getNotificationCount, itemToShow, previousCount, token, volumeLevel]);

  useEffect(() => {
    alertsFetchPoll();
    return () => {
      if (alertsFetchTimer.current) {
        clearInterval(alertsFetchTimer.current);
      }
    };
  }, [alertsFetchPoll]);

  if ((readItems.length === 0)) {
    return (
      <Container>
        <EmptyNotification>{noNotificationsText}</EmptyNotification>
      </Container>
    );
  }

  const onClickDismissAll = async() => {
    setLoadingSpinner(true);
    try {   
      const res = await dismissedAlert();
      if(res.data.status_code === 0) {
        setReadItems([]);
        setShowMore(false);
        setShowDismissAll(false);
        if(getNotificationCount) {
          getNotificationCount(0, []); // need to check
        }
        notifictaionRef.current({ type: 'success', message: t('All alerts dismissed successfully') });
      } else {
        notifictaionRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    } catch(err) {
      console.error(err);
      notifictaionRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    }
    setLoadingSpinner(false);
  };

  return (
    <Container>
      {readItems && (
        <Fragment>
          <DismissContainer lang={i18n.language} loading={loadingSpinner}>
            <StatusContainer>{unreadNotificationsText}</StatusContainer>
            {
              TokenService.getUserType() && showDismissAll && <ButtonWithLoading size='xsmall' design='secondary' loading={loadingSpinner} onClick={() => onClickDismissAll()}>{t('Dismiss All')}</ButtonWithLoading>
            }
            
          </DismissContainer>
          <Divider />
          {itemToShow !== 0 &&
            <NotificationContainer ref={optionListRef}>
              {readItems.map((item, index) => {
                return (
                  <NotificationWrapper key={index}>
                    <NotificationItem {...item} />
                  </NotificationWrapper>
                );
              })}
            </NotificationContainer>}
          {isLoadingMore.current ?
            <FetchingConatiner>
              <div>{t('Loading ...')}</div>
            </FetchingConatiner>
            :
            showMore &&
              <ButtonConatiner>
                <ViewAllButton icon='ProductLogs' position='left' size='small' design='secondary' disabled={location.pathname === '/all-alerts'} onClick={() => {push('/all-alerts');}}>{t('View All Alerts')}</ViewAllButton>
              </ButtonConatiner>}
        </Fragment>
      )}
    </Container>
  );
};

export default NotificationsHistory;