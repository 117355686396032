import React, { FC, useState, useCallback, useRef, useEffect, useContext, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader, Content, Button, ButtonWithIcon, FilterDropdown, Switch, Label, useNotification, Spinner, Input, useModal, Tab, TabContent, TabList, Tabs, SmallInput, PercentageSlider, TextField, AlertBar, ButtonWithLoading, TextAreaField } from 'scorer-ui-kit';
import styled from 'styled-components';
import { getGlobalAlertsSettings, sendEmail, updateGlobalAlertsSettings, testHTTPPOST } from '../../services/camerasService';
import { IGlobalAlertSettingsResponse, IAlertSettingsData, IGlobalEmailSettingsResponse, IHTTPPOSTPayload } from '../../interface';
import { formatDate, getAudioAlertName } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import { DATE_FORMAT_WITH_SEC, ENABLE_ADAVANCE_LOW_SPEED_ANALYSIS } from '../../constants';
import clonedeep from 'lodash.clonedeep';
import { isEqual } from 'lodash';
import { AlertDrawerContext } from 'Context/AlertDrawerProvider';

const MainContainer = styled(Content)`
  @media screen and (min-width: 1440px) {
    padding: 67px 168px;
  }
`;

const AddAdditionalNoteTextArea = styled(TextAreaField)`
  resize:none
`;

const UpperContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 942px !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  & > div > div > div{
    bottom: 4px;
  }
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  & div > div > div:nth-child(1) {
    top: -14px;
  }
  & div > div > div:nth-child(3) {
    top: 3px;
  }
`;

const ButtonTopMargin = styled.div`
  display: flex;
  margin-top: -100px;
  & > button:first-child {
    margin-right: 20px;
    background: #e4edf4;
  }
`;

const AlertBarWrapper = styled.div`
  width:100%;
  margin:0 0 12px 0;
`;

const IntroductionText = styled.span`
  height: 60px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: normal;
  color: #7b8288;
  line-height: 1.43;
  letter-spacing: normal;
  margin-top: -10px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 130px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const LoadingText = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
  margin: 0;
  padding: 0;
`;

const AudioSelection = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop ? marginTop : '52px'};
`;

const AudioListDropdown = styled.div<{ width?: string }>`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width};
  margin-top: 8px;
  margin-left: -2px;
`;

const VolumeLevelSlider = styled.div`
  display: flex;
  justify-content: space-between;
  width: 567px;
  margin-top: 8px;
  margin-left: -2px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const DetectionTypeLabel = styled.div`
  height: 24px;
  margin: 5px 85px 17px 0;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5a6269;
`;

const AudioDropdownLabel = styled.span<{ margin?: string }>`
  height: 16px;
  margin: ${({ margin }) => margin};
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
`;

const ButtonWrapper = styled.div<{ lang?: string }>`
  margin-left: 2px;
  & > button {
    width: ${({ lang }) => lang === 'ja' ? '108px' : '104px'};
    padding: 0 9px;
    background: #e4edf4;
    height: 30px;
    :hover:enabled {
      background: #e4edf4;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
  & > button > div > div:nth-child(1) {
    padding-left: ${({ lang }) => lang === 'ja' ? '5px' : '7px'};
    font-size: 14px;
  }
`;

const FilterDropdownWrapper = styled.div`
  margin-top: -2px;
  & > div > div > div > button{
    min-width: 221px;
  }
  & > div > div > div > button > div > div:nth-child(2) {
    padding-right: 80px;
    padding-left: 10px;
    text-align: left;
    width: 191px;
  }
`;

const AlertVolumeLabel = styled(AudioDropdownLabel)`
  margin: 18px 0 0 1px;
`;

const AudioDetectionType = styled.div<{ marginTop?: string }>`
  width: 400px;
  display: flex;
  height: 30px;
  margin-top: ${({ marginTop }) => marginTop ? marginTop : '10px'};
`;

const HTTPToggleSwitchContainer = styled(AudioDetectionType)`
  margin-top: 0;
`;

const SwitchText = styled(Label)`
  margin-left: 11px;
  line-height: 22px;
  text-align: left;
  font-size: 14px;
`;

const DetectionTypeWrapper = styled.div`
  margin-bottom: 40px;
`;

const EndpointContainer = styled.div`
  margin-top: 10px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  margin-top:-8px;
  flex-direction: column;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #7b8288;
  line-height: 1.43;
  margin: 0 25px 0 0;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  padding: 20px 0 0 0;
`;

const Field = styled.div<{ width?:string }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 595px;
  & > div > div > input {
    width: ${({ width })=> width};
  }
  & > p {
    margin-top: 9px;
  }
`;

const EndpointLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
  margin: 0;
  padding: 0;

  ::after{
    font-weight: bold;
    content: " *";
    color: rgb(238, 75, 43);
  }
`;

const TabLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
  margin-top: -1.1px;
`;

const StyledSmallInput = styled(SmallInput)`
  margin-top: -15px;
  margin-left: 10px;
  & > label > div > input{
    width: 82px;
    height: 30px;
    margin: 10px 12px 52px 0;
    padding: 0 22px 0 8px;
    border-radius: 3px;
    border: solid 1px #d9dad9;
    background-color: #fff;
  }
  & > label > div > div{
    right: 28px;
    top: 10px;
  }
`;

const CodeBlock = styled.pre`
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #ccc;
  font-family: Consolas, monospace;
  width: 595px;
`;

const EmailPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 350px;
  margin-top: 17px;
`;

const PreviewBlockHeader = styled.div`
  display:flex;
  justify-content:space-between;
`;

const PreviewLable = styled.label`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: #948F90;
  margin: 0;
  padding: 0;
`;

const EmailPreviewBlock = styled.div`
  width: 470px;
  height:auto !important;
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #ccc;
  font-family: Consolas, monospace;
  margin-top: 10px;
`;

const EmailPreviewBlockItemWrapper = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom}; 
  width: 100%; 
  display: flex;
`;

const EmailPreviewBlockItemOne = styled.div<{ marginRight?: string, width?: string }>`
  white-space: nowrap;
  width: ${({ width }) => width};
  margin-right: ${({ marginRight }) => marginRight};
`;

const EmailPreviewBlockItemTwo = styled.div<{ margin?: string, width?: string, whiteSpace?:string }>`
  word-break: break-all;
  white-space: ${({ whiteSpace }) => whiteSpace };
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
`;

const payload = {
  'id': '<内部カメラID CAMXX>',
  'name': '<カメラ名称>',
  'timestamp': '<UNIXタイムスタンプ>',
  'detection_time':  '<検知時刻 YYYY-MM-DD HH:mm:ss+/-hhmm>',
  'alert_type': '<解析タイプ>',
  'image': '<BASE64エンコードされたカメラの画像>'
};

const StyledInput = styled(Input)`
  ::placeholder {
    font-style: normal;
  }  
`;

const PercentageSliderWrapper = styled.div`
  & > div {
    width: 230px;
    margin-top: -12px;
  }
  & > div > div:nth-child(1) {
    margin-bottom: 0;
    margin-right: 94px;
  }
  & > div > div:nth-child(2) {
    margin-top: 0;
  }
  & > div > div:nth-child(1) > label:nth-child(2) {
    margin-bottom: 1px;
  }
`;

const EmailDetectionTypeWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const AlertBarContainer = styled.div`
  max-width: 940px !important;
  margin-top: 20px;
`;

const EmailSettingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 942px !important;
  margin-top: -8px;
`;

const InputFieldWrapper = styled.div``;

const InputFieldBox = styled.div`
  margin-top: 20px;
  min-width: 350px;
`;

const TextFieldRequired = styled.div`
  span::after{
    font-weight: bold;
    content: " *";
    color: rgb(238, 75, 43);
  }
`;

const ButtonAligner = styled.div<{ cc_bcc?: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-top: -28px;
  margin-bottom: ${({ cc_bcc }) => cc_bcc ? '0px' : '-20px'};
`;

const CommonButtonStyling = styled.div`
  & > button {
    width: 60px;
    height: 20px;
    background: #e4edf4;
    padding: 0 5px;
    font-size: 10px;
    margin-left: 8px;
    :hover:enabled {
      background: #e4edf4;
    }
    :disabled {
      background: #e4edf4;
    }
  }
`;

const ButtonContainer = styled(CommonButtonStyling)`
  margin-top: 14px;
  display: flex;
`;

const RemoveButtonContainer = styled(CommonButtonStyling)`
  margin-top: 10px;
  & > button {
    width: 80px;
  }
`;

const CancelModalContainer = styled.div`
  padding: 18px 48px 22px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CancelConfirmText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
  & > button:nth-child(1) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }

  & > button:nth-child(3) {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const CancelChangesButton = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const SaveContinueLoadingButton = styled(ButtonWithLoading)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const PreviewBlockHeaderItem = styled.div`
  align-self:center;
`;

const POSTLableContainer = styled.div`
  margin-top: 52px;
  display: flex;
  justify-content: space-between;
  max-width: 595px;
`;

const TestPostAlertWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: -30px;
  max-width: 595px;
`;

const dropdownOption = [
  { value: 'Twinkle-Sound', text: 'Twinkle-Sound' },
  { value: 'Wrong-Answer', text: 'Wrong-Answer' },
  { value: 'Interface', text: 'Interface' },
  { value: 'Ding-Idea', text: 'Ding-Idea' }
];

const marks = [
  { value: 0, label: '0%' },
  { value: 20, label: '20%' },
  { value: 40, label: '40%' },
  { value: 60, label: '60%' },
  { value: 80, label: '80%' },
  { value: 100, label: '100%' }
];


const AlertDetectionTypes = [
  { value: 'congestion_and_stop_audio_enabled', label: 'Congestion & Stop', state: false },
  { value: 'low_speed_detection_audio_enabled', label: 'Low Speed', state: false },
  ...(ENABLE_ADAVANCE_LOW_SPEED_ANALYSIS ?
    [{ value: 'advanced_low_speed_detection_audio_enabled', label: 'Advanced - Low Speed', state: false }]
    : []),
  { value: 'frequent_lane_changes_audio_enabled', label: 'Frequent Lane Change', state: false },
  { value: 'reverse_run_detection_audio_enabled', label: 'Reverse Run', state: false }
];

const EmailDetectionTypes = [
  { value: 'congestion_and_stop_email_enabled', label: 'Congestion & Stop', state: false },
  { value: 'low_speed_detection_email_enabled', label: 'Low Speed', state: false },
  ...(ENABLE_ADAVANCE_LOW_SPEED_ANALYSIS ?
    [{ value: 'advanced_low_speed_detection_email_enabled', label: 'Advanced - Low Speed', state: false }]
    : []),
  { value: 'frequent_lane_changes_email_enabled', label: 'Frequent Lane Change', state: false },
  { value: 'reverse_run_detection_email_enabled', label: 'Reverse Run', state: false }
];

type key = 'sender' | 'recipient' | 'cc' | 'bcc' | 'subject_prefix';

const AlertConfiguration: FC = () => {
  const [isChangesInProgress,setIsChangesInProgress]=useState(false);
  const { t, i18n } = useTranslation(['CommonDict']);
  const [alertType, setAlertType] = useState<string | null>(null);
  const [newAlertSound, setNewAlertSound] = useState({ text: 'Twinkle-Sound', value: 'Twinkle-Sound' });
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { sendNotification } = useNotification();
  const notifictaionRef = useRef(sendNotification);
  const { isAlertSwitchEnabled, toggleAlertsSwitch, getNewAlertsSettings } = useContext(AlertDrawerContext);
  const [alertGlobalSettingsDetails, setAlertGlobalSettingsDetails] = useState<IGlobalAlertSettingsResponse>({ audio_name: '', http_post_endpoint_enabled: false, global_alert_enabled: isAlertSwitchEnabled, url: '', volume: 100, low_speed_detection_audio_enabled: false, advanced_low_speed_detection_audio_enabled: false, congestion_and_stop_audio_enabled: false, frequent_lane_changes_audio_enabled: false, reverse_run_detection_audio_enabled: false });
  const [prevAlertGlobalSettingsDetails, setPrevAlertGlobalSettingsDetails] = useState<IGlobalAlertSettingsResponse>({ audio_name: '', http_post_endpoint_enabled: false, global_alert_enabled: false, url: '', volume: 100, low_speed_detection_audio_enabled: false, advanced_low_speed_detection_audio_enabled: false, congestion_and_stop_audio_enabled: false, frequent_lane_changes_audio_enabled: false, reverse_run_detection_audio_enabled: false });
  const [emailGlobalSettingsDetails, setEmailGlobalSettingsDetails] = useState<IGlobalEmailSettingsResponse>({ sender: '', recipient: '', cc: '', bcc: '', subject_prefix: '', enabled: false, low_speed_detection_email_enabled: false, advanced_low_speed_detection_email_enabled: false, congestion_and_stop_email_enabled: false, frequent_lane_changes_email_enabled: false, reverse_run_detection_email_enabled: false, notes: '', attachment_enabled: false });
  const [prevEmailGlobalSettingsDetails, setPrevEmailGlobalSettingsDetails] = useState<IGlobalEmailSettingsResponse>({ sender: '', recipient: '', cc: '', bcc: '', subject_prefix: '', enabled: false, low_speed_detection_email_enabled: false, advanced_low_speed_detection_email_enabled: false, congestion_and_stop_email_enabled: false, frequent_lane_changes_email_enabled: false, reverse_run_detection_email_enabled: false, notes: '', attachment_enabled: false });
  const [alertDetectionTypes, setAlertDetectionTypes] = useState(AlertDetectionTypes);
  const [emailDetectionTypes, setEmailDetectionTypes] = useState(EmailDetectionTypes);
  const [loading, setLoading] = useState(true);
  const [soundPlaying, setSoundPlaying] = useState(false);
  const { createModal, setModalOpen, isModalOpen } = useModal();
  const [selectedTab, setSelectedTab] = useState<string>('audio_settings');
  const { replace } = useHistory();
  const params = useLocation().search;
  const [historyParams] = useState<string>(params);
  const tRef = useRef(t);
  const [cc, setCC] = useState(false);
  const [bcc, setBCC] = useState(false);
  const [isSendEmailOnProgress, setIsSendEmailOnProgress] = useState(false);
  const [emailServiceConfigured, setEmailServiceConfigured] = useState(true);
  const [testButtonLoading, setTestButtonLoading] = useState(false);
  const [httpPostResponse, setHttpPostResponse] = useState<string | null>(null);
  const [modalInProgressButton,setModalInProgressButton]=useState(false);
  const prevTab=useRef('');
  const [EmailSettings, setEmailSettings] = useState([
    { name: 'From', value: 'sender@example.com' },
    { name: 'To', value: 'receiver@example.com' },
    { name: 'Date', value: formatDate(new Date().getTime()/1000,DATE_FORMAT_WITH_SEC)},
    { name: 'Subject', value: '' },
    { name: 'CC', value: '' },
    { name: 'BCC', value: '' },
    { name: 'Note', value: '' },
    { name: 'Camera Name', value: 'CAM01' },
    { name: 'Detection Location', value: 'Area01' },
    { name: 'Detection Type', value: 'Reverse Run' },
    { name: 'Detection Details', value: 'Reverse run detected for more than 20 seconds' },
    { name: 'Detection Time', value: '2023-01-01 14:59:12' }
  ]);

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const sendTestMail = useCallback(async () => {
    try {
      const payload = {
        action: 'validate',
        data:{
          from: EmailSettings[0].value,
          to: EmailSettings[1].value,
          subject: (EmailSettings[3].value ? EmailSettings[3].value + ' ' : '') + t('Reverse run detected'),
          body: `${emailGlobalSettingsDetails.attachment_enabled?'\n\n':''}${EmailSettings[6].value?`${EmailSettings[6].value}\n\n`:''}Detection Time: ${EmailSettings[11].value}\nCamera Name: ${EmailSettings[7].value}\nDetection Location: ${EmailSettings[8].value}\nDetection Type: ${EmailSettings[9].value}\nDetection Details: ${EmailSettings[10].value}`
        }
      };
      if (!payload.data.from) {
        sendNotification({  type: 'error', message: t('Please enter the sender email address') });
        return;
      }
      if (!validateEmail(payload.data.from)) {
        sendNotification({ type: 'error', message: t('Please enter valid sender email address') });
        return;
      }
      if (!payload.data.to) {
        sendNotification({ type: 'error', message: t('Please enter the receiver email address') });
        return;
      }
      const checkRecipientEmail = payload.data.to.split(',').every((email: string) => validateEmail(email));
      if (!checkRecipientEmail) {
        sendNotification({ type: 'error', message: t('Please enter valid receiver email address') });
        return;
      }
      setIsSendEmailOnProgress(true);
      const response = await sendEmail(payload);
      if (response && response.data && response.data.status_code === '10000') {
        setAlertType('success');
      } else {
        setAlertType('error');
      }
      const testMailMessageTime = setTimeout(() => {
        setAlertType(null);
      }, 5000);
      setIsSendEmailOnProgress(false);
      return () => clearTimeout(testMailMessageTime);
    } catch (error) {
      console.log(error);
      setAlertType('error');
      setIsSendEmailOnProgress(false);
    }
  }, [sendNotification, t, EmailSettings,emailGlobalSettingsDetails]);

  const fetchHistoryParams = useCallback(() => {
    const urlString = new URLSearchParams(historyParams);
    const tabName = urlString.get('tab');
    if (tabName) {
      setSelectedTab(tabName);
    } else {
      setSelectedTab('audio_settings');
      replace(window.location.pathname + '?tab=audio_settings');
    }
  }, [historyParams, setSelectedTab, replace]);

  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);

  useEffect(() => {
    setAlertGlobalSettingsDetails(prev => {
      const temp = clonedeep(prev);
      temp.global_alert_enabled = isAlertSwitchEnabled;
      return temp;
    });
  }, [isAlertSwitchEnabled]);

  const getGlobalAudioAlertSettings = useCallback(async () => {
    try {
      const res = await getGlobalAlertsSettings();
      if (res.data.status_code === 0) {
        if (res.data.data.global_alert_audio_setting.length > 0) {
          setAlertGlobalSettingsDetails(res.data.data.global_alert_audio_setting[0]);
          toggleAlertsSwitch(res.data.data.global_alert_audio_setting[0].global_alert_enabled);
          setPrevAlertGlobalSettingsDetails(res.data.data.global_alert_audio_setting[0]);
          if(res.data.data.global_alert_audio_setting[0].audio_name) setNewAlertSound({ text: res.data.data.global_alert_audio_setting[0].audio_name, value: res.data.data.global_alert_audio_setting[0].audio_name });
        }
        if (res.data.data.email_alert_configuration_setting.length > 0) {
          setEmailGlobalSettingsDetails(res.data.data.email_alert_configuration_setting[0]);
          if (res.data.data.email_alert_configuration_setting[0].bcc.length) {
            setBCC(true);
          }
          if (res.data.data.email_alert_configuration_setting[0].cc.length) {
            setCC(true);
          }
          setEmailSettings(prev => {
            if (res.data.data.email_alert_configuration_setting[0].notes) prev[6].value = res.data.data.email_alert_configuration_setting[0].notes;
            if (res.data.data.email_alert_configuration_setting[0].cc) prev[4].value = res.data.data.email_alert_configuration_setting[0].cc;
            if (res.data.data.email_alert_configuration_setting[0].bcc) prev[5].value = res.data.data.email_alert_configuration_setting[0].bcc;
            if (res.data.data.email_alert_configuration_setting[0].sender) prev[0].value = res.data.data.email_alert_configuration_setting[0].sender;
            if (res.data.data.email_alert_configuration_setting[0].recipient) prev[1].value = res.data.data.email_alert_configuration_setting[0].recipient;
            if (res.data.data.email_alert_configuration_setting[0].subject_prefix) prev[3].value = res.data.data.email_alert_configuration_setting[0].subject_prefix;
            return [...prev];
          });
          setPrevEmailGlobalSettingsDetails(res.data.data.email_alert_configuration_setting[0]);
          setEmailServiceConfigured(true);
        } else {
          setEmailServiceConfigured(false);
        }
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      notifictaionRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
    }
  }, [toggleAlertsSwitch]);

  useEffect(() => {
    const alertUpdatedDetectionTypes = [...AlertDetectionTypes];
    if (alertGlobalSettingsDetails) {
      Object.entries(alertGlobalSettingsDetails).forEach(([key, value]) => {
        const targetItem = alertUpdatedDetectionTypes.find((item) => item.value === key);
        if (targetItem && typeof value === 'boolean') {
          targetItem.state = value;
        }
      });
    }
    const emailUpdatedDetectionTypes = [...EmailDetectionTypes];
    if (emailGlobalSettingsDetails) {
      Object.entries(emailGlobalSettingsDetails).forEach(([key, value]) => {
        const targetItem = emailUpdatedDetectionTypes.find((item) => item.value === key);
        if (targetItem && typeof value === 'boolean') {
          targetItem.state = value;
        }
      });
    }
    setAlertDetectionTypes(alertUpdatedDetectionTypes);
  }, [alertGlobalSettingsDetails, emailGlobalSettingsDetails]);

  useEffect(() => {
    getGlobalAudioAlertSettings();
  }, [getGlobalAudioAlertSettings]);

  const onChangeAlertSettings = useCallback((formData) => {
    setAlertGlobalSettingsDetails({ ...formData });
    toggleAlertsSwitch(formData.global_alert_enabled);
  }, [toggleAlertsSwitch]);

  const onChangeEmailSettings = useCallback((formData) => {
    setEmailGlobalSettingsDetails({ ...formData });
  }, []);

  const removeCC = useCallback(() => {
    setCC(false);
    setEmailSettings(prev => {
      prev[4].value = '';
      return [...prev];
    });
    const newEmailGlobalSettingsDetails = clonedeep(emailGlobalSettingsDetails);
    newEmailGlobalSettingsDetails.cc = '';
    onChangeEmailSettings(newEmailGlobalSettingsDetails);
  }, [onChangeEmailSettings, emailGlobalSettingsDetails]);

  const removeBCC = useCallback(() => {
    setBCC(false);
    setEmailSettings(prev => {
      prev[5].value = '';
      return [...prev];
    });
    const newEmailGlobalSettingsDetails = clonedeep(emailGlobalSettingsDetails);
    newEmailGlobalSettingsDetails.bcc = '';
    onChangeEmailSettings(newEmailGlobalSettingsDetails);
  }, [onChangeEmailSettings, emailGlobalSettingsDetails]);

  const handleEndpointChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (alertGlobalSettingsDetails) {
      const newAlertGlobalSettingsDetails = clonedeep(alertGlobalSettingsDetails);
      newAlertGlobalSettingsDetails.url = event.target.value;
      onChangeAlertSettings(newAlertGlobalSettingsDetails);
    }
  }, [alertGlobalSettingsDetails, onChangeAlertSettings]);

  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        if (document.activeElement?.id === 'YesButton') {
          document.getElementById('CancelButton')?.focus();
          return;
        }
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        if (document.activeElement?.id === 'YesButton') {
          document.getElementById('CancelButton')?.focus();
          return;
        }
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('CancelButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'CancelButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        else if (document.activeElement?.id === 'YesButton') {
          document.getElementById('CancelButton')?.focus();
          return;
        }
        document.getElementById('CancelButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('CancelButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
  }, [isModalOpen, arrowEvent]);

  const handleCancel = useCallback(async (setModalOpen: (newStatus: boolean) => void) => {
    await setModalOpen(false);
    if(prevTab.current){
      prevTab.current='';
    }
    getGlobalAudioAlertSettings();
  }, [getGlobalAudioAlertSettings]);

  const handleCancleNo = useCallback(() => {
    if(prevTab.current){
      setSelectedTab(prevTab.current);
      replace(window.location.pathname + '?tab=' + prevTab.current); 
      prevTab.current='';
    }
    setModalOpen(false);
  }, [setModalOpen,replace,prevTab]);

  const handleSaveChanges = useCallback(async () => {
    try {
      const clone_emailGlobalSettingsDetails = clonedeep(emailGlobalSettingsDetails);
      clone_emailGlobalSettingsDetails.sender = clone_emailGlobalSettingsDetails.sender?clone_emailGlobalSettingsDetails.sender.replace(/\s/g, ''):'';
      clone_emailGlobalSettingsDetails.cc = clone_emailGlobalSettingsDetails.cc?clone_emailGlobalSettingsDetails.cc.split(',').filter(item => item.replace(/\s/g, '') !== '').map(item => item.replace(/\s/g, '')).join(','):'';
      clone_emailGlobalSettingsDetails.bcc = clone_emailGlobalSettingsDetails.bcc?clone_emailGlobalSettingsDetails.bcc.split(',').filter(item => item.replace(/\s/g, '') !== '').map(item => item.replace(/\s/g, '')).join(','):'';
      clone_emailGlobalSettingsDetails.recipient = clone_emailGlobalSettingsDetails.recipient?clone_emailGlobalSettingsDetails.recipient.split(',').filter(item => item.replace(/\s/g, '') !== '').map(item => item.replace(/\s/g, '')).join(','):'';
      clone_emailGlobalSettingsDetails.subject_prefix = clone_emailGlobalSettingsDetails.subject_prefix.trim();
      clone_emailGlobalSettingsDetails.notes=clone_emailGlobalSettingsDetails.notes.trim();
      const alertSettingsArray = [];
      const emailSettingsArray = [];
      let errorFound=false;
      if (clone_emailGlobalSettingsDetails.enabled) {
        if (!clone_emailGlobalSettingsDetails.sender) {
          if(!errorFound){
            sendNotification({ type: 'error', message: t('Please enter the sender email address') });
            errorFound=true;
          }
        }
        if (!validateEmail(clone_emailGlobalSettingsDetails.sender)) {
          if(!errorFound){
            sendNotification({ type: 'error', message: t('Please enter valid sender email address') });
            errorFound=true;
          }
        }
        if (!clone_emailGlobalSettingsDetails.recipient) {
          if(!errorFound){
            sendNotification({ type: 'error', message: t('Please enter the receiver email address') });
            errorFound=true;
          }
        }
        const checkRecipientEmail = clone_emailGlobalSettingsDetails.recipient.split(',').every(email => validateEmail(email));
        if (!checkRecipientEmail) {
          if(!errorFound){
            sendNotification({ type: 'error', message: t('Please enter valid receiver email address') });
            errorFound=true;
          }
        }
        if (clone_emailGlobalSettingsDetails.bcc && bcc) {
          const checkBccEmail = clone_emailGlobalSettingsDetails.bcc.split(',').every(email => validateEmail(email));
          if (!checkBccEmail) {
            if(!errorFound){
              sendNotification({ type: 'error', message: t('Please enter valid BCC email address') });
              errorFound=true;
            }
          }
        }
        if (clone_emailGlobalSettingsDetails.cc && cc) {
          const checkCcEmail = clone_emailGlobalSettingsDetails.cc.split(',').every(email => validateEmail(email));
          if (!checkCcEmail) {
            if(!errorFound){
              sendNotification({ type: 'error', message: t('Please enter valid CC email address') });
              errorFound=true;
            }
          }
        }
      }

      if (alertGlobalSettingsDetails.http_post_endpoint_enabled) {
        const urlRegEx = /^(http|https):\/\//;
        if (!alertGlobalSettingsDetails.url) {
          if(!errorFound){
            sendNotification({ type: 'error', message: t('Please enter the endpoint') }); // need to add jp
            errorFound=true;
          }
        }
        if (!urlRegEx.test(alertGlobalSettingsDetails.url)) {
          if(!errorFound){
            sendNotification({ type: 'error', message: t('Invalid HTTP URL') });
            errorFound=true;
          }
        }
      }

      if(errorFound){
        if(prevTab.current){
          setSelectedTab(prevTab.current);
          replace(window.location.pathname + '?tab=' + prevTab.current); 
          prevTab.current='';
          setModalOpen(false);
        }
        return;
      }

      
      alertSettingsArray.push(alertGlobalSettingsDetails);
      emailSettingsArray.push(clone_emailGlobalSettingsDetails);

      const payload: IAlertSettingsData = {
        global_alert_audio_setting: alertSettingsArray,
        email_alert_configuration_setting: emailSettingsArray
      };

      alertDetectionTypes.forEach((item) => {
        if (item.value in payload) {
          payload.global_alert_audio_setting[0][item.value] = item.state;
        }
      });
      if (emailGlobalSettingsDetails.enabled) {
        emailDetectionTypes.forEach((item) => {
          if (item.value in payload) {
            payload.email_alert_configuration_setting[0][item.value] = item.state;
          }
        });
      }
      if (alertGlobalSettingsDetails.volume.toString() === '' || Number.isNaN(alertGlobalSettingsDetails.volume)) {
        notifictaionRef.current({ type: 'error', message: tRef.current('Please enter the volume level') });
      } else {
        if(prevTab.current) setModalInProgressButton(true);
        else setIsChangesInProgress(true);
        const res = await updateGlobalAlertsSettings(payload);
        if(prevTab.current) setModalInProgressButton(false);
        else setIsChangesInProgress(false);
        if (res.data.status_code === 0) {
          notifictaionRef.current({ type: 'success', message: tRef.current('Alerts setting saved successfully') });
        }
        getGlobalAudioAlertSettings();
        getNewAlertsSettings(true);
        if(prevTab.current){
          prevTab.current='';
          setModalOpen(false);
        }
      }
    } catch (err) {
      console.error(err);
      notifictaionRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
      setIsChangesInProgress(false);
    }
  }, [alertDetectionTypes,replace, cc, bcc, alertGlobalSettingsDetails, t, setModalOpen, sendNotification, getGlobalAudioAlertSettings, getNewAlertsSettings, emailGlobalSettingsDetails, emailDetectionTypes]);


  const goToPreviousPage = useCallback((tabName:string='') => {
    createModal({
      isCloseEnable: false,
      width: tabName?i18n.language === 'ja'?'500px':'480px':'480px',
      padding: false,
      dismissCallback: () => { 
        if(prevTab.current){
          setSelectedTab(prevTab.current);
          replace(window.location.pathname + '?tab=' + prevTab.current); 
          prevTab.current='';
        }
        setModalOpen(false); 
      },
      customComponent: (
        <CancelModalContainer>
          <CancelConfirmText>{tRef.current(!tabName?'Are you sure you want to cancel the changes?':'You have unsaved changes. Do you want to save?')}</CancelConfirmText>
          <ModalButtonContainer>
            <CancelChangesButton design='secondary' id='CancelButton' onClick={() => handleCancleNo()}>{tRef.current(tabName?'Cancel':'No')}</CancelChangesButton>
            {tabName &&  <SaveContinueLoadingButton loading={modalInProgressButton} type='submit' design='primary' onClick={handleSaveChanges}>{tRef.current('Save')}</SaveContinueLoadingButton>}
            <CancelChangesButton design={tabName?'secondary':'primary'} id='YesButton' onClick={() => handleCancel(setModalOpen)}>{tRef.current(tabName?'Discard':'Yes')}</CancelChangesButton>
          </ModalButtonContainer>
        </CancelModalContainer>),
    });
  }, [createModal, setModalOpen, i18n, replace, handleCancel, handleCancleNo,modalInProgressButton,handleSaveChanges]);

  const configureNewAlertSound = useCallback((e) => {
    setNewAlertSound(e);
    if (alertGlobalSettingsDetails) {
      const newAlertGlobalSettingsDetails = clonedeep(alertGlobalSettingsDetails);
      newAlertGlobalSettingsDetails.audio_name = e.value;
      onChangeAlertSettings(newAlertGlobalSettingsDetails);
    }
  }, [alertGlobalSettingsDetails, onChangeAlertSettings]);

  const checkAlertSound = useCallback(() => {
    setSoundPlaying(true);
    const audioName = getAudioAlertName(newAlertSound.text);
    const audio = new Audio(audioName);
    audio.volume = alertGlobalSettingsDetails.volume / 100;
    audio.play();
    audio.addEventListener('ended', () => {
      setSoundPlaying(false);
    });
  }, [newAlertSound, alertGlobalSettingsDetails]);

  const onChangeSwitch = (value: boolean, detectionTypeValue: string, settingsName: string) => {
    if (settingsName === 'alert_settings') {
      setAlertDetectionTypes((prevDetectionTypes) => {
        return prevDetectionTypes.map((item) => {
          if (item.value === detectionTypeValue) {
            return { ...item, state: value };
          }
          return item;
        });
      });
      if (alertGlobalSettingsDetails) {
        const newAlertGlobalSettingsDetails = clonedeep(alertGlobalSettingsDetails);
        newAlertGlobalSettingsDetails[detectionTypeValue] = value;
        onChangeAlertSettings(newAlertGlobalSettingsDetails);
      }
    } else {
      setEmailDetectionTypes((prevDetectionTypes) => {
        return prevDetectionTypes.map((item) => {
          if (item.value === detectionTypeValue) {
            return { ...item, state: value };
          }
          return item;
        });
      });
      if (emailGlobalSettingsDetails) {
        const newEmailGlobalSettingsDetails = clonedeep(emailGlobalSettingsDetails);
        newEmailGlobalSettingsDetails[detectionTypeValue] = value;
        onChangeEmailSettings(newEmailGlobalSettingsDetails);
      }
    }
  };

  const onClickTab = useCallback((tabName: string) => {
    setSelectedTab(tabName);
    replace(window.location.pathname + '?tab=' + tabName);
    if(!(isEqual(alertGlobalSettingsDetails, prevAlertGlobalSettingsDetails) && isEqual(emailGlobalSettingsDetails, prevEmailGlobalSettingsDetails))){
      prevTab.current=selectedTab;
      goToPreviousPage(tabName);
    }
  }, [replace,goToPreviousPage,selectedTab,alertGlobalSettingsDetails,prevAlertGlobalSettingsDetails,emailGlobalSettingsDetails,prevEmailGlobalSettingsDetails]);

  const handleSliderUpdate = useCallback((value: number) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    const audioName = getAudioAlertName(newAlertSound.text);
    const audio = new Audio(audioName);
    audio.volume = value / 100;
    audio.play();

    audioRef.current = audio;
    if (alertGlobalSettingsDetails) {
      const newAlertGlobalSettingsDetails = clonedeep(alertGlobalSettingsDetails);
      newAlertGlobalSettingsDetails.volume = value;
      onChangeAlertSettings(newAlertGlobalSettingsDetails);
    }
  }, [newAlertSound, alertGlobalSettingsDetails, onChangeAlertSettings]);

  const onChangeAlertvolume = useCallback((e) => {
    const inputValue = e.target.value;
    const regex = /^(?:100|[0-9]|[1-9][0-9])?$/;

    if (regex.test(inputValue)) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      const audioName = getAudioAlertName(newAlertSound.text);
      const audio = new Audio(audioName);
      audio.volume = inputValue / 100;
      audio.play();
      audioRef.current = audio;
      if (alertGlobalSettingsDetails) {
        const newAlertGlobalSettingsDetails = clonedeep(alertGlobalSettingsDetails);
        newAlertGlobalSettingsDetails.volume = parseInt(inputValue);
        onChangeAlertSettings(newAlertGlobalSettingsDetails);
      }
    }
  }, [newAlertSound, alertGlobalSettingsDetails, onChangeAlertSettings]);

  const sliderColorHandler = useCallback((value: number) => {
    if ((value >= 0) && (value <= 100)) {
      return 'info';
    }
    return 'error';
  }, []);

  const sliderTitleHandler = () => {
    return '';
  };

  const onChangeGlobalEnpointStatus = useCallback((value) => {
    if (alertGlobalSettingsDetails) {
      const newAlertGlobalSettingsDetails = clonedeep(alertGlobalSettingsDetails);
      newAlertGlobalSettingsDetails.http_post_endpoint_enabled = value;
      onChangeAlertSettings(newAlertGlobalSettingsDetails);
    }
  }, [alertGlobalSettingsDetails, onChangeAlertSettings]);

  const onChangeGlobalEmailAlert = useCallback((value) => {
    if (emailGlobalSettingsDetails) {
      const newEmailGlobalSettingsDetails = clonedeep(emailGlobalSettingsDetails);
      newEmailGlobalSettingsDetails.enabled = value;
      if (newEmailGlobalSettingsDetails.enabled) {
        setEmailDetectionTypes((prevDetectionTypes) => {
          return prevDetectionTypes.map((item) => {
            item.state = true;
            return item;
          });
        });
        EmailDetectionTypes.forEach((item:any) => {
          newEmailGlobalSettingsDetails[item.value] = true;
        });
      }
      onChangeEmailSettings(newEmailGlobalSettingsDetails);
    }
  }, [emailGlobalSettingsDetails, onChangeEmailSettings]);

  const onChangeGlobalAlert = useCallback((value) => {
    if (alertGlobalSettingsDetails) {
      const newAlertGlobalSettingsDetails = clonedeep(alertGlobalSettingsDetails);
      newAlertGlobalSettingsDetails.global_alert_enabled = value;
      onChangeAlertSettings(newAlertGlobalSettingsDetails);
    }
  }, [alertGlobalSettingsDetails, onChangeAlertSettings]);

  const onEmailInputChange = useCallback((key: key) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (emailGlobalSettingsDetails) {
      const newEmailGlobalSettingsDetails = clonedeep(emailGlobalSettingsDetails);
      newEmailGlobalSettingsDetails[key] = (key === 'sender') ? value.split(',').filter(item => item.replace(/\s/g, '') !== '').map(item => item.replace(/\s/g, ''))[0] : value;
      setEmailSettings(old => {
        if (key === 'sender') old[0].value = value ? value.split(',').filter(item => item.replace(/\s/g, '') !== '').map(item => item.replace(/\s/g, ''))[0] : 'sender@example.com';
        if (key === 'subject_prefix') old[3].value = value ? value : '';
        if (key === 'recipient') old[1].value = value ? value.split(',').filter(item => item.replace(/\s/g, '') !== '').map(item => item.replace(/\s/g, '')).join(',') : 'recevier@example.com';
        if (key === 'cc') old[4].value = value.split(',').filter(item => item.replace(/\s/g, '') !== '').map(item => item.replace(/\s/g, '')).join(',');
        if (key === 'bcc') old[5].value = value.split(',').filter(item => item.replace(/\s/g, '') !== '').map(item => item.replace(/\s/g, '')).join(',');
        return [...old];
      });
      onChangeEmailSettings(newEmailGlobalSettingsDetails);
    }
  }, [emailGlobalSettingsDetails, onChangeEmailSettings]);

  const onChangeNotes = useCallback(({ target: { name, value } }) => {
    if (emailGlobalSettingsDetails) {
      const newEmailGlobalSettingsDetails = clonedeep(emailGlobalSettingsDetails);
      newEmailGlobalSettingsDetails[name] = value;
      setEmailSettings(old => {
        old[6].value = value;
        return [...old];
      });
      onChangeEmailSettings(newEmailGlobalSettingsDetails);
    }
  }, [emailGlobalSettingsDetails, onChangeEmailSettings]);

  const testHTTPPOSTPayload = async() => {
    setTestButtonLoading(true);
    try {   
      const payload: IHTTPPOSTPayload = {action: 'test', http_endpoint: alertGlobalSettingsDetails?.url};
      const res = await testHTTPPOST(payload);
      if(res.data.status_code === '0') {
        setHttpPostResponse('success');
      } else {
        setHttpPostResponse('error');
      }
      const testHTTPPOSTTime = setTimeout(() => {
        setHttpPostResponse(null);
      }, 5000);
      setTestButtonLoading(false);
      return () => clearTimeout(testHTTPPOSTTime);
    } catch(err) {
      console.error(err);
      setHttpPostResponse('error');
      const testHTTPPOSTTime = setTimeout(() => {
        setHttpPostResponse(null);
      }, 5000);
      setTestButtonLoading(false);
      return () => clearTimeout(testHTTPPOSTTime);
    }
  };

  return (
    <MainContainer id='mainContainer'>
      <UpperContainer>
        <PageHeaderWrapper>
          <PageHeader title={tRef.current('Alerts')} areaTitle={tRef.current('Settings')} icon='ViewSettings' updateDocTitle={false} />
          <IntroductionText>{tRef.current('Configure the alert output options.')}</IntroductionText>
        </PageHeaderWrapper>
        <ButtonTopMargin>
          <Button design='secondary' disabled={isEqual(alertGlobalSettingsDetails, prevAlertGlobalSettingsDetails) && isEqual(emailGlobalSettingsDetails, prevEmailGlobalSettingsDetails)} onClick={()=>goToPreviousPage('')}>{t('Cancel')}</Button>
          <ButtonWithLoading loading={isChangesInProgress} type='submit' design='primary' disabled={isEqual(alertGlobalSettingsDetails, prevAlertGlobalSettingsDetails) && isEqual(emailGlobalSettingsDetails, prevEmailGlobalSettingsDetails)} onClick={handleSaveChanges}>{tRef.current('Save Changes')}</ButtonWithLoading>
        </ButtonTopMargin>
      </UpperContainer>
      {loading ?
        <SpinnerContainer>
          <Spinner size='large' styling='primary' />
          <LoadingText>{tRef.current('Loading')}</LoadingText>
        </SpinnerContainer> :
        <Tabs>
          <TabList defaultTabId={selectedTab}>
            <Tab tabFor='audio_settings' onClick={() => onClickTab('audio_settings')}>{tRef.current('Audio')}</Tab>
            <Tab tabFor='email_settings' onClick={() => onClickTab('email_settings')}>{tRef.current('Email')}</Tab>
            <Tab tabFor='endpoint_settings' onClick={() => onClickTab('endpoint_settings')}>{tRef.current('HTTP POST')}</Tab>
          </TabList>
          <TabLine />
          <TabContent tabId='audio_settings'>
            <AudioSelection>
              <AudioDetectionType key=''>
                <Switch checked={alertGlobalSettingsDetails.global_alert_enabled} state='default' onChangeCallback={onChangeGlobalAlert} />
                <SwitchText htmlFor='' labelText={tRef.current('Play Sound For Alerts')} />
              </AudioDetectionType>
              <AudioListDropdown width={i18n.language === 'ja' ? '580px' : '576px'}>
                <AudioDropdownLabel margin={i18n.language === 'ja' ? '4px 124px 17px 1px' : '4px 72px 17px 1px'}>{tRef.current('Sound For Audible Alerts')}</AudioDropdownLabel>
                <FilterDropdownWrapper>
                  <FilterDropdown
                    buttonIcon=''
                    buttonText={newAlertSound.text}
                    list={dropdownOption}
                    loadingText={tRef.current('Loading Sounds...')}
                    maxDisplayedItems={6}
                    onSelect={configureNewAlertSound}
                    optionType='radio'
                    selected={newAlertSound}
                  />
                </FilterDropdownWrapper>
                <ButtonWrapper lang={i18n.language}><ButtonWithIcon icon='Play' position='left' design='secondary' disabled={soundPlaying} onClick={checkAlertSound}>{soundPlaying ? tRef.current('Playing') : tRef.current('Preview')}</ButtonWithIcon></ButtonWrapper>
              </AudioListDropdown>
              <VolumeLevelSlider>
                <AlertVolumeLabel>{tRef.current('Alert Volume')}</AlertVolumeLabel>
                <InputWrapper>
                  <StyledSmallInput
                    onChange={onChangeAlertvolume}
                    label=''
                    unit='%'
                    max={100}
                    maxLength={3}
                    step={10}
                    value={(alertGlobalSettingsDetails.volume).toString()}
                    type='number'
                  />
                  <PercentageSliderWrapper>
                    <PercentageSlider
                      step={10}
                      value={alertGlobalSettingsDetails.volume}
                      marks={marks}
                      defaultValue={alertGlobalSettingsDetails.volume}
                      inputCallback={handleSliderUpdate}
                      updateThumbColor={sliderColorHandler}
                      updateTitle={sliderTitleHandler}
                      showValue={false}
                    />
                  </PercentageSliderWrapper>
                </InputWrapper>
              </VolumeLevelSlider>
            </AudioSelection>
            <DetectionTypeWrapper>
              <DetectionTypeLabel>{tRef.current('Enable Audio By Detection Type')}</DetectionTypeLabel>
              {alertDetectionTypes.map((item) => {
                return (
                  <AudioDetectionType key={item.value}>
                    <Switch checked={item.state} state='default' onChangeCallback={(value) => { onChangeSwitch(value, item.value, 'alert_settings'); }} />
                    <SwitchText htmlFor='' labelText={tRef.current(item.label)} />
                  </AudioDetectionType>
                );
              })}
            </DetectionTypeWrapper>
          </TabContent>
          <TabContent tabId='email_settings'>
            {
              !emailServiceConfigured ? (
                <AlertBarContainer>
                  <AlertBar message={tRef.current('Email service is not configured, Please contact support')} type='warning' hideCloseButton />
                </AlertBarContainer>
              ) : (
                <>
                  <AudioSelection>
                    <AudioDetectionType key=''>
                      <Switch checked={emailGlobalSettingsDetails.enabled} state='default' onChangeCallback={onChangeGlobalEmailAlert} />
                      <SwitchText htmlFor='' labelText={tRef.current('Send Email Alerts')} />
                    </AudioDetectionType>
                  </AudioSelection>
                </>
              )
            }
            <EmailSettingsContainer>
              <InputFieldWrapper>
                <InputFieldBox>
                  <TextFieldRequired>
                    <TextField
                      tabIndex={1}
                      name='sender'
                      value={emailGlobalSettingsDetails?.sender}
                      label='From'
                      fieldState={(emailServiceConfigured) ? 'default' : 'disabled'}
                      onChange={onEmailInputChange('sender')}
                      maxLength={255}
                      placeholder={t('sender@example.com')}
                    />
                  </TextFieldRequired>
                </InputFieldBox>
                <InputFieldBox>
                  <TextFieldRequired>
                    <TextField
                      tabIndex={1}
                      name='recipient'
                      value={emailGlobalSettingsDetails?.recipient}
                      label='To'
                      fieldState={(emailServiceConfigured) ? 'default' : 'disabled'}
                      onChange={onEmailInputChange('recipient')}
                      maxLength={255}
                      placeholder={t('receiver1@example.com, receiver2@example.com')}
                    />
                  </TextFieldRequired>
                </InputFieldBox>
                <ButtonAligner cc_bcc={cc && bcc}>
                  <ButtonContainer>
                    {(!cc || !(emailServiceConfigured)) && <Button disabled={!emailServiceConfigured} design='secondary' size='small' onClick={(emailServiceConfigured) ? () => setCC(true) : () => { }}>{t('Add CC')}</Button>}
                    {(!bcc || !(emailServiceConfigured)) && <Button disabled={!emailServiceConfigured} design='secondary' size='small' onClick={(emailServiceConfigured) ? () => setBCC(true) : () => { }}>{t('Add BCC')}</Button>}
                  </ButtonContainer>
                </ButtonAligner>
                {(cc && emailServiceConfigured) &&
                  <>
                    <InputFieldBox>
                      <TextField
                        tabIndex={1}
                        name='cc'
                        value={emailGlobalSettingsDetails?.cc}
                        label={t('CC')}
                        fieldState={(emailServiceConfigured) ? 'default' : 'disabled'}
                        onChange={onEmailInputChange('cc')}
                        maxLength={255}
                        placeholder={t('receiver1@example.com, receiver2@example.com')}
                      />
                    </InputFieldBox>
                    <ButtonAligner cc_bcc={false}>
                      <RemoveButtonContainer>
                        {cc && <Button design='secondary' size='small' onClick={removeCC}>{t('Remove CC')}</Button>}
                      </RemoveButtonContainer>
                    </ButtonAligner>
                  </>}
                {(bcc && emailServiceConfigured) &&
                  <>
                    <InputFieldBox>
                      <TextField
                        tabIndex={1}
                        name='bcc'
                        fieldState={(emailServiceConfigured) ? 'default' : 'disabled'}
                        value={emailGlobalSettingsDetails?.bcc}
                        label={t('BCC')}
                        onChange={onEmailInputChange('bcc')}
                        maxLength={255}
                        placeholder={t('receiver1@example.com, receiver2@example.com')}
                      />
                    </InputFieldBox>
                    <ButtonAligner cc_bcc={false}>
                      <RemoveButtonContainer>
                        {bcc && <Button design='secondary' size='small' onClick={removeBCC}>{t('Remove BCC')}</Button>}
                      </RemoveButtonContainer>
                    </ButtonAligner>
                  </>}
                <InputFieldBox>
                  <TextField
                    tabIndex={1}
                    name='subject_prefix'
                    value={emailGlobalSettingsDetails?.subject_prefix}
                    label={t('Subject Prefix')}
                    fieldState={(emailServiceConfigured) ? 'default' : 'disabled'}
                    onChange={onEmailInputChange('subject_prefix')}
                    maxLength={255}
                    placeholder={t('[WARN]')}
                  />
                </InputFieldBox>
                <InputFieldBox>
                  <AddAdditionalNoteTextArea tabIndex={3} maxLength={1024} value={emailGlobalSettingsDetails.notes} fieldState={(emailServiceConfigured) ? 'default' : 'disabled'} label={t('Notes')} name='notes' placeholder={t('Add additional notes')} onChange={onChangeNotes} />
                </InputFieldBox>

                <EmailDetectionTypeWrapper>
                  <DetectionTypeLabel>{tRef.current('Enable Email By Detection Type')}</DetectionTypeLabel>
                  {emailDetectionTypes.map((item) => {
                    return (
                      <AudioDetectionType id={item.value} key={item.value}>
                        <Switch checked={item.state} state={(emailServiceConfigured) ? 'default' : 'disabled'} onChangeCallback={(value) => { onChangeSwitch(value, item.value, 'email_settings'); }} />
                        <SwitchText htmlFor='' labelText={tRef.current(item.label)} />
                      </AudioDetectionType>
                    );
                  })}
                </EmailDetectionTypeWrapper>
              </InputFieldWrapper>
              <EmailPreviewWrapper>
                {alertType !== null && <AlertBarWrapper onClick={() => { setAlertType(null); }}><AlertBar type={alertType === 'error' ? 'error' : alertType === 'success' ? 'success' : undefined} message={alertType === 'error' ? tRef.current('Failed to send a test mail') : tRef.current('Email sent successfully')} /></AlertBarWrapper>}
                <PreviewBlockHeader>
                  <PreviewBlockHeaderItem><PreviewLable>{t('Preview')}</PreviewLable></PreviewBlockHeaderItem>
                  <PreviewBlockHeaderItem><ButtonWithLoading loading={isSendEmailOnProgress} size='xsmall' type='submit' design='primary' disabled={!(emailGlobalSettingsDetails.sender && emailGlobalSettingsDetails.recipient.length) || !emailServiceConfigured || isSendEmailOnProgress} onClick={sendTestMail}>{tRef.current('Send Test Email')}</ButtonWithLoading></PreviewBlockHeaderItem>
                </PreviewBlockHeader>
                <EmailPreviewBlock>
                  {EmailSettings.map((item, index) => {
                    return ((cc && index === 4 && EmailSettings[4].value && (!EmailSettings[5].value || !bcc)) || (item.name === 'BCC' && bcc && EmailSettings[5].value) || (index === 3 && !EmailSettings[4].value && !EmailSettings[5].value))?(
                      <EmailPreviewBlockItemWrapper key={index.toString()} marginBottom='15px'>
                        <EmailPreviewBlockItemOne width='45%' marginRight='8px'>{item.name}:</EmailPreviewBlockItemOne>
                        <EmailPreviewBlockItemTwo whiteSpace='pre-wrap' width='55%'>{index === 3 ? (item.value ? item.value + ' ' : '') + t('Reverse run detected') : item.value.replace(/\s/g, '')}</EmailPreviewBlockItemTwo>
                      </EmailPreviewBlockItemWrapper>
                    ):((cc && index === 4 && EmailSettings[4].value) || (bcc && index === 5 && EmailSettings[5].value) || (index !== 4 && index !== 5)) && (index === 6 ? item.value : true)?(
                      <EmailPreviewBlockItemWrapper key={index.toString()} marginBottom='5px'>
                        {index !== 6 && <EmailPreviewBlockItemOne width='45%' marginRight='8px'>{(index < 4) ? item.name : t(item.name)}:</EmailPreviewBlockItemOne>}
                        <EmailPreviewBlockItemTwo whiteSpace='pre-wrap' width={index === 6 ? '100%' : '55%'} margin={index === 6 ? '12px 0' : '0'}>{(index === 6 || index === 2 || index === 7 || index === 11) ? item.value : (index === 1 || index === 7) ? item.value.replace(/\s/g, '') : index === 3 ? (item.value ? item.value + ' ' : '') + t('Reverse run detected') : t(item.value)}</EmailPreviewBlockItemTwo>
                      </EmailPreviewBlockItemWrapper>
                    ):null;

                  })}
                </EmailPreviewBlock>
                <AudioDetectionType key='attachment' marginTop='25px'>
                  <Switch checked={emailGlobalSettingsDetails.attachment_enabled} state={(emailServiceConfigured) ? 'default' : 'disabled'} onChangeCallback={(value) => { onChangeSwitch(value, 'attachment_enabled', ''); }} />
                  <SwitchText htmlFor='' labelText={tRef.current('Attach the camera image at the time of the event')} />
                </AudioDetectionType>
              </EmailPreviewWrapper>
            </EmailSettingsContainer>
          </TabContent>
          <TabContent tabId='endpoint_settings'>
            {httpPostResponse !== null && <TestPostAlertWrapper onClick={() => { setHttpPostResponse(null); }}><AlertBar type={httpPostResponse === 'error' ? 'error' : httpPostResponse === 'success' ? 'success' : undefined} message={httpPostResponse === 'error' ? tRef.current('Failed to send HTTP POST') : tRef.current('The HTTP POST sent successfully')} /></TestPostAlertWrapper>}
            <POSTLableContainer>
              <HTTPToggleSwitchContainer key=''>
                <Switch checked={alertGlobalSettingsDetails.http_post_endpoint_enabled} state='default' onChangeCallback={onChangeGlobalEnpointStatus} />
                <SwitchText htmlFor='' labelText={tRef.current('Enable HTTP POST')} />
              </HTTPToggleSwitchContainer>
              <PreviewBlockHeaderItem><ButtonWithLoading loading={testButtonLoading} disabled={alertGlobalSettingsDetails?.url === ''} size='xsmall' type='submit' design='primary' onClick={testHTTPPOSTPayload}>{tRef.current('Test HTTP POST')}</ButtonWithLoading></PreviewBlockHeaderItem>
            </POSTLableContainer>
            <DescriptionContainer>
              <LabelText>{t('When an event is detected, a HTTP POST is sent to this endpoint with the following payload.')}</LabelText>
              <CodeBlock>
                {JSON.stringify(payload, null, 2)}
              </CodeBlock>
            </DescriptionContainer>
            <EndpointContainer>
              <Field width={i18n.language === 'ja'?'480px':'510px'}>
                <EndpointLabel>{tRef.current('Endpoint')}</EndpointLabel>
                <StyledInput fieldState='default' maxLength={255} value={alertGlobalSettingsDetails?.url} onChange={handleEndpointChange} placeholder='https://your-post-endpoint-for-alert:port/' />
              </Field>
            </EndpointContainer>
          </TabContent>
        </Tabs>}
    </MainContainer>
  );
};

export default AlertConfiguration;