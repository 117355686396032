import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { ButtonWithLoading, TextField, PasswordField, AlertBar, Button, Label } from 'scorer-ui-kit';
import GhostLogo from 'svg/ghost-logo.svg';
import { LoginScreenLogo } from 'svg/loginScreenLogo';
import styled, { css, keyframes } from 'styled-components';
import { Form, AlertWrapper } from 'scorer-ui-kit';
import AuthService from 'services/authService';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const widthDesk = 480;

type gradientDesign = 'primary' | 'secondary';

interface IAlert {
  type: 'error' | 'warning' | 'info' | 'success';
  message: string;
}

interface Props {
  design?: gradientDesign,
  guestUser: boolean,
  versionInfo: {
    icon: string,
    title: string
  }
  loginGuest: (isGuestButtonClicked: boolean) => void
}

const gradients = {
  'primary': 'linear-gradient(321deg, #5baced 95%, #71c3ee 3%)',
  'secondary': 'linear-gradient(139deg, hsl(250, 60%, 62%), hsl(0, 46%, 54%))',
};

const RowCss = css`
  display: flex;
  flex-direction: row;
`;

const fadeInAnimation = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const Box = styled.div<{ margin?: string; flex?: string; }>`
  button{
    width: 100%;
    background-image: linear-gradient(97deg, #71c3ed, #5baced);
  }
  
  ${({ margin }) => margin && css`
    margin:${margin};
  `}

  ${({ flex }) => flex && css`
    flex:${flex};
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
  `}
`;

const LoginForm = styled(Form)`
  max-width: 320px;
  margin: auto;
  padding: 44px 0;
  min-height: calc(100vh - 126px);
  height:570px;

  ${({ theme }) => css`
    animation: ${fadeInAnimation} ${theme.animation.speed.normal} ${theme.animation.easing.primary.in};
    ${AlertWrapper} {
      margin-bottom: 20px;
    }
    @media ${theme.deviceMediaQuery.medium} {
      min-height: auto;
    }
    @media ${theme.deviceMediaQuery.large} {
      max-width: ${widthDesk}px;
      padding: 70px;
      margin: initial;
    }
  `};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  position:relative;
  margin: 23px 0 40px 0;
  color: #5a6269;
`;

const InputBox = styled.div`
  label > div > div:nth-child(2) > div > div {
    height: 20px;
  }
`;

const PasswordContainer = styled.div`
  button > div {
    margin-top: 5px;
  }
`;

const CopyRightStyle = css`
  opacity: 0.65;
  font-size: 14px;
  font-style: italic;
  line-height: 1.79;
  text-align: center;
  color: hsl(207, 5%, 57%);
  margin-top:49px;
`;

const CopyRight = styled.div`
  ${CopyRightStyle};
  margin-right: 17px;
  color: #8b9196;
`;

const CopyRightGroup = styled.div`
  ${RowCss}
  justify-content: center;
`;

const Container = styled.div`
  ${CopyRightGroup}{
    display:none;
  }
  ${({ theme }) => css`
    @media ${theme.deviceMediaQuery.medium} {
      position: absolute;
      transform: translate(-50%,-50%);
      left: 50%;
      top: 50%;
      height: auto;
      ${CopyRightGroup}{
        display: flex;
      }
    }
  `};
`;

const LogoBackground = styled.img<{ design: gradientDesign }>`
  position: absolute;
  background-image: ${({ design }) => gradients[design]};
  bottom: -360px;
  left: -600px;
  min-width: ${widthDesk}px;
  object-fit: contain;
  mix-blend-mode: overlay;
  opacity:0.87;
  svg {
    width: 100%;
  }
  ${({ theme }) => css`
    @media ${theme.deviceMediaQuery.large} {
      height: 1080px;
      width: auto;
      bottom: 0;
      left: -300px;
    }
  `};
`;

const LogoContainer = styled.div`
  height: 126px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  position: relative;
  justify-content: center;
  overflow: hidden;
  ${({ theme }) => css`
    @media ${theme.deviceMediaQuery.large} {
      height: auto;
      max-width: ${widthDesk}px;
    }
  `};
`;

const LoginBox = styled.div`
  border: solid 1px hsl(0, 0%, 91%);
  max-width: ${widthDesk}px;
  margin: auto;
  height: 100%;
  ${({ theme }) => css`
    @media ${theme.deviceMediaQuery} {
      box-shadow: 0 20px 30px 0 hsla(205, 24%, 26%, 0.15);
      border-radius: 5px;
    }
    @media ${theme.deviceMediaQuery.large} {
      ${RowCss}
      overflow: hidden;
      position:relative;
      max-width: none;
      min-width: ${widthDesk * 2}px;
      min-height: 560px;
      input{
        min-width: 296px;
      }
    }
  `};
`;

const OrTextBox = styled.div`
  margin: 9px 0;
`;

const OrText = styled(Label)`
 text-align: center;
 font-size: 14px;
 margin-bottom: 0;
 span{
  margin-bottom: 0;
 }
`;

const VersionContainer = styled.span`
  color: white;
  position: relative;
  font-size: 16px;
  top: 200px;
  margin-left: 12px;
  opacity: 0.7;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fontFamily.ui};
`;

const Login: React.FC<Props> = ({ design = 'primary', guestUser = false, versionInfo = {icon: 'Information', title: '-'}, loginGuest = () => {} }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlert | null>(null);
  const [form, setForm] = useState({ username: '', password: '' });
  const { t } = useTranslation(['CommonDict']);

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  const onFieldChange = useCallback((key: 'username' | 'password') => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setAlert(null);
    setForm({ ...form, [key]: value });
  }, [form]);

  const onSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    e.preventDefault();
    setAlert(null);
    setLoading(true);
    try {
      const res = await AuthService.loginUser(form.username, form.password);
      if (res.data) {
        window.location.replace('/cameras');
        sessionStorage.removeItem('isGuestLogout');
        window.location.href = '/';
      } else {
        setAlert({
          message: (res.status === 404) ? t('Invalid username or password') : t('Failed to communicate with the system'),
          type: 'error'
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        setAlert({
          message: t('Failed to communicate with the system'),
          type: 'error'
        });
      }
    }
    if (localStorage.getItem(`${window.location.hostname}_isMenuOpen`) === null) {
      localStorage.setItem(`${window.location.hostname}_isMenuOpen`, 'true');
    }
    setLoading(false);
  }, [form, t]);

  return (
    <Container>
      <LoginBox>
        <LogoContainer>
          <LoginScreenLogo />
          <LogoBackground src={GhostLogo} {...{ design }} />
          <VersionContainer>{versionInfo.title !== '-' ? versionInfo.title : ''}</VersionContainer>
        </LogoContainer>
        <LoginForm onSubmit={onSubmit}>
          <Title>{t('Sign In')}</Title>
          <InputBox>
            <TextField
              autoFocus
              fieldState={!alert ? 'required' : 'invalid'}
              label={t('Username')}
              onChange={onFieldChange('username')}
              value={form.username}
              name='username'
              maxLength={255}
            />
          </InputBox>
          <PasswordContainer>
            <InputBox>
              <PasswordField
                fieldState={!alert ? 'required' : 'invalid'}
                label={t('Password')}
                onChange={onFieldChange('password')}
                value={form.password}
                name='password'
                maxLength={255}
              />
            </InputBox>
          </PasswordContainer>
          {alert && <AlertBar type={alert.type} message={alert.message} />}
          <Box flex='1'>
            <ButtonWithLoading loading={loading} disabled={!((form.username.length > 0) && (form.password.length > 0))} position='right' type='submit' onClick={onSubmit}>{t('Sign In')}</ButtonWithLoading>
            {guestUser &&
              <>
                <OrTextBox>
                  <OrText htmlFor='' labelText={t('Or')} />
                </OrTextBox>
                <Button onClick={() => loginGuest(true)}>{t('Access as Guest')}</Button>
              </>}
          </Box>
        </LoginForm>
      </LoginBox>
      <CopyRightGroup>
        <CopyRight>Copyright {new Date().getFullYear()} - Future Standard Co. Ltd. All Rights Reserved.</CopyRight>
      </CopyRightGroup>
    </Container>
  );
};

export default Login;