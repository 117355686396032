import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonWithIcon, Content, DateInterval, FilterBar, IFilterDropdownConfig, IFilterItem, IFilterResult, IFilterValue, ISearchFilter, Label, PageTitle, SortDropdown, Spinner, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import Card from 'components/Card';
import { getAllCamera } from 'services/apiConfig';
import TokenService from 'services/tokenService';
import AuthService from 'services/authService';

const MainContainer = styled(Content)`
  @media screen and (min-width: 1440px) {
    padding: 65.5px 40px 66px 170px;
  }
`;

const Container =styled.div`
  > div {
    max-width: unset !important;
  }

  @media screen and (min-width: 1709px) {
    max-width: 940px !important;
  }

  @media screen and (min-width: 939px) {
    max-width: calc(100vw - 320px) !important;
  }

  @media screen and (min-width: 1710px) {
    max-width: 1420px !important;
  }

  @media screen and (min-width: 2190px) {
    max-width: 1900px !important;
  }

  @media screen and (min-width: 2860px) {
    max-width: 2380px !important;
  }

  @media screen and (min-width: 2861px) {
    max-width: calc(100vw - 210px) !important;
  }
`;

const HeaderBar = styled.div`
  @media only screen and (width: 1920px) {
    max-width: 1421px !important;
  }
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & > div > div > div{
    bottom: 2px;
  }
`;

const FilterContainer = styled.div`
  @media only screen and (width: 1920px) {
    max-width: 1421px !important;
  }
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  margin: 24px 0 43px 0px;
  & > div > div > div:nth-child(2) > div{
    max-width: 214px;
  }
  & > div > div > div:nth-child(3){
    margin-top: 15px;
    margin-right: 233px;
  }
`;

const FilterWrapper = styled.div<{pointerEvent: string, background:string} >`
  flex-grow: 1;
  margin-top: 14px;
  button {
    opacity:${({background}) => background};
    pointer-events: ${({pointerEvent}) => pointerEvent};
  }
  input {
    opacity:${({background}) => background};
    pointer-events: ${({pointerEvent}) => pointerEvent};
  }
`;

const SortBox = styled.div<{pointerEvent: string, background:string}>`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  white-space: nowrap;
  position: absolute;
  right: 0;
  bottom: -7px;
  button > div > div:nth-child(2){
    min-width: 145px;
    display: flex;
    justify-content: space-around;
  }
  button {
    opacity:${({background}) => background};
    pointer-events: ${({pointerEvent}) => pointerEvent};
  }
`;

const CameraListBox = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 60px;
  max-width: fit-content ! important;
`;

const EmptyStateBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 160px;
  border: solid 1px #eee;
  padding: 37px 0;
  @media only screen and (min-width: 1439px) {
    max-width: 1422px !important;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  border: solid 1px #eee;
  padding: 37px 0;
  @media only screen and (min-width: 1439px) {
    max-width: 1422px !important;
  }
`;

const EmptyStateTitle = styled(Label)`
  font-size: 20px;
  margin-bottom: 0;
`;

const EmptyStateSubtitle = styled(Label)`
  font-size: 14px;
  width: 621px;
  text-align: center;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

interface IFilterData {
  name: string;
  status: string;
  alerts: string;
  paramsLoaded: string;
  [key: string]: string;
}

interface INewParams {
  searchText?: string | null;
  status?: string | null;
  alerts?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
}

interface IData {
  camera_enabled: boolean;
  camera_name: string;
  camera_type: string;
  line_configuration: [];
  length: number;
  notes: string;
  status_code: number;
  alert_status: number;
  status_message: string;
  stream_name: string;
  video_configuration:{};
  status: {
    status_category: string;
  }
}

const statusSort: {[key: string]: string} = {
  Running: '1',
  Error: '2',
  Warning: '3',
  Disabled: '4'
};

const localFilterValues = localStorage.getItem('camerasFilterValues');

const FILTER_DATA: IFilterData = {
  name: '',
  status: '',
  alerts: '',
  paramsLoaded: '',
  ...(localFilterValues ? JSON.parse(localFilterValues) : {})
};

const getSelectedFilter = (text: string, value: DateInterval | undefined | string | Date) => (value === '' ? undefined : ({ text, value }));

const Camera: FC = () => {
  const [allCameraData, setAllCameraData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({ ...FILTER_DATA });
  const [sorting, setSorting] = useState({ value: 'name', ascending: false, text: 'Sort By Name' });
  const { push, replace } = useHistory();
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);

  const status = ['Running', 'Error', 'Warning', 'Disabled'];
  const options = { showPointHandle: false, showPointLabel: false, showMoveHandle: false, boundaryOffset: 50, showDirectionMark: true };

  const sortByList = useMemo(() => {
    return ([
      {
        value: 'name',
        text: 'Sort By Name'
      },
      {
        value: 'status',
        text: 'Sort By Status'
      },
      {
        value: 'latestAlerts',
        text: 'Sort By Latest Alerts'
      }
    ]);
  }, []);

  const getSearchConfig = useCallback((): ISearchFilter[] => {
    return [
      {
        id: 'name',
        placeholder: t('Filter by name...'),
        name: t('Name'),
        selected: filterValues.name ? { text: filterValues.name, value: filterValues.name } : undefined
      }
    ];
  }, [filterValues.name, t]);


  const dropdownConfig: IFilterDropdownConfig[] = [
    {
      id: 'status',
      buttonText: t('Status'),
      buttonIcon: 'ProductLogs',
      optionType: 'radio',
      list: status.map((value) => ({ text: t(value), value: value.toLowerCase() })) as IFilterItem[],
      selected: getSelectedFilter(t(filterValues.status), filterValues.status) as IFilterValue | undefined
    },
    {
      id: 'alerts',
      buttonText: t('Alerts'),
      optionType: 'radio',
      list: [
        {
          'value': 'activeAlerts',
          'text': t('Active Alerts')
        },
        {
          'value': 'noAlerts',
          'text': t('No Alerts')
        }
      ],
      buttonIcon: 'Notifications',
      selected: getSelectedFilter(t(filterValues.alerts), filterValues.alerts) as IFilterValue | undefined
    },
  ];

  const fetchAllCamera = useCallback(async () => {
    setLoading(true);
    try {
      const { data: { status_code, data } } = await getAllCamera();
      if (status_code === 200) {
        setAllCameraData(data);
        setLoading(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        setLoading(false);
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load camera device') });
      }
    } catch (error) {
      setLoading(false);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to load camera device') });
      console.error((error as Error).message);
    }
  }, []);

  useEffect(() => {
    fetchAllCamera();
  }, [fetchAllCamera]);

  // when user comeback from another page then we use local storage values to set filter.
  useEffect(() => {
    const localFilterValues = localStorage.getItem('camerasFilterValues');
    if (localFilterValues) {
      const {paramsLoaded = ''} = JSON.parse(localFilterValues) as IFilterData;
      if (paramsLoaded === 'true') {
        setFilterValues({
          name: '',
          status: '',
          alerts: '',
          paramsLoaded: '',
          ...(localFilterValues ? JSON.parse(localFilterValues) : {})
        });
      }
    }
  }, []);

  const handleFilter = useCallback((res: IFilterResult[]) => {
    const searchFilter: IFilterData = { name: '', status: '', alerts: '', paramsLoaded: ''};
    res.forEach((item) => {
      searchFilter[item.id] = (item.selected as IFilterItem).value as string;
    });
    setFilterValues({ ...searchFilter, paramsLoaded: 'true' });
    localStorage.setItem('camerasFilterValues', JSON.stringify({ ...searchFilter, paramsLoaded: 'true' }));
  }, []);

  const setParams = useCallback(() => {
    const params = '?' +
      `${filterValues.name === '' ? '' : '&searchText=' + filterValues.name}` +
      `${filterValues.status === '' ? '' : '&status=' + filterValues.status}` +
      `${filterValues.alerts === '' ? '' : '&alerts=' + filterValues.alerts}` +
      `${!sorting.ascending && sorting.value === 'name' ? '' : '&sortBy=' + sorting.value}` +
      `${!sorting.ascending && sorting.value === 'name' ? '' : '&sortOrder=' + sorting.ascending}`;

    if (params === '?') {
      return '';
    }
    return params;
  }, [filterValues, sorting]);

  useEffect(() => {
    replace(window.location.pathname + setParams());
  }, [replace, setParams, filterValues]);


  const fetchHistoryParams = useCallback(() => {
    const params = new URLSearchParams(historyParams);
    const newParams: INewParams = {};
    newParams.searchText = params.get('searchText');
    newParams.status = params.get('status');
    newParams.alerts = params.get('alerts');
    newParams.sortBy = params.get('sortBy');
    newParams.sortOrder = params.get('sortOrder');

    const searchFilter: IFilterData = { name: '', status: '', alerts: '', paramsLoaded: ''};
    searchFilter.name = newParams.searchText ?? '';
    searchFilter.status = newParams.status ?? '';
    searchFilter.alerts = newParams.alerts ?? '';
    setSorting(prev => {
      return (
        {
          ...prev,
          ...(newParams.sortOrder ? {ascending: newParams.sortOrder === 'true'} : {}),
          ...(newParams.sortBy ? sortByList.find(item => item.value === newParams.sortBy) : {})
        }
      );
    });

    searchFilter.paramsLoaded = 'true';
    const localFilterValues = localStorage.getItem('camerasFilterValues');
    if (localFilterValues) {
      const {paramsLoaded = ''} = JSON.parse(localFilterValues) as IFilterData;
      if (paramsLoaded === '') {
        localStorage.setItem('camerasFilterValues', JSON.stringify(searchFilter));
      } else if (paramsLoaded === 'true') {
        setFilterValues({
          name: '',
          status: '',
          alerts: '',
          paramsLoaded: '',
          ...(localFilterValues ? JSON.parse(localFilterValues) : {})
        });
        return;
      }
    }
    setFilterValues(searchFilter);
  }, [historyParams, sortByList]);

  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);


  const compare = useCallback((a, b) => {
    let result: number = 0;
    const {last_alert_timestamp: aLastAlertTime = 0} = a;
    const {last_alert_timestamp: bLastAlertTime = 0} = b;

    switch (sorting.value) {
    case 'name':
      result = sorting.ascending ? a.camera_name.localeCompare(b.camera_name, 'en', { numeric: true }) : b.camera_name.localeCompare(a.camera_name, 'en', { numeric: true });
      break;
    case 'status':
      result = sorting.ascending ? statusSort[a.status.status_category]?.toString().localeCompare(statusSort[b.status.status_category]) : statusSort[b.status.status_category]?.toString().localeCompare(statusSort[a.status.status_category]);
      break;
    case 'latestAlerts':
      result = sorting.ascending ? aLastAlertTime.toString().localeCompare(bLastAlertTime) :  bLastAlertTime.toString().localeCompare(aLastAlertTime)  ;
      break;
    default:
      result = 0;
    }
    return result;
  }, [sorting]);

  const filterCameraData = useCallback((data = []) => {
    const filterData = data.filter((item: IData) => {
      if (
        ((item.camera_name.toLowerCase().includes(filterValues.name.toLowerCase())) || filterValues.name.length === 0)
        && (item.status.status_category.toLowerCase().includes(filterValues.status.toLowerCase()))
        && (filterValues.alerts === '' || filterValues.alerts === ((item.alert_status===40001 || item.alert_status===40002) ? 'activeAlerts' : 'noAlerts'))
      ) {
        return item;
      } else {
        return false;
      }
    });
    return filterData.sort(compare);
  }, [filterValues, compare]);

  useEffect(() => {
    const data = filterCameraData(allCameraData);
    setFilterData(data);
  }, [filterCameraData, allCameraData, filterValues]);

  const onChangeSorting = useCallback((event, ascending) => {
    const selectedSort = sortByList.find(item => item.value === event.value.toString());
    setSorting(prev => ({
      ...prev,
      ascending,
      ...(selectedSort ? selectedSort :{})
    }));
  }, [sortByList]);

  if (loading) {
    return (
      <SpinnerBox>
        <Spinner size='large' styling='primary' />
        <Label htmlFor='loader' labelText={t('Loading') + '...'} />
      </SpinnerBox>
    );
  }

  return (
    <MainContainer>
      <Container>
        <HeaderBar>
          <div>
            <PageTitle
              icon='DevicesScorerCamera'
              title={t('Cameras')}
              updateDocTitle={false}
            />
          </div>
          {TokenService.getUserType() &&
            <ButtonContainer>
              <ButtonWithIcon
                design='primary'
                icon='Add'
                onClick={() => push('/add-camera')}
                position='left'
                size='small'
              >
                {t('Add Camera')}
              </ButtonWithIcon>
            </ButtonContainer>}
        </HeaderBar>
        <FilterContainer>
          <FilterWrapper pointerEvent={allCameraData.length === 0 ? 'none' : 'auto'} background={allCameraData.length === 0  ? '0.5' : ''}>
            {filterValues.paramsLoaded === 'true' &&
              <FilterBar
                resultTextTemplate={t('Showing All') + `${t(' ({X})').replace('{X}', filterData.length.toString())}:`} filtersTitle={t('Filters') + ':'}
                totalResults={filterData.length} searchersConfig={getSearchConfig()} dropdownsConfig={dropdownConfig} onChangeCallback={handleFilter}
                clearText={t('CLEAR ALL')}
              />}
          </FilterWrapper>
          <SortBox pointerEvent={allCameraData.length === 0 ? 'none' : 'auto'} background={allCameraData.length === 0  ? '0.5' : ''}>
            <SortDropdown
              ascendingText={t('Ascending')}
              buttonText={t(sorting.text)}
              descendingText={t('Descending')}
              isSortAscending={sorting.ascending}
              list={sortByList.map(({text, value}) => ({text: t(text), value}))}
              onSelect={onChangeSorting}
              selected={{ text: sorting.text, value: sorting.value }}
            />
          </SortBox>
        </FilterContainer>

        {filterData.length > 0 ?
          <CameraListBox>
            {filterData.map((item: IData, index: number) => (
              <Card key={index} data={item} options={options} />
            ))}
          </CameraListBox>
          :
          (filterValues.name.trim() !== '' || filterValues.status.trim() !== '' || filterValues.alerts.trim() !== '' ?  
            <EmptyBox>
              <EmptyStateTitle htmlFor='' labelText={t('No camera found')} />
            </EmptyBox>
            :
            <EmptyStateBox>
              <EmptyStateTitle htmlFor='' labelText={t('Add Cameras to Begin')} />
              <EmptyStateSubtitle htmlFor='' labelText={t('The system currently has no cameras registered. To begin, use the “Add Camera” button above to get started. Once cameras have been added they will appear here.')} />
            </EmptyStateBox>)}
      </Container>
    </MainContainer>
  );
};

export default Camera;