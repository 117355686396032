import axios from 'axios';
import React, { FC, useCallback, useReducer, useState, useEffect } from 'react';
import { LineSetContext, LineReducer, Icon, Spinner, LineUI, LineUIRTC, useMediaModal, usePoll } from 'scorer-ui-kit';
import { LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL, EDGE_API_BASE_URL, WEBRTC_BASE_URL } from '../../constants';
import { ILineViewerConfig, IWebrtcPort } from './types';
import styled, { css } from 'styled-components';
import i18n from 'i18n';
import AwaitingStreamEn from '../../svg/img_awaiting_stream_en.jpg';
import AwaitingStreamJp from '../../svg/img_awaiting_stream_jp.jpg';
import { useWebsocketFrame } from 'hooks/useWebsocketFrame';
import { useTranslation } from 'react-i18next';
import TokenService from 'services/tokenService';

const Container = styled.div<{isModal: boolean}>`
  display: flex;
  flex-direction: column;
  
  ${({isModal}) => isModal && css`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    > :nth-child(1) {
      z-index: 2;
      width: auto;
      img, video {
        max-width: calc(100vw - 100px);
        max-height: calc(100vh - 130px);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(238, 243, 246, 0.5);
      backdrop-filter: blur(5px);
      z-index: 1;
    }
  `}
`;

const LineUIContainer = styled.div<{ width: string, incresedLineThickness: boolean, isModal: boolean}>`
  position: relative;
  min-height: 300px;
  min-width: 400px;
  ${({ width }) => css`
    width: ${width};
  `}
  > div:not(#controls) {
    height: auto;
    > div {
      height: inherit;
      > img, video {
        min-width: 400px;
        border-radius: 3px;
      }
    }
  }
  
  ${({incresedLineThickness}) => incresedLineThickness && css`
    svg line:nth-child(2) {
      stroke-linecap: round;
      stroke-width: 10;
    }
  `}
`;

const CloseButtonContainer = styled.span<{isModal: boolean}>`
  display: ${({isModal}) => isModal ? 'flex' : 'none'};
  color: ${({theme}) => theme.colors.icons.mono};
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: -30px;
  right: 0;
  z-index: 7;
  cursor: pointer;
  :hover {
    opacity: 0.8;
    transition: transform 0.35s cubic-bezier(0.87, 0, 0.13, 1) 0s;
  }
  > div {
    display: flex;
  }
`;

const ImageToolsContainer = styled.div<{isModal: boolean}>`
  display: flex;
  justify-content: center;
  gap: 0 25px;

  div:nth-child(1) {
    align-items: baseline;
  }
`;

const ToolContainer = styled.div<{isActive: boolean}>`
  display: flex;
  align-items: center;
  gap: 0 10px;
  font-size: 14px;
  z-index: 4;

  > div {
    display: flex;
  }
  &:hover {
    color: #5aaeea;
    cursor: pointer;
    > div > svg > g {
      stroke: #5aaeea;
    }
  }
  ${({isActive}) => isActive && css`
    color: #5aaeea;
    > div > svg > g {
      stroke: #5aaeea;
    }
  `}
  svg {
    padding-top: 2px;
  }
`;

const ToolContainerRTC = styled(ToolContainer)<{isActive: boolean}>`
  &:hover {
    cursor: ${({isActive}) => isActive ? 'default' : 'pointer'};
  }
`;

const ControlsWrapper = styled.div<{isModal: boolean}>`
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: ${({isModal}) => isModal ? '#fff' : 'transparent'};
  position: ${({isModal}) => isModal ? 'relative' : 'absolute'};
  z-index: 1;
`;

const ButtonWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  & > div {
    display: flex;
  }
  :hover {
    color: #5aaeea;
    cursor: pointer;
    > div > svg > g {
      stroke: #5aaeea;
    }
  }
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const ControlLabel = styled.div<{ isActive?: boolean, modalIcon?:boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  margin-bottom: ${({modalIcon}) => modalIcon ? '8px' : '2px'};
  color: ${({ isActive }) => (isActive ? '#5aaeea' : '#9aa8ad')};
`;

const SpinnerContainer = styled.div<{imageLoaded: boolean}>`
  width: 100%;
  height: 100%;
  min-height: 390px;
  border-radius: 3px;
  background-color: #bababa;
  display: ${({imageLoaded}) => imageLoaded ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 12;
`;

const LineUIWrapper = styled.div<{showPointer: boolean}>`
  ${({ showPointer }) => showPointer && css`
    cursor: pointer;
  `};
`;

const LineUIRTCWrapper = styled.div<{showPointer: boolean, zIndex: string}>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({zIndex}) => zIndex};

  ${({ showPointer }) => showPointer && css`
    cursor: pointer;
  `};
`;

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

const ConfigurationLineViewer: FC<ILineViewerConfig> = ({
  streamName,
  options = {
    showMoveHandle: false,
    boundaryOffset: 0
  },
  linesData = [],
  enableRtc = false,
  showPointer = false,
  incresedLineThickness = false,
  latestSnapshotText = '',
  hasClickSensingBorder = true,
  zoomText='',
  rtcText = '',
  width = '700px',
  // height = '390px',
  iconSize = 14,
  isModal = false,
  onMediaLoadedCallback = () => {},
  onGetImgWidth = () => {},
  onLineClickCallback = () => {},
  onLineChangeCallback = () => {},
}) => {
  const {t}=useTranslation(['CommonDict']);
  const userName = TokenService.getUser();
  const [state, dispatch] = useReducer(LineReducer, linesData);
  const [image, setImage] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [noImage, setNoImage] = useState(false);
  const { isMediaUrlValid } = useMediaModal();
  const boundingBoxes = JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string);
  const pathUrl = boundingBoxes ? '/websocket-sink-tracking/socket.io': '/websocket-sink/socket.io' ;
  const { loadingFrame, frame, action: { stopFrame, startFrame } } = useWebsocketFrame(pathUrl, streamName);
  const [isLiveFeed, setIsLiveFeed] = useState(false);
  const [isLatestSnapshot, setIsLatestSnapshot] = useState(false);
  const [isRTCLoaded, setIsRTCLoaded] = useState(false);
  const [rtcSrc, setRtcSrc] = useState<string>('');
  const [isMount, setIsMount] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(isModal);

  const onVideoLoaded = useCallback(() => {
    setIsLiveFeed(true);
    setIsRTCLoaded(false);
    onMediaLoadedCallback('video', isModal);
  }, [onMediaLoadedCallback, isModal]);

  const onImageLoaded = useCallback(() => {
    onMediaLoadedCallback('image', isModal);
  }, [onMediaLoadedCallback, isModal]);

  const getWebrtcPort = useCallback(async () => {
    try {
      const res: IWebrtcPort = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/webrtc_broadcaster`);
      if (res.status === 200 && res.data) {
        setRtcSrc(`${WEBRTC_BASE_URL}/websocket-${res.data.data.sender_port}`);
      } else {
        setRtcSrc('');
      }
    } catch (err) {
      console.error(err);
    }
  }, [streamName]);

  const updateParentSnapshot = useCallback(async() => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      const imgData = res.data;
      if (res.status === 200 && imgData) {
        if(imgData.byteLength > 5){ // check for not null response
          const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
          setImage(imgBase64);
        }
      }
    } catch (err) {
      console.error(err);
    }
  },[streamName]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
        const isImageValid = await isMediaUrlValid(imgBase64, 'img');
        if (isImageValid === true) {
          setImage(imgBase64);
          onImageLoaded();
          const img = new Image();
          img.src = imgBase64;
          // setImageDetails({w: img.width, h: img.height});
          onGetImgWidth(img.width);
          setNoImage(false);
        } else {
          onGetImgWidth(1920);
          setNoImage(true);
        }
      }
    } catch (err) {
      console.error(err);
      setNoImage(true);
    } finally {
      setImageLoaded(true);
    }
  }, [isMediaUrlValid, streamName, onGetImgWidth, onImageLoaded]);

  usePoll(async () => {
    if (!isLiveFeed) {
      getCameraImage();
    }
  }, LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL * 1000);

  const onClickSnapshot = useCallback(() => {
    updateParentSnapshot();
    if (isLiveFeed || isRTCLoaded) {
      setIsLiveFeed(false);
      setIsRTCLoaded(false);
      setRtcSrc('');
      stopFrame();
    }
    setIsLatestSnapshot(true);
  }, [isLiveFeed, isRTCLoaded, stopFrame, updateParentSnapshot]);

  const onClickLiveFeed = useCallback((isLatestSnapshot) => {
    if (isLatestSnapshot) return;
    if (boundingBoxes || (!boundingBoxes && !enableRtc)) {
      setIsLiveFeed(true);
      startFrame(image);
    } else {
      setIsLiveFeed(false);
      setIsRTCLoaded(true);
      getWebrtcPort();
      stopFrame();
    }
  }, [image, boundingBoxes, enableRtc, startFrame, stopFrame, getWebrtcPort]);

  const handleZoomButton = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeZooom = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    if (isMount && (image !== '' && !noImage)) {
      onClickLiveFeed(isLatestSnapshot);
      setIsMount(false);
    }
  }, [isMount, image, noImage, isLatestSnapshot, onClickLiveFeed]);

  useEffect(() => {
    if (!enableRtc) return;
    setIsMount(true);
  }, [boundingBoxes, enableRtc]);

  const onLineMoveEnd = useCallback(() => {
    onLineChangeCallback(state);
  }, [onLineChangeCallback, state]);

  const onClickLine = useCallback((lineId: number) => {
    onLineClickCallback(lineId);
  }, [onLineClickCallback]);

  return (
    <Container isModal={isModalOpen}>
      <LineUIContainer isModal={isModalOpen} {...{ width, incresedLineThickness }}>
        <LineSetContext.Provider value={{ state, dispatch }}>
          <SpinnerContainer {...{ imageLoaded }}>
            <Spinner size='large' styling='primary' />
          </SpinnerContainer>
          <LineUIWrapper showPointer={showPointer} onClick={showPointer ? handleZoomButton : undefined}>
            <LineUI hasClickSensingBorder={hasClickSensingBorder} options={options} src={(boundingBoxes || (!boundingBoxes && !enableRtc)) && isLiveFeed && frame !== '' ? frame : image !== '' ? image : i18n.language === 'ja' ? AwaitingStreamJp : AwaitingStreamEn} onLineMoveEnd={onLineMoveEnd} onLineClick={onClickLine} />
          </LineUIWrapper>
          {
            enableRtc && !boundingBoxes && rtcSrc !== '' &&
              <LineUIRTCWrapper showPointer={showPointer} onClick={showPointer ? handleZoomButton : undefined} zIndex={isLiveFeed ? '11' : '-1'}>
                <LineUIRTC hasClickSensingBorder={hasClickSensingBorder} options={options} ws={rtcSrc} onLoaded={onVideoLoaded} onLineMoveEnd={onLineMoveEnd} onLineClick={onClickLine} />
              </LineUIRTCWrapper>
          }
        </LineSetContext.Provider>
        {imageLoaded && !noImage &&
          <ControlsWrapper id='controls' isModal={isModalOpen}>
            <ImageToolsContainer isModal={isModalOpen}>
              <ToolContainer onClick={onClickSnapshot} isActive={!isLiveFeed && !isRTCLoaded}>
                <Icon icon='FileTypeImage' size={14} color='dimmed' />
                <div>{latestSnapshotText}</div>
              </ToolContainer>
              <ToolContainerRTC onClick={!isLiveFeed ? () => {setIsLatestSnapshot(false); onClickLiveFeed(false);} : undefined} isActive={!loadingFrame && isLiveFeed}>
                {((boundingBoxes || (!boundingBoxes && !enableRtc)) ? loadingFrame : isRTCLoaded) ? <Spinner size='small' styling='primary' /> : <Icon icon='Camera' size={14} color='dimmed' />}
                <div>{rtcText}</div>
              </ToolContainerRTC>
            </ImageToolsContainer>
            {!isModalOpen &&
              <ButtonWrapper onClick={handleZoomButton}>
                <Icon icon='Search' size={iconSize} color='dimmed' />
                <ControlLabel isActive={false}>{zoomText}</ControlLabel>
              </ButtonWrapper>}
          </ControlsWrapper>}
        <CloseButtonContainer isModal={isModalOpen} onClick={closeZooom}>
          <div>{t('CLOSE')}</div>
          <Icon icon='CloseCompact' size={15} />
        </CloseButtonContainer>
      </LineUIContainer>
    </Container>
  );
};

export default ConfigurationLineViewer;