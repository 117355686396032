import { IUploader } from 'interface';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, TypeTable, MultilineContent, Switch, SelectField, Icon } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';

import styled, { css } from 'styled-components';

const UploadContent = styled.div<INotConfigured>`
  width: 541px;
  margin-top: 34px;
  & > label {
    font-size: 16px;
    color: #5a6269;
  }
  opacity: ${({notConfigured}) => notConfigured ? 0.5 : 1};
`;

const StatusContainer = styled.div<{isReadOnly: boolean, notConfigured: boolean, featureEnabled:boolean}>`

  #LogsStatus > label > span {
    opacity: 0.5;
  }
  ${({isReadOnly}) => isReadOnly && css`
    > label > span {
      opacity: 0.5;
    }
  `};

  div {
    cursor: ${({ featureEnabled }) => featureEnabled ? 'pointer' : 'not-allowed'}
  }

  ${({notConfigured}) => notConfigured && css`
    > label > div {
      cursor: not-allowed;
    }
    > label {
      cursor: not-allowed;
    }
  `};
`;

const NoteContainer = styled.div<INotConfigured>`
  margin-top: 18px;
  height: 60px;
  padding: 10px 8px 10px 7px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 16px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
  opacity: ${({notConfigured}) => notConfigured ? 0.5 : 1};
`;

const StyledSelectField = styled(SelectField)`
  width: 238px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
`;

const TypeTableFormatter = styled(TypeTable)`
`;

const TableFormate = styled.div`
  margin-top: -23px;
`;

interface INotConfigured {
  notConfigured: boolean;
}

interface ITableData {
  type: string,
  enabled: boolean,
  retention: string
}

interface IUploadManagement {
  isSavingState: boolean;
  inputData: IUploader | undefined;
  featureEnabled: boolean,
  notConfigured: boolean;
  getCloudUploadDetails: (tableData: ITableData[]) => void;
  setRemoveFeatureButtonValidation: (data: boolean) => void;
  eraseDataValue: boolean;
}

const UploadManagement: React.FC<IUploadManagement> = ({isSavingState, inputData, featureEnabled, notConfigured, getCloudUploadDetails, setRemoveFeatureButtonValidation, eraseDataValue}) => {

  const [tableData, setTableData] = useState([
    {
      type: 'Logs',
      enabled: true,
      retention: '30'
    },
    {
      type: 'Images',
      enabled: false,
      retention: '30'
    },
    {
      type: 'Videos',
      enabled: false,
      retention: '30'
    }
  ]);
  const [rows, setRows] = useState<ITypeTableData>([]);
  const { t } = useTranslation(['CommonDict']);

  const restoreInputData = useMemo(() => [
    {
      type: 'Logs',
      enabled: inputData?.texts_enabled,
      retention: inputData?.texts_data_retention_days,
    },
    {
      type: 'Images',
      enabled: inputData?.images_enabled,
      retention: inputData?.images_data_retention_days,
    },
    {
      type: 'Videos',
      enabled: inputData?.videos_enabled,
      retention: inputData?.videos_data_retention_days,
    }
  ], [inputData]);  
  
  useEffect(()=>{
    if (inputData !== undefined) {
      setTableData(prev => {
        const updatedData = prev.map(item => {    
          if (item.type === 'Logs') {
            item.enabled = inputData.texts_enabled;
            item.retention = inputData.texts_data_retention_days;
          } else if (item.type === 'Images') {
            item.enabled = inputData.images_enabled;
            item.retention = inputData.images_data_retention_days;
          } else if (item.type === 'Videos') {
            item.enabled = inputData.videos_enabled;
            item.retention = inputData.videos_data_retention_days;
          }
          return item;
        });
        return updatedData;
      });
    }
  }, [inputData]);

  const onChangeStatus = useCallback((e, type)=>{
    setRemoveFeatureButtonValidation(true);
    setTableData(prevData => {
      const updatedData = prevData.map(item => {
        if (item.type === type) {
          item.enabled = e;
        }
        return item;
      });
      return updatedData;
    });
  },[setRemoveFeatureButtonValidation]);

  const generateStatusCategoryColumn = useCallback((status: boolean, type: string, index: number, notConfigured: boolean): ReactElement[] => {
    return [

      <StatusContainer id={type + 'Status'} key={type} isReadOnly={(type === 'Logs') ? true : false} featureEnabled={(type === 'Logs') ? featureEnabled : true} notConfigured={notConfigured}>
        <Switch
          key={index}
          checked={status}
          labelText={status ? t('Enabled') : t('Disabled')}
          leftTheme='off'
          onChangeCallback={(e) => onChangeStatus(e, type)}
          rightTheme='on'
          state={(type === 'Logs') ? 'disabled' : 'default'}
        />
      </StatusContainer>
    ];
  }, [featureEnabled, t, onChangeStatus]);

  const onSelectChange = useCallback((e, type) => {
    setRemoveFeatureButtonValidation(true);
    setTableData(prevData => {
      const updatedData = prevData.map(item => {
        if (item.type === type) {
          item.retention = e;
        }
        return item;
      });
      return updatedData;
    });
  }, [setRemoveFeatureButtonValidation]);

  const generateRetentionColumn = useCallback((defaultVal, type: string, index: number, notConfigured: boolean): ReactElement[] =>{ 
    let handleDefaultValue;
    if(defaultVal.length === 1){
      handleDefaultValue = '0' + defaultVal; 
    } else {
      handleDefaultValue = defaultVal;
    }
    return [
      <StyledSelectField
        key={index}
        changeCallback={e => onSelectChange(e, type)}
        defaultValue={handleDefaultValue}
        value={handleDefaultValue}
        disabled={isSavingState || notConfigured}
        isCompact
      >
        {
          Array.from({length: 30}, (_, i) => i + 1 + '').map((item, index) => {
            return (
              <option key={type + index} value={/^\d$/.test(item)? ('0' + item) : (item)}>
                {/^\d$/.test(item)? `0${item} ${item === '1' ? t(' day') : t(' days')}` : (item + t(' days'))}
              </option>
            );
          })
        }
      </StyledSelectField>
    ];
  }, [isSavingState, onSelectChange, t]);

  // function to create rows of table from provided rowData
  const rowMaker = useCallback((rowData: ITableData[]): ITypeTableData => {
    const newRows: ITypeTableData = rowData.map(
      ({ type, enabled, retention }, index) => {
        const row: IRowData = {
          id: type,
          columns: [
            { text: t(type) },
            { customComponent: (<MultilineContent contentArray={generateStatusCategoryColumn(enabled, type, index, notConfigured)} />)},
            { customComponent: (<MultilineContent contentArray={generateRetentionColumn(retention, type, index, notConfigured)} />)}
          ],
        };
        return row;
      }
    );
    return newRows;
  }, [notConfigured, generateStatusCategoryColumn, generateRetentionColumn, t]);
  
  useEffect(()=>{
    if(eraseDataValue){
      setTableData(restoreInputData as any);
      setRows(rowMaker(tableData));
    }
  }, [eraseDataValue, onSelectChange, restoreInputData, rowMaker, tableData]);

  // config of table columns
  const tableColumns: ITableColumnConfig[] = [
    {
      columnId: 'type',
      header: t('Type'),
      cellStyle: 'normalImportance',
      sortable: false,
      alignment: 'left',
      minWidth: 115
    },
    {
      columnId: 'upload',
      header: t('Upload'),
      cellStyle: 'normalImportance',
      sortable: false,
      alignment: 'left',
      minWidth: 182
    },
    {
      columnId: 'retentionPeriod',
      header: t('Retention Period'),
      cellStyle: 'normalImportance',
      sortable: false,
      alignment: 'left',
      minWidth: 182
    },
  ];

  useEffect(()=>{
    setRows(rowMaker(tableData));
  },[tableData, rowMaker]);

  useEffect(()=>{
    getCloudUploadDetails(tableData);
  },[tableData, getCloudUploadDetails]);

  return (
    <UploadContent notConfigured={notConfigured}>
      <Label htmlFor='uploadTypes' labelText={t('Upload Management')} />
      <TableFormate>
        <TypeTableFormatter
          emptyTableTitle={t('tableMessage')}
          isLoading={false}
          loadingText={t('Loading data') + '...'}
          columnConfig={tableColumns}
          rows={rows}
          hasHeaderGroups
        />
      </TableFormate>
      <NoteContainer notConfigured={notConfigured}>
        <Icon size={18} icon='Information' color='dimmed' />
        <div>{t('Please note, enabling image or video uploads will result in high transfer bandwidth usage.')}</div>
      </NoteContainer>
    </UploadContent>
  );
};

export default UploadManagement;