import api from './api';
import { IExclusionZoneData, IFrequentLaneData, IExclusionAreasResponse, IReverseRunData, ICongestionStopData, IHitPointData } from 'pages/CameraConfiguration/types';
import { ISingleCameraResponse, IToggleAnalysisPayload, IToggleAnalysisResponse, IGlobalAlertResponse, IGetGlobalAlertSettingsResponse, IAlertSettingsData, IConfigurationResponse, ITrafficCounterData, ILowSpeedDetectionData, ILowSpeedDetectionResponse, ILowSpeedResponse, IAdvancedLowSpeedDetectionResponse, IAdvancedLowSpeedDetectionData, IHTTPPOSTPayload, IAlertHistoryParams, IAlertHistoryResponse, IAlertDownloadPayload, IAllAlertDismissedResponse, IAllAlertDismissPayload, IDownloadVideoPayload, IAlertDismissedResponse, IAlertCountResponse, IAlertSuppResponse, IAlertHistoryDismissPayload, IAlertHistoryDismissedResponse, IDownloadVideoResponse, IAlertDownloadResponse, IAlertSupp } from '../interface';

export const getCamera = (camName: string) => {
  return api.userInstance.get<ISingleCameraResponse>(`/cameras/${camName}`);
};

// Exclusion Zone Configuration

export const getExclusionZoneSettings = (camName: string) => {
  return api.userInstance.get<{data: IExclusionZoneData; status_code: number;}>(`/alert-config/exclusion-area/${camName}`);
};

export const saveExclusionZoneSettings = (camName: string, payload: IExclusionZoneData | undefined) => {
  return api.userInstance.put<{ message: string; status_code: number }>(`/alert-config/exclusion-area/${camName}`,payload);
};

// Enable/Disable Configuration analysis
export const toggleConfigurationAnalysis = (stream_name: string, payload: IToggleAnalysisPayload) => {
  return api.userInstance.post<IToggleAnalysisResponse>(`/alert-config/analysis-status-update/${stream_name}`, payload);
};

// Frequent Lane Configuration
export const getFrequentLaneSettings = (camName: string) => {
  return api.userInstance.get<{data: IFrequentLaneData; exclusion_areas: IExclusionAreasResponse; status_code: number; }>(`/alert-config/lane-change/${camName}`);
};

export const saveFrequentLaneSettings = (camName: string, payload: IFrequentLaneData | undefined) => {
  return api.userInstance.put<{ message: string; status_code: number }>(`/alert-config/lane-change/${camName}`, payload);
};

// Global Alerts
export const getGlobalAlertsSettings = () => {
  return api.userInstance.get<IGetGlobalAlertSettingsResponse>('/alert-config/global-alert');
};

export const updateGlobalAlertsSettings = (payload: IAlertSettingsData) => {
  return api.userInstance.put<IGlobalAlertResponse>('/alert-config/global-alert', payload);
};

export const sendEmail= (payload:any)=>{
  return api.userInstance.post<any>('/alert/email',payload);
};

// Configuration
export const getConfiguration = (camName: string) => {
  return api.userInstance.get<IConfigurationResponse>(`/alert-config/camera-configuration/${camName}`);
};

// Reverse Run Detection
export const getReverseRunSettings = (camName: string) => {
  return api.userInstance.get<{data: IReverseRunData; exclusion_areas: IExclusionAreasResponse; status_code: number;}>(`/alert-config/reverse-run/${camName}`);
};

export const saveReverseRunSettings = (camName: string, payload: IReverseRunData | undefined) => {
  return api.userInstance.put<{ message: string; status_code: number }>(`/alert-config/reverse-run/${camName}`,payload);
};

// Congestion and Stop Detection
export const getCongestionSettings = (camName: string) => {
  return api.userInstance.get<{data: ICongestionStopData; exclusion_areas: IExclusionAreasResponse; status_code: number;}>(`/alert-config/congestion-stop/${camName}`);
};

export const saveCongestionSettings = (camName: string, payload: ICongestionStopData | undefined) => {
  return api.userInstance.put<{ message: string; status_code: number }>(`/alert-config/congestion-stop/${camName}`, payload);
};

// Traffic Counting
export const getTrafficSettings = (camName: string) => {
  return api.userInstance.get<{data: ITrafficCounterData; exclusion_areas: IExclusionAreasResponse; status_code: number;}>(`/alert-config/traffic-counter/${camName}`);
};

export const saveTrafficCounterSettings = (camName: string, payload: ITrafficCounterData | undefined) => {
  return api.userInstance.put<{ message: string; status_code: number }>(`/alert-config/traffic-counter/${camName}`, payload);
};

// Low Speed detection
export const getLowSpeed = (camName: string) => {
  return api.userInstance.get<ILowSpeedDetectionResponse>(`/alert-config/low-speed-detection/${camName}`);
};

export const setLowSpeed = (camName: string, payload:ILowSpeedDetectionData) => {
  return api.userInstance.put<ILowSpeedResponse>(`/alert-config/low-speed-detection/${camName}`, payload);
};

// Advanced Low Speed
export const getAdvancedLowSpeed = (camName: string) => {
  return api.userInstance.get<IAdvancedLowSpeedDetectionResponse>(`/alert-config/advanced-low-speed-detection/${camName}`);
};

export const setAdvancedLowSpeed = (camName: string, payload:IAdvancedLowSpeedDetectionData) => {
  return api.userInstance.put<ILowSpeedResponse>(`/alert-config/advanced-low-speed-detection/${camName}`, payload);
};

// Hit Point
export const getHitPointDetails = (camName: string) => {
  return api.userInstance.get<any>(`/alert-config/hit-point/${camName}`);
};

export const saveHitPointDetails = (camName: string, payload: IHitPointData) => {
  return api.userInstance.put<{ message: string; status_code: number }>(
    `/alert-config/hit-point/${camName}`,
    payload
  );
};

//Test HTTP POST 
export const testHTTPPOST = (payload: IHTTPPOSTPayload) => {
  return api.userInstance.post<{ message: string; status_code: string }>('/http-endpoint', payload);
};

export const getAllAlerts = (params: IAlertHistoryParams) => {
  return api.userInstance.get<IAlertHistoryResponse>('/alerts', {params});
};

export const downloadSelectedAlerts = (payload: IAlertDownloadPayload) => {
  return api.userInstance.post<IAlertDownloadResponse>('/alert-logs-download', payload);
};

export const dismissedAllAlert = () => {
  return api.userInstance.post<IAllAlertDismissedResponse>('/alerts/status/dismiss');
};

export const dismissedSingleAllAlert = (payload: IAllAlertDismissPayload) => {
  return api.userInstance.post<IAllAlertDismissedResponse>('/alerts', payload);
};

export const downloadVideo = (prefix: string, payload: IDownloadVideoPayload) => {
  return api.userInstance.post<IDownloadVideoResponse>('/alerts/media?prefix=' + prefix, payload);
};

export const getActiveAlerts = (
  minutes: number,
  hours: number,
  days: number,
  stream_name: string
) => {
  return api.userInstance.get<IAlertCountResponse>('/alerts/count', {
    params: { minutes, hours, days, stream_name },
  });
};

export const getAlertsHistory = (
  camName: string,
  params: IAlertHistoryParams
) => {
  return api.userInstance.get<IAlertHistoryResponse>(`/alerts/${camName}/all`, {
    params,
  });
};

export const dismissedSingleAlertHistory = (payload: IAlertHistoryDismissPayload) => {
  return api.userInstance.post<IAlertHistoryDismissedResponse>('/alerts', payload);
};

export const dismissedAlertHistory = (
  camName: string,
  action : string
) => {
  return api.userInstance.post<IAlertHistoryDismissedResponse>(`/alerts/${camName}/status/${action}`);
};

export const getAlertSupression = (camName: string) => {
  return api.userInstance.get<IAlertSuppResponse>('/alert-suppression/'+ camName);
};

export const addAlertSuppRules =  (camName: string, payload: IAlertSupp) =>{
  return api.userInstance.put<IAlertSuppResponse>('/alert-suppression/'+ camName, payload);
};

// Alert Drawer

export const getAlerts = (params: IAlertHistoryParams) => {
  return api.userInstance.get<IAlertHistoryResponse>('/alerts', {params});
};

export const dismissedAlert = () => {
  return api.userInstance.post<IAlertDismissedResponse>('/alerts/status/dismiss');
};
