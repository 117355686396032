import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ModelBox from 'components/ModelBox';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useModal, Button, ButtonWithIcon, Content, Label, PageHeader, PasswordField, SelectField, Spinner, TextAreaField, TextField, useNotification } from 'scorer-ui-kit';
import { addUser, getSingleUser, onDeleteUser, updateSingleUser } from 'services/apiConfig';
import AuthService from 'services/authService';
import TokenService from 'services/tokenService';
import styled, { css } from 'styled-components';
import { onValidUsername } from '../../utils/validation';
import { IEditUser } from 'interface';
import isEqual from 'lodash.isequal';

const MainContainer = styled(Content)`
  @media screen and (min-width: 1440px) {
    padding: 67px 168px;
  }
`;

const Container = styled.div`
  max-width: 1100px;
`;

const BasicDetailsBox = styled.div`
  margin: 48px 0;
`;

const PasswordDetailsBox = styled.div`
  margin: 64px 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 941px;
  line-height: 1.2;
`;

const HeaderButton = styled.div`
  display: flex;
  & > button:first-child {
    margin-right: 20px;
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const TextLineBox = styled.div`
  position: relative;
`;

const TextConfig = styled(Label)`
  position: absolute;
  font-size: 16px;
  color: #5a6269;
  background: #f9fafb;
  z-index: 1;
  padding-right: 18px;
`;

const Line = styled.div`
  position: absolute;
  height: 1px;
  top: 10px;
  width: 100%;
  background-color: #eee;
  max-width: 941px;
`;

const InputFieldContainer = styled.div`
  display: flex;
  position: relative;
  top: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 792px;
`;

const InputPasswordContainer = styled.div<{isRequired: boolean}>`
  display: flex;
  position: relative;
  top: 30px;
  flex-wrap: wrap;
  max-width: 928px;
  ${({isRequired}) => isRequired && css`
    span::after {
      font-weight: bold;
      content: ' *';
      color: rgb(238, 75, 43);
    }
  `}
`;

const InputFieldBox = styled.div`
  margin-top: 20px;
  min-width: 380px;
`;

const PasswordFieldBox = styled.div`
  margin-top: 20px;
  min-width: 380px;
  margin-right: 32px;
  button > div {
    margin-top: 5px;
  }
`;

const NotesBox = styled.div`
  margin-top: 35px;
  max-width: 794px;
  textarea{
    resize: none;
    min-width: 200px;
    max-width: 100%;
    min-height: 165px;
    max-height: 165px;
    @media (max-width: 1169px) {
      max-width: 380px;
    }
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const AdvancedContainer = styled.div`
  display: flex;
  position: relative;
  top: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 944px;
`;

const AdvancedBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AdvanceTextBox = styled.div`
  margin-top: 25px;
  width: 59%;
`;

const AdvancedTitle = styled(Label)`
  font-size: 20px;
  color: #5a6269;
  margin-bottom: 0;
`;

const DeleteButton = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 21px;
`;

const AdvancedTitleSubtitle = styled(Label)`
  font-size: 14px;
`;

const AdvancedDetailsBox = styled.div`
  margin-top: 89px;
`;

const TextFieldRequired = styled.div`
  span::after{
    font-weight: bold;
    content: ' *';
    color: rgb(238, 75, 43);
  }
  select{
    background-color: #fff;
  }
`;

const TextFieldNotRequired = styled.div`
  span::after{
    font-weight: bold;
    color: rgb(238, 75, 43);
  }
  select{
    background-color: #fff;
  }
`;

const ModelText = styled(Label)`
  margin-bottom: 0;
  span{
    margin-bottom: 0;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatusBar = styled.div<{ color: string }>`
  width: 130px;
  height: 5px;
  margin: -6px 0 37px 0;
  border-radius: 2.5px;
  background-color: ${({ color }) => color};
`;

const StatusText = styled(Label)`
  font-size: 12px;
  margin-bottom: 0;
  margin-top: -13px;
  span{
    margin-bottom: 0;
    margin-left:12px;
  }
`;

const StatusDetailBox = styled.div`
  width:788px;
  margin-top: -34px;
`;

const USER_DETAILS: IEditUser = { user_id: '',  username: '', note: '', role: 'viewer', password: '', confirmPassword: '' };

const CreateEditUser: FC = () => {
  const { pathname, search } = useLocation();
  const [isEditUser] = useState<boolean>(pathname === '/settings/edit-user');
  const [userDetails, setUserDetails] = useState<IEditUser>(USER_DETAILS);
  const [prevUserDetails, setPrevUserDetails] = useState<IEditUser>(USER_DETAILS);
  const [isFormDataChanged, setIsFormDataChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState(isEditUser ? true : false);
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const [showNotes, setShowNotes] = useState(false);
  const { goBack, push } = useHistory();
  const { createModal, setModalOpen } = useModal();
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const [passwordStatus, setPasswordStatus] = useState('#d9d9d9');
  const [goodPassword, setGoodPassword] = useState('');

  useEffect(() => {
    if(userDetails.confirmPassword === '' && userDetails.password === ''){
      setPasswordStatus('#d9d9d9');
      setGoodPassword('');
    } else {
      if(userDetails.confirmPassword === userDetails.password){
        setPasswordStatus('#9dd09d');
        setGoodPassword('Matched');
      } else {
        setPasswordStatus('#ff6666');
        setGoodPassword('Not Matched');
      }
    } 
  }, [userDetails]);

  const fetchUser = useCallback(async (id) => {
    setLoading(true);
    try {
      if(id){
        const { data: { status, message, data } } = await getSingleUser(id);
        if (status === 200) {
          setUserDetails({ ...USER_DETAILS, ...data[0] });
          setPrevUserDetails({ ...USER_DETAILS, ...data[0] });
          if (data[0]?.note?.trim().length > 0) {
            setShowNotes(true);
          }
        }else if (status === 403) {
          notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
          if (await AuthService.logoutUser() !== 200) {
            notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
          }
        }
        else {
          notificationRef.current({ type: 'error', message: tRef.current(message) });
          push('/settings/user-management');
        }
      }
    } catch (error) {
      notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
      console.error((error as Error).message);
    }
    setLoading(false);
  }, [push]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (isEditUser) {
      fetchUser(query.get('user-id') ?? '');
    }
  }, [isEditUser, fetchUser, search]);

  const matchPassword = useCallback(({ password, confirmPassword }) => {
    if (password.trim() === '' || confirmPassword.trim() === '') {
      return false;
    } else if (password.trim() === confirmPassword.trim()) {
      return true;
    }
    return false;
  }, []);

  const updateUserData = useCallback(async() => {
    const { user_id, username, confirmPassword, ...userData } = userDetails;
    const { role } = prevUserDetails;
    if(userDetails.password !== '' || userDetails.confirmPassword !== '') {
      if(userDetails.confirmPassword !== userDetails.password){
        notificationRef.current({ type: 'error', message: t('Passwords are not matching') });
        return;
      }
      if(userDetails.password.trim() === '' ){
        notificationRef.current({ type: 'error', message: t('Please enter a valid password. Only spaces are not allowed.') });
        return;
      }
    }
    setLoading(true);
    try {
      if (role === userData.role) {
        delete userData.role;
      }
      const { data: { status } } = await updateSingleUser(user_id, {...userData, note:userData.note.trim()});
      if (status === 200) {
        sendNotification({ type: 'success', message: t('User details updated successfully') });
        push('/settings/user-management');
      } else if (status === 403) {
        notificationRef.current({ type: 'error', message: t('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
        }
      }
      else {
        sendNotification({ type: 'error', message: t('Failed to communicate with the system') });
      }
    } catch (error) {
      sendNotification({ type: 'error', message: t('Failed to communicate with the system') });
      console.error((error as Error).message);
    }
    setLoading(false);
  }, [push, userDetails, t, sendNotification, prevUserDetails]);

  const onClickAdd = useCallback(async () => {
    const { user_id, confirmPassword, ...userData } = userDetails;
    if (userData.username.trim() === '') {
      sendNotification({ type: 'error', message: t('Please enter the Username') });
      return;
    }

    if (userData.password === '' && confirmPassword === '') {
      sendNotification({ type: 'error', message: t('Please enter the Password') });
      return;
    }

    if(userDetails.password.trim() === ''){
      notificationRef.current({ type: 'error', message: t('Please enter a valid password. Only spaces are not allowed.') });
      return;
    }

    if (matchPassword(userDetails)) {
      setLoading(true);
      try {
        const { data: { status } } = await addUser({ ...userData, username: userData.username, note: userData.note.trim() });
        if (status === 201) {
          sendNotification({ type: 'success', message: t('User added successfully') });
          push('/settings/user-management');
        } else if (status === 409) {
          notificationRef.current({ type: 'error', message: t('Username already exists') });
        }
        else if (status === 403) {
          notificationRef.current({ type: 'error', message: t('Authorization required to access') });
          if (await AuthService.logoutUser() !== 200) {
            notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
          }
        }
        else {
          sendNotification({ type: 'error', message: t('Failed to communicate with the system') });
        }
      } catch (error) {
        sendNotification({ type: 'error', message: t('Failed to communicate with the system') });
        console.error((error as Error).message);
      }
      setLoading(false);
    } else {
      sendNotification({ type: 'error', message: t('Passwords must be the same') });
    }
  }, [userDetails, matchPassword, sendNotification, push, t]);

  const onChangeHandler = useCallback(({ target: { name, value } }) => {
    if ((name === 'password' || name === 'confirmPassword') && isEditUser) {
      setUserDetails(prev => ({ ...prev, [name]: value }));
    } else if (name === 'username' && value.length <= 255) {
      if (value === '') {
        setUserDetails(prev => ({ ...prev, username: value }));
      } else if (onValidUsername(value)) {
        setUserDetails(prev => ({ ...prev, username: value }));
      }
    } else if (name === 'notes') {
      if(value.trim().length < 1024){
        setUserDetails(prev => ({ ...prev, notes: value }));
      }
    } else {
      setUserDetails(prev => ({ ...prev, [name]: value }));
    }
  }, [isEditUser]);

  const onDelete = useCallback(async () => {
    try {
      const { data: { status } } = await onDeleteUser(userDetails.user_id);
      if (status === 200) {
        setModalOpen(false);
        sendNotification({ type: 'success', message: t('User deleted successfully') });
        if (userDetails.user_id === TokenService.getUserID()) {
          await AuthService.logoutUser();
        }
        push('/settings/user-management');
      }else if (status === 403) {
        notificationRef.current({ type: 'error', message: t('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
        }
      }
      else {
        sendNotification({ type: 'error', message: t('Failed to communicate with the system') });
      }
    } catch (error) {
      sendNotification({ type: 'error', message: t('Failed to communicate with the system') });
      console.error((error as Error).message);
    }
  }, [userDetails.user_id, setModalOpen, sendNotification, t, push]);

  useEffect(() => {
    if (isEditUser) {
      setIsFormDataChanged(isEqual(prevUserDetails, userDetails));
    }
  }, [isEditUser, prevUserDetails, userDetails]);

  const handleGoBack = useCallback(() => {
    goBack();
    setTimeout(() => {      
      if (!window.location.pathname.includes('/settings/user-management')) {
        goBack();
      }
    }, 200);
  }, [goBack]);

  const onClickCancel = useCallback(() => {
    if(JSON.stringify(userDetails) === JSON.stringify(prevUserDetails)){
      handleGoBack();
      return;
    } else{
      createModal({
        isCloseEnable: false,
        width: '480px',
        padding: true,
        dismissCallback: () => { setModalOpen(false); },
        customComponent: (
          <ModelBox onConfirm={() => {setModalOpen(false);handleGoBack();}} modalOpen={setModalOpen} cancelText='No' confirmText='Yes' confirmStyle='primary'>
            <ModelText htmlFor='' labelText={t('Are you sure you want to cancel the changes?')} />
          </ModelBox>),
      });
    }
  }, [createModal, setModalOpen, handleGoBack, t, userDetails, prevUserDetails]);

  const onClickDelete = useCallback(() => {
    createModal({
      isCloseEnable: false,
      width: '480px',
      padding: true,
      dismissCallback: () => { setModalOpen(false); },
      customComponent: (
        <ModelBox titleText='Delete User?' onConfirm={() => { onDelete(); }} modalOpen={setModalOpen}>
          <ModelText htmlFor='' labelText={t('Please confirm that you want to delete the following User')} />
          <ModelText title={(userDetails.username)} htmlFor='' labelText={(userDetails.username)} />
          <br />
          <ModelText htmlFor='' labelText={t('Please Note') + ':'} />
          <ModelText htmlFor='' labelText={t('• Once confirmed this action cannot be undone.')} />
        </ModelBox>),
    });
  }, [userDetails.username, onDelete, createModal, setModalOpen, t]);

  if (loading) {
    return (
      <SpinnerBox>
        <Spinner size='large' styling='primary' />
        <Label htmlFor='loader' labelText={t('Loading') + '...'} />
      </SpinnerBox>
    );
  }

  return (
    <MainContainer>
      <Container>
        <HeaderContainer>
          <PageHeader
            areaHref='/settings/user-management'
            areaTitle={t('User Management')}
            icon='ViewSettings'
            introductionText=''
            title={isEditUser ? t('Edit User') : t('Create New User')}
            updateDocTitle={false}
          />

          <HeaderButton>
            <Button design='secondary' onClick={onClickCancel}>{t('Cancel')}</Button>
            <Button onClick={isEditUser ? updateUserData : onClickAdd} disabled={userDetails.role === '' || isFormDataChanged}>{isEditUser ? t('Update User') : t('Add User')}</Button>
          </HeaderButton>
        </HeaderContainer>
        <BasicDetailsBox>
          <TextLineBox>
            <TextConfig htmlFor='' labelText={t('Basic Details')} />
            <Line />
          </TextLineBox>
          <InputFieldContainer>
            <InputFieldBox>
              <TextFieldRequired>
                <TextField
                  tabIndex={1}
                  name='username'
                  label={t('Username')}
                  fieldState={isEditUser ? 'disabled' : 'default'}
                  onChange={onChangeHandler}
                  value={userDetails.username}
                  maxLength={255}
                />
              </TextFieldRequired>
            </InputFieldBox>
            <InputFieldBox>
              <TextFieldNotRequired>
                <SelectField
                  tabIndex={2}
                  changeCallback={(event: string | number) => setUserDetails(prev => ({ ...prev, role: event as string }))}
                  defaultValue={userDetails.role}
                  label={{
                    htmlFor: 'userType',
                    text: t('User Type')
                  }}
                  placeholder={t('Select access level…')}
                >
                  <option value='admin'>{t('Administrator')}</option>
                  <option value='viewer'>{t('Viewer')}</option>
                </SelectField>
              </TextFieldNotRequired>
            </InputFieldBox>
          </InputFieldContainer>
          <NotesBox>
            {showNotes ?
              <TextAreaField
                tabIndex={3}
                value={userDetails.note}
                fieldState='default'
                label={t('Notes')}
                name='note'
                onChange={onChangeHandler}
                maxLength={1024}
              />
              :
              <ButtonWithIcon
                design='primary'
                icon='Add'
                onClick={() => setShowNotes(true)}
                position='left'
                size='small'
              >
                {t('Add Notes')}
              </ButtonWithIcon>}
          </NotesBox>

        </BasicDetailsBox>
        <PasswordDetailsBox>
          <TextLineBox>
            {isEditUser ?
              <TextConfig htmlFor='' labelText={t('Update Password')} />:
              <TextConfig htmlFor='' labelText={t('Set Password')} />}
            <Line />
          </TextLineBox>
          <InputPasswordContainer isRequired={!isEditUser}>
            <PasswordFieldBox>
              <PasswordField
                tabIndex={4}
                name='password'
                label={t('Password')}
                fieldState='default'
                onChange={onChangeHandler}
                maxLength={255}
                autoComplete='new-password'
                value={userDetails.password}
              />
            </PasswordFieldBox>
            <PasswordFieldBox>
              <PasswordField
                tabIndex={5}
                name='confirmPassword'
                label={t('Confirm Password')}
                fieldState='default'
                onChange={onChangeHandler}
                maxLength={255}
                autoComplete='new-password'
                value={userDetails.confirmPassword}
              />
            </PasswordFieldBox>
          </InputPasswordContainer>
        </PasswordDetailsBox>

        <StatusDetailBox>
          <StatusBox>
            <StatusBar color={passwordStatus} />
            <StatusText htmlFor='' labelText={t(goodPassword)} />
          </StatusBox>
        </StatusDetailBox>

        {TokenService?.getUser() !== userDetails?.username &&
          <>
            {isEditUser &&
              <AdvancedDetailsBox>
                <TextLineBox>
                  <TextConfig htmlFor='' labelText={t('Advanced')} />
                  <Line />
                </TextLineBox>
                <AdvancedContainer>
                  <AdvancedBox>
                    <AdvanceTextBox>
                      <AdvancedTitle htmlFor='' labelText={t('Delete User')} />
                      <AdvancedTitleSubtitle htmlFor='' labelText={t('Delete this user from the system. This action cannot be undone and to restore access you will need to recreate the user. No other data will be removed.')} />
                    </AdvanceTextBox>
                    <DeleteButton design='danger' onClick={onClickDelete}>{t('Delete User')}</DeleteButton>
                  </AdvancedBox>
                </AdvancedContainer>
              </AdvancedDetailsBox>}
          </>}
      </Container>
    </MainContainer>
  );
};

export default CreateEditUser;