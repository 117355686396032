import * as React from "react";
const SvgFeaturePolyUi = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M3.75 17.25a3 3 0 1 1 0 6 3 3 0 0 1 0-6zM7 1a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm-.5 6.5-2 9m13-9 2 9m-4 4h-8m7-17h-5M17 1a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm3 16a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgFeaturePolyUi;
