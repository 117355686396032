import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Content, PageHeader, TypeTable, ButtonWithIcon, FilterBar, IFilterDropdownConfig, IFilterItem, IFilterValue, DateInterval, IFilterResult, useNotification, IconButton, ISearchFilter, Button, Spinner, ButtonWithLoading, IFilterDatePicker, Icon, SortDropdown, } from 'scorer-ui-kit';
import styled from 'styled-components';
import Pagination from 'components/Pagination';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import { useTranslation } from 'react-i18next';
import { dismissedAllAlert, dismissedSingleAllAlert, downloadSelectedAlerts, downloadVideo, getAllAlerts } from 'services/camerasService';
import { IAlert, IAlertDownloadPayload, IAllAlertDismissedData, IAllAlertDismissPayload, IAllAlertParams, IDownloadVideoPayload } from 'interface';
import { formatDate, downloadZipFile, downloadFile, readParams, updateParams, getSelectedDate } from 'utils';
import { ISortBy, ISortItem } from 'components/molecules/SortBy';
import { DATE_FORMAT_WITHOUT_SEC, ENABLE_DOWNLOAD_VIDEO, analysisTypeList, alertStatusList, DATE_FORMAT_DASH_WITHOUT_SEC, BASE_API_URL } from '../constants';
import TokenService from 'services/tokenService';
import { format } from 'date-fns/esm';
import axios from 'axios';
import i18n from 'i18n';
import { useHistory, useLocation } from 'react-router-dom';
import { StatusContext } from 'App';
let isStatusFunctionActive = false;

const AlertHistoryTabContainer = styled.div`
  /* margin-top: 20px; */
`;

const SortBox = styled.div<{pointerEvent: string, background:string}>`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  white-space: nowrap;
  button > div > div:nth-child(2){
    min-width: 116px;
    display: flex;
    justify-content: space-around;
  }
  button {
    opacity:${({background}) => background};
    pointer-events: ${({pointerEvent}) => pointerEvent};
  }
`;

const MainContainer = styled(Content)`
  width: 100%;
  padding: 62px 30px 62px 90px;
  @media screen and (min-width: 1440px) {
    padding: 62px 25px 62px 170px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 976px !important;
`;

const UpperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 11px;
  > div > div {
    h1 {
      letter-spacing: -0.3px;
    }
  }
  & > div > div > div{
    bottom: 5px;
  }
`;

const Para = styled.div`
  width: 610px;
  height: 40px;
  margin-top: -6px;
  margin-bottom: 32px;
  color: #7b8288;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

const PaginationContainer = styled.div`
  max-width: 976px !important;
  margin-bottom: 30px;
`;

const StatusButton = styled.button`
  width: 110px;
  height: 25px;
  font-size: 13px;
  border-radius: 3px;
  background-color: #fbfafd;
  border: none;
  color: #9097a8;
  cursor: pointer;
`;

const StatusActiveButton = styled.button`
  width: 110px;
  height: 25px;
  font-size: 13px;
  border-radius: 3px;
  background-color: #fbfafd;
  border: none;
  color: rgb(255, 90, 90);
  font-weight: 700;
  cursor: pointer;
`;

const FilterAndDismiss = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  margin: 33px 0 0 -28px;
  > div > div > :nth-child(1) {
    > :nth-child(3) {
      width: 360px;
    }
  }
  > div > div > div > div > div > :nth-child(2) {
    z-index: 0;
  }
`;

const FilterbarWrapper = styled.div<{width: string}>`
  width: ${({width}) => width};
  height: 130px;
  > div > div:first-child {
    margin-bottom: 14px;
    margin-left: 1px;
    letter-spacing: -0.2px;
  }
  > div > div:nth-child(2) {
    flex-wrap: nowrap;
    > :nth-child(1) {
      margin-left: 0;
    }
  }
  > div > div:last-child {
    margin-top: 20px;
    margin-left: 2px;
  }
`;

const BlurredDiv = styled.div`
  height: 59px;
  position: absolute;
  bottom: 0;
  top: -30px;
  left: -100%;
  right: -5%;
  opacity: 0.8;
  filter: blur(1px);
  background-color: rgb(248 249 250);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1;
`;

const Actions = styled.div`
  flex-grow: 1;
  height: 30px;
  margin-top: 8px;
  margin-left: 3px;
`;

const DismissButtonContainer = styled.div`
  > button {
    padding: 0 15px 0 20px;
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
    > div {
      > :nth-child(1) {
        padding-left: 11px;
      }
    }
  }
`;

const DismissLoadingButton = styled(ButtonWithLoading)``;

const DismissSortingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: 'relative';
  margin-top: 32px;
  gap: 24px;
  min-height: 84px;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 7px;
  gap: 0 10px;
  padding-left: 5px;
`;

const ActionsMainContainer = styled.div`
  position: sticky;
  top: -6%;
  z-index: 1;
`;

const Action = styled.span`
  width: 137px;
  height: 10px;
  margin-left: 1px;
  position: relative;
  bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #8b9196;
  z-index: 2;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: end;
  & > button {
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
`;

const VideoButtonWithIcon = styled(ButtonWithIcon)`
  margin-right: 5px;
`;

const DownloadButtonWithIcon = styled(ButtonWithIcon)`
  margin-right: 5px;
`;

const DownloadButtonWithLoading = styled(ButtonWithLoading)`
  height: 100%;
  margin-right: 5px;
`;

const SelectedResults = styled.span`
  height: 14px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  color: hsl(207,5%,57%);
`;

const FinishButton = styled(Button)`
  height: 30px;
  align-self: flex-end;
  padding: 0px 20px;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-image: linear-gradient(111deg, #71c3ed, #5baced);
`;

const ActionFinishContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const ResultTextSortContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;
  padding-bottom: 14px;
  padding-left: 3px;
  > div {
    padding-top: 2px;
  }
`;

const Title = styled.div<{width: string}>`
  width: ${({width}) => width};
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.data};
  color: rgba(120, 138, 144, 0.72);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IgnoredButton = styled.button`
  width: 110px;
  height: 25px;
  font-size: 13px;
  border-radius: 3px;
  padding: 3px;
  background-color: #fbfafd;
  border: none;
  color: #9097a8;
  font-style: italic;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const ResultText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.data };
  color: #5a6269;
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fontFamily.data};
`;

const TextDiv = styled.div<({maxWidth?: string})>`
  line-height: 1.2;
  max-width: ${({maxWidth}) => maxWidth};
  font-size: 12px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px 0;
  padding: 9px 0;
`;

const MessageTitle = styled.div`
  font-size: 10px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fontFamily.data };
  color: #a0bfcb;
  line-height: 1.2;
`;

const PlayableDrop = styled.div`
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > div {
    display: flex;
    transform: translateX(2px);
    svg {
      path {
        stroke: #bbb;
        fill: #bbb;
      }
    }
  };

  :hover {
    background-color: #bbb;
    > div {
      svg {
        path {
          stroke: #fafbfb;
          fill: #fafbfb;
        }
      }
    }
  }
`;

interface ISelectedStream {
  [key: string]: boolean
}

interface IAppFilter {
  [key: string]: DateInterval | string | number | undefined | Date | IFilterItem[] | boolean
}

const getSelectedFilter = (value: DateInterval | undefined | string | number | Date, filterType: string) => {
  switch (filterType) {
  case 'type':
    return analysisTypeList.find(item => item.value === value);
  case 'status':
    return alertStatusList.find(item => item.value === value);
  }
};

const AllAlerts = () => {
  const localFilterValues = useRef(JSON.parse(localStorage.getItem('filterValuesAllAlerts') as string));
  const localFilterState: IAppFilter = ((localFilterValues.current !== null) ? {...localFilterValues.current, paramsLoaded: true} :  {
    alertStatus: undefined,
    analysisName: undefined,
    cameraName: undefined,
    startDate: undefined,
    endDate: undefined,
    pageSize: 10,
    sortOrder: 'desc',
    sortBy: 'timestamp',
    paramsLoaded: false
  });
  const defaultFilterState = useRef(localFilterState);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [loading, setLoading] = useState(false);
  const {t}=useTranslation(['CommonDict']);
  const [allAlertList, setAllAlertList]=useState<IAlert[]>([]);
  const [filterValues, setFilterValues] = useState<IAppFilter>(defaultFilterState.current);
  const [pageSize, setPageSize] = useState(filterValues.pageSize as number);
  const [resultCount, setResultCount] = useState<number>(0);
  const token = TokenService.getLocalAccessToken();
  const [sorting, setSorting] = useState<ISortBy>({
    sort:'timestamp',
    title:t('Date/Time'),
    ascending: filterValues.sortOrder === 'asc',
  });
  const sizeOptions = [10, 20, 50, 100];
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const [selectRow, setSelectRow]=useState(0);
  const [selectedData, setSeletedData]=useState<IRowData[]>([]);
  const [finish , setFinish]=useState(true);
  const tRef = useRef(t);
  const [loadingData , setLoadingData]=useState<boolean>(false);
  const { push } = useHistory();
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const { setActiveAlertTimePages, alertActiveCount, alertData} = useContext(StatusContext);
  const [lodingSpinner, setLoadingSpinner] = useState(false);
  const [isDownloadStarted, setIsDownloadStarted] = useState<boolean>(false);
  const [filterLoading, setFilterLoading] = useState<boolean>();
  const [selectedStreams, setSelectedStreams] = useState<ISelectedStream>({});
  const [activeCount, setActiveCount]=useState(0);
  const [dismissCount, setDismissCount]=useState(0);

  useEffect(()=>{
    let title = 'Sort By Name';
    if(filterValues.sortBy === 'display_name'){
      title = 'Sort By Name';
    }
    else if(filterValues.sortBy === 'timestamp'){
      title = 'Sort By Date/Time';
    }
    else if(filterValues.sortBy === 'status'){
      title = 'Sort By Alert Status';
    }
    setSorting({
      title,
      ascending: filterValues.sortOrder === 'asc',
      sort: filterValues.sortBy as string
    });
  },[filterValues]);

  const columnConfig: ITableColumnConfig[] = [
    {
      cellStyle: 'firstColumn',
      columnId: 'message',
      groupTitle: tRef.current('Detection Meta'),
      header: tRef.current('Message'),
      sortActive: true,
      sortable: false,
    },
    {
      cellStyle: 'normalImportance',
      columnId: 'camerName',
      groupTitle: tRef.current('Detection Meta'),
      header: tRef.current('Camera Name').replace('Camera ', ''),
      sortActive: true,
      sortable: false,
      minWidth: 102,
    },
    {
      cellStyle: 'normalImportance',
      columnId: 'when',
      hasCopyButton: false,
      groupTitle: tRef.current('Detection Meta'),
      header: tRef.current('When'),
      sortable: false,
      minWidth: 140
    },
    {
      alignment: 'center',
      cellStyle: 'normalImportance',
      columnId: 'alertStatus',
      hasCopyButton: false,
      header: tRef.current('Alert Status'),
      minWidth: 120,
    },
    {
      alignment: 'right',
      cellStyle: 'normalImportance',
      columnId: 'actions',
      hasCopyButton: false,
      header: '',
      minWidth: 60,
    },
  ];

  const handleFinish = useCallback((data) =>{
    const newRows = [...data];
    newRows.forEach((row) => {
      row._checked = false;
    });
    setRowData(newRows);
    setSelectRow(0);
    setSeletedData([]); 
    setSelectedStreams({});
    setFinish(true);
  },[]);

  const handleSort = useCallback((event, ascending) => {
    setFilterLoading(true);
    setSeletedData([]);
    setSelectRow(0);
    setSelectedStreams({});
    let sortOrder = 'asc';
    if(ascending) {
      sortOrder = 'asc';
    } else {
      sortOrder = 'desc';
    }
    setCurrentPage(1);
    setFilterValues(prev => {
      const filterValues = {...prev, sortOrder, sortBy: event.value.toString(), paramsLoaded: true};
      localStorage.setItem('filterValuesAllAlerts', JSON.stringify(filterValues));
      return filterValues;  
    });
  }, []);


  const sortByList: ISortItem[] = [
    {
      sort:'display_name',
      title:t('Sort By Name'),
    },
    {
      sort: 'timestamp',
      title: t('Sort By Date/Time')
    },
    {
      sort : 'status',
      title: t('Sort By Alert Status')
    },
  ];

  const fileDownload = useCallback((fileUrl: string, timestamp: number, fileType: 'mp4'|'jpg', displayName: string, analysisType: string) => {
    if(fileType === 'mp4'){
      fileUrl = fileUrl + '?t=' + new Date().getTime();
    }
    axios.get<Blob>(fileUrl, {responseType: 'blob'}).then((res) => {
      if(res.data && res.data.type !== 'text/html'){
        const file = new Blob([res.data]);
        const _url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = _url;
        link.setAttribute('download', `${
          displayName.length > 180 ? displayName.slice(0, 180) + '...' : displayName
        }_${analysisType}_${format(new Date(timestamp*1000), 'yyyyMMdd_HHmmss')}_JST.${fileType}`);
        document.body.appendChild(link);
        link.click();
        notificationRef.current({
          type: 'success',
          message: t('Downloading started successfully')
        });
      } else {
        notificationRef.current({
          type: 'error',
          message: fileType==='mp4' ? t('Video is not generated yet. Please check after sometime.') : t('Fail to download')
        });
      }
    }).catch(err => {
      console.error(err);
      notificationRef.current({
        type: 'error',
        message: t('Fail to download')
      });
    });
  },[t]);

  const getAction = useCallback((imgURL: string, vidName: string, timestamp: number, displayName: string, analysisType: string) => {
    const vidUrl = '/data/archives/videos/' + vidName;
    return (
      <ActionButtons>
        {ENABLE_DOWNLOAD_VIDEO ?<IconButton title={t('Download Video')} onClick={() => fileDownload(vidUrl, timestamp, 'mp4', displayName, analysisType)} icon='DownloadVideo' /> : ''}
        <IconButton title={t('Download Image')} onClick={() => fileDownload(imgURL, timestamp, 'jpg', displayName, analysisType)} icon='Download' />
        <PlayableDrop style={{display: 'none'}}>
          <Icon size={14} icon='Play' color='inverse' />
        </PlayableDrop>
      </ActionButtons>
    );
  },[fileDownload, t]);

  const getAllAlert = useCallback(async(params) => {
    try {
      const res = await getAllAlerts(params);
      if (res) {
        const totalCount = Math.abs(Math.ceil(res.data.total_count / res.data.page_size));
        setTotalPages(totalCount <= 0 ? 1 : totalCount);
        setResultCount(res.data.count);
        setAllAlertList(res.data.data);  
      }
      setFilterLoading(false);
    } catch (error) {
      console.error(error);
      setResultCount(0);
      setAllAlertList([]);
      setFilterLoading(false);
    }
    setLoadingData(true);
    setLoading(false);
  },[]);

  const getApiParams = useCallback(() => {
    const {pageSize = 10, cameraName, alertStatus, analysisName, sortOrder = 'asc', sortBy = 'display_name', startDate, endDate} = filterValues;

    const params: IAllAlertParams = {
      page_size: pageSize as number,
      page: currentPage - 1,
      sort_by: sortBy as string,
      sort_order: sortOrder as string,

      ...(alertStatus !== undefined ? { status: alertStatus as number } : {}),
      ...(analysisName !== undefined ? { analysis_name: analysisName as number } : {}),
      ...(cameraName !== undefined ? { stream_display_name: cameraName as string } : {}),
      ...(endDate !== undefined ? { end_date: endDate as Date } : {}),
      ...(startDate !== undefined ? { start_date: startDate as Date } : {}),
    };

    return params;
  }, [currentPage, filterValues]);

  useEffect(() => {
    if (!filterValues.paramsLoaded) return;
    if(!isStatusFunctionActive) getAllAlert(getApiParams());
    else{
      isStatusFunctionActive = false;
      //setFilterLoading(false);
    }
  },[getAllAlert, getApiParams, filterValues, alertData]);

  const onClickDismissAll = useCallback(async() => {
    setLoadingSpinner(true);
    try {
      const res = await dismissedAllAlert();
      if(res.data.status_code === 0) {
        Object.keys(allAlertList).map((item) => {
          const val = parseInt(item);
          const updateAlertList:IAlert[] = [...allAlertList];
          if(updateAlertList[val].status === 1) {
            updateAlertList[val].status=0;
          }
          setActiveAlertTimePages(new Date().getMilliseconds());
          return item;
        });
        setFilterLoading(true);
        getAllAlert(getApiParams());
        setLoading(false);
        notificationRef.current({ type: 'success', message: tRef.current('All alerts dismissed successfully') });
      } else {
        notificationRef.current({ type: 'error', message: tRef.current('Failed to dismiss alert') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to dismiss alert') });
    }
    setLoadingSpinner(false);
  },[allAlertList, getAllAlert, getApiParams, setActiveAlertTimePages]);

  const handleActive = useCallback(async(id:string | number) =>{
    try {
      const dismissArr: IAllAlertDismissedData[] = [];
      const item ={
        id: id,
        status: 0
      };
      dismissArr.push(item);
        
      const payload: IAllAlertDismissPayload ={
        action: 'update_status',
        data: dismissArr
      };
      isStatusFunctionActive=true;
      //setFilterLoading(true);
      const res = await dismissedSingleAllAlert(payload);
      if(res.data.status_code === 0) {
        Object.keys(allAlertList).map((item:string) => {
          const val = parseInt(item);
          if(allAlertList[val].id === id) {
            const updateAlertList:IAlert[] = [...allAlertList];
            updateAlertList[val].status=0;
            setAllAlertList(updateAlertList);
            setActiveAlertTimePages(new Date().getMilliseconds());
          }
          return item;
        });
        setLoading(false);
      } else {
        notificationRef.current({ type: 'error', message: tRef.current('Failed to change alert status') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
    }
  },[allAlertList,setActiveAlertTimePages]);

  const handleDismissed = useCallback(async(id:string | number) =>{
    try {
      const dismissArr: IAllAlertDismissedData[] = [];
      const item ={
        id: id,
        status: 1
      };
      dismissArr.push(item);
        
      const payload: IAllAlertDismissPayload ={
        action: 'update_status',
        data: dismissArr
      };

      isStatusFunctionActive=true;
      //setFilterLoading(true);
      const res = await dismissedSingleAllAlert(payload);
      if(res.data.status_code === 0) {
        Object.keys(allAlertList).map((item:string) => {
          const val = parseInt(item);
          if(allAlertList[val].id === id) {
            const updateAlertList:IAlert[] = [...allAlertList];
            updateAlertList[val].status=1;
            setAllAlertList(updateAlertList);
            setActiveAlertTimePages(new Date().getMilliseconds());
          }
          return item;
        });
        setLoading(false);
      } else {
        notificationRef.current({ type: 'error', message: tRef.current('Failed to change alert status') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
    }
  },[ allAlertList, setActiveAlertTimePages]);

  const handleAlertStatus = useCallback((id:string | number) =>{
    const oneItem = allAlertList.filter((item, i)=>{
      return id === i;
    });
    if(oneItem){
      const dismiss_id = document.getElementById('status_1' + id);
      const active_id = document.getElementById('status_2' + id);
      dismiss_id && (dismiss_id.style.backgroundColor = ' #e4edf4');
      dismiss_id && (dismiss_id.style.fontStyle = 'normal');
      dismiss_id && (dismiss_id.style.fontWeight = '700');
      dismiss_id && (dismiss_id.innerText = t('Mark Active'));
      active_id && (active_id.style.backgroundColor = ' #e4edf4');
      active_id && (active_id.style.fontStyle = 'normal');
      active_id && (active_id.style.color = '#9097a8');
      active_id && (active_id.style.fontWeight = 'normal');
      active_id && (active_id.innerText = t('Dismiss'));
    }
  },[allAlertList, t]);
  const handleHover = useCallback((id:string | number) => {
    const oneItem = allAlertList.filter((item, i)=>{
      return id === i;
    });
    if(oneItem){
      const dismiss_id = document.getElementById('status_1' + id);
      const active_id = document.getElementById('status_2' + id);
      dismiss_id && (dismiss_id.style.backgroundColor = '');
      dismiss_id && (dismiss_id.style.fontStyle = 'normal');
      dismiss_id && (dismiss_id.style.color = '#9097a8');
      dismiss_id && (dismiss_id.style.fontWeight = 'normal');
      dismiss_id && (dismiss_id.innerText = tRef.current('Dismissed'));

      active_id && (active_id.style.backgroundColor = '');
      active_id && (active_id.style.fontStyle = 'bold');
      active_id && (active_id.style.fontWeight = '700');
      active_id && (active_id.style.color = 'rgb(255, 90, 90)');
      active_id && (active_id.innerText = tRef.current('Active'));
    }
  },[allAlertList,]);

  const getStatus = useCallback((item) =>{
    return(
      <>
        {
          item.status === 0 ?
            <StatusButton key={item.id} value={item.id} id={'status_1'+item.id} onMouseOver={()=> handleAlertStatus( item.id)} onMouseOut={()=>handleHover(item.id)} onClick={()=>handleDismissed(item.id)}>{tRef.current('Dismissed')}</StatusButton> :
            item.status===1 ? 
              <StatusActiveButton key={item.id} id={'status_2'+item.id} onMouseOver={()=> handleAlertStatus(item.id)} onMouseOut={()=>handleHover(item.id)} onClick={()=>handleActive(item.id)}>{tRef.current('Active')}</StatusActiveButton>
              : <IgnoredButton key={item.id}>{tRef.current('Ignored')}</IgnoredButton>
        }
      </>
    );
  },[handleActive, handleAlertStatus, handleDismissed, handleHover,]);

  const getAlertTableColumns = useCallback((item, imgUrl) =>{
    let messageTitle = '', messageBody = '';

    if (item.message !== undefined && (item.message as string).includes('#')) {
      const messageArr = (item.message as string).split('#');
      messageTitle = messageArr[0];
      messageBody = messageArr[1];
    }

    return(
      [
        {customComponent:
          (item.message !== undefined && (item.message as string).includes('#')) ?
            <MessageContainer>
              <MessageTitle>{messageTitle}</MessageTitle>
              <TextDiv maxWidth='calc(100% - 20px)'>{messageBody}</TextDiv>
            </MessageContainer>
            : <TextDiv maxWidth='calc(100% - 20px)'>{t(item.message)}</TextDiv>
        },
        {customComponent: <Title key={item.id} title={item.display_name} width='100px'>{item.display_name}</Title>},
        {text: formatDate(item.timestamp, DATE_FORMAT_WITHOUT_SEC) + ' JST'},
        {customComponent: getStatus(item)},
        {customComponent: getAction(imgUrl, item.video_name, item.timestamp, item.display_name, item.alert_type)}
      ]
    );
  },[t, getAction, getStatus]);

  useEffect(()=>{
    let countForActive=0;
    let countForDismiss=0;
    allAlertList.forEach((item)=>{
      selectedData.forEach((data)=>{
        if(item.id===data.id){
          if(item.status===1){
            countForActive=countForActive+1;
          }
          if(item.status===0){
            countForDismiss=countForDismiss+1;
          }
          setDismissCount(countForDismiss);
          setActiveCount(countForActive);
        }
      });
    });
    
  },[selectedData, allAlertList]);

  const generateRowData = useCallback((): ITypeTableData => {
    const _rows: ITypeTableData = allAlertList.map((item) => {
      const imgUrl = `${BASE_API_URL}/alerts/images/${item.id}?access_token=${token}`;
      const _item: IRowData = {
        id: item.id,
        _checked: selectedStreams[item.id] || false ,
        header:{
          image: imgUrl,
          mediaUrl: imgUrl,
          mediaType: 'img'
        },
        columns: getAlertTableColumns(item, imgUrl)
      };
      return _item;
    });
    return _rows;
  }, [ token, allAlertList, getAlertTableColumns, selectedStreams]);

  useEffect(() => {
    setRowData(generateRowData());
  }, [generateRowData]);
  
  useEffect(() => {
    if (localFilterValues.current !== null || defaultFilterState.current.paramsLoaded === true) return;
    const filterValues = readParams(historyParams, defaultFilterState.current);
    setFilterValues(filterValues);
    localStorage.setItem('filterValuesAllAlerts', JSON.stringify(filterValues));
  }, [historyParams]);

  useEffect(() => {
    if (!filterValues.paramsLoaded) return;
    push({ search: updateParams(filterValues) });
  }, [push, filterValues]);

  const handleFilter = useCallback((res: IFilterResult[]) => {
    setFilterLoading(true);
    const searchFilter:IAppFilter = {
      alertStatus: undefined,
      analysisName: undefined,
      cameraName: undefined,
      startDate: undefined,
      endDate: undefined,
    };
    res.forEach((item) => {
      if(item.id === 'datePickerForRuntime') {
        searchFilter.startDate = format((item.selected as DateInterval).start, (DATE_FORMAT_DASH_WITHOUT_SEC + ':00'));
        searchFilter.endDate = format((item.selected as DateInterval).end, (DATE_FORMAT_DASH_WITHOUT_SEC + ':59'));
      } else {
        searchFilter[item.id] = (item.selected as IFilterItem).value as string;
      }
    });
    setFilterValues(prev => {
      const filterValues = {...searchFilter, pageSize: prev.pageSize, sortOrder: prev.sortOrder, sortBy: prev.sortBy, paramsLoaded: true};
      localStorage.setItem('filterValuesAllAlerts', JSON.stringify(filterValues));
      return filterValues;
    });
    setCurrentPage(1);
  },[]);

  const updateValues = useCallback(() => {
    setLoading(false);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (selectRow > 0) {
      setFinish(false);
    } else {
      setFinish(true);
    }
  }, [selectRow]);

  const selectCallback = useCallback((checked: boolean, id?: string | number) => {
    if(id){
      setSelectedStreams((selectedStreams) => ({ ...selectedStreams, [id]: checked }));
    }
    const newRows = [...rowData];
    const targetRowIndex = newRows.findIndex((row) => row.id === id);
    newRows[targetRowIndex]._checked = checked;
    setRowData(newRows);
    if(checked){
      setSelectRow(selectRow+1);
      setSeletedData([...selectedData,newRows[targetRowIndex]]);
    }else{
      setSelectRow(selectRow-1);
      setSeletedData(prev => prev.filter((item)=>{
        return item.id !== newRows[targetRowIndex].id;
      }));
    }
  },[ rowData, setRowData, selectRow, selectedData]);
  
  const toggleAllCallback = useCallback((checked: boolean) => {
    allAlertList.forEach(({id}) => {
      if(id){
        setSelectedStreams((selectedStreams) => ({ ...selectedStreams, [id]: checked }));
      }
    });
    const newRows = [...rowData];
    const alldata:ITypeTableData = newRows.map((row) => {
      row._checked = checked;
      return row;
    });
    setRowData(newRows);
    if(checked){
      setSelectRow(alldata.length);
      setSeletedData(alldata);
    }else{
      setSelectRow(0);
      setSeletedData([]);
      setSelectedStreams({});
    }
  },[rowData, setRowData, allAlertList]);

  const selectedDownloadVideo = useCallback(async() =>{
    try {
      const dismissArr: number[] = [];
      const selectedRow = [...selectedData];
      selectedRow.map((item)=>{
        dismissArr.push(item.id as number);
        return item;
      });
      const payload: IDownloadVideoPayload ={
        action: 'mp4_download',
        data: dismissArr
      };
      const resData = await downloadVideo('all_alerts_video_download', payload);
      const downloadStatus = await downloadZipFile('/data/downloads/' + resData?.data?.download_filename);
      if(downloadStatus){
        notificationRef.current({ type: 'success', message: tRef.current('Downloading started successfully') });
      } else {
        notificationRef.current({ type: 'error', message: tRef.current('Fail to download') });
      }

      const newRows = [...rowData];
      newRows.forEach((row) => {
        row._checked = false;
      });
      setRowData(newRows);
      setSelectRow(0);
      setSeletedData([]);
      setSelectedStreams({});
      return true;
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
    }
    setSeletedData([]);
    setSelectedStreams({});
  },[ selectedData, rowData]);

  const onDownloadAlerts = useCallback(async () => {
    try {
      setIsDownloadStarted(true);
  
      const selectedRow = selectedData.map((item)=>{
        return item.id as number;
      });
      const payload: IAlertDownloadPayload ={
        language: i18n.language === 'ja' ? 'jp' : 'en',
        alerts_ids: selectedRow
      };
  
      const resData = await downloadSelectedAlerts(payload);

      if (resData === undefined || resData.data === undefined || resData.data.data === undefined) {
        setIsDownloadStarted(false);
        notificationRef.current({ type: 'error', message: tRef.current('Failed to download') });
        return;
      }

      if (resData.data.message === 'Success') {
        setIsDownloadStarted(false);
        notificationRef.current({ type: 'success', message: tRef.current('Downloading started successfully') });
        const downloadStatus = await downloadFile(resData.data.data);
        
        if(downloadStatus){
          toggleAllCallback(false);
        } else {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to download') });
        }
      } else {
        setIsDownloadStarted(false);
        notificationRef.current({ type: 'error', message: tRef.current((resData.data.message + '').replace('.', '')) });
      }
    } catch (error) {
      console.error(error);
      setIsDownloadStarted(false);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
    }
  }, [selectedData, toggleAllCallback]);

  const selectRowDismiss = useCallback(async() =>{
    try {
      const dismissArr: IAllAlertDismissedData[] = [];
      const selectedRow = [...selectedData];
      selectedRow.map((item)=>{
        const data ={
          id: item.id,
          status: 0
        };
        dismissArr.push(data);
        return item;
      });
      const payload: IAllAlertDismissPayload ={
        action: 'update_status',
        data: dismissArr
      };
      const res = await dismissedSingleAllAlert(payload);
      if(res.data.status_code === 0) {
        const updateSelectRow = [...allAlertList];
        Object.keys(updateSelectRow).map((item:string) => {
          const val = parseInt(item);
          Object.keys(selectedData).map((data:string)=>{
            const val2 = parseInt(data);
            if(updateSelectRow[val].id===selectedData[val2].id){
              const updateAlertList: IAlert[] = [...allAlertList];
              if(updateAlertList[val].status === 1) {
                updateAlertList[val].status=0;
              }
              setActiveAlertTimePages(new Date().getMilliseconds());
            }
            return data;
          });
          return item;
        });
        setSelectRow(0);
        setFilterLoading(true);
        getAllAlert(getApiParams());
        setLoading(false);
        notificationRef.current({ type: 'success', message: tRef.current('Alerts dismissed successfully') });
      } 
      else {
        notificationRef.current({ type: 'error', message: tRef.current('Failed to dismiss alert') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to dismiss alert') });
    }
    setSeletedData([]);
    setSelectedStreams({});
  },[ selectedData, allAlertList, getAllAlert, getApiParams, setActiveAlertTimePages]);

  const selectRowActive = useCallback(async() =>{
    try {
      const dismissArr: IAllAlertDismissedData[] = [];
      const selectedRow = [...selectedData];
      selectedRow.map((item)=>{
        const data ={
          id: item.id,
          status: 1
        };
        dismissArr.push(data);
        return item;
      });
      const payload: IAllAlertDismissPayload ={
        action: 'update_status',
        data: dismissArr
      };
      const res = await dismissedSingleAllAlert(payload);
      if(res.data.status_code === 0) {
        
        const updateSelectRow = [...allAlertList];
        Object.keys(updateSelectRow).map((item:string) => {
          const val = parseInt(item);
          Object.keys(selectedData).map((data:string)=>{
            const val2 = parseInt(data);
            if(updateSelectRow[val].id===selectedData[val2].id){
              const updateAlertList: IAlert[] = [...allAlertList];
              if(updateAlertList[val].status === 0) {
                updateAlertList[val].status=1;
              }
              setActiveAlertTimePages(new Date().getMilliseconds());
            }
            return data;
          });
          return item;
        });
        setSelectRow(0);
        setFilterLoading(true);
        getAllAlert(getApiParams());
        setLoading(false);
        notificationRef.current({ type: 'success', message: tRef.current('Alerts Active successfully') });
      } 
      else {
        notificationRef.current({ type: 'error', message: tRef.current('Failed to dismiss alert') });
      }
    } catch(err) {
      console.error(err);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to dismiss alert') });
    }
    setSeletedData([]);
    setSelectedStreams({});
  },[ selectedData, allAlertList, getAllAlert, getApiParams, setActiveAlertTimePages]);

  const dateConfig:IFilterDatePicker[] = [
    {
      buttonIcon: 'DateTime',
      buttonText: tRef.current('Date Range'),
      dateMode: 'interval',
      id: 'datePickerForRuntime',
      timeMode: 'interval',
      lang: i18n.language === 'ja' ? 'ja' : 'en',
      timeZoneTitle: tRef.current('Timezone'),
      dateTimeTextUpper: tRef.current('From'),
      dateTimeTextLower: tRef.current('To'),
      selected: getSelectedDate({start: filterValues.startDate, end: filterValues.endDate}) as DateInterval | undefined,
    },
  ];

  const dropdownConfig: IFilterDropdownConfig[] = [
    {
      id: 'analysisName',
      buttonText: t('AnalysisType').replace('Analysis', ''),
      list: analysisTypeList.map(({text, value}) => ({text : t(text), value})) as IFilterItem[],
      buttonIcon: 'Analyse',
      optionType:'radio',
      selected: getSelectedFilter(filterValues.analysisName as number, 'type') as IFilterValue | undefined
    },
    {
      id: 'alertStatus',
      buttonText: t('Alert Status'),
      list: alertStatusList.map(({text, value}) => ({text : t(text), value})) as IFilterItem[],
      buttonIcon: 'Notifications',
      optionType:'radio',
      selected: getSelectedFilter( filterValues.alertStatus as number, 'status') as IFilterValue | undefined
    },
  ];

  const getDropdownConfig = () => {
    return dropdownConfig.filter((item) => {
      return item.id === 'analysisName' || item.id === 'alertStatus' || item.id === 'datePickerForRuntime';
    });
  };

  const getSearchConfig = (): ISearchFilter[] => {
    const searchedString = filterValues.cameraName as string; 
    const searchConfig: ISearchFilter[] = [
      {
        id: 'cameraName',
        placeholder: tRef.current('Search by Name...'),
        name: tRef.current('Camera Name'),
        selected: {text: searchedString, value: searchedString}
      }
    ];
    return searchConfig;
  };

  const onSizePerPageChange = useCallback((size: number) => {
    setFilterLoading(true);
    setSeletedData([]);
    setSelectedStreams({});
    setSelectRow(0);
    setPageSize(size);
    setFilterValues(prev => {
      const filterValues = {...prev, pageSize: size, paramsLoaded: true};
      localStorage.setItem('filterValuesAllAlerts', JSON.stringify(filterValues));
      return filterValues;
    });
    updateValues();
  }, [updateValues]);

  useEffect(() => {
    if (filterValues.pageSize) {
      setPageSize(filterValues.pageSize as number);
    }
  }, [filterValues.pageSize]);

  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
    setSeletedData([]);
    setSelectedStreams({});
    setSelectRow(0);
  }, []);

  const getLoadingScreen = () => (
    <SpinnerContainer>
      <Spinner size='large' styling='primary' />
      <ResultText>{t('Loading')}</ResultText>
    </SpinnerContainer>
  );

  return (
    <>
      {
        loadingData===false ?
          getLoadingScreen() :
          <MainContainer>
            <Container>
              <UpperContainer>
                <PageHeader icon='ProductLogs' title={t('All Alerts')} updateDocTitle={false} />
                <Para>
                  {t('You can search for alerts that have been detected until now.')}
                </Para>
              </UpperContainer>
              {
                (finish === false || selectRow > 0) ?
                  (
                    <>
                      <Action>{t('Actions')}:</Action>
                      <ActionsMainContainer>
                        <Actions>
                          <ActionFinishContainer>
                            <ActionsContainer>
                              {isDownloadStarted ? <DownloadButtonWithLoading loading position='left' design='secondary' disabled>{t('Download')}</DownloadButtonWithLoading>
                                : <DownloadButtonWithIcon position='left' icon='Download' design='secondary' size='small' onClick={onDownloadAlerts}> {t('Download')} </DownloadButtonWithIcon>}
                              {ENABLE_DOWNLOAD_VIDEO ? <VideoButtonWithIcon position='left' icon='DownloadVideo' design='secondary' size='small' disabled={selectRow===0} onClick={selectedDownloadVideo}>{t('Download Video')}</VideoButtonWithIcon> : null}
                              <VideoButtonWithIcon position='left' icon='Notifications' design='secondary' size='small' disabled={activeCount===0 && dismissCount!==0} onClick={()=>selectRowDismiss()}> {t('Dismiss Alert')} </VideoButtonWithIcon>
                              <VideoButtonWithIcon position='left' icon='Notifications' design='secondary' size='small' disabled={activeCount!==0 && dismissCount===0} onClick={()=>selectRowActive()}> {t('Mark Active')} </VideoButtonWithIcon>
                            </ActionsContainer>
                            <FinishButton onClick={()=>handleFinish(rowData)}>{t('Finish')}</FinishButton>
                          </ActionFinishContainer>
                          <BlurredDiv />
                        </Actions>
                      </ActionsMainContainer>
                    </>
                  ) : null
              }
              <AlertHistoryTabContainer>
                {
                  finish===true || selectRow === 0 ?
                    <FilterAndDismiss>
                      <FilterbarWrapper width='760px'> 
                        <FilterBar filtersTitle={t('Filters')+':'} resultTextTemplate={t('Showing Results') + t(' ({X})').replace('{X}', '' + allAlertList.length) + ':'} searchersConfig={getSearchConfig()} dropdownsConfig={getDropdownConfig()} resultsDateFormat={DATE_FORMAT_WITHOUT_SEC} datePickersConfig={dateConfig} totalResults={resultCount} onChangeCallback={handleFilter} clearText={t('CLEAR ALL')} />
                      </FilterbarWrapper>
                      <DismissSortingWrapper>
                        <DismissButtonContainer>
                          {
                            (finish===true || selectRow === 0) ?
                              lodingSpinner ?
                                <DismissLoadingButton loading position='left' design='secondary' size='small'>{t('Dismiss All Alerts')}</DismissLoadingButton>
                                : <ButtonWithIcon design='secondary' icon='Notifications' position='left' size='small' disabled={alertActiveCount===0} onClick={() => {onClickDismissAll();}}> {t('Dismiss All Alerts')} </ButtonWithIcon>
                              : null
                          }
                        </DismissButtonContainer>
                        <SortBox pointerEvent={allAlertList.length === 0 ? 'none' : 'auto'} background={allAlertList.length === 0  ? '0.5' : ''}>
                          <SortDropdown
                            ascendingText={t('Ascending')}
                            buttonText={t(sorting.title)}
                            descendingText={t('Descending')}
                            isSortAscending={sorting.ascending}
                            list={sortByList.map(({title, sort}) => ({text: t(title), value:sort}))}
                            onSelect={handleSort}
                            selected={{ text: sorting.title, value: sorting.sort }}
                          />
                        </SortBox>
                      </DismissSortingWrapper>
                    </FilterAndDismiss>
                    : null
                }
                {
                  (finish === false || selectRow > 0) ?
                    <ResultTextSortContainer>
                      <SelectedResults>{t('Selected {x} of {y} Results').replace('{x}',''+ selectRow).replace('{y}', '' + resultCount)}</SelectedResults>
                      <SortBox pointerEvent={allAlertList.length === 0 ? 'none' : 'auto'} background={allAlertList.length === 0  ? '0.5' : ''}>
                        <SortDropdown
                          ascendingText={t('Ascending')}
                          buttonText={t(sorting.title)}
                          descendingText={t('Descending')}
                          isSortAscending={sorting.ascending}
                          list={sortByList.map(({title, sort}) => ({text: t(title), value:sort}))}
                          onSelect={handleSort}
                          selected={{ text: sorting.title, value: sorting.sort }}
                        />
                      </SortBox>
                    </ResultTextSortContainer>
                    : null
                }
                <TableContainer>
                  <TypeTable columnConfig={columnConfig} rows={rowData.length > 0 ? rowData : [{ columns: [] }]} loadingText={t('Loading')} defaultAscending isLoading={loading || filterLoading} hasThumbnail hasHeaderGroups emptyTableTitle={t('No Alerts Found.')} selectCallback={selectCallback} selectable toggleAllCallback={toggleAllCallback} closeText={t('CLOSE')} />
                </TableContainer>
                {
                  allAlertList.length === 0 ? null :<PaginationContainer> <Pagination showPrevNextButtons pageSizeOptions={sizeOptions} totalPages={totalPages} defaultPage={currentPage} defaultPageSize={pageSize} onPageSizeChange={onSizePerPageChange} onPageChange={onPageChange} pageSizeText={t('Items Per Page')} prevPageText={t('Page')} /></PaginationContainer>
                }
              </AlertHistoryTabContainer>
            </Container>
          </MainContainer>
      }
    </>);
};

export default AllAlerts;
